import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import {Card, CardBody, Col, Row} from "reactstrap";
import Loader from "../common/loader";
import CurrencyInput from "react-currency-input";

const InvoiceDetail = (props) => {

    const [establishments, setEstablishments] = useState([]);
    const [establishment, setEstablishment] = useState(null);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);

    const [invoice, setInvoice] = useState(null);

    const [project, setProject] = useState(null);

    const [editableBillToAddress, setEditableBillToAddress] = useState(false);
    const [editableDeliveryAddress, setEditableDeliveryAddress] = useState(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [showChangeInvoicer, setShowChangeInvoicer] = useState(false);
    const [showChangeExtraInformation, setShowChangeExtraInformation] = useState(false);
    const [showChangeInvoiceDates, setShowChangeInvoiceDates] = useState(false);
    const [showChangeDetails, setShowChangeDetails] = useState(false);


    useEffect(() => {
        API.get('establishments').then(function(response) {
            setEstablishments(response.data);
            API.get('invoice/' + props.match.params.id).then(function(response) {
                

                response.data.invoiceDate = new Intl.DateTimeFormat("fr-CA", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }).format(new Date(response.data.invoiceDate));
                                    response.data.expiryDate = new Intl.DateTimeFormat("fr-CA", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }).format(new Date(response.data.expiryDate));
                response.data.deliveryDate = new Intl.DateTimeFormat("fr-CA", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                }).format(new Date(response.data.deliveryDate));
                setInvoice(response.data);
            });
        });
    }, []);

    useEffect(() => {
        if (invoice !== null) {
            setProject(invoice.project);
            API.get('config/countries').then(function(response) {
                setCountries(response.data);
            });
        }
    }, [invoice]);

    useEffect(() => {
        if (project !== null) {
            setLoading(false);
        }
    }, [project]);
    

    const changeEstablishment = (value) => {
        for (const [index, establishment] of establishments.entries()) {
            if (parseInt(establishment.id) === parseInt(value)) {
                invoice.invoicerAddress.id = establishment.id;
                invoice.invoicerAddress.organisationName = establishment.companyName;
                invoice.invoicerAddress.cbeNumber = establishment.cbeNumber;
                invoice.invoicerAddress.street = establishment.street;
                invoice.invoicerAddress.houseNumber = establishment.houseNumber;
                invoice.invoicerAddress.bus = establishment.bus;
                invoice.invoicerAddress.postalCode = establishment.postalCode;
                invoice.invoicerAddress.city = establishment.city;
                invoice.invoicerAddress.country = establishment.country;
                invoice.invoicerAddress.email = establishment.email;
                invoice.invoicerAddress.phone = establishment.phone;
                invoice.invoicerAddress.mobile = establishment.mobile;
            }
        }
    };

    const addInvoiceItem = () => {
        let tmpInvoiceItems = [...invoice.invoiceItems];
        tmpInvoiceItems.push({description: "", numberOfItems : 1, unitOfItems : "Stuks", unitPrice: 0.00, vatPercentage: "21", vatAmount: 0.00, totalAmount: 0.00 });
        invoice.invoiceItems = tmpInvoiceItems;
    };

    const removeInvoiceItem = (id) => {
        let tmpInvoiceItems = [...invoice.invoiceItems];
        tmpInvoiceItems.splice(id, 1);
        invoice.invoiceItems = tmpInvoiceItems;
    };

    const changeInvoiceItem = (name, value, index) => {
        let tmpInvoice = {...invoice};
        tmpInvoice.invoiceItems[index][name] = value;
        if (name === 'unitPrice' || name === 'numberOfItems' || name === 'vatPercentage') {
            tmpInvoice.invoiceItems[index]['vatAmount'] = tmpInvoice.invoiceItems[index]['vatPercentage'] / 100 * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
            tmpInvoice.invoiceItems[index]['totalAmount'] = (1 + tmpInvoice.invoiceItems[index]['vatPercentage'] / 100) * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        }
        setInvoice(tmpInvoice);
    };

    const changeInvoiceItem2 = (maskedvalue, floatvalue, index) => {
        let tmpInvoice = {...invoice};
        tmpInvoice.invoiceItems[index]['unitPrice'] = floatvalue;

        tmpInvoice.invoiceItems[index]['vatAmount'] = tmpInvoice.invoiceItems[index]['vatPercentage'] / 100 * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        tmpInvoice.invoiceItems[index]['totalAmount'] = (1 + tmpInvoice.invoiceItems[index]['vatPercentage'] / 100) * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
    
        setInvoice(tmpInvoice);
    };

    const changeInvoiceItemNumberOfItems = (maskedvalue, floatvalue, index) => {
        let tmpInvoice = {...invoice};
        tmpInvoice.invoiceItems[index]['numberOfItems'] = floatvalue;

        tmpInvoice.invoiceItems[index]['vatAmount'] = tmpInvoice.invoiceItems[index]['vatPercentage'] / 100 * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        tmpInvoice.invoiceItems[index]['totalAmount'] = (1 + tmpInvoice.invoiceItems[index]['vatPercentage'] / 100) * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        
        setInvoice(tmpInvoice);
    }

    const updateInvoice = () => {
        setLoading(true);
        API.put('invoice/' + invoice.id, {
            invoice
        }).then(function(response) {
            setLoading(false);
            setShowChangeInvoicer(false);
            setShowChangeExtraInformation(false);
            setShowChangeInvoiceDates(false);
            setShowChangeDetails(false);
            setEditableBillToAddress(false);
            setEditableDeliveryAddress(false);
        });
    };

    return (
        <>
            {loading ? <Loader /> : (
                <>
                    <Fragment>
                        {project !== null && invoice !== null ? (
                            <Breadcrumb parent={'Project ' + project.name} parentLink={'/project/detail/' + project.id}>
                                {invoice.draft === true ? ('DRAFT') : ("Factuur " + invoice.invoiceNumber)}
                            </Breadcrumb>
                        ) : null}
                        <div className="container-fluid">
                            <div className="edit-profile">
                                <form onSubmit={() => updateInvoice()}>
                                    {invoice.draft ? (
                                        <>
                                            {establishments.length > 1 ? (
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className={'card'}>
                                                            <div className="card-body">
                                                                <div className={'row form-group'}>
                                                                    <label className={'col-xl-6'}>Factureren in naam van</label>
                                                                    <div className={'col-xl-6'}>
                                                                        <select onChange={this.changeEstablishment}
                                                                                className={'form-control'} name={'establishmentId'}>
                                                                            <option></option>
                                                                            {establishments.map(function (establishment) {
                                                                                return (
                                                                                    <option
                                                                                        value={establishment.id}>{establishment.companyName}</option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}

                                    {invoice.draft !== true ? (
                                        <Card style={{backgroundColor : '#cc0000', color: '#ffffff' }}>
                                            <CardBody>
                                                Deze factuur is niet meer aanmaak (DRAFT). Wanneer een klant deze factuur heeft ontvangen, let er op dat deze nog niet ingeboekt is in hun boekhoudsysteem. Indien ingeboekt, gelieve deze factuur te crediteren en een nieuwe factuur te maken.
                                            </CardBody>
                                        </Card>
                                    ) : null}

                                    <Row>
                                        <Col lg={4}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-8'}>
                                                            <h4>Maker van factuur:</h4>
                                                        </div>
                                                        <div className={'col-sm-4 text-right'}>
                                                            <a onClick={() => setShowChangeInvoicer(!showChangeInvoicer)} href={'#'}><span className={'fa fa-pencil'}></span> Wijzig</a>
                                                        </div>
                                                    </div>
                                                    {/*this.state.invoice.draft === true && */ showChangeInvoicer ? (
                                                        <>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Organisatienaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.organisationName} onChange={({target: {value}}) => {invoice.invoicerAddress.organisationName = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>BTW-nummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.cbeNumber} onChange={({target: {value}}) => {invoice.invoicerAddress.cbeNumber = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Voornaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.firstName} onChange={({target: {value}}) => {invoice.invoicerAddress.firstName = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Naam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.lastName} onChange={({target: {value}}) => {invoice.invoicerAddress.lastName = value; setInvoice({...invoice})}}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Straat</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.street} onChange={({target: {value}}) => {invoice.invoicerAddress.street = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Huisnummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.houseNumber} onChange={({target: {value}}) => {invoice.invoicerAddress.houseNumber = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Bus</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.bus} onChange={({target: {value}}) => {invoice.invoicerAddress.bus = value; setInvoice({...invoice})}}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Postcode</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.postalCode} onChange={({target: {value}}) => {invoice.invoicerAddress.postalCode = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Gemeente</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoice.invoicerAddress.city} onChange={({target: {value}}) => {invoice.invoicerAddress.city = value; setInvoice({...invoice})}} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Land</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <select className={'form-control'} value={invoice.invoicerAddress.country !== undefined && invoice.invoicerAddress.country !== null ? invoice.invoicerAddress.country.id : null} onChange={({target: {value}}) => {invoice.invoicerAddress.country = countries.find(x => x.id == value); setInvoice({...invoice}) }}>
                                                                    <option>---</option>
                                                                    {countries.map((country) => {
                                                                        return (<option key={country.id} value={country.id}>{country.name}</option> )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    invoice.invoicerAddress !== null ? (
                                                        <>
                                                            <strong>{invoice.invoicerAddress.organisationName}</strong>
                                                            <br />{invoice.invoicerAddress.cbeNumber}
                                                            <br />
                                                            <br />{invoice.invoicerAddress.firstName} {invoice.invoicerAddress.lastName}
                                                            <br />{invoice.invoicerAddress.street} {invoice.invoicerAddress.houseNumber} {invoice.invoicerAddress.bus}
                                                            <br />{invoice.invoicerAddress.postalCode} {invoice.invoicerAddress.city}
                                                            <br />{invoice.invoicerAddress.country !== undefined && invoice.invoicerAddress.country !== null ? invoice.invoicerAddress.country.name : ""}
                                                        </>
                                                    ) : null
                                                )}
                                                </div>
                                            </div>
                                        </Col>
                                        <div className={'col-lg-4'}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-8'}>
                                                            <h4>Gefactureerd naar:</h4>
                                                        </div>
                                                        <div className={'col-sm-4 text-right'}>
                                                            <a onClick={() => setEditableBillToAddress(!editableBillToAddress)} href={'#'}><span className={'fa fa-pencil'}></span> Wijzig</a>
                                                        </div>
                                                    </div>
                                                    {editableBillToAddress ? (
                                                        <>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Organisatienaam</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.organisationName} onChange={({target:{value}}) => {invoice.billToAddress.organisationName = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>BTW-nummer</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.cbeNumber} onChange={({target:{value}}) => {invoice.billToAddress.cbeNumber = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Voornaam</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.firstName} onChange={({target:{value}}) => {invoice.billToAddress.firstName = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Naam</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.lastName} onChange={({target:{value}}) => {invoice.billToAddress.lastName = value; setInvoice({...invoice})}}/>
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Straat</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.street} onChange={({target:{value}}) => {invoice.billToAddress.street = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Huisnummer</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.houseNumber} onChange={({target:{value}}) => {invoice.billToAddress.houseNumber = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Bus</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.bus} onChange={({target:{value}}) => {invoice.billToAddress.bus = value; setInvoice({...invoice})}}/>
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Postcode</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.postalCode} onChange={({target:{value}}) => {invoice.billToAddress.postalCode = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Gemeente</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.billToAddress.city} onChange={({target:{value}}) => {invoice.billToAddress.city = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Land</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                <select className={'form-control'} value={invoice.billToAddress.country !== undefined && invoice.billToAddress.country !== null ? invoice.billToAddress.country.id : null} onChange={({target: {value}}) => {invoice.billToAddress.country = countries.find(x => x.id == value); setInvoice({...invoice}) }}>
                                                                    <option>---</option>
                                                                    {countries.map((country) => {
                                                                        return (<option key={country.id} value={country.id}>{country.name}</option> )
                                                                    })}
                                                                </select>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>{invoice.billToAddress.organisationName}</strong>
                                                            <br />{invoice.billToAddress.cbeNumber}
                                                            <br />
                                                            <br />{invoice.billToAddress.firstName} {invoice.billToAddress.lastName}
                                                            <br />{invoice.billToAddress.street} {invoice.billToAddress.houseNumber} {invoice.billToAddress.bus}
                                                            <br />{invoice.billToAddress.postalCode} {invoice.billToAddress.city}
                                                            <br />{invoice.billToAddress.country !== undefined && invoice.billToAddress.country !== null ? invoice.billToAddress.country.name : ""}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col-lg-4'}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-8'}>
                                                            <h4>Diensten geleverd aan:</h4>
                                                        </div>
                                                        <div className={'col-sm-4 text-right'}>
                                                            <a onClick={() => setEditableDeliveryAddress(!editableDeliveryAddress)} href={'#'}><span className={'fa fa-pencil'}></span> Wijzig</a>
                                                        </div>
                                                    </div>
                                                    {editableDeliveryAddress ? (
                                                        <>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Organisatienaam</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.organisationName} onChange={({target:{value}}) => {invoice.deliveryAddress.organisationName = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Voornaam</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.firstName} onChange={({target:{value}}) => {invoice.deliveryAddress.firstName = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Naam</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.lastName} onChange={({target:{value}}) => {invoice.deliveryAddress.lastName = value; setInvoice({...invoice})}}/>
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Straat</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.street} onChange={({target:{value}}) => {invoice.deliveryAddress.street = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Huisnummer</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.houseNumber} onChange={({target:{value}}) => {invoice.deliveryAddress.houseNumber = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Bus</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.bus} onChange={({target:{value}}) => {invoice.deliveryAddress.bus = value; setInvoice({...invoice})}}/>
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Postcode</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.postalCode} onChange={({target:{value}}) => {invoice.deliveryAddress.postalCode = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Gemeente</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                    <input type={'text'} className={'form-control'} value={invoice.deliveryAddress.city} onChange={({target:{value}}) => {invoice.deliveryAddress.city = value; setInvoice({...invoice})}} />
                                                                </div>
                                                            </div>
                                                            <div className={'form-group'}>
                                                                <div className={'col-sm-12'}>
                                                                    <label>Land</label>
                                                                </div>
                                                                <div className={'col-sm-12'}>
                                                                <select className={'form-control'} value={invoice.deliveryAddress.country !== undefined && invoice.deliveryAddress.country !== null ? invoice.deliveryAddress.country.id : null} onChange={({target: {value}}) => {invoice.deliveryAddress.country = countries.find(x => x.id == value); setInvoice({...invoice}) }}>
                                                                    <option>---</option>
                                                                    {countries.map((country) => {
                                                                        return (<option key={country.id} value={country.id}>{country.name}</option> )
                                                                    })}
                                                                </select>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>{invoice.deliveryAddress.organisationName}</strong>
                                                            <br />{invoice.deliveryAddress.cbeNumber}
                                                            <br />
                                                            <br />{invoice.deliveryAddress.firstName} {invoice.deliveryAddress.lastName}
                                                            <br />{invoice.deliveryAddress.street} {invoice.deliveryAddress.houseNumber} {invoice.deliveryAddress.bus}
                                                            <br />{invoice.deliveryAddress.postalCode} {invoice.deliveryAddress.city}
                                                            <br />{invoice.deliveryAddress.country !== undefined && invoice.deliveryAddress.country !== null ? invoice.deliveryAddress.country.name : ""}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-8'}>
                                                            <h4>Factuurdata:</h4>
                                                        </div>
                                                        <div className={'col-sm-4 text-right'}>
                                                            <a onClick={() => setShowChangeInvoiceDates(!showChangeInvoiceDates)} href={'#'}><span className={'fa fa-pencil'}></span> Wijzig</a>
                                                        </div>
                                                    </div>
                                                    {showChangeInvoiceDates ? (
                                                        <>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Factuurdatum:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'date'} className={'form-control'} name={'invoiceDate'} value={invoice.invoiceDate} onChange={({target: {value}}) => {invoice.invoiceDate = value; setInvoice({...invoice}) }} />
                                                                </Col>
                                                            </Row>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Vervaldatum:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'date'} className={'form-control'} name={'expiryDate'} value={invoice.expiryDate} onChange={({target: {value}}) => {invoice.expiryDate = value; setInvoice({...invoice}) }} />
                                                                </Col>
                                                            </Row>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Leveringsdatum:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'date'} className={'form-control'} name={'deliveryDate'} value={invoice.deliveryDate} onChange={({target: {value}}) => {invoice.deliveryDate = value; setInvoice({...invoice}) }} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>Factuurdatum: </strong>{new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(invoice.invoiceDate))} <br />
                                                            <strong>Vervaldatum: </strong>{new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(invoice.expiryDate))} <br />
                                                            <strong>Leveringsdatum: </strong>{new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(invoice.deliveryDate))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={8}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-8'}>
                                                            <h4>Extra informatie:</h4>
                                                        </div>
                                                        <div className={'col-sm-4 text-right'}>
                                                            <a onClick={() => setShowChangeExtraInformation(!showChangeExtraInformation)} href={'#'}><span className={'fa fa-pencil'}></span> Wijzig</a>
                                                        </div>
                                                    </div>
                                                    { showChangeExtraInformation ? (
                                                        <>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Externe referentie 1:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'text'} className={'form-control'} name={'externalReference1'} value={invoice.externalReference1} onChange={({target: {value}}) => {invoice.externalReference1 = value; setInvoice({...invoice}) }} />
                                                                </Col>
                                                            </Row>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Externe referentie 2:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'text'} className={'form-control'} name={'externalReference2'} value={invoice.externalReference2} onChange={({target: {value}}) => {invoice.externalReference2 = value; setInvoice({...invoice}) }} />
                                                                </Col>
                                                            </Row>
                                                            <Row className={'form-group'}>
                                                                <Col lg={'3'}>
                                                                    Externe referentie 3:
                                                                </Col>
                                                                <Col lg={'9'}>
                                                                    <input type={'text'} className={'form-control'} name={'externalReference3'} value={invoice.externalReference3} onChange={({target: {value}}) => {invoice.externalReference3 = value; setInvoice({...invoice}) }} />
                                                                </Col>
                                                            </Row>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <CKEditors
                                                                        activeclassName="p10"
                                                                        content={invoice.extraInformation}
                                                                        events={{
                                                                            "change": ({editor}) => invoice.extraInformation = editor.getData()
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!_.isEmpty(invoice.externalReference1) ? (
                                                                <>
                                                                    Externe referentie 1: {invoice.externalReference1} <br />
                                                                </>
                                                            ) : null}
                                                            {!_.isEmpty(invoice.externalReference2) ? (
                                                                <>
                                                                    Externe referentie 2: {invoice.externalReference2} <br />
                                                                </>
                                                            ) : null}
                                                            {!_.isEmpty(invoice.externalReference3) ? (
                                                                <>
                                                                    Externe referentie 3: {invoice.externalReference3} <br />
                                                                </>
                                                            ) : null}
                                                            {!_.isEmpty(invoice.extraInformation) ? (
                                                                <>
                                                                    {invoice.extraInformation}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Geen extra informatie beschikbaar
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <div className={"card"}>
                                                <div className={'card-body'}>
                                                    <div className={'form-group row'}>
                                                        <div className={'col-sm-8'}>
                                                            <h4>Detaillijnen:</h4>
                                                        </div>
                                                        <div className={'col-sm-4 text-right'}>
                                                            <a onClick={() => setShowChangeDetails(!showChangeDetails)} href={'#'}><span className={'fa fa-pencil'}></span> Wijzig</a>
                                                        </div>
                                                    </div>
                                                    {showChangeDetails ? (
                                                        <>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <div className={'row form-group'}>
                                                                        <div className={'col-xl-4'}>Beschrijving</div>
                                                                        <div className={'col-xl-1'}>Aantal</div>
                                                                        <div className={'col-xl-1'}>Eenheid</div>
                                                                        <div className={'col-xl-1'}>Eenheidsprijs</div>
                                                                        <div className={'col-xl-1'}>BTW %</div>
                                                                        <div className={'col-xl-1'}>BTW bedrag</div>
                                                                        <div className={'col-xl-1'}>Totaal</div>
                                                                        <div className={'col-xl-1'}></div>
                                                                        <div className={'col-xl-1'}></div>
                                                                    </div>
                                                                    {invoice.invoiceItems.map((x,i) => {
                                                                        return (
                                                                            <div className={'row form-group'}>
                                                                                <label className={'col-xl-5'}>
                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <CurrencyInput decimalSeparator="," thousandSeparator="." allowNegative="true" className={'form-control'} name={'numberOfItems'} value={x.numberOfItems}  onChangeEvent={(e, maskedvalue, floatvalue) => changeInvoiceItemNumberOfItems(maskedvalue, floatvalue, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <input className={'form-control'} name={'unitOfItems'} value={x.unitOfItems} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <CurrencyInput decimalSeparator="," thousandSeparator="."  className={'form-control'} name={'unitPrice'} value={x.unitPrice} onChangeEvent={(e, maskedvalue, floatvalue, index) => changeInvoiceItem2(maskedvalue, floatvalue, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    <input className={'form-control'} name={'vatPercentage'} value={x.vatPercentage} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.vatAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.totalAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {invoice.invoiceItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeInvoiceItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                </label>
                                                                            
                                                                            </div>
                                                                        );
                                                                    })}
                                                                    <div className='row'>
                                                                        <label className={'col-xl-12 text-right'}>
                                                                            <a href={'#'} className={'btn btn-primary'} onClick={() => addInvoiceItem()}><span className={'fa fa-plus'}></span> Detaillijn toevoegen</a>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <div className={'row form-group'}>
                                                                        <div className={'col-xl-4'}>Beschrijving</div>
                                                                        <div className={'col-xl-1'}>Aantal</div>
                                                                        <div className={'col-xl-1'}>Eenheid</div>
                                                                        <div className={'col-xl-1'}>Eenheidsprijs</div>
                                                                        <div className={'col-xl-1'}>BTW %</div>
                                                                        <div className={'col-xl-1'}>BTW bedrag</div>
                                                                        <div className={'col-xl-1'}>Totaal</div>
                                                                        <div className={'col-xl-1'}></div>
                                                                        <div className={'col-xl-1'}></div>
                                                                    </div>
                                                                    {invoice.invoiceItems.map((x,i) => {
                                                                        return (
                                                                            <div className={'row form-group'}>
                                                                                <label className={'col-xl-4'}>
                                                                                    {x.description}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {x.numberOfItems}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {x.unitOfItems}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.unitPrice)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {x.vatPercentage}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.vatAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.totalAmount)}
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                </label>
                                                                                <label className={'col-xl-1'}>
                                                                                </label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-12'}>
                                                                    <h4 style={{textAlign: 'right'}}>Totaal: {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(invoice.invoiceItems.reduce((totalPrice, invoiceItem) => totalPrice + parseFloat(invoiceItem.totalAmount),0))}</h4>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className={'card'}>
                                                <div className={'card-body'}>
                                                    <div className="card-footer text-right">
                                                        <button className="btn btn-primary" type="submit"
                                                                onClick={() => updateInvoice()}>Factuur wijzigen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Fragment>
                </>
            )}     
        </>
    );

}

export default InvoiceDetail;