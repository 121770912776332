import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import _ from "lodash";
import useReactTable from '../../hooks/useReactTable';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const PostalCodesOverview = () =>  {

    const [postalCodes, setPostalCodes] = useState([]);
    const [city, setCity] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const columns = useMemo(() => [
        {
            Header : 'Postcode',
            accessor : 'postalCode',
            headerClassName: 'text-left'
        },
        {
            Header : 'Gemeente',
            accessor : 'city',
            headerClassName: 'text-left'
        },
        {
            Header : '',
            accessor : 'id',
            width: 100,
            Cell: row => <Button className='btn btn-primary' onClick={() => deletePostalCode(row.row.id)}><i className="icofont icofont-2x icofont-close"></i></Button>
        }
    ]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();

    useEffect(() => {
        loadPostalCodes();
    }, []);

    const loadPostalCodes = () => {
        API.get('postalcodes').then(function(response) {
            setPostalCodes(response.data);
        });
    };

    const createNewPostalCode = () => {
        API.post('postalcode', {
            city,
            postalCode
        }).then(function(response) {
            setCity("");
            setPostalCode("");
            loadPostalCodes();
        })
    };

    const deletePostalCode = (id) => {
        API.delete('postalcode/' + id).then(function() {
            loadPostalCodes();
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Regio's" title="Overzicht van alle regio's" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <Row>
                        <Col lg={12}>
                            <ReactTable 
                                data={postalCodes}
                                columns={columns}
                                minRows={0}
                                className={"-striped -highlight"}
                                showPagination={true}
                                previousText={'Vorige'}
                                nextText={'Volgende'}
                                pageText={'Pagina'}
                                rowsText={'rijen'}
                                ofText={'van'}
                                getTheadThProps={getTheadThProps}
                                getTdProps={getTdProps}
                                getTrProps={getTrProps}
                                getPaginationProps={getPaginationProps}
                            />
                        </Col>
                    </Row>

                    <Card className='mt-3'>
                        <CardBody>
                            <Row>
                                <Col lg={2}><input placeholder='Postcode' type={'text'} className={'form-control'} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} /></Col>
                                <Col lg={8}><input placeholder='Gemeente' type={'text'} className={'form-control'} value={city} onChange={(e) => setCity(e.target.value)} /></Col>
                                <Col lg={2}><Button onClick={() => createNewPostalCode() }>Creëer nieuwe postcode</Button></Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default PostalCodesOverview;