import React, {Fragment, useEffect, useState} from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import {useParams} from "react-router";
import _ from "lodash";
import Dropzone from "react-dropzone-uploader";


const WorkOrderTemplateDetail = () => {
    const {id} = useParams();
    const [workOrderTemplate, setWorkOrderTemplate] = useState(null);
    const [editableQuestions, setEditableQuestions] = useState([]);

    const changeEditStateOfItem = (arr, itemId) => {
       let listToUpdate = [...arr];
       listToUpdate[itemId] = !listToUpdate[itemId];
       setEditableQuestions(listToUpdate);

       console.log(workOrderTemplate);
    };

    const changeQuestionOfWorkOrderTemplateItem = (template, itemId, newValue) => {
        let newWorkOrderTemplate = {...template};
        newWorkOrderTemplate.items.map((workOrderTemplateItem) => {
            if (workOrderTemplateItem.id === itemId) {
                workOrderTemplateItem.question = newValue;
            }
        });
        setWorkOrderTemplate(newWorkOrderTemplate);
    };
    const changeDescriptionOfWorkOrderTemplateItem = (template, itemId, newValue) => {
        let newWorkOrderTemplate = {...template};
        newWorkOrderTemplate.items.map((workOrderTemplateItem) => {
            if (workOrderTemplateItem.id === itemId) {
                workOrderTemplateItem.description = newValue;
            }
        });
        setWorkOrderTemplate(newWorkOrderTemplate);
    };

    const changeHelpingImageOfWorkOrderTemplateItem = (template, itemId, fileWithMeta, status) => {
        // if (status === 'done') {
        //     let files = this.state.files;
        //     files.push(fileWithMeta);
        //     this.setState((prevState) => ({files: files}));
        // }
        readFile(template, itemId, fileWithMeta.file);
    };

    const saveWorkOrderTemplate = () => {
        API.put('workordertemplate/' + workOrderTemplate.id, workOrderTemplate);
    };

    const readFile = (template, itemId, file) => {
        const reader = new FileReader();
        reader.onload = event => {
            let newFile = {
                fileName : file.name,
                mimeType : file.type,
                fileSize : file.size,
                file : event.target.result
            };
            let newWorkOrderTemplate = {...workOrderTemplate};
            newWorkOrderTemplate.items.map((workOrderTemplateItem) => {
                workOrderTemplateItem.helpingImage = newFile.file;
            });
            setWorkOrderTemplate(newWorkOrderTemplate);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        API.get('workordertemplate/' + id).then(function(response) {
           setWorkOrderTemplate(response.data);
           let newList = [];
           response.data.items.map((item) => {
               newList[item.id] = false;
           });
           setEditableQuestions(newList);
        });
    }, []);
    return (
        workOrderTemplate !== null ? (
            <Fragment>
                <Breadcrumb parent="Sjablonenbeheer voor werkbonnen" title={'Detail van sjabloon ' + workOrderTemplate.name} />
                <div className="container-fluid">
                    <div className="user-profile">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="profile-img-style">
                                        <div className="card-body">
                                            <div className={'row'}>
                                                <div className={'col-md-12'}>
                                                    <div dangerouslySetInnerHTML={{__html: workOrderTemplate.description}}></div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className={'row'}>
                                                <div className={'col-md-12'}>
                                                    <h5>Vragen van het werkbonsjabloon</h5>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col-md-12'}>
                                                    <table className={'table'}>
                                                        <thead>
                                                            <tr>
                                                                <th>Vraag</th>
                                                                <th>Type</th>
                                                                <th>Beschrijving</th>
                                                                <th>Opties</th>
                                                                <th>Hulpafbeelding</th>
                                                                <th>Wijzig</th>
                                                            </tr>
                                                        </thead>
                                                    {workOrderTemplate.items.map((workOrderTemplateItem) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {editableQuestions[workOrderTemplateItem.id] !== undefined && editableQuestions[workOrderTemplateItem.id] === true ? (
                                                                        <input
                                                                            type={'text'}
                                                                            value={workOrderTemplateItem.question}
                                                                            onChange={(event) => {changeQuestionOfWorkOrderTemplateItem(workOrderTemplate, workOrderTemplateItem.id, event.target.value)}}
                                                                        />
                                                                    ) : (
                                                                        <>{workOrderTemplateItem.question}</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {editableQuestions[workOrderTemplateItem.id] !== undefined && editableQuestions[workOrderTemplateItem.id] === true ? (
                                                                        <select className={'form-control'} >
                                                                            <option value={'text'} selected={workOrderTemplateItem.type.id === 2}>Decimaal</option>
                                                                            <option value={'email'} selected={workOrderTemplateItem.type.id === 3}>E-mailadres</option>
                                                                            <option value={'multiplechoice'} selected={workOrderTemplateItem.type.id === 4}>Keuze uit meerdere items</option>
                                                                            <option value={'text'} selected={workOrderTemplateItem.type.id === 1}>Tekst</option>
                                                                            <option value={'phone'} selected={workOrderTemplateItem.type.id === 5}>Telefoonnummer</option>
                                                                        </select>
                                                                    ) : (
                                                                        <>
                                                                            {workOrderTemplateItem.type.name === 'number' ? <>Decimaal</> : ''}
                                                                            {workOrderTemplateItem.type.name === 'email' ? <>E-mailadres</> : ''}
                                                                            {workOrderTemplateItem.type.name === 'text' ? <>Tekst</> : ''}
                                                                            {workOrderTemplateItem.type.name === 'phone' ? <>Telefoonnummer</> : ''}
                                                                            {workOrderTemplateItem.type.name === 'multiplechoice' ? <>Keuze uit meerdere items</> : ''}
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {editableQuestions[workOrderTemplateItem.id] !== undefined && editableQuestions[workOrderTemplateItem.id] === true ? (
                                                                        <input
                                                                            type={'text'}
                                                                            value={workOrderTemplateItem.description}
                                                                            onChange={(event) => {changeDescriptionOfWorkOrderTemplateItem(workOrderTemplate, workOrderTemplateItem.id, event.target.value)}}
                                                                        />
                                                                    ) : (
                                                                        <>{workOrderTemplateItem.description}</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {workOrderTemplateItem.options.map((optionValue, optionIndex) => {
                                                                        return (
                                                                            optionIndex > 0 ? (
                                                                                <span key={optionIndex}> - {optionValue.name}</span>
                                                                            ) : (
                                                                                <span key={optionIndex}>{optionValue.name}</span>
                                                                            )
                                                                        );
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    {editableQuestions[workOrderTemplateItem.id] !== undefined && editableQuestions[workOrderTemplateItem.id] == true ? (
                                                                        <Dropzone
                                                                            onChangeStatus={(fileWithMeta, status) => {changeHelpingImageOfWorkOrderTemplateItem(workOrderTemplate, workOrderTemplateItem.id, fileWithMeta, status)}}
                                                                            maxFiles={1}
                                                                            multiple={false}
                                                                            canCancel={false}
                                                                            canRemove={false}
                                                                            canRestart={false}
                                                                            inputContent="Plaats hier een afbeelding ter verduidelijking van de vraag"
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {_.isEmpty(workOrderTemplateItem.helpingImage) ? "" : (
                                                                                <img src={workOrderTemplateItem.helpingImage} style={{maxHeight: '150px'}} />
                                                                            )  }
                                                                        </>
                                                                    )}

                                                                </td>
                                                                <td><a href={'#'} onClick={() => changeEditStateOfItem(editableQuestions, workOrderTemplateItem.id)}><span className={'fa fa-2x fa-pencil'}></span></a></td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </table>

                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col-md-12'}>
                                                    <a href={'#'} className={'btn btn-primary'} onClick={() => {saveWorkOrderTemplate()}}>Werkbonsjabloon wijzigen</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        ) : null
    );
};

export default WorkOrderTemplateDetail;