import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import {Link} from "react-router-dom";
import _ from "lodash";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Extension, Group, GroupWork, LocationCity, BeachAccess } from '@material-ui/icons';


class ConfigurationOverview extends React.Component {

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="false" title="Instellingen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <Row>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/users/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}} >
                                            <h1><Group fontSize="large" /></h1>
                                            <p>Gebruikersbeheer</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/workordertemplates/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><GroupWork fontSize="large" /></h1>
                                            <p>Sjablonen werkbonnen</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/establishments/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><LocationCity fontSize="large" /></h1>
                                            <p>Mijn vestigingen</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/externalparties/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><Extension fontSize="large" /></h1>
                                            <p>Externe partijen</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/holidays/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><BeachAccess fontSize="large" /></h1>
                                            <p>Verlofdagen</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/config/projectstatusses/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><Extension fontSize="large" /></h1>
                                            <p>Project statussen</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/config/projectflows/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><Extension fontSize="large" /></h1>
                                            <p>Project flows</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card>
                                    <CardBody style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                                        <Link to={'/config/projecttaskboards/overview'} className={'btn'} style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR, backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR + ' !important', width: '100%'}}>
                                            <h1><Extension fontSize="large" /></h1>
                                            <p>Projecttakenborden</p>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default ConfigurationOverview;