import React, { useState, useEffect } from 'react';
import logo_pouillon from '../assets/images/logo-pouillon.png';
import logo_planaxis from '../assets/images/logo_planaxis.png';
import logo_windowrepair from '../assets/images/logo_windowrepair.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from "react-router";
import API from '../utils/API';

const TwoFactorAuthentication = ({ history }) => {
    const [image, setImage] = useState("");
    const [token, setToken] = useState("");

    useEffect(() => {
        API.get('2fa-ga-qr').then(function(response) {
            setImage(response.data);
        });
    }, []);


    const loginAuth = async (e) => {
        e.preventDefault();

        try {
                await fetch(process.env.REACT_APP_BASE_URL + '/api/2fa-check', {
                  method : 'POST',
                  responseType: "json",
                  headers : {
                    'Content-Type': 'application/json',
                    'X-AUTH-TOKEN' : localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN),
                    'Accept' : '*/*',
                    'Access-Control-Allow-Origin' : '*'
                    },
                    body : '{"authCode":"' + token + '"}'
                }).then(function(response) {
                    return response.json();
                }).then(function() {
                    window.location.href = "/";
                });
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                toast.error("Er is een systeemfout opgetreden. Gelieve je systeembeheerder te contacteren.");
            }, 200);
        }
    };


    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body" style={{backgroundColor: process.env.REACT_APP_STYLING_LOGIN_CARD_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_LOGIN_CARD_FONT_COLOR}}>
                                                <div style={{textAlign: "center"}}>
                                                    {window.location.host.includes('planaxis') ? (
                                                        <img src={logo_planaxis} alt="" style={{height: '60px'}} />
                                                    ) : null}
                                                    {window.location.host.includes('windowrepair') ? (
                                                        <img src={logo_windowrepair} alt="" style={{height: '100px'}} />
                                                    ) : null}
                                                    {window.location.host.includes('pouillon') ? (
                                                        <img src={logo_pouillon} alt="" style={{height: '100px'}} />
                                                    ) : null}
                                                    {window.location.host.includes('localhost') ? (
                                                        <img src={logo_pouillon} alt="" style={{height: '100px'}} />
                                                    ) : null}
                                                </div>
                                                <br />
                                                <div>
                                                    <h4>QR Code Two Factor Authentication</h4>
                                                </div>
                                                <form className="theme-form" onSubmit={loginAuth} >

                                                <div className="form-group">
                                                        <label className="col-form-label pt-0">Token</label>
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="token"
                                                            value={token}
                                                            onChange={e => setToken(e.target.value)}
                                                            placeholder="Token"
                                                        />
                                                    </div>
                                                    <img src={'data:image/png;base64,' + image }  />
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit">Inloggen</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};

export default withRouter(TwoFactorAuthentication);