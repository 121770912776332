import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";


class ExternalPartiesOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            externalParties : [],
            columns : [
                {
                    Header : 'Naam',
                    accessor : 'name',
                    width: 250,
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <Link to={`/externalparty/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
                    }
                },
                {
                    id: 'address',
                    Header : 'Adres',
                    headerClassName : 'text-left',
                    accessor : externalParty => `${externalParty.street} ${externalParty.houseNumber} ${externalParty.bus}`
                },
                {
                    Header : 'Postcode',
                    headerClassName : 'text-left',
                    accessor : 'postalCode'
                },
                {
                    Header : 'Gemeente',
                    headerClassName : 'text-left',
                    accessor : 'city'
                },
                {
                    Header : 'Land',
                    headerClassName : 'text-left',
                    accessor : 'country',
                    Cell: row => {
                        if (row.row.country !== undefined && row.row.country !== null) {
                            return <span>{row.row.country.name}</span>
                        } else {
                            return <span></span>
                        }
                    }
                }
            ]
        };
    }

    componentDidMount() {
        var me = this;
        API.get('externalparties').then(function(response) {
            me.setState({externalParties: response.data});
        });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Externe partijen" title="Overzicht van externe partijen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.externalParties}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   minRows={1}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   showPagination={true}
                                   previousText={'Vorige'}
                                   nextText={'Volgende'}
                                   pageText={'Pagina'}
                                   rowsText={'rijen'}
                                   ofText={'van'}
                               />
                            </div>
                        </div>
                        <br />
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Link to={'/externalparty/create'} className={'btn btn-primary'}>Nieuwe externe partij toevoegen</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default ExternalPartiesOverview;