import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";


class WorkOrderTemplatesOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workOrderTemplates : [],
            filtered: [],
            columns : [
                {
                    Header : 'ID',
                    accessor : 'id',
                    width: 50,
                    Cell : row => {
                        return <Link to={`/workordertemplate/detail/${row.row.id}`}>{row.row.id}</Link>
                    }
                },
                {
                    Header : 'Naam',
                    accessor : 'name',
                    width: 250
                },
                {
                    Header : 'Beschrijving',
                    accessor : 'description',
                    Cell : row => {
                        return <>{row.row.description.replace(/<[^>]+>/g, '')}</>
                    }
                }
            ]
        };
    }

    componentDidMount() {
        var me = this;
        API.get('workordertemplates').then(function(response) {
            me.setState({workOrderTemplates: response.data});
        });
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Sjablonen Werkbonnen" title="Overzicht van werkbonsjablonen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.workOrderTemplates}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   minRows={1}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   noDataText={'Geen werkbonnen gevonden'}
                                   getNoDataProps={(state, rowInfo, column) => {
                                       return {
                                           style : {
                                               backgroundColor: 'transparent'
                                           }
                                       }
                                   }}
                               />
                            </div>
                        </div>
                        <Row className={'mt-3'}>
                            <Col lg={12}>
                                <Link className={'btn btn-primary'} to={'/workordertemplates/create'}>Nieuw werkbonsjabloon aanmaken</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default WorkOrderTemplatesOverview;