import React, { Fragment } from 'react';
import _ from "lodash";

class ConfirmCustomerCreation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : props.user
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        {!_.isEmpty(this.state.user) ? (
                            <>
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        Klant met naam {this.state.user.firstName} {this.state.user.lastName} is succesvol aangemaakt. De gegevens van deze klant zijn:
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <h5>Persoonsgegevens</h5>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        Naam:
                                    </div>
                                    <div className={'col-md-9'}>
                                        {this.state.user.firstName} {this.state.user.lastName}
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        Adres:
                                    </div>
                                    <div className={'col-md-9'}>
                                        {this.state.user.street} {this.state.user.houseNumber} {this.state.user.bus}<br />
                                        {this.state.user.postalCode} {this.state.user.city}
                                        {this.state.user.country !== null ? (this.state.user.country.name) : null}
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        Extra informatie:
                                    </div>
                                    <div className={'col-md-9'}>
                                        {this.state.user.extraInformation}
                                    </div>
                                </div>
                                <br />
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <h5>Contactgegevens</h5>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        Primair e-mailadres:
                                    </div>
                                    <div className={'col-md-9'}>
                                        {this.state.user.primaryEmail}
                                    </div>
                                </div>
                                {!_.isEmpty(this.state.user.primaryPhone) ? (
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            Primair tel.:
                                        </div>
                                        <div className={'col-md-9'}>
                                            {this.state.user.primaryPhone}
                                        </div>
                                    </div>
                                ) : null}
                                {!_.isEmpty(this.state.user.primaryMobile) ? (
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            Primair GSM:
                                        </div>
                                        <div className={'col-md-9'}>
                                            {this.state.user.primaryMobile}
                                        </div>
                                    </div>
                                ) : null}
                                {!_.isEmpty(this.state.user.alternativeEmail) ? (
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            Alternatief e-mailadres:
                                        </div>
                                        <div className={'col-md-9'}>
                                            {this.state.user.alternativeEmail}
                                        </div>
                                    </div>
                                ) : null}
                                {!_.isEmpty() ? (
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            Alternatief tel.:
                                        </div>
                                        <div className={'col-md-9'}>
                                            {this.state.user.alternativePhone}
                                        </div>
                                    </div>
                                ) : null}
                                {!_.isEmpty(this.state.user.alternativeMobile) ? (
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            Alternatief GSM:
                                        </div>
                                        <div className={'col-md-9'}>
                                            {this.state.user.alternativeMobile}
                                        </div>
                                    </div>
                                ) : null}
                                <br />
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <h5>Facturatiegegevens</h5>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        Organisatie:
                                    </div>
                                    <div className={'col-md-9'}>
                                        {this.state.user.invoiceData.companyName}
                                        {!_.isEmpty(this.state.user.invoiceData.vatNumber) ? (
                                            <>
                                                <br />BTW-nummer: {this.state.user.invoiceData.vatNumber}
                                            </>
                                        ): null}
                                        {!_.isEmpty(this.state.user.invoiceData.cbeNumber) ? (
                                            <>
                                                <br />Ondernemingsnummer: {this.state.user.invoiceData.cbeNumber}
                                            </>
                                        ): null}

                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        Adres:
                                    </div>
                                    <div className={'col-md-9'}>
                                        {this.state.user.invoiceData.street} {this.state.user.invoiceData.houseNumber} {this.state.user.invoiceData.bus}<br />
                                        {this.state.user.invoiceData.postalCode} {this.state.user.invoiceData.city}
                                        {this.state.user.invoiceData.country !== null ? (this.state.user.invoiceData.country.name) : null}
                                    </div>
                                </div>
                            </>
                        ) : null }
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default ConfirmCustomerCreation;