import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import SweetAlert from "react-bootstrap-sweetalert";

class ExternalPartyCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countries : [],
            showSuccessDialog : false,
            externalParty : {
                name: "",
                street: "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                phone : "",
                mobile : "",
                email : ""
            }
        };
    }

    componentDidMount() {
        let me = this;
        API.get('config/countries').then(function(response) {
            me.setState({countries: response.data});
        });
    }

    changeName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, name: value}}));
    };

    changeStreet = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, street: value}}));
    };

    changeHouseNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, houseNumber: value}}));
    };

    changeBus = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, bus: value}}));
    };

    changePostalCode = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, postalCode: value}}));
    };

    changeCity = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, city: value}}));
    };

    changeCountry = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, country: value}}));
    };

    changePhone = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, phone: value}}));
    };

    changeMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, mobile: value}}));
    };

    changeEmail = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({externalParty: { ...prevState.externalParty, email: value}}));
    };

    createNewExternalParty = (event) => {
        event.preventDefault();
        let me = this;
        API.post('externalparty', {
            name: this.state.externalParty.name,
            street: this.state.externalParty.street,
            houseNumber: this.state.externalParty.houseNumber,
            bus: this.state.externalParty.bus,
            postalCode: this.state.externalParty.postalCode,
            city: this.state.externalParty.city,
            country: this.state.externalParty.country,
            email: this.state.externalParty.email,
            phone: this.state.externalParty.phone,
            mobile: this.state.externalParty.mobile
        }).then(function() {
            me.setState({showSuccessDialog: true});
        });
    };

    navigateToOverview = () => {
      window.location.href = `${process.env.PUBLIC_URL}/externalparties/overview`;
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Mijn externe partijen" title="Nieuwe externe partij toevoegen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                                <form className="card" onSubmit={this.createNewProject}>
                                    <div className="card-body">
                                        <SweetAlert show={this.state.showSuccessDialog} success title="Succesvol toegevoegd" onConfirm={() => this.navigateToOverview()}>
                                            Je externe partij is succesvol toegevoegd!
                                        </SweetAlert>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Naam:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'name'} className={'form-control'} value={this.state.externalParty.name} onChange={this.changeName} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        E-mail:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'email'} className={'form-control'} value={this.state.externalParty.email} onChange={this.changeEmail} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Straat:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'street'} className={'form-control'} value={this.state.externalParty.street} onChange={this.changeStreet} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Huisnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'houseNumber'} className={'form-control'} value={this.state.externalParty.houseNumber} onChange={this.changeHouseNumber} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Bus:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'bus'} className={'form-control'} value={this.state.externalParty.bus} onChange={this.changeBus} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Postcode:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'postalCode'} className={'form-control'} value={this.state.externalParty.postalCode} onChange={this.changePostalCode} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Gemeente:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'city'} className={'form-control'} value={this.state.externalParty.city} onChange={this.changeCity} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Land:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <select onChange={this.changeCountry} className={'form-control'} name={'countryId'}>
                                                            <option value={'0'}>---</option>
                                                            {this.state.countries.map(function(country) {
                                                                return (
                                                                    <option value={country.id}>{country.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Telefoonnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'phone'} className={'form-control'} value={this.state.externalParty.phone} onChange={this.changePhone} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        GSMnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'mobile'} className={'form-control'} value={this.state.externalParty.mobile} onChange={this.changeMobile} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" type="submit" onClick={this.createNewExternalParty}>Nieuwe externe partij toevoegen</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ExternalPartyCreate;