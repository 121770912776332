import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import {Card, CardBody, Col, Row} from "reactstrap";
import Loader from "../common/loader";
import CurrencyInput from "react-currency-input";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader';


const OfferDetail = (props) => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    const [isEditableCreator, setIsEditableCreator] = useState(false);
    const [isEditableCustomer, setIsEditableCustomer] = useState(false);

    const [offer, setOffer] = useState(null);

    const [creatorId, setCreatorId] = useState(null);
    const [creatorCompanyName, setCreatorCompanyName] = useState(null);
    const [creatorCbeNumber, setCreatorCbeNumber] = useState(null);
    const [creatorFirstName, setCreatorFirstName] = useState(null);
    const [creatorLastName, setCreatorLastName] = useState(null);
    const [creatorStreet, setCreatorStreet] = useState(null);
    const [creatorHouseNumber, setCreatorHouseNumber] = useState(null);
    const [creatorBus, setCreatorBus] = useState(null);
    const [creatorPostalCode, setCreatorPostalCode] = useState(null);
    const [creatorCity, setCreatorCity] = useState(null);
    const [creatorCountry, setCreatorCountry] = useState(null);
    const [creatorEmail, setCreatorEmail] = useState(null);
    const [creatorPhone, setCreatorPhone] = useState(null);
    const [creatorMobile, setCreatorMobile] = useState(null);

    const [customerId, setCustomerId] = useState(null);
    const [customerCompanyName, setCustomerCompanyName] = useState(null);
    const [customerCbeNumber, setCustomerCbeNumber] = useState(null);
    const [customerFirstName, setCustomerFirstName] = useState(null);
    const [customerLastName, setCustomerLastName] = useState(null);
    const [customerStreet, setCustomerStreet] = useState(null);
    const [customerHouseNumber, setCustomerHouseNumber] = useState(null);
    const [customerBus, setCustomerBus] = useState(null);
    const [customerPostalCode, setCustomerPostalCode] = useState(null);
    const [customerCity, setCustomerCity] = useState(null);
    const [customerCountry, setCustomerCountry] = useState(null);

    const [extraInformation, setExtraInformation] = useState(null);
    const [externalReference1, setExternalReference1] = useState(null);
    const [externalReference2, setExternalReference2] = useState(null);
    const [externalReference3, setExternalReference3] = useState(null);

    const [offerItems, setOfferItems] = useState([]);

    useEffect(() => {
        API.get('offer/' + props.match.params.id).then(function(response) {
            setOffer(response.data);
        });
    }, []);

    useEffect(() => {
        if (offer !== null) {
            if (offer.creator !== null ) {
                setCreatorId(offer.creator.id);
                setCreatorCompanyName(offer.creator.companyName);
                setCreatorCbeNumber(offer.creator.cbeNumber);
                setCreatorFirstName(offer.creator.firstName);
                setCreatorLastName(offer.creator.lastName);
                setCreatorStreet(offer.creator.street);
                setCreatorHouseNumber(offer.creator.houseNumber);
                setCreatorBus(offer.creator.bus);
                setCreatorPostalCode(offer.creator.postalCode);
                setCreatorCity(offer.creator.city);
                setCreatorCountry(offer.creator.country);
                setCreatorEmail(offer.creator.email);
                setCreatorPhone(offer.creator.phone);
                setCreatorMobile(offer.creator.mobile);
            }
            if (offer.customer !== null) {
                setCustomerId(offer.customer.id);
                setCustomerFirstName(offer.customer.firstName);
                setCustomerLastName(offer.customer.lastName);
                setCustomerCompanyName(offer.customer.companyName);
                setCustomerCbeNumber(offer.customer.cbeNumber);
                setCustomerStreet(offer.customer.street);
                setCustomerHouseNumber(offer.customer.houseNumber);
                setCustomerBus(offer.customer.bus);
                setCustomerPostalCode(offer.customer.postalCode);
                setCustomerCity(offer.customer.city);
                setCustomerCountry(offer.customer.country);
            
            }
            setExtraInformation(offer.extraInformation);
            setExternalReference1(offer.externalReference1);
            setExternalReference2(offer.externalReference2);
            setExternalReference3(offer.externalReference3);

            let tmpOfferItems = [];
            offer.offerItems.map((offerItem) => {
                
                if (offerItem.type === 'AMOUNT') {
                    tmpOfferItems.push({
                        type: offerItem.type, 
                        description: offerItem.description, 
                        numberOfItems : offerItem.numberOfItems, 
                        unitOfItems : offerItem.unitOfItems, 
                        unitPrice: offerItem.unitPrice, 
                        vatPercentage: offerItem.vatPercentage, 
                        vatAmount: offerItem.vatPercentage, 
                        totalAmount: offerItem.totalAmount 
                    });
                } else if (offerItem.type === 'TEXT') {
                    tmpOfferItems.push({
                        type: offerItem.type, 
                        description: offerItem.description, 
                        text: offerItem.text
                    });
                } else if (offerItem.type === 'HTML') {
                    tmpOfferItems.push({
                        type: offerItem.type, 
                        description: offerItem.description, 
                        html : offerItem.text 
                    });
                } else if (offerItem.type === 'IMAGE') {
                    tmpOfferItems.push({
                        type: offerItem.type, description: offerItem.description, base64 : offerItem.text});
                } else if (offerItem.type === 'HEADER1') {
                    tmpOfferItems.push({
                        type: offerItem.type, description: offerItem.description, header : offerItem.text});
                } else if (offerItem.type === 'HEADER3') {
                    tmpOfferItems.push({type: offerItem.type, description: offerItem.description, header : offerItem.text});
                } else if (offerItem.type === 'HEADER6') {
                    tmpOfferItems.push({type: offerItem.type, description: offerItem.description, header : offerItem.text});
                } else if (offerItem.type === 'HORIZONTAL_LINE') {
                    tmpOfferItems.push({type: offerItem.type, description: offerItem.description});
                } else if (offerItem.type === 'SIGNATURE') {
                    tmpOfferItems.push({type: offerItem.type, description: offerItem.description});
                } else if (offerItem.type === 'EMAIL') {
                    tmpOfferItems.push({type: offerItem.type, description: "", textBeforeEmail : "", textAfterEmail : ""});
                } else if (offerItem.type === 'DATE') {
                    tmpOfferItems.push({type: offerItem.type, description: "", textBeforeDate : "", textAfterDate : ""});
                } else {
                    tmpOfferItems.push({
                        type: "AMOUNT", 
                        description: offerItem.description, 
                        numberOfItems : offerItem.numberOfItems, 
                        unitOfItems : offerItem.unitOfItems, 
                        unitPrice: offerItem.unitPrice, 
                        vatPercentage: offerItem.vatPercentage, 
                        vatAmount: offerItem.vatPercentage, 
                        totalAmount: offerItem.totalAmount 
                    });
                }
            });
            setOfferItems(tmpOfferItems);

            setIsLoading(false);
        }
    }, [offer]);

    const addOfferItem = (eventKey, event) => {
        event.preventDefault();
        let tmpOfferItems = [...offerItems];
        if (eventKey === 'AMOUNT') {
            tmpOfferItems.push({type: "AMOUNT", description: "", numberOfItems : 1, unitOfItems : "Stuks", unitPrice: 0.00, vatPercentage: "21", vatAmount: 0.00, totalAmount: 0.00 });
        } else if (eventKey === 'TEXT') {
            tmpOfferItems.push({type: "TEXT", description: "", text : "" });
        } else if (eventKey === 'HTML') {
            tmpOfferItems.push({type: "HTML", description: "", html : "" });
        } else if (eventKey === 'IMAGE') {
            tmpOfferItems.push({type: "IMAGE", description: "", base64 : ""});
        } else if (eventKey === 'HEADER1') {
            tmpOfferItems.push({type: "HEADER1", description: "", header : ""});
        } else if (eventKey === 'HEADER3') {
            tmpOfferItems.push({type: "HEADER3", description: "", header : ""});
        } else if (eventKey === 'HEADER6') {
            tmpOfferItems.push({type: "HEADER6", description: "", header : ""});
        } else if (eventKey === 'HORIZONTAL_LINE') {
            tmpOfferItems.push({type: "HORIZONTAL_LINE", description: ""});
        } else if (eventKey === 'SIGNATURE') {
            tmpOfferItems.push({type: "SIGNATURE", description: ""});
        } else if (eventKey === 'EMAIL') {
            tmpOfferItems.push({type: "EMAIL", description: "", textBeforeEmail : "", textAfterEmail : ""});
        } else if (eventKey === 'DATE') {
            tmpOfferItems.push({type: "DATE", description: "", textBeforeDate : "", textAfterDate : ""});
        }
        setOfferItems(tmpOfferItems);
    }

    const removeOfferItem = (id) => {
        let tmpOfferItems = offerItems;
        tmpOfferItems.splice(id, 1);
        setOfferItems(tmpOfferItems);
    };

    const changeOfferItem = (name, value, index) => {
        let tmpOfferItems = [...offerItems];
        tmpOfferItems[index][name] = value;
        if (name === 'unitPrice' || name === 'numberOfItems' || name === 'vatPercentage') {
            tmpOfferItems[index]['vatAmount'] = tmpOfferItems[index]['vatPercentage'] / 100 * tmpOfferItems[index]['unitPrice'] * tmpOfferItems[index]['numberOfItems'];
            tmpOfferItems[index]['totalAmount'] = (1 + tmpOfferItems[index]['vatPercentage'] / 100) * tmpOfferItems[index]['unitPrice'] * tmpOfferItems[index]['numberOfItems'];
        }
        setOfferItems(tmpOfferItems);
    };

    const changeOfferItem2 = (maskedvalue, floatvalue, index) => {
        let tmpOfferItems = [...offerItems];
        tmpOfferItems[index]['unitPrice'] = floatvalue;

        tmpOfferItems[index]['vatAmount'] = tmpOfferItems[index]['vatPercentage'] / 100 * tmpOfferItems[index]['unitPrice'] * tmpOfferItems[index]['numberOfItems'];
        tmpOfferItems[index]['totalAmount'] = (1 + tmpOfferItems[index]['vatPercentage'] / 100) * tmpOfferItems[index]['unitPrice'] * tmpOfferItems[index]['numberOfItems'];
    
        setOfferItems(tmpOfferItems);
    };

    const changeOfferItemNumberOfItems = (maskedvalue, floatvalue, index) => {
        let tmpOfferItems = [...offerItems];
        tmpOfferItems[index]['numberOfItems'] = floatvalue;

        tmpOfferItems[index]['vatAmount'] = tmpOfferItems[index]['vatPercentage'] / 100 * tmpOfferItems[index]['unitPrice'] * tmpOfferItems[index]['numberOfItems'];
        tmpOfferItems[index]['totalAmount'] = (1 + tmpOfferItems[index]['vatPercentage'] / 100) * tmpOfferItems[index]['unitPrice'] * tmpOfferItems[index]['numberOfItems'];
        setOfferItems(tmpOfferItems);
    }

    const readImage = (id, file, index) => {
        getBase64(file, (result) => {
            let tmpOfferItems = [...offerItems];
            tmpOfferItems[index]['base64'] = result;
            setOfferItems(tmpOfferItems);
        });
        
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const handleImage = ({ meta, file }, index) => {
        if (meta.status === "done") {
            readImage(meta.id, file, index);
        }
    };

    const updateOffer = () => {
        setIsLoading(true);
        API.put('/offer/' + props.match.params.id, {
            creator: {
                id : creatorId,
                companyName: creatorCompanyName,
                cbeNumber: creatorCbeNumber,
                street: creatorStreet,
                houseNumber: creatorHouseNumber,
                bus: creatorBus,
                postalCode: creatorPostalCode,
                city: creatorCity,
                country: creatorCountry,
                phone: creatorPhone,
                mobile: creatorMobile,
                email: creatorEmail
            },
            customer: {
                id: customerId,
                companyName: customerCompanyName,
                firstName: customerFirstName,
                lastName: customerLastName,
                street: customerStreet,
                houseNumber: customerHouseNumber,
                bus: customerBus,
                postalCode: customerPostalCode,
                city: customerCity,
                country: customerCountry
            },
            extraInformation : extraInformation,
            externalReference1 : externalReference1,
            externalReference2 : externalReference2,
            externalReference3: externalReference3,
            offerItems
        }).then(function(response) {
            setIsLoading(false);
            history.push("/project/detail/" + offer.project.id);
        });
    };

    return (
        isLoading ? (
            <Loader />
        ) : (
            offer.project != null ? (
                <>
                    <Breadcrumb parent={'Project ' + offer.project.name} parentLink={'/project/detail' + offer.project.id}>Offerte maken</Breadcrumb>
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <Row>
                                <Col lg={12}>
                                    <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                        <CardBody>
                                            <p>U staat op punt om een offerte te maken voor project <strong>'{offer.project.name}'</strong></p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <form onSubmit={() => updateOffer}>
                                <Row>
                                    <Col lg={6}>
                                        <Card>
                                            <CardBody>
                                                <Row className='form-group'>
                                                    <Col lg={8}>
                                                        <h4>Gemaakt door:</h4>
                                                    </Col>
                                                    <Col lg={4} className='text-right'>
                                                        <a onClick={() => setIsEditableCreator(!isEditableCreator)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                    </Col>
                                                </Row>
                                                {isEditableCreator ? (
                                                    <>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Organisatienaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorCompanyName} onChange={({target: {value}}) => setCreatorCompanyName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>BTW-nummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorCbeNumber} onChange={({target: {value}}) => setCreatorCbeNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Voornaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorFirstName} onChange={({target: {value}}) => setCreatorFirstName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Naam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorLastName} onChange={({target: {value}}) => setCreatorLastName(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Straat</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorStreet} onChange={({target: {value}}) => setCreatorStreet(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Huisnummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorHouseNumber} onChange={({target: {value}}) => setCreatorHouseNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Bus</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorBus} onChange={({target: {value}}) => setCreatorBus(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Postcode</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorPostalCode} onChange={({target: {value}}) => setCreatorPostalCode(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Gemeente</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorCity} onChange={({target: {value}}) => setCreatorCity(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Land</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={creatorCountry} onChange={({target: {value}}) => setCreatorCountry(value)} />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>{creatorCompanyName}</strong><br />
                                                        {creatorCbeNumber}<br />
                                                        <br />
                                                        {creatorStreet} {creatorHouseNumber} {creatorBus}<br />
                                                        {creatorPostalCode} {creatorCity} <br />
                                                        {creatorCountry}<br />
                                                        <br />
                                                        {creatorEmail}<br />
                                                        {creatorPhone}<br />
                                                        {creatorMobile}
                                                    </>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Card>
                                            <CardBody>
                                                <Row className='form-group'>
                                                    <Col lg={8}>
                                                        <h4>Gemaakt voor:</h4>
                                                    </Col>
                                                    <Col lg={4} className='text-right'>
                                                        <a onClick={() => setIsEditableCustomer(!isEditableCustomer)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                    </Col>
                                                </Row>
                                                {isEditableCustomer ? (
                                                    <>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Organisatienaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerCompanyName} onChange={({target: {value}}) => setCustomerCompanyName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>BTW-nummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerCbeNumber} onChange={({target: {value}}) => setCustomerCbeNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Voornaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerFirstName} onChange={({target: {value}}) => setCustomerFirstName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Naam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerLastName} onChange={({target: {value}}) => setCustomerLastName(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Straat</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerStreet} onChange={({target: {value}}) => setCustomerStreet(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Huisnummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerHouseNumber} onChange={({target: {value}}) => setCustomerHouseNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Bus</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerBus} onChange={({target: {value}}) => setCustomerBus(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Postcode</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerPostalCode} onChange={({target: {value}}) => setCustomerPostalCode(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Gemeente</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerCity} onChange={({target: {value}}) => setCustomerCity(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Land</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={customerCountry} onChange={({target: {value}}) => setCustomerCountry(value)}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {customerCompanyName && <><strong>{customerCompanyName}</strong><br /></>}
                                                        {customerCbeNumber && <><br />{customerCbeNumber}<br /><br /></>}
                                                        {customerFirstName} {customerLastName}
                                                        <br />{customerStreet} {customerHouseNumber} {customerBus}
                                                        <br />{customerPostalCode && <>{customerPostalCode} </>}{customerCity && <>{customerCity}</>}
                                                        <br />{customerCountry}
                                                    </>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className={'row'}>
                                    <div className={'col-xl-12'}>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <Row>
                                                    <Col className={'col-xl-12'}>
                                                        <h4>Extra informatie</h4>
                                                    </Col>
                                                </Row>
                                                <Row className={'row form-group'}>
                                                    <Col className={'col-sm-2'}>
                                                        <label>Externe referentie 1:</label>
                                                    </Col>
                                                    <Col className={'col-sm-10'}>
                                                        <input type={'text'} className={'form-control'} value={externalReference1} onChange={({target:{value}}) => setExternalReference1(value)} />
                                                    </Col>
                                                </Row>
                                                <Row className={'row form-group'}>
                                                    <Col className={'col-sm-2'}>
                                                        <label>Externe referentie 2:</label>
                                                    </Col>
                                                    <Col className={'col-sm-10'}>
                                                        <input type={'text'} className={'form-control'} value={externalReference2} onChange={({target:{value}}) => setExternalReference2(value)} />
                                                    </Col>
                                                </Row>
                                                <Row className={'row form-group'}>
                                                    <Col className={'col-sm-2'}>
                                                        <label>Externe referentie 3:</label>
                                                    </Col>
                                                    <Col className={'col-sm-10'}>
                                                        <input type={'text'} className={'form-control'} value={externalReference3} onChange={({target:{value}}) => setExternalReference3(value)} />
                                                    </Col>
                                                </Row>
                                                <Row className={'row form-group'}>
                                                    <Col className={'col-xl-12'}>
                                                        <label>Extra informatie:</label>
                                                    </Col>
                                                    <Col className={'col-xl-12'}>
                                                        <CKEditors
                                                            activeclassName="p10"
                                                            content={extraInformation}
                                                            events={{
                                                                "change": ({editor}) => setExtraInformation(editor.getData())
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <Row>
                                                    <Col className={'col-xl-12'}>
                                                        <div className={'card'}>
                                                            <div className={'card-body'}>
                                                                <div className={'row'}>
                                                                    <div className={'col-xl-12'}>
                                                                        <h4>Detaillijnen:</h4>
                                                                    </div>
                                                                </div>
                                                                <div className={'row'}>
                                                                    <div className={'col-xl-12'}>
                                                                        
                                                                        {offerItems.map((x,i) => {
                                                                            return (
                                                                                <>
                                                                                    {x.type === 'AMOUNT' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'1'}>Aantal</Col>
                                                                                                <Col lg={'1'}>Eenheid</Col>
                                                                                                <Col lg={'1'}>Eenheidsprijs</Col>
                                                                                                <Col lg={'1'}>BTW %</Col>
                                                                                                <Col lg={'1'}>BTW bedrag</Col>
                                                                                                <Col lg={'1'}>Totaal</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    <CurrencyInput decimalSeparator="," thousandSeparator="." allowNegative={true}  className={'form-control'} name={'numberOfItems'} value={x.numberOfItems}  onChangeEvent={(e, maskedvalue, floatvalue) => changeOfferItemNumberOfItems(maskedvalue, floatvalue, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    <input className={'form-control'} name={'unitOfItems'} value={x.unitOfItems} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    <CurrencyInput decimalSeparator="," thousandSeparator="."  className={'form-control'} name={'unitPrice'} value={x.unitPrice} onChangeEvent={(e, maskedvalue, floatvalue) => changeOfferItem2(maskedvalue, floatvalue, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    <input className={'form-control'} name={'vatPercentage'} value={x.vatPercentage} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(x.vatAmount)}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(x.totalAmount)}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'TEXT' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Tekst</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-6'}>
                                                                                                    <input className={'form-control'} name={'text'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'HTML' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>HTML-tekst</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-7'}>
                                                                                                    <CKEditors
                                                                                                        activeclassName="p10"
                                                                                                        content={x.html}
                                                                                                        events={{
                                                                                                            "change": ({editor}) => changeOfferItem('html', editor.getData(), i)
                                                                                                        }}
                                                                                                    />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'IMAGE' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Afbeelding</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-7'}>
                                                                                                <Dropzone
                                                                                                    onChangeStatus={e => {
                                                                                                        handleImage(e, i)
                                                                                                    }}
                                                                                                    accept={'image/*'}
                                                                                                    maxFiles={20}
                                                                                                    multiple={true}
                                                                                                    canCancel={false}
                                                                                                    canRemove={false}
                                                                                                    inputContent="Plaats je voor-foto's hier"
                                                                                                    styles={{
                                                                                                        previewImage: { minHeight: 150, maxHeight: 150 },
                                                                                                        dropzone: { minHeight: 200, maxHeight: 250 }
                                                                                                    }}
                                                                                                />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'HEADER1' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Header 1</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-6'}>
                                                                                                    <input className={'form-control'} name={'header'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'HEADER3' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Header 3</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-6'}>
                                                                                                    <input className={'form-control'} name={'header'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'HEADER6' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Header 6</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-6'}>
                                                                                                    <input className={'form-control'} name={'header'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'SIGNATURE' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Handtekening</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-6'}></label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'HORIZONTAL_LINE' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'6'}>Horizontale lijn</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-6'}></label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'EMAIL' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'2'}>Tekst voor e-mail</Col>
                                                                                                <Col lg={'2'}>Email</Col>
                                                                                                <Col lg={'2'}>Tekst na e-mail</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-2'}>
                                                                                                    <input className={'form-control'} name={'textBeforeEmail'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-2'}>
                                                                                                    <input className={'form-control'} type={'email'} name={'email'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-2'}>
                                                                                                    <input className={'form-control'} name={'textAfterEmail'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                    {x.type === 'DATE' ? (
                                                                                        <>
                                                                                            <Row className={'form-group'}>
                                                                                                <Col lg={'4'}>Beschrijving</Col>
                                                                                                <Col lg={'2'}>Tekst voor datum</Col>
                                                                                                <Col lg={'2'}>Datum</Col>
                                                                                                <Col lg={'2'}>Tekst na datum</Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                                <Col lg={'1'}></Col>
                                                                                            </Row>
                                                                                            <Row key={'offerItem' + i} className={'form-group'}>
                                                                                                <label className={'col-xl-4'}>
                                                                                                    <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-2'}>
                                                                                                    <input className={'form-control'} name={'textBeforeDate'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-2'}>
                                                                                                    <input className={'form-control'} type={'date'} name={'date'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-2'}>
                                                                                                    <input className={'form-control'} name={'textAfterDate'} value={x.text} onChange={({target: {name, value}}) => changeOfferItem(name, value, i)} />
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeOfferItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                                                </label>
                                                                                                <label className={'col-xl-1'}>
                                                                                                    {offerItems.length - 1 === i ? (
                                                                                                        <>
                                                                                                            <DropdownButton drop='up' title="Toevoegen" onSelect={(eventKey, event) => addOfferItem(eventKey, event)}>
                                                                                                                <Dropdown.Item href={'#'} eventKey="AMOUNT">Bedrag</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="TEXT">Tekst zonder styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HTML">Tekst met styling</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="IMAGE">Afbeelding</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER1">Header groot</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER3">Header standaard</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HEADER6">Header klein</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="SIGNATURE">Handtekening</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="EMAIL">Email</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="DATE">Datum</Dropdown.Item>
                                                                                                                <Dropdown.Item href={'#'} eventKey="HORIZONTAL_LINE">Horizontal lijn</Dropdown.Item>
                                                                                                            </DropdownButton>
                                                                                                        </>
                                                                                                    ): null }
                                                                                                </label>
                                                                                            </Row>
                                                                                        </>
                                                                                    ) : null}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <Col className={'col-xl-12'}>
                                                                        <h4 style={{textAlign: 'right'}}>Totaal: {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(offerItems.reduce((totalPrice, offerItem) => totalPrice + parseFloat(offerItem.totalAmount ? offerItem.totalAmount : 0),0))}</h4>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <a href={'#'} className={'btn btn-primary'} onClick={() => updateOffer()}><span className={'fa fa-plus'}></span> Offerte wijzigen</a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null
        )
    );
}

export default OfferDetail;