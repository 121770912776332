import React, { useState, useEffect, useMemo } from 'react';
import {Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import { Trash2 } from 'react-feather';
import ReactTable from 'react-table-6';
import useReactTable from '../../../hooks/useReactTable';
import Loader from '../../common/loader';
import moment from 'moment';

const ProjectTodos = (props) =>  {
    const [isLoading, setIsLoading] = useState(false);
    const [todoItems, setTodoItems] = useState([]);
    const [todoItem, setTodoItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = [
        {
            Header : 'Item',
            accessor : 'itemName',
            width: 150,
            Cell : cell => (
                <div>
                    <a href={`${process.env.PUBLIC_URL}/offer/detail/` + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : 'Beschrijving',
            accessor : 'description',
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Status',
            accessor : 'status',
            width: 100,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Vervaldatum',
            accessor : 'dueDate',
            width: 150,
            Cell : cell => (
                <div>
                    <span>{moment(cell.value).format('DD/MM/YYYY HH:mm')}</span>
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 300,
            Cell : cell => (
                <div>
                    {cell.original.status === 'new' ? (
                        <>
                            <a href={'#'} className='btn btn-primary mr-2' onClick={() => changeTodoItemStatus(cell.value, 'ongoing')}>Start</a>
                            <a href={'#'} className='btn btn-primary' onClick={() => changeTodoItemStatus(cell.value, 'done')}>Beëindig</a>
                        </>
                    ) : null}
                    {cell.original.status === 'ongoing' ? (
                        <>
                            <a href={'#'} className='btn btn-primary mr-2' onClick={() => changeTodoItemStatus(cell.value, 'done')}>Beëindig</a>
                            <a href={'#'} className='btn btn-primary' onClick={() => changeTodoItemStatus(cell.value, 'new')}>Annuleer</a>
                        </>
                    ) : null}
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 100,
            Cell : cell => (
                
                    <>
                        <a href={'#'} className='btn btn-primary'  onClick={() => deleteTodoItem(cell.value)}><Trash2 /></a>
                    </>
            )
        }
    ];

    useEffect(() => {
        setTodoItem({itemName: "", description: "Beschrijving", priority: "medium", dueDate : ""})
    }, []);

    useEffect(() => {
        if (props.project !== null) {
            API.get('/project/' + props.project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        }
    }, [props.project]);

    const addTodoItem = () => {
        API.post('/project/' + props.project.id + '/todoitem', {
            todoItem
        }).then(function() {
            API.get('/project/' + props.project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        });
    };

    const changeTodoItemStatus = (todoItemId, status) => {
        setLoading(true);
        API.put('/project/' + props.project.id + '/todoitem/' + todoItemId + "/changestatus", {
            status
        }).then(function() {
            API.get('/project/' + props.project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
                setLoading(false);
            });
        });
    }

    const deleteTodoItem = (todoItemId) => {
        API.delete('/project/' + props.project.id + '/todoitem/' + todoItemId).then(function() {
            API.get('/project/' + props.project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        });
    }

    return (

        loading ? <Loader /> : (
            <>
                <Row className={'mt-3'}>
                    <Col lg={12}>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <br />
                                
                                {props.project && todoItems !== undefined ? (
                                    <ReactTable 
                                        data={todoItems}
                                        columns={columns}
                                        minRows={1}
                                        defaultPageSize={10}
                                        showPagination={true}
                                        previousText={'Vorige'}
                                        nextText={'Volgende'}
                                        pageText={'Pagina'}
                                        rowsText={'rijen'}
                                        ofText={'van'}
                                        getTheadThProps={getTheadThProps}
                                        getTdProps={getTdProps}
                                        getTrProps={getTrProps}
                                        getPaginationProps={getPaginationProps}
                                    />
                                ) : (
                                    <p>Er zijn geen offerten gedefinieerd voor dit project.</p>
                                )}
                                
                            </div>
                        </div>
                        <Row className='mt-5'>
                            <Col lg={12}>
                                {todoItem !== null && todoItem !== undefined ? (
                                    <form>
                                        <div className='row form-group'>
                                            <label className='col-md-2'>Naam:</label>
                                            <div className='col-md-10'>
                                                <input className={'form-control'} name={'itemName'} type={'text'} value={todoItem.itemName} onChange={({target: {value}}) => {todoItem.itemName = value; setTodoItem({...todoItem});}} />
                                            </div>
                                        </div>
                                        <div className='row form-group'>
                                        <label className={'col-xl-2'}>Beschrijving</label>
                                            <div className='col-md-10'>
                                                <textarea className={'form-control'} name={'description'} onChange={({target: {value}}) => {todoItem.description = value; setTodoItem({...todoItem});}}>
                                                    {todoItem.itemName}
                                                </textarea>
                                            
                                            </div>
                                        </div>
                                        <div className={'row form-group'}>
                                            <label className={'col-xl-2'}>Prioriteit</label>
                                            <div className={'col-xl-6'}>
                                                <select className={'form-control'} value={todoItem.priority} name={'priority'} onChange={({target: {value}}) => {todoItem.priority = value; setTodoItem({...todoItem});}}>
                                                    <option value='low'>Laag</option>
                                                    <option value='medium'>Medium</option>
                                                    <option value='high'>Hoog</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row form-group'>
                                            <label className='col-md-2'>Vervaldatum:</label>
                                            <div className='col-md-10'>
                                                <input className={'form-control'} name={'dueDate'} type={'date'} value={todoItem.dueDate} onChange={({target: {value}}) => {todoItem.dueDate = value; setTodoItem({...todoItem});}} />
                                            </div>
                                        </div>
                                        <div className='row form-group'>
                                            <div className='col-md-12'>
                                                <a href='#' className="btn btn-primary" type="submit" onClick={() => addTodoItem()}>Todo toevoegen</a>
                                            </div>
                                        </div>
                                    </form>
                                ) : null}
                            </Col>
                        </Row>
                        
                        <br />
                    </Col>
                </Row>
            </>
        )
    );
};

export default ProjectTodos;