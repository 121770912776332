import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";

class WorkOrdersOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workOrders : [],
            filtered: [],
            columns : [
                {
                    Header : 'ID',
                    accessor : 'id',
                    Cell : row => {
                        return <Link to={`/workorder/detail/${row.row.id}`}>{row.row.id}</Link>
                    },
                    width: 50
                },
                {
                    Header : 'Naam',
                    accessor : 'name',
                    width: 250
                },
                {
                    Header : 'Beschrijving',
                    accessor : 'description',
                    Cell : row => {
                        return <span dangerouslySetInnerHTML={{__html: row.row.description}}></span>
                    }
                },
                {
                    Header : 'Project',
                    accessor : 'project',
                    Cell : row => {
                        return <Link to={`/project/detail/${row.row.project.id}`} target={'_blank'}>{row.row.project.name}</Link>
                    },
                    width: 250
                }
            ]
        };
    }

    componentDidMount() {
        var me = this;
        API.get('workorders').then(function(response) {
            me.setState({workOrders: response.data});
        });
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Werkbonnen" title="Overzicht van werkbonnen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.workOrders}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   filterable
                                           filtered={this.state.filtered}
                                           onFilteredChange={(filtered, column, value) => {
                                               this.onFilteredChangeCustom(value, column.id || column.accessor);
                                           }}
                                           defaultFilterMethod={(filter, row, column) => {
                                               const id = filter.pivotId || filter.id;
                                               if (typeof filter.value === "object") {
                                                   return row[id] !== undefined
                                                       ? filter.value.indexOf(row[id]) > -1
                                                       : true;
                                               } else {
                                                   return row[id] !== undefined
                                                       ? String(row[id]).indexOf(filter.value) > -1
                                                       : true;
                                               }
                                           }}
                               />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default WorkOrdersOverview;