import React, { Fragment, useState, useMemo, useEffect } from 'react';
import {Badge, Card, CardBody, Col, Input, Row, Table} from "reactstrap";
import _ from "lodash";
import CurrencyInput from 'react-currency-input';
import { Typeahead } from 'react-bootstrap-typeahead';
import API from '../../../utils/API';
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from '../../common/loader';

const ProjectDetailInformation = (props) =>  {

    const [editView, setEditView] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [project, setProject] = useState(null);
    const [countries, setCountries] = useState([]);
    const [agreementTypes, setAgreementTypes] = useState([]);
    const [locationUsages, setLocationUsages] = useState([]);
    const [projectRelationships, setProjectRelationships] = useState([]);
    const [showPauseDialog, setShowPauseDialog] = useState(false);
    const [showStopDialog, setShowStopDialog] = useState(false);
    const [showContinueDialog, setShowContinueDialog] = useState(false);
    const [reasonOfCancellation, setReasonOfCancellation] = useState("");
    const [reasonOfPause, setReasonOfPause] = useState("");
    const [projectStatusses, setProjectStatusses] = useState([]);


    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setProject(props.project);
        setEditView(props.editView);
        setReadOnly(props.readOnly);

        API.get('config/countries').then(function(response) {
            setCountries(response.data);
        });

        API.get('config/agreementtypes').then(function(response) {
            setAgreementTypes(response.data);
        });

        API.get('config/locationusages').then(function(response) {
            setLocationUsages(response.data);
        });

        API.get('config/projectrelationships').then(function(response) {
            setProjectRelationships(response.data);
        });

        API.get('/projectstatusses').then(function(response) {
            setProjectStatusses(response.data);
        })

    }, []);

    const changeEditView = (event, editViewOpen) => {
        event.preventDefault();
        setEditView(editViewOpen);
    };

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (value === "" || value === null) {
            //this.setState((prevState) => ({errorsOfTaskForm: { ...prevState.errorsOfTaskForm, name : true }}));
        }
        setProject({...project, [name] : value});
    };

    const handleCurrencyInput = (event, maskedValue, floatValue) => {
        let name = event.target.name;
        let value = event.target.value;
        setProject({...project, [name] : value});
    };

    const handleCountryChange = (selectedArray) => {
        let newCountry = selectedArray[0];
        setProject({...project, country : newCountry });
    };

    const changeProjectIsAdvantageousTaxRate = () => {
        setProject({...project, isAdvantageousTaxRate : project.isAdvantageousTaxRate });
    };

    const changeProjectLocationUsage = (selectedArray) => {
        let newLocationUsage = selectedArray[0];
        setProject({...project, locationUsage : newLocationUsage });
    };

    const changeProjectProjectRelationships = (selectedArray) => {
        let newProjectRelationship = selectedArray[0];
        setProject({...project, projectRelationship : newProjectRelationship });
    };

    const changeProjectAgreementType = (selectedArray) => {
        let newAgreementType = selectedArray[0];
        setProject({...project, agreementType : newAgreementType });
    };

    const changeProjectStatus = (value) => {
        setProject({...project, projectStatus : value });
    };

    const updateProject = (event) => {
        event.preventDefault();
        setIsSaving(true);
        API.put('/project/' + project.id, project).then(function() {
            setIsSaving(false);
        });
    }

    const pauseProject = () => {
        API.post('/project/' + project.id + '/pause', {
            reasonOfPause
        }).then(function() {
            window.location.reload(false);
        });
        setShowPauseDialog(false);
    };

    const cancelProject = () => {
        API.post('/project/' + project.id + '/cancel', {
            reasonOfPause
        }).then(function() {
            window.location.reload(false);
        });
        setShowStopDialog(false);
    };

    const continueProject = () => {
        API.post('/project/' + project.id + '/continue').then(function() {
            window.location.reload(false);
        });
        setShowContinueDialog(false);
    };

    return (
        project !== null ? (
            <>
                <SweetAlert
                    show={showStopDialog}
                    showCloseButton={true}
                    onCancel={() => setShowStopDialog(false)}
                    warning
                    title="Project annuleren"
                    onConfirm={cancelProject}
                    confirmBtnText={'Project annuleren'}
                    cancelBtnText={'Annuleren'}
                    style={{width: '90%'}}
                >
                    <form className={'form'}>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Reden van annulering/stopzetting</label>
                            <div className="col-sm-9">
                                <input className="form-control" onChange={(event) => setReasonOfCancellation(event.target.value)} type="text" value={reasonOfCancellation} /></div>
                        </div>
                    </form>
                </SweetAlert>
                <SweetAlert
                    show={showContinueDialog}
                    showCloseButton={true}
                    onCancel={() => setShowContinueDialog(false)}
                    warning
                    title="Project verder zetten"
                    onConfirm={continueProject}
                    confirmBtnText={'Project verder zetten'}
                    cancelBtnText={'Annuleren'}
                    style={{width: '90%'}}
                >
                    <p>
                        U staat op het punt om het project {project.name} terug te activeren.  
                    </p>
                </SweetAlert>
                <SweetAlert
                    show={showPauseDialog}
                    showCloseButton={true}
                    onCancel={() => setShowPauseDialog(false)}
                    warning
                    title="Project pauzeren"
                    onConfirm={pauseProject}
                    confirmBtnText={'Project pauzeren'}
                    cancelBtnText={'Annuleren'}
                    style={{width: '90%'}}
                >
                    <form className={'form'}>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Reden van pauze</label>
                            <div className="col-sm-9">
                                <input className="form-control" onChange={(event) => setReasonOfPause(event.target.value)} type="text" value={reasonOfPause} /></div>
                        </div>
                    </form>
                </SweetAlert>
            <Card>
                <CardBody>
                    {isSaving ? (
                        <Loader />
                    ) : (
                        <>
                            {!readOnly ? (
                        <>
                            <br />
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <div className="btn-group btn-group-square" role="group">
                                        <button className={editView ? ('btn btn-outline-primary') : ('btn btn-primary')} onClick={(event) => changeEditView(event, false)} type="button">Overzicht</button>
                                        <button className={editView ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={(event) => changeEditView(event, true)} type="button"><i className="icofont icofont-pencil"></i> Bewerken</button>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <button className='btn btn-outline-primary btn-square float-right' onClick={() => setShowStopDialog(true)}>
                                        <i className="icofont icofont-square"></i> Beëindigen
                                    </button>
                                    {project.paused ? (
                                        <button className='btn btn-outline-primary btn-square mr-2 float-right' onClick={() => setShowContinueDialog(true)}>
                                            <i className="icofont icofont-ui-play"></i> Voortzetten
                                        </button>
                                    ) : (
                                        <button className='btn btn-outline-primary btn-square mr-2 float-right' onClick={() => setShowPauseDialog(true)}>
                                            <i className="icofont icofont-ui-pause"></i> Pauzeer
                                        </button>
                                    )}
                                    
                                </div>
                            </div>
                            <br />

                            
                        </>
                    ) : null}
                    {editView ? (
                        <>
                            <Row className='form-group'>
                                <Col lg={3}>
                                    <strong>Projectstatus</strong>
                                </Col>
                                <Col lg={9}>
                                    <select className='form-control' name="projectStatus" onChange={({target: {value}}) => changeProjectStatus(value)}>
                                        <option>---</option>
                                        {projectStatusses.map((projectStatus) => <option value={projectStatus.id} selected={project.projectStatus !== null && projectStatus.id === project.projectStatus.id ? "selected" : ""}>{projectStatus.name}</option>)}
                                    </select>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={12}>
                                    <h5>ALGEMENE INFORMATIE</h5>
                                </Col>
                            </Row>    
                            <Row className={'form-group'}>
                                <Col lg={2}>Naam</Col>
                                <Col lg={10}><input type={'text'} className={'form-control'} name="name" value={project.name} onChange={handleChange} /></Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>Interventienummer</Col>
                                <Col lg={10}>
                                    <input type={'text'} className={'form-control'} name="interventionNumber" value={project.interventionNumber} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>Korte beschrijving</Col>
                                <Col lg={10}>
                                    <input type={'text'} className={'form-control'} name="shortDescription" value={project.shortDescription} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>Beschrijving</Col>
                                <Col lg={10}>
                                    <input type={'text'} className={'form-control'} name="description" value={project.description} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>Franchise</Col>
                                <Col lg={10}>
                                    <CurrencyInput 
                                        decimalSeparator="," 
                                        thousandSeparator="."  
                                        className={'form-control'} 
                                        name={'franchiseAmount'} 
                                        value={project.franchiseAmount} 
                                        onChangeEvent={(e, maskedvalue, floatvalue) => handleCurrencyInput(e, maskedvalue, floatvalue)} 
                                    />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={12}>
                                    <h5>REFERENTIES</h5>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>
                                    Externe referentie 1:
                                </Col>
                                <Col lg={10}>
                                    <input type={'text'} className={'form-control'} name={'externalReference1'} value={project.externalReference1} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>
                                    Externe referentie 2:
                                </Col>
                                <Col lg={10}>
                                    <input type={'text'} className={'form-control'} name={'externalReference2'} value={project.externalReference2} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={2}>
                                    Externe referentie 3:
                                </Col>
                                <Col lg={10}>
                                    <input type={'text'} className={'form-control'} name={'externalReference3'} value={project.externalReference3} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={12}>
                                    <h5>PROJECTLOCATIE</h5>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <label className={'col-lg-2'}>
                                    Straat:
                                </label>
                                <Col lg={4}>
                                    <input type={'text'} className={'form-control'} value={project.street} name={'street'} onChange={handleChange} />
                                </Col>
                                <label className={'col-lg-1'}>
                                    Nr.:
                                </label>
                                <Col lg={2}>
                                    <input type={'text'} className={'form-control'} value={project.houseNumber} name={'houseNumber'} onChange={handleChange} />
                                </Col>
                                <label className={'col-lg-1'}>
                                    Bus:
                                </label>
                                <Col lg={2}>
                                    <input type={'text'} className={'form-control'} value={project.bus} name={'bus'} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <label className={'col-xl-2'}>
                                    Postcode:
                                </label>
                                <Col lg={2}>
                                    <input type={'text'} className={'form-control'} name={'postalCode'} value={project.postalCode} onChange={handleChange} />
                                </Col>
                                <label className={'col-xl-2'}>
                                    Gemeente:
                                </label>
                                <Col lg={3}>
                                    <input type={'text'} className={'form-control'} name={'city'} value={project.city} onChange={handleChange} />
                                </Col>
                                <label className={'col-xl-1'}>
                                    Land:
                                </label>
                                <Col lg={2}>
                                    {countries && project ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={handleCountryChange}
                                            clearButton
                                            labelKey="name"
                                            selected={project.country ? [project.country] : []}
                                            multiple={false}
                                            options={countries}
                                            placeholder="Selecteer een land ..."
                                        />
                                    ) : (
                                        <p> </p>
                                    )}
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={12}>
                                    <h5>BTW-informatie</h5>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={6}>Voordeliger BTW-tarief aan 6%</Col>
                                <Col lg={6}>
                                    <input 
                                        type={'checkbox'} 
                                        className={'form-control uniform'}
                                        checked={project.advantageousTaxRate} 
                                        onChange={changeProjectIsAdvantageousTaxRate} 
                                    />
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={6}>Gebruik van de locatie waar de werken gebeuren</Col>
                                <Col lg={6}>
                                    {locationUsages && project ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={changeProjectLocationUsage}
                                            clearButton
                                            labelKey="name"
                                            selected={project.locationUsage ? [project.locationUsage] : []}
                                            multiple={false}
                                            options={locationUsages}
                                            placeholder="Selecteer ..."
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={6}>Wie geeft de opdracht voor de te uitvoeren werken</Col>
                                <Col lg={6}>
                                    {projectRelationships && project ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={changeProjectProjectRelationships}
                                            clearButton
                                            labelKey="name"
                                            selected={project.projectRelationship ? [project.projectRelationship] : []}
                                            multiple={false}
                                            options={projectRelationships}
                                            placeholder="Selecteer ..."
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col lg={6}>Type document waarop de keuze van btw-tarief bepaald wordt</Col>
                                <Col lg={6}>
                                    {agreementTypes && project ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={changeProjectAgreementType}
                                            clearButton
                                            labelKey="name"
                                            selected={project.agreementType ? [project.agreementType] : []}
                                            multiple={false}
                                            options={agreementTypes}
                                            placeholder="Selecteer ..."
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="form-group text-right">
                                <Col lg={12}>
                                    <button className="btn btn-primary" type="submit" onClick={(event) => updateProject(event)}>Project wijzigen</button>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <h5>
                                {project.name} <Badge pill={true} variant={"primary"}>{_.isEmpty(project.status) ? "Nieuw" : project.status === 'FINISHED' ? "Afgewerkt" : project.status}</Badge>
                            </h5>

                            

                            {_.isEmpty(project.description) ? null : (<div dangerouslySetInnerHTML={{__html: project.description}} />)}

                            <Row className={'mt-3'}>
                                <Col lg={12}>
                                    <h6><strong>ALGEMENE INFORMATIE</strong></h6>
                                </Col>
                            </Row>

                            

                            <Table className={'mt-3'}>
                                <tr>
                                    <td>Projectstatus</td>
                                    <td>{project.projectStatus !== null && project.projectStatus !== undefined ? <p>{project.projectStatus.name}</p> : null}</td>
                                </tr>
                                <tr>
                                    <td>Projectnummer</td>
                                    <td>{_.isEmpty(project.projectNumber) ? '(geen projectnummer)' : project.projectNumber}</td>
                                </tr>
                                <tr>
                                    <td>Interventienummer</td>
                                    <td>{_.isEmpty(project.interventionNumber) ? '(geen interventienummer)' : project.interventionNumber}</td>
                                </tr>
                                <tr>
                                    <td>Franchise</td>
                                    <td>{new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(project.franchiseAmount)}</td>
                                </tr>
                                <tr>
                                    <td>Locatie</td>
                                    <td>
                                        {project.street} {project.houseNumber} {project.bus},
                                        {project.postalCode} {project.city},
                                        {project.country !== undefined && project.country !== null ? project.country.name : 'België'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Externe referenties</td>
                                    <td>
                                        {_.isEmpty(project.externalReference1) ? null : '(1) ' + project.externalReference1}
                                        {_.isEmpty(project.externalReference2) ? null : '<br />(2) ' + project.externalReference2}
                                        {_.isEmpty(project.externalReference3) ? null : '<br />(3) ' + project.externalReference3}
                                    </td>
                                </tr>
                            </Table>

                            <Row className={'mt-3'}>
                                <Col lg={12}>
                                    <h6><strong>BTW-informatie</strong></h6>
                                </Col>
                            </Row>

                            <Table className={'mt-3'}>
                                <tr>
                                    <td>BTW-tarief</td>
                                    <td>
                                        {_.isEmpty(project.projectNumber) ? '(geen projectnummer)' : (
                                            project.advantageousTaxRate ? (
                                                <div>Voordeliger BTW-tarief aan 6%</div>
                                            ) : (
                                                <div>BTW-tarief aan 21%</div>
                                            )
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Gebruik van de locatie waar de werken gebeuren</td>
                                    <td>
                                        {project.locationUsage !== null ? (
                                            <>{project.locationUsage.name}</>
                                        ) : 'niet van toepassing'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Wie geeft de opdracht voor de te uitvoeren werken</td>
                                    <td>
                                        {project.projectRelationship !== null ? (
                                            <>{project.projectRelationship.name}</>
                                        ) : 'niet van toepassing'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type document waarop de keuze van btw-tarief bepaald wordt</td>
                                    <td>
                                        {project.agreementType !== null ? (
                                            <>{project.agreementType.name}</>
                                        ) : 'niet van toepassing'}
                                    </td>
                                </tr>
                            </Table>
                        </>
                    )}
                        </>
                    )}



                    
                </CardBody>
            </Card>
        </>
        ) : null
        
    );
};

export default ProjectDetailInformation;