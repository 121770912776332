import React, { Fragment } from 'react';
import _ from "lodash";
import API from "../../../utils/API";
import {Typeahead} from "react-bootstrap-typeahead";
import {Col, Row} from "reactstrap";

class LocationInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            useCustomerLocationData : true,
            countries : [],
            location : {
                useLocationOfCustomer: true,
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : ""
            },
            errors : {
                street : "",
                houseNumber : "",
                postalCode : "",
                city : "",
                country : ""
            }
        };

        this._validateOnDemand = true;
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {
        var me = this;
        API.get('config/countries').then(function(response) {
            me.setState({countries: response.data});
        });
    }

    componentWillUnmount() {
    }

    isValidated() {
        const isDataValid = this.validationCheck();
        if (isDataValid) {
            this.props.updateLocationStore(this.state.location);
        }
        return isDataValid;
    };

    validationCheck() {
        let isValid = true;
        if (!this.state.useCustomerLocationData) {
            if (_.isEmpty(this.state.location.street)) {
                this.setState((prevState) => ({errors: { ...prevState.errors, street : "Fout opgetreden" }}));
                isValid = false;
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, street : "" }}));
            }
            if (_.isEmpty(this.state.location.houseNumber)) {
                this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "Fout opgetreden" }}));
                isValid = false;
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "" }}));
            }
            if (_.isEmpty(this.state.location.postalCode)) {
                this.setState((prevState) => ({errors: { ...prevState.postalCode, street : "Fout opgetreden" }}));
                isValid = false;
            } else {
                this.setState((prevState) => ({errors: { ...prevState.postalCode, street : "" }}));
            }
            if (_.isEmpty(this.state.location.city)) {
                this.setState((prevState) => ({errors: { ...prevState.errors, city : "Fout opgetreden" }}));
                isValid = false;
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, city : "" }}));
            }
            if (_.isEmpty(this.state.location.country)) {
                this.setState((prevState) => ({errors: { ...prevState.errors, country : "Fout opgetreden" }}));
                isValid = false;
            } else {
                this.setState((prevState) => ({errors: { ...prevState.errors, country : "" }}));
            }
        }
        return isValid;
    }

    changeLocationField = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        this.setState((prevState) => ({
            location: { ...prevState.location, [name] : value }
        }));
    };

    changeUseCustomerLocationData = (event) => {
        let value = event.target.checked;
        this.setState((prevState) => ({location: { ...prevState.location, useLocationOfCustomer : value }}));
    };

    changeCountry = (selectedArray) => {
        let newCountry = selectedArray[0];
        this.setState((prevState) => ({location: { ...prevState.location, country : newCountry }}));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <input type={'checkbox'} key={'useCustomerLocationData'} name={'useCustomerLocationData'} defaultChecked={this.state.useCustomerLocationData} onChange={this.changeUseCustomerLocationData} /> Op de locatie van de klant
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <hr />
                            </div>
                        </div>
                        {this.state.location.useLocationOfCustomer ? (
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    {this.props.customerLocation != null ? (
                                        <div className="card">
                                            <div className="card-body">
                                                {this.props.customerLocation !== null && this.props.customerLocation !== undefined ? (
                                                    <Row>
                                                        <Col lg={1}>
                                                            <i className="icofont icofont-4x icofont-ui-home"></i>
                                                        </Col>
                                                        <Col lg={11}>
                                                            <h4>Woonplaats van de klant</h4>
                                                            {this.props.customerLocation.street} {this.props.customerLocation.houseNumber} {this.props.customerLocation.bus}<br />
                                                            {this.props.customerLocation.postalCode} {this.props.customerLocation.city}<br />
                                                            {this.props.customerLocation.country !== null && this.props.customerLocation !== undefined ? (
                                                                <>{this.props.customerLocation.country !== null && this.props.customerLocation.country !== undefined ? this.props.customerLocation.country.name : ''}</>
                                                            ) : null}
                                                        </Col>

                                                    </Row>
                                                ) : null}

                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <div className={"row"}>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Straat</label>
                                                <input className="form-control" type="text" name="street" value={this.state.location.street} onChange={this.changeLocationField} />
                                                {this.state.errors.street ? <span className={'errorInForm'}>Straat is verplicht</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Huisnummer</label>
                                                <input className="form-control" type="text" name="houseNumber" value={this.state.location.houseNumber} onChange={this.changeLocationField} />
                                                {this.state.errors.houseNumber ? <span className={'errorInForm'}>Huisnummer is verplicht</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Bus</label>
                                                <input className="form-control" type="text" name="bus" value={this.state.location.bus} onChange={this.changeLocationField} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Postcode</label>
                                                <input className="form-control" type="text" name="postalCode" value={this.state.location.postalCode} onChange={this.changeLocationField} />
                                                {this.state.errors.postalCode ? <span className={'errorInForm'}>Postcode is verplicht</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Gemeente</label>
                                                <input className="form-control" type="text" name="city" value={this.state.location.city} onChange={this.changeLocationField} />
                                                {this.state.errors.city ? <span className={'errorInForm'}>Gemeente is verplicht</span> : null }
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">Land</label>
                                                {this.state.countries ? (
                                                    <Typeahead
                                                        id="project-typeahead"
                                                        name="country"
                                                        onChange={this.changeCountry}
                                                        clearButton
                                                        labelKey="name"
                                                        selected={this.state.location.country ? [this.state.location.country] : []}
                                                        multiple={false}
                                                        options={this.state.countries}
                                                        placeholder="Selecteer een land ..."
                                                    />
                                                ) : null}
                                                {this.state.errors.country ? <span className={'errorInForm'}>Land is verplicht</span> : null }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <br />
                <br />
            </Fragment>
        );
    }
};

export default LocationInformation;