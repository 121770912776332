import React from "react";
import styled from "@xstyled/styled-components";
import { colors } from "@atlaskit/theme";
import { grid, borderRadius } from "./constants";
import { Draggable } from "react-beautiful-dnd";
import ProjectStatusBoardList from "./projectstatusboardlist";
import Title from "./projectstatusboardtitle";

const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff8800;
  border-bottom: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: ${({ isDragging }) =>
    isDragging ? colors.G50 : 'rgba(246, 130, 32, 0.75)'};
  transition: background-color 0.2s ease;
  color: #ffffff;
`;

const ProjectTaskboardColumn = (props) => {
    const projectStatus = props.projectStatus;
    const projects = props.projects;

  const index = props.index;
  return (
    <Draggable index={index}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header isDragging={snapshot.isDragging}>
            <Title
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${projectStatus.name} quote list`}
            >
              {projectStatus.name} {projects.length}
            </Title>
          </Header>
          <ProjectStatusBoardList
            listId={'' + projectStatus.id}
            listType="PROJECT"
            style={{
              backgroundColor: snapshot.isDragging ? colors.G50 : null
            }}
            projects={projects}
            internalScroll={props.isScrollable}
            isCombineEnabled={Boolean(props.isCombineEnabled)}
            useClone={Boolean(props.useClone)}
          />
        </Container>
      )}
    </Draggable>
  );
};

export default ProjectTaskboardColumn;
