import React, { Fragment } from 'react';
import CKEditors from "react-ckeditor-component";
import _ from "lodash";

class ProjectInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            project : {
                name: '',
                shortDescription : '',
                description : '',
                interventionNumber:  '',
                externalReference1: '',
                externalReference2: '',
                externalReference3: ''
            },
            errors : {
                name: "",
                shortDescription: "",
                description: ""
            }
        };

        this._validateOnDemand = true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        let isDataValid = true;
        this.props.updateProjectStore(this.state.project);
        return this.validationCheck();
    };

    validationCheck = () => {
        let isValid = true;
        if (_.isEmpty(this.state.project.name)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, name : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, name : "" }}));
        }

        if (_.isEmpty(this.state.project.shortDescription)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, shortDescription : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, shortDescription : "" }}));
        }

        if (_.isEmpty(this.state.project.description)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, description : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, description : "" }}));
        }
        return isValid;
    };

    changeProjectName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            project: { ...prevState.project, name : value }
        }));
    };

    changeInterventionNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            project: { ...prevState.project, interventionNumber : value }
        }));
    };

    changeProjectShortDescription = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            project: {...prevState.project, shortDescription: value}
        }));
    };

    changeProjectDescription = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({
            project: { ...prevState.project, description : value }
        }));
    };

    changeExternalReference1 = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            project: {...prevState.project, externalReference1: value}
        }));
    };

    changeExternalReference2 = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            project: {...prevState.project, externalReference2: value}
        }));
    };

    changeExternalReference3 = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            project: {...prevState.project, externalReference3: value}
        }));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Naam</label>
                                    <input className="form-control" type="text" name="name" value={this.state.project.name} onChange={this.changeProjectName} />
                                    {this.state.errors.name ? <span className={'errorInForm'}>Naam is verplicht</span> : null }
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Interventienummer</label>
                                    <input className="form-control" type="text" name="name" value={this.state.project.interventionNumber} onChange={this.changeInterventionNumber} />
                                </div>
                            </div>
                        </div>
                            <div className={'row'}>
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Korte beschrijving</label>
                                        <input className="form-control" type="text" name="shortDescription" value={this.state.project.shortDescription} onChange={this.changeProjectShortDescription} />
                                        {this.state.errors.shortDescription ? <span className={'errorInForm'}>Korte beschrijving is verplicht</span> : null}
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Lange beschrijving</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.project.description}
                                            events={{
                                                "change": this.changeProjectDescription
                                            }}
                                        />
                                        {this.state.errors.description ? <span className={'errorInForm'}>Beschrijving is verplicht</span> : null}
                                    </div>
                                </div>
                            </div>
                        <div className={'row'}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Externe referentie 1</label>
                                    <input className="form-control" type="text" name="externalReference1" value={this.state.project.externalReference1} onChange={this.changeExternalReference1} />
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Externe referentie 2</label>
                                    <input className="form-control" type="text" name="externalReference2" value={this.state.project.externalReference2} onChange={this.changeExternalReference2} />
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Externe referentie 3</label>
                                    <input className="form-control" type="text" name="externalReference3" value={this.state.project.externalReference3} onChange={this.changeExternalReference3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </Fragment>
        );
    }
};

export default ProjectInformation;