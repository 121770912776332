import React, {Fragment, useState} from 'react';
import logo from '../assets/images/logo-pouillon.png';
import {useParams} from "react-router";

const ResetPwd = () => {
    const {token} = useParams();
    const [password, setPassword] = useState();
    const [verifyPassword, setVerifyPassword] = useState();
    const [image, setImage] = useState(null);

    const changePassword = (e) => {
        e.preventDefault();
        const data = {
            token : token,
            password : password
        };
        try {
            fetch(process.env.REACT_APP_BASE_URL + '/changepassword', {
                method : 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body : JSON.stringify(data)
            }).then(function(response) {
                return response.json();
            }).then(function(response) {
                if (response) {
                    if (response.error) {
                        console.log("error");
                    } else {
                        fetch(process.env.REACT_APP_BASE_URL + '/api/2fa-ga-qr?token=' + token, {
                            method: 'GET'
                        }).then(function(response) {
                            console.log(response);
                            return response.text();
                        }).then(function(response) {
                            if (response.error) {
                                console.log("error");
                            } else {
                                setImage(response);
                            }
                        });
                    }
                } else {
                     console.log("password didnt change");
                }

            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4 p-4">
                                            <div>
                                                <img src={logo} alt="" />
                                            </div>
                                            {image == null ? (
                                                <form className="theme-form">
                                                <h5 className="f-14 mt-4 mb-3">WIJZIG WACHTWOORD</h5>
                                                <div className="form-group">
                                                    <label className="col-form-label">Nieuw wachtwoord</label>
                                                    <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Typ nieuw wachtwoord opnieuw</label>
                                                    <input className="form-control" type="password" value={verifyPassword} onChange={(e) => setVerifyPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group form-row mb-0">
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary" onClick={changePassword}>Wachtwoord wijzigen</button>
                                                    </div>
                                                </div>
                                            </form>
                                            ) : (
                                                <img alt="" src={'data:image/png;base64,' + image }  />
                                            )}                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Reset Password page end--> */}
                </div>
            </div>
        </Fragment>
    );
};

export default ResetPwd;