import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {Card, CardBody, Col, Row} from "reactstrap";
import moment from "moment-timezone";
import nlLocale from '@fullcalendar/core/locales/nl';
import GeoCode from "react-geocode";
import TaskDetailSummary from './taskDetailSummary';

const TasksOverview = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [doAddHolidays, setDoAddHolidays] = useState(false);
    const [holidaysAdded, setHolidayAdded] = useState(false);
    const [tasksLoaded, setTasksLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [selectedTaskProjectNotes, setSelectedTaskProjectNotes] = useState([]);

    const ref = React.createRef();

    let timer;

    const dayReRender = (date) => {

        let startOfMonth = moment(date).startOf('month').subtract(6,'day').format('YYYY-MM-DD 00:00:00');
        let endOfMonth = moment(date).endOf('month').add(11,'day').format('YYYY-MM-DD 23:59:59');

        API.get('tasks/calendar?dateFrom=' + startOfMonth + '&dateUntil=' + endOfMonth).then(function(response) {
            const tasks = response.data;
            let list = calendarEvents.filter(function(calendarEvent) {
                return calendarEvent.holiday === true;
            });
        
            tasks.map((task) => {
                if (task.plannedDateForExecution !== null && task.plannedEndDateForExecution !== null) {
                    let startDate = new Date(task.plannedDateForExecution);
                    let endDate = new Date(task.plannedEndDateForExecution);
                    let firstItem = true;

                    if (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth() && startDate.getDate() === endDate.getDate()) {
                        list.push({
                            taskId: task.id,
                            display: 'block',
                            title: task.description,
                            description: task.projectName,
                            executor: task.responsibleUser !== null ? task.responsibleUser.firstName : '',
                            backgroundColor: task.status === "FINISHED" || task.status === "DONE" ? "#aeaeae" : task.responsibleUser !== null ? task.responsibleUser.colorCode : '#FF6600',
                            start: startDate,
                            end: endDate,
                            holiday: false
                        });
                    } else {
                        for (let currentDate = new Date(task.plannedDateForExecution); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {        
                            if (currentDate.getDay() === 1 || currentDate.getDay() === 2 || currentDate.getDay() === 3 || currentDate.getDay() === 4 || currentDate.getDay() === 5 || (task.saturdayIncluded && currentDate.getDay() === 6) || (task.sundayIncluded && currentDate.getDay() === 0)) {
                                let begin = new Date(currentDate);
                                let end = new Date(currentDate);
                                if (firstItem === true) {
                                    begin.setHours(startDate.getHours());
                                    begin.setMinutes(startDate.getMinutes());
                                    if ((endDate.getTime() - startDate.getTime()) / 1000 * 3600 * 24 > 1) {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    } else {
                                        end.setHours(currentDate.getHours());
                                        end.setMinutes(currentDate.getMinutes());
                                    }
                                    firstItem = false;
                                } else {
                                    begin.setHours(8);
                                    begin.setMinutes(0);
                                    if (endDate.getFullYear() === end.getFullYear() && endDate.getMonth() === end.getMonth() && endDate.getDate() === end.getDate()) {
                                        end.setHours(endDate.getHours());
                                        end.setMinutes(endDate.getMinutes());
                                    } else {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    }
                                }
                                list.push({
                                    taskId: task.id,
                                    display: 'block',
                                    title: task.description,
                                    description: task.projectName,
                                    //customer: task.project !== null && task.project.customer !== null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                                    //location : task.project !== null ? task.project.street + ' ' + task.project.houseNumber + ' ' + task.project.bus + ', ' + task.project.postalCode + ' ' + task.project.city : '',
                                    executor: task.responsibleUser !== null ? task.responsibleUser.firstName : '',
                                    backgroundColor: task.responsibleUser !== null ? task.responsibleUser.colorCode : '#FF6600',
                                    start: new Date(begin),
                                    end: new Date(end)
                                });
                            }
                        }
                    }
                }
            });
            setTasksLoaded(true);
            setCalendarEvents(list);
        });
    };

    useEffect(() => {
        API.get('myuser').then(function(response) {
            setUser(response.data[0]);
            dayReRender(new Date());
        });
    }, []);


    useEffect(() => {
        if (tasksLoaded === true) {
            setDoAddHolidays(true);
        }
    }, [calendarEvents]);

    useEffect(() => {
        if (holidaysAdded === false && doAddHolidays === true) {
            API.get('holidays').then(function(response) {
                let tempList = [...calendarEvents];
                let holidays = response.data;
                holidays.map((holiday) => {
    
                    tempList.push({
                        taskId: -1,
                        title: holiday.name,
                        backgroundColor: "#cc2200",
                        start: new Date(holiday.startDateTime),
                        end: new Date(holiday.endDateTime),
                        holiday: true
                    });
    
                });
                setCalendarEvents(tempList);
                setHolidayAdded(true);
                setDoAddHolidays(false);
            });
        }
        
    }, [doAddHolidays]);

    useEffect(() => {
        if (selectedTask !== null && selectedTask.project !== null) {
            const address = selectedTask.project.street + ' ' + selectedTask.project.houseNumber + ' ' + selectedTask.project.bus + ", " + selectedTask.project.postalCode + " " + selectedTask.project.city + ", " + selectedTask.project.country;
            GeoCode.setApiKey("AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo");
            GeoCode.fromAddress(address).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setLat(lat);
                    setLng(lng);
                }
            );
            API.get('/project/' + selectedTask.project.id + '/notes').then(function(response) {
                setSelectedTaskProjectNotes(response.data);
            });
        }
        
    }, [selectedTask]);

    const renderEventContent = (eventInfo) => {
        return (
          <>
            <b>{moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}</b>
            {eventInfo.event.extendedProps.taskId !== -1 ? (
                <>
                    &nbsp;&nbsp;&nbsp;<span style={{textTransform: 'uppercase'}}>{eventInfo.event.extendedProps.executor}</span>
                </>
            ) : null}   
          </>
      );
      
  };

    const handleEventClick = (eventInfo) => {
        API.get('/task/' + eventInfo.event.extendedProps.taskId).then(function(response) {
            setSelectedTask(response.data);
        });
    }

    const handleDatesSet = (info) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (ref != null && ref.current != null) {
                let calendarApi = ref.current.getApi();
                dayReRender(calendarApi.getDate());
            }
            ;
        }, 2000);
    };

    

    return (
        <Fragment>
            <Breadcrumb title="Project">Dashboard</Breadcrumb>
            <div className="container-fluid">
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody>
                                <FullCalendar
                                    ref={ref}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView={'dayGridMonth'}
                                    eventContent={(eventInfo) => renderEventContent(eventInfo)}
                                    events={calendarEvents}
                                    headerToolbar={{
                                        left: 'prev,next',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                      }}
                                    eventClick={(a,b,c) => handleEventClick(a,b,c)}
                                    locale={nlLocale}
                                    businessHours={{
                                        daysOfWeek: [ 1, 2, 3, 4, 5 ],
                                        startTime: '08:00',
                                        endTime: '19:00'}
                                    }
                                    height={900}
                                    slotTime={'06:00:00'}
                                    scrollTimeReset={false}
                                    datesSet={(datesSet) => handleDatesSet(datesSet)}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                            <CardBody>
                                {selectedTask != null ? (
                                    <TaskDetailSummary task={selectedTask} lat={lat} lng={lng} user={user} notes={selectedTaskProjectNotes} />
                                ) : null}
                            </CardBody>
                        </Card>
                        
                    </Col>
                </Row>
            </div>
        </Fragment>
    );

}

export default TasksOverview;