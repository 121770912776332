import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router";
import logo from '../../assets/images/logo-pouillon.png';
import APIUnauthorised from "../../utils/APIUnauthorised";
import {ToastContainer} from "react-toastify";
import {Button, Col, Row} from "reactstrap";
import {BuildTwoTone} from "@material-ui/icons";

const OfferApprove = () => {
    const [offer, setOffer] = useState(null);
    const [approved, setApproved] = useState(false);
    const [refused, setRefused] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        APIUnauthorised.get('/offer/' + id).then(function (response) {
            setOffer(response.data);
        });
    }, []);

    const approveOffer = (event) => {
        APIUnauthorised.put('/offer/' + id + '/approve').then(function (response) {
            setApproved(true);
        });
    };

    const refuseOffer = (event) => {
        APIUnauthorised.put('/offer/' + id + '/refuse').then(function (response) {
            setRefused(true);
        });
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box" style={{width: '80%'}}>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div>
                                                    <img src={logo} alt="" />
                                                </div>
                                                <br />
                                                <div>
                                                    <h4>Goedkeuring offerte</h4>
                                                    {!approved && !refused ? (
                                                        offer !== null ? (
                                                            <>
                                                                <Row>
                                                                    <Col lg={5}>
                                                                        <strong>TEN AANZIEN VAN</strong><br />
                                                                        <br />
                                                                        {offer.customer.companyName !== null ? offer.customer.companyName : null}
                                                                        { offer.customer.firstName } { offer.customer.lastName }<br />
                                                                        { offer.customer.street } { offer.customer.houseNumber } { offer.customer.bus }<br />
                                                                        { offer.customer.postalCode } { offer.customer.city }
                                                                    </Col>
                                                                    <Col lg={2}>

                                                                    </Col>
                                                                    <Col lg={5}>
                                                                        <Row>
                                                                            <Col lg={5}>Adres:</Col>
                                                                            <Col lg={7}>
                                                                                <strong>{ offer.creator.companyName }</strong><br />
                                                                                { offer.creator.street } { offer.creator.houseNumber } { offer.creator.bus } <br />
                                                                                { offer.creator.postalCode } { offer.creator.city }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={5}>BTW nr:</Col>
                                                                            <Col lg={7}>
                                                                                { offer.creator.cbeNumber }
                                                                            </Col>
                                                                        </Row>
                                                                        {offer.footer.bankaccounts.map((bankAccount) => {
                                                                            return (
                                                                                <>
                                                                                    <Row>
                                                                                        <Col lg={5}>Bank:</Col>
                                                                                        <Col lg={7}>
                                                                                            {bankAccount.bankName}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col lg={5}>IBAN:</Col>
                                                                                        <Col lg={7}>
                                                                                            {bankAccount.iban}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col lg={5}>BIC:</Col>
                                                                                        <Col lg={7}>
                                                                                            {bankAccount.bic}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <Row>
                                                                            <Col lg={5}>Tel:</Col>
                                                                            <Col lg={7}>
                                                                                { offer.creator.phone }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={5}>GSM:</Col>
                                                                            <Col lg={7}>
                                                                                { offer.creator.mobile }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={5}>E-mail:</Col>
                                                                            <Col lg={7}>
                                                                                { offer.creator.email }
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={5}>Website:</Col>
                                                                            <Col lg={7}>
                                                                                { offer.footer.website }
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}><h2>OFFERTE</h2></Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}><hr /></Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={3}><strong>Offertedatum:</strong></Col>
                                                                    <Col lg={9}>{ offer.creationDate }</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={3}>Offertenummer:</Col>
                                                                    <Col lg={9}>{ offer.offerNumber }</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={3}>Uw referentie:</Col>
                                                                    <Col lg={9}></Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={3}>Vervaldatum:</Col>
                                                                    <Col lg={9}>{ offer.expiryDate }</Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <table className={'table'}>
                                                                            <thead>
                                                                            <tr>
                                                                                <th>NR.</th>
                                                                                <th>Omschrijving</th>
                                                                                <th>Aantal</th>
                                                                                <th>Tarief</th>
                                                                                <th>BTW %</th>
                                                                                <th>Subtotaal</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {offer.offerItems.map((offerItem) => {
                                                                                return(
                                                                                    <tr>
                                                                                        <td>{offerItem.id}</td>
                                                                                        <td>{offerItem.description}</td>
                                                                                        <td>{offerItem.numberOfItems}</td>
                                                                                        <td>{offerItem.unitPrice}</td>
                                                                                        <td>{offerItem.vatPercentage} %</td>
                                                                                        <td>{offerItem.totalAmount}</td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                            </tbody>
                                                                        </table>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={6}></Col>
                                                                    <Col lg={6}>
                                                                        <Row>
                                                                            <Col lg={6}><h4>Totaal excl BTW</h4></Col>
                                                                            <Col lg={6} className={'text-right'}><h4>{offer.totalExcl}</h4></Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={6}><h4>Totaal BTW</h4></Col>
                                                                            <Col lg={6} className={'text-right'}><h4>{offer.totalVatAmount}</h4></Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={6}><h4>Totaal incl BTW</h4></Col>
                                                                            <Col lg={6} className={'text-right'}><h4>{offer.totalIncl}</h4></Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>

                                                                <Row className={'mt-5'}>
                                                                    <Col lg={'12'}>
                                                                        <a className={'btn btn-primary'} href={'#'} onClick={(event) => refuseOffer(event)}><h5>Weigeren</h5></a>
                                                                        &nbsp;&nbsp;
                                                                        <a className={'btn btn-primary'} href={'#'} onClick={(event) => approveOffer(event)}><h5>Goedkeuren</h5></a>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ) : null
                                                    ) : (
                                                        approved ? (
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <p>
                                                                        De offerte {offer.offerNumber} is succesvol geaccepteerd.

                                                                        <br />Wij nemen zo snel mogelijk contact met je op om de uitvoeringsdatum te bespreken.
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <p>
                                                                        De offerte {offer.offerNumber} is niet geaccepteerd.

                                                                        <br />Bedankt dat je ons raadpleegde voor een offerte. Hopelijk tot een volgende keer.
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};

export default OfferApprove;