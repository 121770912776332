import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import Loader from '../common/loader';
import useReactTable from '../../hooks/useReactTable';
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Form, Input, Row} from "reactstrap";

const SettingsOverview = () =>  {

    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [settings, setSettings] = useState([]);
    const [pages, setPages] = useState(-1);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();

    useEffect(() => {
        loadCurrentUser();
        globalSearch();
    }, []);

    const saveSetting = (id, key, value) => {
        API.put('setting/' + id, {key: key, value: value}).then(function(response) {
            API.get('settings').then(function(response) {
                setSettings(response.data);
                setIsLoading(false);
            });
        });
    };

    const loadCurrentUser = () => {
        API.get('myuser').then(function(response) {
            if (response.data !== undefined) {
                setCurrentUser(response.data[0]);
                setIsLoading(false);
            }
        });
    }

    const globalSearch = () => {
        API.get('settings').then(function(response) {
            setSettings(response.data);
            setIsLoading(false);
        });
    };

    const handleFormChange = (index, event) => {
        let data = [...settings];
        data.map((setting) => {
            if (setting.key == event.target.name) {
                setting.value = event.target.value;
            }
        })
        setSettings(data);
    }

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Row>
                        <Col sm={12}>
                            <Breadcrumb>Projecten</Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card style={{backgroundColor: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR}}>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <h2>Overzicht van applicatie-eigenschappen</h2>
                                        </Col>
                                    </Row>
                                    {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER' || currentUser.userRole === 'EMPLOYEE') ? (
                                        <>
                                            <Row className='mt-3'>
                                                <Col sm={12}>
                                                    <Form>
                                                    {settings.map((setting, index) => {
                                                        return (
                                                            <Row key={index} className='mb-3'>
                                                                <Col lg={3}>
                                                                    {setting.key}
                                                                </Col>
                                                                <Col lg={7}>
                                                                    <input id={setting.id} className={'form-control'} type='text' onChange={event => handleFormChange(index, event)} name={setting.key} value={setting.value} />
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <a className='btn btn-primary' onClick={() => saveSetting(setting.id, setting.key, setting.value)}><i className="icofont icofont-save"></i></a>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>
                                        
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default SettingsOverview;