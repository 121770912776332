import React, { useState, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import { Link } from 'react-router-dom';
import useReactTable from '../../../hooks/useReactTable';

const ProjectTimesheets = (props) =>  {
    const [project, setProject] = useState(null);
    const [timesheetItems, setTimesheetItems] = useState([]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = [
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell: row => {
                return <Link to={`/timesheet/detail/` + row.row.id}>{row.row.id}</Link>
            }
        },
        {
            Header : 'Dag',
            accessor : 'startTime',
            width: 175,
            headerClassName: 'text-left',
            Cell: row => {
                if (_.isEmpty(row.row.startTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit"
                    }).format(new Date(row.row.startTime))
                }
            }
        },
        {
            Header : 'Starttijd',
            accessor : 'startTime',
            width: 100,
            headerClassName: 'text-left',
            Cell: row => {
                if (_.isEmpty(row.row.startTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.startTime))
                }
            }
        },
        {
            Header : 'Eindtijd',
            accessor : 'endTime',
            width: 100,
            Cell: row => {
                if (_.isEmpty(row.row.endTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.endTime))
                }
            }
        },
        {
            Header : 'Duur (in u)',
            accessor : 'duration',
            width: 100,
            Cell: row => {
                if (_.isNumber(row.row.duration)) {
                    return <span>{row.row.duration / 60 }</span>
                } else {
                    return <span>-</span>
                }
            }
        },
        {
            Header : 'Werkbon',
            accessor: 'workOrder',
            width: 175,
            headerClassName: 'text-left',
            Cell: row => {
                if (row.row.workOrder !== undefined && row.row.workOrder !== null) {
                    return <span>{row.row.workOrder.name}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Taak',
            accessor: 'task',
            headerClassName: 'text-left',
            Cell: row => {
                if (row.row.task !== undefined && row.row.task !== null) {
                    return <span>{row.row.task.name}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Beschrijving',
            accessor : 'description',
            headerClassName: 'text-left'
        }
    ];

    useEffect(() => {
        setProject(props.project);
    }, []);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id +'/timesheetItems').then(function(response) {
                setTimesheetItems(response.data);
            });
        }
    }, [project]);

    return (
        <>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            {project && timesheetItems !== undefined ? (
                                <ReactTable
                                    data={timesheetItems}
                                    columns={columns}
                                    minRows={1}
                                    defaultPageSize={10}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                            />
                            ) : (
                                <p>Er zijn geen prestatiegegevens ingegeven voor dit project.</p>
                            )}
                        </div>
                    </div>
                    <br />
                </Col>
            </Row>
        </>
    );
};

export default ProjectTimesheets;