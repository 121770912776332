import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import styled from "@xstyled/styled-components";
import { colors } from "@atlaskit/theme";
import PropTypes from "prop-types";
import API from "../../utils/API";
import Loader from '../common/loader';
import {useLocation} from "react-router-dom";
import { set } from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { User } from 'react-feather';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ProjectTaskboardColumn from './projecttaskboardcolumn';

import reorder, { reorderQuoteMap } from "./reorder";

const ProjectStatusBoard = (props) =>  {

    const Container = styled.div`
        background-color: transparent;
        min-height: 100vh;
        min-width: 100vw;
        display: inline-flex;
    `;

    const [isLoading, setIsLoading] = useState(true);
    const [projectStatusses, setProjectStatusses] = useState([]);
    const [projectTaskboard, setProjectTaskboard] = useState(null);

    const [projectsGroupedByStatusses, setProjectsGroupedByStatusses] = useState(null);

    useEffect(() => {
        loadProjectTaskboard();
    }, []);

    useEffect(() => {
        if (projectTaskboard !== null) {
            let statusses = [];
            projectTaskboard.projectFlow.projectFlowItems.map((projectFlowItem) => {
                statusses.push(projectFlowItem.projectStatus);
            });
            setProjectStatusses([...statusses]);  
        }    
    }, [projectTaskboard]);
    
    useEffect(() => {
        if (projectStatusses.length > 0) {
            let queryParamString = "?";
            projectStatusses.map((projectStatus, index) => {
                if (index > 0) {
                    queryParamString += "&";
                }
                queryParamString += "projectstatusses[]=" + projectStatus.id;
            });

            API.get('projectsGroupedByStatusses' + queryParamString).then(function(response) {
                setProjectsGroupedByStatusses(response.data);
            });
        }    
    }, [projectStatusses]);

    useEffect(() => {
        if (projectsGroupedByStatusses != null) {
            setIsLoading(false);
        }
    }, [projectsGroupedByStatusses]);

    const loadProjectTaskboard = () => {
        API.get('projecttaskboards/' + props.id).then(function(response) {
            setProjectTaskboard(response.data);
        });
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
    
        const source = result.source;
        const destination = result.destination;
    
        if (source.droppableId === destination.droppableId &&source.index === destination.index) {
          return;
        }

        const destinationProjectStatusId = destination.droppableId;
        const sourceProjectStatusId = source.droppableId;
        const draggebleProjectId = result.draggableId;

        const allProjects = {...projectsGroupedByStatusses};

        const newListOfSource = [];
        allProjects[sourceProjectStatusId].projects.map((project) => {
            if (draggebleProjectId === '' + project.id) {
                allProjects[destinationProjectStatusId].projects.push(project);
            } else {
                newListOfSource.push(project);
            }
        });
        allProjects[sourceProjectStatusId].projects = [...newListOfSource];

        setProjectsGroupedByStatusses(allProjects);

        API.put('/project/' + draggebleProjectId + '/changestatus/' + destinationProjectStatusId).then(function() {}).catch(function() {
            const allProjects = {...projectsGroupedByStatusses};

            const newListOfSource = [];
            allProjects[destinationProjectStatusId].projects.map((project) => {
                if (draggebleProjectId === '' + project.id) {
                    allProjects[sourceProjectStatusId].projects.push(project);
                } else {
                    newListOfSource.push(project);
                }
            });
            allProjects[destinationProjectStatusId].projects = [...newListOfSource];

            setProjectsGroupedByStatusses(allProjects);
        });
      };

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <DragDropContext onDragEnd={onDragEnd}>

                        <Droppable
                            droppableId="statusboard"
                            type="COLUMN"
                            direction="horizontal"
                            ignoreContainerClipping={Boolean('400px')}
                            isCombineEnabled={false}
                        >
                            {(provided) => (
                                <Container ref={provided.innerRef} {...provided.droppableProps}>
                                {projectStatusses.map((projectStatus, index) => (
                                    <ProjectTaskboardColumn
                                        key={projectStatus.id}
                                        index={index}
                                        title={projectStatus.name}
                                        projects={projectsGroupedByStatusses[projectStatus.id].projects}
                                        projectStatus={projectStatus}
                                        isScrollable={true}
                                        isCombineEnabled={false}
                                        useClone={false}
                                    />
                                ))}
                                {provided.placeholder}
                                </Container>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ProjectStatusBoard;