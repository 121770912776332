import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import useReactTable from '../../../hooks/useReactTable';

const ProjectOffers = (props) =>  {
    const history = useHistory();
    const [project, setProject] = useState(null);
    const [offers, setOffers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = [
        {
            Header : 'Offernummer',
            accessor : 'offerNumber',
            width: 150,
            Cell : cell => (
                <div>
                    <a href={`${process.env.PUBLIC_URL}/offer/detail/` + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : 'Status',
            accessor : 'status',
            width: 100,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Acties',
            accessor : 'id',
            Cell : cell => (
                <div>
                    {cell.original.status === 'DRAFT' ? (
                        <a href={'#'} onClick={(event) => changeOfferStatus(event, cell.value, 'FINISHED')}>Finaliseer</a>
                    ) : null}
                    {cell.original.status === 'FINISHED' ? (
                        <>
                            <a href={'#'} onClick={(event) => changeOfferStatus(event, cell.value, 'DRAFT')}>Wijzig naar DRAFT</a>
                        </>
                    ) : null}
                    {cell.original.status === 'SENT' ? (
                        <a href={'#'} onClick={(event) => changeOfferStatus(event, cell.value, 'FINISHED')}>Finaliseer</a>
                    ) : null}
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                cell.original.status === 'FINISHED' ? (
                    <>
                        <a href={'#'}  onClick={(event) => sendOffer(event, cell.value)}><i className="icofont icofont-2x icofont-email"></i></a>
                    </>
                ) : null
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                <>
                    <a href={'#'} onClick={() => downloadOffer(cell.original.id, cell.original.offerNumber)}><i className="icofont icofont-2x icofont-download"></i></a>
                </>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                cell.original.status === 'FINISHED' ? (
                    <>
                        <a href={'/offer/' + cell.original.id + '/invoice'}><i className="icofont icofont-2x icofont-instrument"></i></a>
                    </>
                ) : null
            )
        }
    ];

    useEffect(() => {
        setProject(props.project);
    }, []);

    useEffect(() => {
        if (project !== null) {
            setOffers(project.offers);
        }
    }, [project]);

    const changeOfferStatus = (event, offerId, status) => {
        event.preventDefault();
        setIsLoading(true);
        API.put('project/' + project.id + '/offer/' + offerId, {
            newStatus: status
        }).then(function(response) {
            setProject(response.data);
            setIsLoading(false);
        });
    };

    const sendOffer = (event, offerId) => {
        event.preventDefault();
        setIsLoading(true);
        API.get('mail/offer/' + offerId).then(() => {
            setIsLoading(false);
        });
    };

    const downloadOffer = (offerId, offerName) => {
        API.get('pdf/offer/' + offerId).then(function(response) {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:application/pdf;base64,${response.data.base64}`;
            downloadLink.download = offerName + ".pdf";
            downloadLink.click();
        });
    };

    const onClickCreateOffer = () => {
        setShowDialog(true);
    }

    const onCancelCreateOffer = () => {
        setShowDialog(false);
    }

    const onConfirmCreateOffer = () => {
        setShowDialog(false);
        history.push(`${process.env.PUBLIC_URL}/project/` + project.id + `/offer/create`);
    }

    return (
        <>
            <SweetAlert show={showDialog}
                        info
                        title="Nieuwe offerte aanmaken"
                        showCancel={true}
                        cancelBtnText={'Annuleren'}
                        onCancel={() => onCancelCreateOffer()}
                        onConfirm={() => onConfirmCreateOffer()}>
                <p>U staat op punt een nieuwe offerte te maken. </p>
                {project !== null && project !== undefined && !_.isEmpty(project.interventionNumber) ? (
                    <p>Er zal een offerte gemaakt worden met het offertenummer R{project.interventionNumber}</p>
                ) : (
                    <p>Er zal een algemeen offertenummer gegenereerd worden aangezien er <strong>geen interventienummer</strong> beschikbaar is.</p>
                )}
            </SweetAlert>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            {project && offers !== undefined ? (
                                <ReactTable 
                                    data={offers}
                                    columns={columns}
                                    minRows={0}
                                    defaultPageSize={10}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                                />
                            ) : (
                                <p>Er zijn geen offerten gedefinieerd voor dit project.</p>
                            )}
                        </div>
                    </div>
                    <br />
                    {project !== null ? (
                        <a href={'#'} onClick={(event) => onClickCreateOffer(event)} className={'btn btn-primary'}><span className={'fa fa-plus'}></span> Nieuwe offerte toevoegen</a>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default ProjectOffers;