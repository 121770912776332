import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import _ from "lodash";

const Breadcrumb = props => {
    const breadcrumb = props;

    return (
        <>  
            <Row>
                <Col lg={12}>
                    <Card className='mt-3' style={{borderRadius: '600px 200px', backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}>
                        <CardBody className='pb-2 pt-3'>
                            <table style={{width: '100%'}}>
                                <tr>
                                    <td>
                                        <h6 style={{backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}>
                                            <Link to="/dashboard/default"  style={{backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}>
                                                <i className="icofont icofont-home"></i>
                                            </Link>
                                            {breadcrumb.parent ? (
                                                <>
                                                    {_.isEmpty(breadcrumb.parentLink) ? (
                                                        <Fragment  style={{backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}>
                                                            <i className="icofont icofont-rounded-right"></i>
                                                            {breadcrumb.parent}
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment style={{backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR}}>
                                                            <i className="icofont icofont-rounded-right"></i>
                                                            <a style={{backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR}} href={breadcrumb.parentLink}>{breadcrumb.parent}</a>
                                                        </Fragment>
                                                    )}
                                                </>
                                            ) : null}
                                            <i className="icofont icofont-rounded-right" style={{backgroundColor: process.env.REACT_APP_STYLING_BREADCRUMB_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}></i>
                                            <strong>{breadcrumb.children}</strong>
                                        </h6>
                                    </td>
                                    <td style={{width:'250px', textAlign: 'right'}}>
                                        <h6>
                                            <Link title={'Offerten'} to={'/offerten'} className={'btn'} style={{fontSize: '1.5em', backgroundColor: 'transparent', color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}><i className="icofont icofont-list"></i></Link>
                                            
                                            <Link title={'Facturen'} to={'/facturen'} className={'btn'} style={{fontSize: '1.5em', backgroundColor: 'transparent !important', color: process.env.REACT_APP_STYLING_BREADCRUMB_FONT_COLOR }}><i className="icofont icofont-coins"></i></Link>
                                        </h6>
                                    </td>
                                </tr>
                            </table>
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

export default Breadcrumb