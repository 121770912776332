import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, Col, Row } from 'reactstrap';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import WorkSharpIcon from '@material-ui/icons/WorkSharp';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { serratedTabsStylesHook } from '@mui-treasury/styles/tabs';
import DocumentsInvoiceOverview from './types/documentsInvoiceOverview';
import DocumentsOffersOverview from './types/documentsOffersOverview';
import DocumentsTaxCertificatesOverview from './types/documentsTaxCertificatesOverview';

const DocumentsOverview = () =>  {
    const [tabValue, setTabValue] = useState(0);
    const tabsStyles = serratedTabsStylesHook.useTabs();
    const tabItemStyles = serratedTabsStylesHook.useTabItem({bgColor: '#ececec', selectedBgColor: '#dc6809'});

    return (
        <Fragment>
            <Breadcrumb>Documenten</Breadcrumb>
            <div className="container-fluid">
                <div className="edit-profile">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <AppBar position={'static'} elevation={0} style={{ backgroundColor: 'transparent' }}>
                                        <Toolbar style={{ overflow: 'hidden', minHeight: 72 }}>
                                            <Row>
                                                <Col lg={12}>
                                                    <Tabs style={{ alignSelf: 'flex-end' }} classes={tabsStyles} value={tabValue} onChange={(e, index) => setTabValue(index)}>
                                                        <Tab classes={tabItemStyles} disableRipple icon={<WorkSharpIcon />} label={"BTW-attesten"}></Tab>
                                                        <Tab classes={tabItemStyles} disableRipple icon={<DescriptionSharpIcon />} label={"Facturen"}></Tab>
                                                        <Tab classes={tabItemStyles} disableRipple icon={<ScheduleIcon />} label={"Offerten"}></Tab>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </Toolbar>
                                    </AppBar>
                                </Col>
                            </Row>
                            <Row className={'mt-3 ml-3 mr-3'}>
                                <Col lg={12}>
                                    {tabValue === 0 ? (
                                        <DocumentsTaxCertificatesOverview />
                                    ) : null}
                                    {tabValue === 1 ? (
                                        <DocumentsInvoiceOverview />
                                        
                                    ) : null}
                                    {tabValue === 2 ? (
                                        <DocumentsOffersOverview />
                                    ) : null}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default DocumentsOverview;