import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import API from '../../../../utils/API';
import _ from "lodash";
import { BookOpen, PenTool, Trash, Trash2 } from 'react-feather';
import CKEditors from "react-ckeditor-component";

const ProjectNotesCompact = (props) =>  {

    const [project, setProject] = useState(props.project);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id + '/notes').then(function(response) {
                setNotes(response.data);
            });
        }
    }, [project]);

    const addNote = (note) => {
        API.post('/project/' + project.id + '/note', {
            note
        }).then(function() {
            API.get('/project/' + project.id + '/notes').then(function(response) {
                setNotes(response.data);
            });
        });
    };

    const deleteNote = (noteId) => {
        API.delete('/project/' + project.id + '/note/' + noteId).then(function() {
            API.get('/project/' + project.id + '/notes').then(function(response) {
                setNotes(response.data);
            });
        });
    }

    return (
        <>
            <Card style={{border: '1px solid ' + process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                <CardBody>
                    <h4>Notities</h4>
                    {notes.length > 0 ? (
                        <>
                            {notes.map(note => {
                                return (
                                    <p dangerouslySetInnerHTML={{__html: note.note}} className='color: #'></p>
                                )
                            })}
                        </>
                    ) : (
                        <p>Er zijn geen notities gedefinieerd voor dit project.</p>   
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default ProjectNotesCompact;