import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import Loader from '../common/loader';
import useReactTable from '../../hooks/useReactTable';
import {Link} from "react-router-dom";
import {Col, Input, Row} from "reactstrap";

const HolidaysOverview = () =>  {

    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [holidays, setHolidays] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [{
        Header : 'Beschrijving',
        accessor : 'name',
        headerClassName : 'text-left',
        Cell: row => {
            return <Link to={`/holiday/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
        }
    },
    {
        Header: 'Begin',
        headerClassName: 'text-center',
        accessor: 'startDateTime',
        width: 200,
        className: 'text-center',
        Cell : row => {
            return <>{new Intl.DateTimeFormat("nl-BE", {
                year: "numeric",
                month: "long",
                day: "2-digit"
            }).format(new Date(row.row.startDateTime))}</>
        }
    },
    {
        Header: 'Einde',
        headerClassName: 'text-center',
        accessor: 'endDateTime',
        width: 200,
        className: 'text-center',
        Cell : row => {
            return <>{new Intl.DateTimeFormat("nl-BE", {
                year: "numeric",
                month: "long",
                day: "2-digit"
            }).format(new Date(row.row.endDateTime))}</>
        }
    }]);

    useEffect(() => {
        loadCurrentUser();
        loadProjects();
    }, []);

    useEffect(() => {
        globalSearch();
    }, [searchInput]);

    const loadCurrentUser = () => {
        API.get('myuser').then(function(response) {
            if (response.data !== undefined) {
                setCurrentUser(response.data[0]);
            }
        });
    }

    const loadProjects = () => {
        API.get('holidays').then(function(response) {
            setHolidays(response.data);
            setFiltered(response.data);
            setIsLoading(false);
        });
    }

    const handleChange = event => {
        setSearchInput(event.target.value);
    };

    const globalSearch = () => {
        let filteredData = holidays.filter(value => {
            return (
                value.name.toLowerCase().includes(searchInput.toLowerCase()) 
            );
        });
        setFiltered(filteredData);
    };

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Row>
                        <Col lg={6}>
                            <Breadcrumb parent="Projecten" title="Overzicht van verlofdagen" />
                        </Col>
                        <Col lg={6} className={'text-right'}>
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER') ? (
                                    <div className={'row mt-3'}>
                                        <div className={'col-md-12'}>
                                            <Link to={'/holiday/create'} className={'btn btn-primary'}>Nieuwe verlofdag aanmaken</Link>
                                        </div>
                                    </div>
                                ) : null}
                        </Col>
                    </Row>
                
                    <div className="container-fluid">
                        <div className="edit-profile">
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER' || currentUser.userRole === 'EMPLOYEE') ? (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Input
                                            size="large"
                                            name="searchInput"
                                            value={searchInput || ""}
                                            onChange={handleChange}
                                            label="Search"
                                            placeholder={'Zoeken'}
                                        />
                                        <br />
                                        <br />
                                        <ReactTable 
                                            data={filtered}
                                            columns={columns}
                                            minRows={0}
                                            defaultPageSize={10}
                                            className={"-striped -highlight"}
                                            showPagination={true}
                                            showPagination={true}
                                            previousText={'Vorige'}
                                            nextText={'Volgende'}
                                            pageText={'Pagina'}
                                            rowsText={'rijen'}
                                            ofText={'van'}
                                            getTheadThProps={getTheadThProps}
                                            getTdProps={getTdProps}
                                            getTrProps={getTrProps}
                                            getPaginationProps={getPaginationProps}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default HolidaysOverview;