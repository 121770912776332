import React from 'react';
import styled from '@xstyled/styled-components';
import { borderRadius, grid } from './constants';
import { Col, Row } from 'reactstrap';

const getBackgroundColor = (isDragging, isGroupedOver, authorColors) => {
  if (isDragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return '#EBECF0';
  }

  return '#ff8800';
};

const getBorderColor = (isDragging, authorColors) =>
  isDragging ? authorColors.hard : 'transparent';

const imageSize = 40;

const CloneBadge = styled.div`
  background: #79f2c0;
  bottom: ${grid / 2}px;
  border: 2px solid #57d9a3;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);
  height: ${imageSize}px;
  width: ${imageSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.a`
  border-radius: 0px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  background-color: #ff8800;
  box-shadow: ${({ isDragging }) => (isDragging ? `2px 2px 1px #A5ADBA` : 'none')};
  box-sizing: border-box;
  padding: ${grid}px;
  min-height: ${imageSize}px;
  margin-bottom: ${grid}px;
  user-select: none;

  color: #ffffff;

  &:hover,
  &:active {
    background-color: #ff8800;
    color: #ffffff;
    text-decoration: none;
  }

  &:focus {
    background-color: #ff8800;
    outline: none;
    border-color: ${(props) => props.colors.hard};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

const Content = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

const BlockQuote = styled.p`
  font-weight: bold;
`;

const Footer = styled.div`
  display: flex;
  margin-top: ${grid}px;
  align-items: center;
`;

const ProjectId = styled.small`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: right;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function ProjectTaskBoardItem(props) {
  const { project, isDragging, isGroupedOver, provided, style, isClone, index } = props;

  return (
    <Container
      href={'/project/detail/' + project.id}
      target={'_blank'}
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      colors={''}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={project.id}
      data-index={index}
      aria-label={`${project.name} quote ${project.description}`}
    >
      {isClone ? <CloneBadge>Clone</CloneBadge> : null}
      <Content>
        
        <Row>
            <Col lg={12}><i className="icofont icofont-tasks"></i><strong>{project.projectNumber}</strong> {project.name} <br /><br /></Col>
        </Row>
        <Row>
            <Col lg={2}><i className="icofont icofont-user"></i> </Col>
            <Col lg={10}>{project.customer ? (
            <>
                {project.customer.firstName + ' ' + project.customer.lastName}
            </>
        ) : null}</Col>
        </Row>
        <Row>
            <Col lg={2}><i className="icofont icofont-social-google-map"></i></Col>
            <Col lg={10}>{project.street + ' ' + project.houseNumber + ' ' + project.bus}<br /> {project.postalCode + ' ' + project.city}<br /> {project.country.name}</Col>
        </Row>
        
        <Footer>
          <ProjectId>
            project: {project.projectNumber}
          </ProjectId>
        </Footer>
      </Content>
    </Container>
  );
}

export default React.memo(ProjectTaskBoardItem);
