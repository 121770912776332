import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import API from "../../../utils/API";
import {Card, CardBody, Col, Row} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import nlLocale from '@fullcalendar/core/locales/nl';
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import _ from "lodash";
import GeoCode from "react-geocode";
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import Loader from '../../common/loader';
import TaskPlanningPerEmployee from '../../planning/taskPlanningPerEmployee';

const Project = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedTaskProjectNotes, setSelectedTaskProjectNotes] = useState([]);
    const [doAddHolidays, setDoAddHolidays] = useState(false);
    const [holidaysAdded, setHolidayAdded] = useState(false);
    const [tasksLoaded, setTasksLoaded] = useState(false);
    const [user, setUser] = useState(null);

    const [numberOfAllTasks, setNumberOfAllTasks] = useState(0);
    const [numberOfAllEvaluatedTasks, setNumberOfAllEvaluatedTasks] = useState(0);
    const [tasksToday, setTasksToday] = useState([]);
    const [tasksNextDay, setTasksNextDay] = useState([]);

    const ref = React.createRef();

    let timer;

    useDocumentTitle("My Workplanner - Dashboard");

    useEffect(() => {
        API.get('myuser').then(function(response) {
            setUser(response.data[0]);
        });
        API.get('my-current-or-next-first-task').then(function(response) {
            setSelectedTask(response.data);
        });
        API.get('mail/send-test-email');


        API.get('tasks/stats').then(function(response) {
            setNumberOfAllTasks(response.data.numberOfAllTasks);
            setNumberOfAllEvaluatedTasks(response.data.numberOfEvaluatedTasks);
        });
        API.get('tasks/my/today').then(function(response) {
            setTasksToday(response.data);
        });
        API.get('tasks/my/future').then(function(response) {
            setTasksNextDay(response.data);
        });
    }, []);

    useEffect(() => {
        if (selectedTask !== null && selectedTask.project !== null) {
            API.get('/project/' + selectedTask.project.id + '/notes').then(function(response) {
                setSelectedTaskProjectNotes(response.data);
            });
        }
    }, [selectedTask]);

    useEffect(() => {
        if (tasksLoaded === true) {
            setDoAddHolidays(true);
        }
    }, [calendarEvents]);

    useEffect(() => {
        if (holidaysAdded === false && doAddHolidays === true) {
            API.get('holidays').then(function(response) {
                let tempList = [...calendarEvents];
                let holidays = response.data;
                holidays.map((holiday) => {
                    tempList.push({
                        taskId: -1,
                        title: holiday.name,
                        backgroundColor: "#cc2200",
                        start: new Date(holiday.startDateTime),
                        end: new Date(holiday.endDateTime),
                        holiday: true
                    });
    
                });
                setCalendarEvents(tempList);
                setHolidayAdded(true);
                setDoAddHolidays(false);
            });
        }
        
    }, [doAddHolidays]);

    const handleEventClick = (eventInfo) => {
        API.get('/task/' + eventInfo.event.extendedProps.taskId).then(function(response) {
           setSelectedTask(response.data);
        });
    }

    const colorOfEvaluatedTasks = (countOfEvaluatedTasks, countOfTotalTasks) => {
        let colorCode = process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR;
        if ((countOfTotalTasks / countOfEvaluatedTasks) > 2) {
            colorCode = '#cc0000'
        } else if ((countOfTotalTasks / countOfEvaluatedTasks) > 1.5) {
            colorCode = '#FFA500';
        } else {
            colorCode = '#008800'
        }
        return colorCode;
    }

        return (
            <Fragment>
                <Breadcrumb title="Project">Dashboard</Breadcrumb>
                <div className="container-fluid">
                    <Row>
                        <Col lg={2}>
                            <Card>
                                <CardBody>
                                    <h4>Geëvalueerde taken</h4>
                                    <Row>
                                        <Col lg={6}>
                                            <Card style={{backgroundColor: colorOfEvaluatedTasks(numberOfAllEvaluatedTasks, numberOfAllTasks) , color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>
                                                <CardBody>
                                                    <p style={{textAlign: 'center', backgroundColor: colorOfEvaluatedTasks(numberOfAllEvaluatedTasks, numberOfAllTasks) , color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>{numberOfAllEvaluatedTasks} / {numberOfAllTasks}</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={6}>
                                            <Card style={{backgroundColor: colorOfEvaluatedTasks(numberOfAllEvaluatedTasks, numberOfAllTasks) , color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>
                                                <CardBody>
                                                    <p style={{textAlign: 'center', backgroundColor: colorOfEvaluatedTasks(numberOfAllEvaluatedTasks, numberOfAllTasks) , color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>{(numberOfAllEvaluatedTasks / numberOfAllTasks * 100).toFixed(1)}%</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={1} style={{backgroundColor: '#cc0000', margin: 0, padding: 0}}></Col>
                                        <Col lg={3}>- 50 %</Col>
                                        <Col lg={1} style={{backgroundColor: '#FFA500', margin: 0, padding: 0}}></Col>
                                        <Col lg={3}>- 75 %</Col>
                                        <Col lg={1} style={{backgroundColor: '#008800', margin: 0, padding: 0}}></Col>
                                        <Col lg={3}>+ 75%</Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={5}>
                            <Card>
                                <CardBody>
                                    <h4>Taken van vandaag</h4>
                                    <Row className='mt-3'>
                                        <Col lg={12}>
                                            <table className='table'>
                                                <tr>
                                                    <th>Naam</th>
                                                    <th>Uitvoeringsperiode</th>
                                                    <th>Status</th>
                                                    <th>Beschrijving</th>
                                                </tr>
                                                {tasksToday.map((task) => {
                                                    return (
                                                        <tr>
                                                            <td>{task.name}</td>
                                                            <td>{moment(task.plannedDateForExecution).format('HH:mm')} - {moment(task.plannedEndDateForExecution).format('HH:mm')}</td>
                                                            <td>{task.status}</td>
                                                            <td>{task.description.substring(0,100)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={5}>
                            <Card>
                                <CardBody>
                                    <h4>Taken voor volgende dag</h4>
                                    <Row className='mt-3'>
                                        <Col lg={12}>
                                            <table className='table'>
                                                <tr>
                                                    <th>Naam</th>
                                                    <th>Uitvoeringsperiode</th>
                                                    <th>Status</th>
                                                    <th>Beschrijving</th>
                                                </tr>
                                                {tasksNextDay.map((task) => {
                                                    return (
                                                        <tr>
                                                            <td>{task.name}</td>
                                                            <td>{moment(task.plannedDateForExecution).format('HH:mm')} - {moment(task.plannedEndDateForExecution).format('HH:mm')}</td>
                                                            <td>{task.status}</td>
                                                            <td>{task.description.substring(0,100)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <TaskPlanningPerEmployee handleEventClick={handleEventClick} />
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
}

export default Project;