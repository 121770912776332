import React, { Fragment } from 'react';
import API from "../../../utils/API";
import _ from "lodash";
import {Typeahead} from "react-bootstrap-typeahead";

class TaxInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            taxInfo : {
                isAdvantageousTaxRate: false,
                projectRelationShip : null,
                locationUsage : null,
                agreementType : null
            },
            locationUsages : [],
            projectRelationships : [],
            agreementTypes : [],
        };

        this._validateOnDemand = true;
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {
        var me = this;
        API.get('config/agreementtypes').then(function(response) {
            me.setState({agreementTypes: response.data});
        });

        API.get('config/locationusages').then(function(response) {
            me.setState({locationUsages: response.data});
        });

        API.get('config/projectrelationships').then(function(response) {
            me.setState({projectRelationships: response.data});
        });
    }

    componentWillUnmount() {
    }

    isValidated() {
        const isDataValid = this.validationCheck();
        if (isDataValid) {
            this.props.updateTaxStore(this.state.taxInfo);
        }
        return isDataValid;
    };

    validationCheck() {
        let isValid = true;
        return isValid;
    }

    changeProjectIsAdvantageousTaxRate = () => {
        this.setState((prevState) => ({taxInfo : { ...prevState.taxInfo, isAdvantageousTaxRate : !this.state.taxInfo.isAdvantageousTaxRate}}));
    };

    changeProjectLocationUsage = (selectedArray) => {
        let newLocationUsage = selectedArray[0];
        this.setState((prevState) => ({taxInfo: { ...prevState.taxInfo, locationUsage : newLocationUsage }}));
    };

    changeProjectProjectRelationships = (selectedArray) => {
        let newProjectRelationShip = selectedArray[0];
        this.setState((prevState) => ({taxInfo: { ...prevState.taxInfo, projectRelationShip : newProjectRelationShip }}));
    };

    changeProjectAgreementType = (selectedArray) => {
        let newAgreementType = selectedArray[0];
        this.setState((prevState) => ({taxInfo: { ...prevState.taxInfo, agreementType : newAgreementType }}));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <input type={'checkbox'} defaultChecked={this.state.taxInfo.isAdvantageousTaxRate} onChange={this.changeProjectIsAdvantageousTaxRate} /> 6% BTW
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Gebruik van de locatie waar de werken gebeuren</label>
                                    {this.state.locationUsages ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={this.changeProjectLocationUsage}
                                            clearButton
                                            labelKey="name"
                                            selected={this.state.taxInfo.locationUsage ? [this.state.taxInfo.locationUsage] : []}
                                            multiple={false}
                                            options={this.state.locationUsages}
                                            placeholder="Selecteer ..."
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Wie geeft de opdracht voor de te uitvoeren werken</label>
                                    {this.state.projectRelationships ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={this.changeProjectProjectRelationships}
                                            clearButton
                                            labelKey="name"
                                            selected={this.state.taxInfo.projectRelationShip ? [this.state.taxInfo.projectRelationShip] : []}
                                            multiple={false}
                                            options={this.state.projectRelationships}
                                            placeholder="Selecteer ..."
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Type document waarop de keuze van btw-tarief bepaald wordt</label>
                                    {this.state.agreementTypes ? (
                                        <Typeahead
                                            id="project-typeahead"
                                            onChange={this.changeProjectAgreementType}
                                            clearButton
                                            labelKey="name"
                                            selected={this.state.taxInfo.agreementType ? [this.state.taxInfo.agreementType] : []}
                                            multiple={false}
                                            options={this.state.agreementTypes}
                                            placeholder="Selecteer ..."
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </Fragment>
        );
    }
};

export default TaxInformation;