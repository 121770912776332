import React, { Fragment } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import {Typeahead} from "react-bootstrap-typeahead";
import Dropzone from "react-dropzone-uploader";
import CKEditors from "react-ckeditor-component";


class WorkOrderTemplateCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workOrderTemplate : {
                name : '',
                description :'',
                items : []
            },
            showOptions : false,
            newQuestion : {
                name : "",
                description : "",
                type : "",
                options : [],
                helpingImage : null
            },
            files : []
        };
    }

    changeTemplateName = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            workOrderTemplate: { ...prevState.workOrderTemplate, name : value }
        }));
    };

    changeTemplateDescription = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({
            workOrderTemplate: { ...prevState.workOrderTemplate, description : value }
        }));
    };

    changeTemplateQuestionName = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            newQuestion: { ...prevState.newQuestion, name : value }
        }));
    };

    changeTemplateQuestionDescription = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            newQuestion: { ...prevState.newQuestion, description : value }
        }));
    };

    readFile(file) {
        const reader = new FileReader();
        reader.onload = event => {
            let newFile = {
                fileName : file.name,
                mimeType : file.type,
                fileSize : file.size,
                file : event.target.result
            };
            this.setState((prevState) => ({
                newQuestion: { ...prevState.newQuestion, helpingImage : newFile }
            }));
        };
        reader.readAsDataURL(file);
    }

    handleChangeStatus = (fileWithMeta, status) => {

        if (status === 'done') {
            let files = this.state.files;
            files.push(fileWithMeta);
            this.setState((prevState) => ({files: files}));
        }
        this.readFile(fileWithMeta.file);
    };

    changeQuestionType = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({
            newQuestion: { ...prevState.newQuestion, type : value }
        }), function() {
            this.setState((prevState) => ({
                showOptions: value === 'multiplechoice'
            }));
        });

    };

    createNewWorkorderTemplate = (event) => {
        event.preventDefault();
        API.post('workordertemplate', this.state.workOrderTemplate);
    };

    changeQuestionOptions = (selectedArray) => {
        this.setState((prevState) => ({newQuestion: { ...prevState.newQuestion, options : selectedArray }}));
    };

    addNewItem = (event) => {
        let items = this.state.workOrderTemplate.items;
        items.push(this.state.newQuestion);

        this.setState((prevState) => ({
            workOrderTemplate: { ...prevState.workOrderTemplate, items : items }
        }), function() {
            this.setState((prevState) => ({newQuestion: { ...prevState.newQuestion, name : "" }}));
            this.setState((prevState) => ({newQuestion: { ...prevState.newQuestion, description : "" }}));
            this.setState((prevState) => ({newQuestion: { ...prevState.newQuestion, type : "" }}));
            this.setState((prevState) => ({newQuestion: { ...prevState.newQuestion, options : [] }}));
            this.setState((prevState) => ({newQuestion: { ...prevState.newQuestion, helpingImage : null }}));
            this.setState((prevState) => ({showOptions: false}));
            let files = this.state.files;
            files.forEach((file) => {
                file.remove();
            });
            this.setState((prevState) => ({files: []}));
        });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Sjablonenbeheer voor werkbonnen" title="Nieuw sjabloon aanmaken" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">

                            <div className="col-xl-6">
                                <div className="ribbon-wrapper card">
                                    <div className="card-body">
                                        <div className="ribbon ribbon-clip ribbon-primary">Algemene informatie</div>
                                        <div className={"row"}>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Naam</label>
                                                    <input className="form-control" type="text" onChange={this.changeTemplateName} value={this.state.workOrderTemplate.name} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Beschrijving</label>
                                                    <CKEditors
                                                        activeclassName="p10"
                                                        content={this.state.workOrderTemplate.description}
                                                        events={{
                                                            "change": this.changeTemplateDescription
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="ribbon-wrapper card">
                                    <div className="card-body">
                                        <div className="ribbon ribbon-clip ribbon-primary">Vragen van het sjabloon</div>
                                        <div className={"row"}>
                                            <div className="col-sm-12 col-xl-12">
                                                <div className={'row'}>
                                                    <div className={'col-sm-12'}>
                                                        <p>Ingegeven vragen</p>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-sm-12'}>
                                                        {this.state.workOrderTemplate.items.length > 0 ? (
                                                            this.state.workOrderTemplate.items.map((value, index) => {
                                                                return <p key={index}>{value.name}</p>
                                                            })
                                                        ) : (
                                                            <p>Geen vragen ingegeven</p>
                                                        )}

                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <div className={'row'}>
                                                    <div className={'col-sm-12 col-md-12'}>
                                                        <div className={'form-group'}>
                                                            <label className="form-label">Vraag</label>
                                                            <input className={'form-control'} type='text' value={this.state.newQuestion.name} onChange={this.changeTemplateQuestionName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-sm-12 col-md-12'}>
                                                        <div className={'form-group'}>
                                                            <label className="form-label">Beschrijving van de vraag</label>
                                                            <input className={'form-control'} type='text' value={this.state.newQuestion.description} onChange={this.changeTemplateQuestionDescription} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Illustratie</label>
                                                            <Dropzone
                                                                onChangeStatus={this.handleChangeStatus}
                                                                maxFiles={1}
                                                                multiple={false}
                                                                canCancel={false}
                                                                canRemove={false}
                                                                canRestart={false}
                                                                inputContent="Plaats hier een afbeelding ter verduidelijking van de vraag"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-sm-12 col-md-12'}>
                                                        <div className={'form-group'}>
                                                            <label className="form-label">Type vraag</label>
                                                            <select onChange={event => this.changeQuestionType(event)} value={this.state.newQuestion.type} className={'form-control'} >
                                                                <option value={'number'}>Decimaal</option>
                                                                <option value={'email'}>E-mailadres</option>
                                                                <option value={'multiplechoice'}>Keuze uit meerdere items</option>
                                                                <option value={'text'}>Tekst</option>
                                                                <option value={'phone'}>Telefoonnummer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.showOptions === false ? (
                                                    null
                                                ) : (
                                                    <div className={'row'}>
                                                        <div className={'col-sm-12 col-md-12'}>
                                                            <div className={'form-group'}>
                                                                <label className="form-label">Opties</label>
                                                                <Typeahead
                                                                    id="option-typeahead"
                                                                    allowNew={true}
                                                                    multiple={true}
                                                                    onChange={this.changeQuestionOptions}
                                                                    selected={this.state.newQuestion.options ? this.state.newQuestion.options : []}
                                                                    newSelectionPrefix="Nieuw optie toevoegen: "
                                                                    options={[]}
                                                                    placeholder="Type nieuw optie..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={'row'}>
                                                    <div className={'col-sm-12 col-md-12'}>
                                                        <div className={'form-group'}>
                                                            <a href={'#'} className={'form-control'} onClick={(event) => this.addNewItem(event)} ref={'questionDescription'} style={{backgroundColor: '#ff6600', color: '#ffffff'}}>Nieuwe vraag toevoegen</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <a href={'#'} className="btn btn-primary form-control" onClick={(event) => this.createNewWorkorderTemplate(event)}>Sjabloon aanmaken</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default WorkOrderTemplateCreate;