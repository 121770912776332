import React, { useState, useEffect, useMemo } from 'react';
import {Card, CardBody} from "reactstrap";
import API from '../../../../utils/API';
import _ from "lodash";
import ReactTable from 'react-table-6';
import useReactTable from '../../../../hooks/useReactTable';

const ProjectTodosCompact = (props) =>  {

    const [project, setProject] = useState(props.project);
    const [todoItems, setTodoItems] = useState([]);
    const [todoItem, setTodoItem] = useState(null);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Item',
            accessor : 'itemName',
            width: 150,
            Cell : cell => (
                <div>
                    <a href={`${process.env.PUBLIC_URL}/offer/detail/` + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : 'Status',
            accessor : 'status',
            width: 100,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Vervaldatum',
            accessor : 'dueDate',
            width: 150,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        }
    ]);

    useEffect(() => {
        setTodoItem({itemName: "", description: "Beschrijving", priority: "medium", dueDate : ""})
    }, []);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        }
    }, [project]);

    return (
        <Card style={{border: '1px solid ' + process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
            <CardBody>
                <h4>Todo Items</h4>
                {project && todoItems !== undefined && todoItems.length > 0 ? (
                    <ReactTable 
                        data={todoItems}
                        columns={columns}
                        minRows={0}
                        defaultPageSize={10}
                        className={"-striped -highlight"}
                        showPagination={false}
                        getTheadThProps={getTheadThProps}
                        getTdProps={getTdProps}
                        getTrProps={getTrProps}
                        getPaginationProps={getPaginationProps}
                    />
                ) : (
                    <p>Er zijn geen todo's gedefinieerd voor dit project.</p>
                )}
                        
            </CardBody>
        </Card>);
};

export default ProjectTodosCompact;