import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
//import "@fullcalendar/core/main.css"
//import "@fullcalendar/daygrid/main.css"
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import _ from "lodash";

class MyTasksOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            calendarView : true,
            calendarEvents : [],
            task : null,
            view : 'workOrder', // Can be 'workOrder' or 'task'
            workOrders : [],
            expiredWorkOrders : [],
            todayWorkOrders : [],
            tomorrowWorkOrders : [],
            restOfWeekWorkOrders : [],
            restOfMonthWorkOrders : [],
            restWorkOrders : [],
            tasks : [],
            expiredTasks : [],
            todayTasks : [],
            tomorrowTasks : [],
            restOfWeekTasks : [],
            restOfMonthTasks : [],
            restTasks : [],
            editView : false,
            openExpiredTasks : true,
            openTodayTasks : true,
            openTomorrowTasks : true,
            openRestOfWeekTasks : true,
            openRestOfMonthTasks : true,
            openRestTasks : true,
            openExpiredWorkOrders : true,
            openTodayWorkOrders : true,
            openTomorrowWorkOrders : true,
            openRestOfWeekWorkOrders : true,
            openRestOfMonthWorkOrders : true,
            openRestWorkOrders : true
        };
    }

    componentDidMount() {
        this.loadTasks();
    }


    handleDateClick = (arg) => {
    }

    handleEventClick = (eventInfo) => {
        let me = this;
        this.state.tasks.map((task) => {
            if (task.id === eventInfo.event.extendedProps.taskId) {
                me.setState({task: task});
            }
        })
    }

    loadTasks = () => {
        var me = this;
        API.get('mytasks').then(function(response) {
            me.setState({tasks: response.data}, function() {
                let list = [];
                me.state.tasks.map((task) => {

                    if (task.plannedDateForExecution !== null && task.plannedEndDateForExecution !== null) {
                        let origStartDate = new Date(task.plannedDateForExecution);
                        let startDate = new Date(task.plannedDateForExecution);
                        let endDate = new Date(task.plannedEndDateForExecution);
                        let firstItem = true;
                        for (let currentDate = new Date(task.plannedDateForExecution); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
                            if (currentDate.getDay() === 1 || currentDate.getDay() === 2 || currentDate.getDay() === 3 || currentDate.getDay() === 4 || currentDate.getDay() === 5) {
                                let begin = new Date(currentDate);
                                let end = new Date(currentDate);

                                if (firstItem === true) {
                                    begin.setHours(startDate.getHours());
                                    begin.setMinutes(startDate.getMinutes());
                                    if ((endDate.getTime() - startDate.getTime()) / 1000 * 3600 * 24 > 1) {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    } else {
                                        end.setHours(currentDate.getHours());
                                        end.setMinutes(currentDate.getMinutes());
                                    }
                                    firstItem = false;
                                } else {
                                    begin.setHours(8);
                                    begin.setMinutes(0);
                                    if (endDate.getFullYear() === end.getFullYear() && endDate.getMonth() === end.getMonth() && endDate.getDate() === end.getDate()) {
                                        end.setHours(endDate.getHours());
                                        end.setMinutes(endDate.getMinutes());
                                    } else {
                                        end.setHours(18);
                                        end.setMinutes(0);
                                    }
                                }
                                list.push({
                                    taskId: task.id,
                                    title: task.name,
                                    project: task.project !== null ? task.project.name : '',
                                    executor: task.responsibleUser !== null ? task.responsibleUser.firstName : '',
                                    backgroundColor: task.responsibleUser !== null ? task.responsibleUser.colorCode : '"FF6600',
                                    workorderId: task.workOrder !== null ? task.workOrder.id : -1,
                                    workorder: task.workOrder !== null ? task.workOrder.name : '',
                                    start: new Date(begin),
                                    end: new Date(end)
                                });
                            }
                        }
                    }

                });
                me.setState({calendarEvents: list});
            });
        });

        API.get('/my/workorders/expired').then(function(response) {
            me.setState({expiredWorkOrders: response.data});
        });

        API.get('/my/workorders/today').then(function(response) {
            me.setState({todayWorkOrders: response.data});
        });

        API.get('/my/workorders/tomorrow').then(function(response) {
            me.setState({tomorrowWorkOrders: response.data});
        });

        API.get('/my/workorders/restofweek').then(function(response) {
            me.setState({restOfWeekWorkOrders: response.data});
        });

        API.get('/my/workorders/restofmonth').then(function(response) {
            me.setState({restOfMonthWorkOrders: response.data});
        });

        API.get('/my/workorders/nextmonthandolder').then(function(response) {
            me.setState({restWorkOrders: response.data});
        });

        API.get('my/tasks/expired').then(function(response) {
            me.setState({expiredTasks: response.data});
        });

        API.get('/my/tasks/today').then(function(response) {
            me.setState({todayTasks: response.data});
        });

        API.get('/my/tasks/tomorrow').then(function(response) {
            me.setState({tomorrowTasks: response.data});
        });

        API.get('/my/tasks/restofweek').then(function(response) {
            me.setState({restOfWeekTasks: response.data});
        });

        API.get('/my/tasks/restofmonth').then(function(response) {
            me.setState({restOfWeekMonth: response.data});
        });

        API.get('/my/tasks/nextmonthandolder').then(function(response) {
            me.setState({restTasks: response.data});
        });
    };

    toggleExpiredTasks = () => {
        this.setState({openExpiredTasks : !this.state.openExpiredTasks});
    };

    toggleTodayTasks = () => {
        this.setState({openTodayTasks : !this.state.openTodayTasks});
    };

    toggleTomorrowTasks = () => {
        this.setState({openTomorrowTasks : !this.state.openTomorrowTasks});
    };

    toggleRestOfWeekTasks = () => {
        this.setState({openRestOfWeekTasks : !this.state.openRestOfWeekTasks});
    };

    toggleRestOfMonthTasks = () => {
        this.setState({openRestOfMonthTasks : !this.state.openRestOfMonthTasks});
    };

    toggleRestTasks = () => {
        this.setState({openRestTasks : !this.state.openRestTasks});
    };

    toggleExpiredWorkOrders = () => {
        this.setState({openExpiredWorkOrders : !this.state.openExpiredWorkOrders});
    };

    toggleTodayWorkOrders = () => {
        this.setState({openTodayWorkOrders : !this.state.openTodayWorkOrders});
    };

    toggleTomorrowWorkOrders = () => {
        this.setState({openTomorrowWorkOrders : !this.state.openTomorrowWorkOrders});
    };

    toggleRestOfWeekWorkOrders = () => {
        this.setState({openRestOfWeekWorkOrders : !this.state.openRestOfWeekWorkOrders});
    };

    toggleRestOfMonthWorkOrders = () => {
        this.setState({openRestOfMonthWorkOrders : !this.state.openRestOfMonthWorkOrders});
    };

    toggleRestWorkOrders = () => {
        this.setState({openRestWorkOrders : !this.state.openRestWorkOrders});
    };

    changeStateToBusy = (taskId) => {
        var me = this;
        API.put('/task/' + taskId + '/state/changetobusy').then(function() {
            me.loadTasks();
        });
    };

    changeStateToDone = (taskId) => {
        var me = this;
        API.put('/task/' + taskId + '/state/changetodone').then(function() {
            me.loadTasks();
        });
    };

    changeStateToNew = (taskId) => {
        var me = this;
        API.put('/task/' + taskId + '/state/changetonew').then(function() {
            me.loadTasks();
        });
    };

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;
                    insertNewFilter = 0;
                }
            });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    };

    openCalendarView = () => {
        this.setState({calendarView: true});
    }

    closeCalendarView = () => {
        this.setState({calendarView : false});
    }

    openTaskView = () => {
        this.setState({view: 'task'});
    };

    openWorkOrderView = () => {
        this.setState({view: 'workOrder'});
    };

    renderEventContent = (info) => {
        info.el.className = "custom-event-container fc-day-grid-event fc-h-event fc-event fc-start fc-end";
        // edit the child's class
        info.el.children[0].className = "custom-event-container fc-content";
        info.el.firstChild.innerHTML= "<strong>" +
            moment(info.event.start).tz('Europe/Brussels').format('H:mm') +
            "</strong> - <strong>" + moment(info.event.end).tz('Europe/Brussels').format('H:mm') + "</strong><br />" +
            info.event.title +
            "<br />" +
            info.event.extendedProps.project +
            "<br />" +
            info.event.extendedProps.workorder;
        return info.el;
    };

    render() {



        return (
            <Fragment>
                <Breadcrumb parent="Planning" title="Planning van taken en werkbonnen"/>

                <div className={'row'}>
                    <div className={'col-xl-12'}>
                        <div className="btn-group btn-group-square" role="group">
                            <button className={this.state.calendarView === true ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={this.openCalendarView} type="button">Kalender</button>
                            <button className={this.state.calendarView === false ? ('btn btn-primary') : ('btn btn-outline-primary') } onClick={this.closeCalendarView} type="button">Takenbord</button>
                        </div>
                    </div>
                </div>
                <br />
                <br />

                {this.state.calendarView ? (
                    <Row>
                        <Col lg={8}>
                            <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                          initialView={'dayGridMonth'}
                                          dateClick={(arg) => this.handleDateClick(arg)}
                                          eventRender={this.renderEventContent} events={this.state.calendarEvents}
                                          eventClick={(a,b,c) => this.handleEventClick(a,b,c)}
                            />
                        </Col>
                        <Col lg={4}>
                            {this.state.task !== null && this.state.task !== undefined ? (
                                <>
                                    <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                        <CardBody>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <h3>
                                                        TAAK: {this.state.task.name}
                                                        <Link
                                                            className={'btn float-right'}
                                                            style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                            target={'_blank'} to={'/task/detail/' + this.state.task.id}><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                    </h3>
                                                </Col>
                                            </Row>
                                            <Row className={'mt-3'}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <div dangerouslySetInnerHTML={{__html: this.state.task.description}} />
                                                </Col>
                                            </Row>
                                            <Row className={'mt-3'}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <table className={'table'}>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Uitvoeringsdatum</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>{new Intl.DateTimeFormat("nl-BE", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit"
                                                            }).format(new Date(this.state.task.plannedDateForExecution))}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Uitvoerders</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {this.state.task.responsibleUser.firstName} {this.state.task.responsibleUser.lastName}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Geschatte werkduur</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>{this.state.task.estimatedDaysWork} dagen en {this.state.task.estimatedHoursWork} uren</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>Verantwoordelijke</th>
                                                            <td style={{color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                                                {this.state.task.accountableUser.firstName} {this.state.task.accountableUser.lastName}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Col>
                                            </Row>
                                            <Row className={'mt-3'}>
                                                <Col sm={12} xs={12} md={12} lg={12}>

                                                    <h6><strong>KLANT</strong></h6>

                                                    {this.state.task.workOrder !== null && this.state.task.workOrder !== undefined ? (
                                                        this.state.task.workOrder.project !== null && this.state.task.workOrder.project !== undefined ? (
                                                            this.state.task.workOrder.project.customer !== null && this.state.task.workOrder.project.customer !== undefined ?
                                                                (
                                                                    <p>
                                                                        {this.state.task.workOrder.project.customer.firstName} {this.state.task.workOrder.project.customer.lastName}<br />
                                                                        {_.isEmpty(this.state.task.workOrder.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> {this.state.task.workOrder.project.customer.primaryPhone}<br /></>)}
                                                                        {_.isEmpty(this.state.task.workOrder.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> {this.state.task.workOrder.project.customer.primaryMobile}<br /></>)}
                                                                        {_.isEmpty(this.state.task.workOrder.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {this.state.task.workOrder.project.customer.primaryEmail}<br /></>)}
                                                                    </p>
                                                                ) : null
                                                        ) : null
                                                    ) : (
                                                        this.state.task.project !== null && this.state.task.project !== undefined ? (
                                                            this.state.task.project.customer !== null && this.state.task.project.customer !== undefined ?
                                                                (
                                                                    <p>
                                                                        {this.state.task.project.customer.firstName} {this.state.task.project.customer.lastName}<br />
                                                                        {_.isEmpty(this.state.task.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> {this.state.task.project.customer.primaryPhone}<br /></>)}
                                                                        {_.isEmpty(this.state.task.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> {this.state.task.project.customer.primaryMobile}<br /></>)}
                                                                        {_.isEmpty(this.state.task.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {this.state.task.project.customer.primaryEmail}<br /></>)}
                                                                    </p>
                                                                ) : null
                                                        ) : null
                                                    )}

                                                </Col>
                                            </Row>
                                            <Row className={'mt-3'}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <h6><strong>LOCATIE VAN UITVOERING</strong></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={8}>
                                                    <div className="text-left">

                                                        {this.state.task.project !== null && this.state.task.project !== undefined ? (
                                                            <>
                                                                {this.state.task.project.street} {this.state.task.project.houseNumber} {this.state.task.project.bus}<br />
                                                                {this.state.task.project.postalCode} {this.state.task.project.city}<br />
                                                                {this.state.task.project.country !== null ? this.state.task.project.country.name : null}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <a
                                                        className={'btn float-right'}
                                                        style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                        target={'_blank'}
                                                        href={'https://www.google.be/maps/dir//' + this.state.task.project.street + ' ' + this.state.task.project.houseNumber + ',' + this.state.task.project.postalCode + ' ' + this.state.task.project.city/* + '/@' + this.state.lat + ','+ this.state.lng*/}
                                                    >
                                                        <i className="icofont icofont-2x icofont-direction-sign"></i>
                                                    </a>
                                                </Col>
                                            </Row>
                                            {this.state.task.workOrder !== null && this.state.task.workOrder !== undefined ? (
                                                <>
                                                    <Row className={'mt-3'}>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <h6>
                                                                <strong>WERKBON: {this.state.task.workOrder.name}</strong>
                                                                <Link
                                                                    className={'btn float-right'}
                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                    target={'_blank'}
                                                                    to={'/workorder/detail/'+ this.state.task.workOrder.id}
                                                                ><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <div dangerouslySetInnerHTML={{__html: this.state.task.workOrder.description}} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                            {this.state.task.project !== null && this.state.task.project !== undefined ? (
                                                <>
                                                    <Row className={'mt-3'}>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <h6>
                                                                <strong>PROJECT: {this.state.task.project.name}</strong>
                                                                <Link
                                                                    className={'btn float-right'}
                                                                    style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                                                    target={'_blank'}
                                                                    to={'/project/detail/'+ this.state.task.project.id}
                                                                ><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <div dangerouslySetInnerHTML={{__html: this.state.task.project.description}} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                        </CardBody>
                                    </Card>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                ) : (
                    <>
                        <div className={'row'}>
                            <div className={'col-xl-12'}>
                                <div className="btn-group btn-group-square" role="group">
                                    <button className={this.state.view === 'task' ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={this.openTaskView} type="button">Taken</button>
                                    <button className={this.state.view === 'workOrder' ? ('btn btn-primary') : ('btn btn-outline-primary') } onClick={this.openWorkOrderView} type="button">Werkbonnen</button>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />

                        {this.state.view === 'workOrder' ? (
                            <div className="container-fluid">
                                <div className="edit-profile">
                                    {this.getGroupedWorkOrder(
                                        'Gepland in het verleden',
                                        this.state.expiredWorkOrders,
                                        '#F08080',
                                        '#ffffff',
                                        '#cc2200',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleExpiredWorkOrders,
                                        this.state.openExpiredWorkOrders)}

                                    {this.getGroupedWorkOrder(
                                        'Gepland voor vandaag',
                                        this.state.todayWorkOrders,
                                        '#3CB371',
                                        '#ffffff',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleTodayWorkOrders,
                                        this.state.openTodayWorkOrders)}

                                    {this.getGroupedWorkOrder(
                                        'Gepland voor morgen',
                                        this.state.tomorrowWorkOrders,
                                        '#9ACD32',
                                        '#ffffff',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleTomorrowWorkOrders,
                                        this.state.openTomorrowWorkOrders)}

                                    {this.getGroupedWorkOrder(
                                        'Gepland voor deze maand',
                                        this.state.restOfMonthWorkOrders,
                                        '#F0E68C',
                                        '#ffffff',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleRestOfMonthWorkOrders,
                                        this.state.openRestOfMonthWorkOrders)}

                                    {this.getGroupedWorkOrder(
                                        'Gepland voor volgende maand en later',
                                        this.state.restWorkOrders,
                                        '#ffffff',
                                        '#555555',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleRestWorkOrders,
                                        this.state.openRestWorkOrders)}
                                </div>
                            </div>
                        ) : (
                            <div className="container-fluid">
                                <div className="edit-profile">
                                    {this.getGroupedItem(
                                        'Gepland in het verleden',
                                        this.state.expiredTasks,
                                        '#F08080',
                                        '#ffffff',
                                        '#cc2200',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleExpiredTasks,
                                        this.state.openExpiredTasks)}

                                    {this.getGroupedItem(
                                        'Gepland voor vandaag',
                                        this.state.todayTasks,
                                        '#3CB371',
                                        '#ffffff',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleTodayTasks,
                                        this.state.openTodayTasks)}

                                    {this.getGroupedItem(
                                        'Gepland voor morgen',
                                        this.state.tomorrowTasks,
                                        '#9ACD32',
                                        '#ffffff',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleTomorrowTasks,
                                        this.state.openTomorrowTasks)}

                                    {this.getGroupedItem(
                                        'Gepland voor deze maand',
                                        this.state.restOfMonthTasks,
                                        '#F0E68C',
                                        '#ffffff',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleRestOfMonthTasks,
                                        this.state.openRestOfMonthTasks)}

                                    {this.getGroupedItem(
                                        'Gepland voor volgende maand en later',
                                        this.state.restTasks,
                                        '#ffffff',
                                        '#555555',
                                        '#ff6600',
                                        '#ffffff',
                                        '#ffffff',
                                        '#555555',
                                        this.toggleRestTasks,
                                        this.state.openRestTasks)}
                                </div>
                            </div>

                        )}
                    </>
                )}
            </Fragment>
        );
    }

    getGroupedWorkOrder(title, workOrders, cardBackground, cardColor, titleBackground, titleColor, itemBackground, itemColor, toggleFunction, openState) {
        return <div className={'card'} style={{backgroundColor : cardBackground, color: cardColor, borderRadius: '0 0 0 0'}}>
            <div className={'card-body'}>
                <div className={'row'}>
                    <div className={'col-xl-8'}>
                        <h4>{title}</h4>
                    </div>
                    <div className={'col-xl-4 text-right'}>
                        {openState ? (
                            <h4><i className="icofont icofont-minus-square" onClick={toggleFunction}></i></h4>
                        ) : (
                            <h4><i className="icofont icofont-plus-square" onClick={toggleFunction}></i></h4>
                        )}
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-xl-12'}>
                        <hr />
                    </div>
                </div>
                {openState ? (
                    <div className={'row'}>
                        <table className={'table'}>
                        {workOrders.map((workOrder, index) => {
                            return (
                                <tr style={{backgroundColor: '#ffffff', color: '#000000', opacity: '0.5'}}>
                                    <td style={{border: '1px solid #ffffff' }}>{workOrder.name}</td>
                                    <td style={{border: '1px solid #ffffff' }}>{workOrder.project !== null ? workOrder.project.name : ''}</td>
                                    <td>
                                        {workOrder.tasks !== null && workOrder.tasks !== undefined ? (
                                            workOrder.tasks.map((task, index) => {
                                                return (
                                                    <>
                                                        {task.name} - {task.plannedDateForExecution !== null ? new Intl.DateTimeFormat("nl-BE", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "2-digit"
                                                    }).format(new Date(task.plannedDateForExecution)) : ' (geen tijdsindicatie)'}<br />
                                                    </>
                                                )
                                            })
                                        ) : null}
                                    </td>
                                    <td style={{border: '1px solid #ffffff' }}>{new Intl.NumberFormat("nl-BE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(workOrder.fulfillmentPercentage)}% ingevuld</td>
                                    <td style={{border: '1px solid #ffffff' }}><a href={'/workorder/detail/' + workOrder.id} target={'_blank'}><h4 style={{fontWeight:800}}>...</h4></a></td>
                                </tr>
                            );


                            return (
                                <div className={'col-lg-3'}>
                                    <div className="ribbon-wrapper card"
                                         style={{border: '1px solid ' + titleBackground, borderRadius: '0'}}>
                                        <div className="card-body" style={{backgroundColor: itemBackground, color: itemColor}}>
                                            <div className="ribbon ribbon-clip ribbon-primary" style={{backgroundColor: titleBackground, color: titleColor}}>{workOrder.name}</div>
                                            <p>{workOrder.description}</p>
                                            <table className={'table'}>
                                                <tbody>
                                                <tr>
                                                    <td>Project:</td>
                                                    <td>{workOrder.project.name}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className={'row'}>
                                                <div className={'col-lg-12'}>
                                                    <br />
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col-lg-12 text-right'}>
                                                    <a href={'/workOrder/detail/' + workOrder.id} target={'_blank'}>Meer informatie ...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        </table>
                    </div>
                ) : null}
            </div>
        </div>
    }

    getGroupedItem(title, tasks, cardBackground, cardColor, titleBackground, titleColor, itemBackground, itemColor, toggleFunction, openState) {
        return <div className={'card'} style={{backgroundColor : cardBackground, color: cardColor}}>
            <div className={'card-body'}>
                <div className={'row'}>
                    <div className={'col-xl-8'}>
                        <h4>{title}</h4>
                    </div>
                    <div className={'col-xl-4 text-right'}>
                        {openState ? (
                            <h4><i className="icofont icofont-minus-square" onClick={toggleFunction}></i></h4>
                        ) : (
                            <h4><i className="icofont icofont-plus-square" onClick={toggleFunction}></i></h4>
                        )}
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-xl-12'}>
                        <hr />
                    </div>
                </div>
                {openState ? (
                    <div className={'row'}>
                        <table className={'table'}>
                            {tasks.map((task, index) => {
                                return (
                                    <tr style={{backgroundColor: '#ffffff', color: '#000000', opacity: '0.5'}}>
                                        <td style={{border: '1px solid #ffffff' }}>{task.name}</td>
                                        <td style={{border: '1px solid #ffffff' }}>
                                            {task.plannedDateForExecution !== null ? new Intl.DateTimeFormat("nl-BE", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                            }).format(new Date(task.plannedDateForExecution)) : ' - '}</td>
                                        <td style={{border: '1px solid #ffffff' }}>{task.estimatedDaysOfWork} dagen</td>
                                        <td style={{border: '1px solid #ffffff' }}>{task.estimatedHoursOfWork} uren</td>
                                        <td style={{border: '1px solid #ffffff' }}>
                                            {(task.project !== undefined && task.project !== null) ? (
                                                <span>{task.project.name}</span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </td>
                                        <td style={{border: '1px solid #ffffff' }}>
                                            {(task.workOrder !== undefined && task.workOrder !== null) ? (
                                                <span>{task.workOrder.name}</span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </td>
                                        <td style={{border: '1px solid #ffffff' }}>{task.status}</td>
                                        <td style={{border: '1px solid #ffffff' }}>{task.responsibleUser !== null ? task.responsibleUser.firstName + ' ' + task.responsibleUser.lastName : ''}</td>
                                        <td style={{border: '1px solid #ffffff' }}><a href={'/task/detail/' + task.id} target={'_blank'}><h4 style={{fontWeight:800}}>...</h4></a></td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                ) : null}
            </div>
        </div>
    }
}

export default MyTasksOverview;