import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import _ from "lodash";
import useReactTable from '../../hooks/useReactTable';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const InvoiceOverview = () =>  {
    const [invoices, setInvoices] = useState([]);

    const [searchInput, setSearchInput] = useState("");
    const [pages, setPages] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const invoiceColumns = useMemo(() => [{
        Header: 'Factuurnummer',
        accessor: 'invoiceNumber',
        headerClassName: 'text-left',
        Cell: row => {
            return <Link to={`/invoice/detail/` + row.row._original.id}
                         target={'_parent'}>{row.row.invoiceNumber}</Link>
        }
    },
    {
        Header: 'Klant',
        accessor: 'billToAddress',
        headerClassName: 'text-left',
        Cell: row => {
            return <>{row.row.billToAddress.firstName} {row.row.billToAddress.lastName}</>
        }
    },
    {
        Header: 'Project',
        accessor: 'project',
        headerClassName: 'text-left',
        Cell: row => {
            return <>{row.row.project != null ? row.row.project.name : ''}</>
        }
    },
    {
        Header: 'Factuurdatum',
        accessor: 'creationDate',
        headerClassName: 'text-left',
        Cell: row => {
            return new Intl.DateTimeFormat("nl-BE", {
                year: "numeric",
                month: "long",
                day: "2-digit"
            }).format(new Date(row.row.creationDate))
        }
    },
    {
        Header: 'Vervaldatum',
        accessor: 'expiryDate',
        headerClassName: 'text-left',
        Cell: row => {
            let isExpired = new Date(row.row.expiryDate) < new Date() && !row.row.paid;
            return (
                isExpired ? (
                    <span style={{
                        backgroundColor: "#cc2200",
                        color: '#ffffff',
                        fontWeight: '300',
                        borderRadius: '10px',
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    }}>
                        {new Intl.DateTimeFormat("nl-BE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(new Date(row.row.expiryDate))}
                    </span>
                ) : (
                    <span>
                        {new Intl.DateTimeFormat("nl-BE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(new Date(row.row.expiryDate))}
                    </span>
                )

            )
        }
    },
    {
    Header : 'Bedrag',
        accessor : 'totalIncl',
        headerClassName : 'text-left',
        Cell: row => {
            return new Intl.NumberFormat("nl-BE", {style: 'currency', currency: 'EUR'}).format(new Date(row.row.totalIncl))
        }
    },
    {
        Header : 'Betaald',
        accessor : 'paid',
        headerClassName : 'text-center',
        Cell: row => {
            return row.row.paid ? <strong><i className="icofont icofont-tick-mark"></i></strong> : <strong><i className="icofont icofont-close"></i></strong>
        }
    },
    {
        Header: '',
        accessor: 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <Link><i className="icofont icofont-2x icofont-email"></i></Link>
        }
    },
    {
        Header: '',
        accessor: 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return row.row._original.paid ? (
                <Link onClick={(event) => revertPayInvoice(event, row.row.id)}><i className="icofont icofont-2x icofont-undo"></i></Link>
            ) : (
                <Link onClick={(event) => payInvoice(event, row.row.id)}><i className="icofont icofont-2x icofont-money"></i></Link>
            )
        }
    },
    {
        Header: '',
        accessor: 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <Link onClick={(event) => downloadPdf(event, row.row.id, row.row._original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
        }
    },
    {
        Header: '',
        accessor: 'id',
        width: 75,
        headerClassName: 'text-center',
        Cell: row => {
            return <Link to={`/invoice/` + row.row._original.id + `/creditnote`}><i className="icofont icofont-2x icofont-paper"></i></Link>
        }
    }]);
    
    useEffect(() => {
        loadInvoices();
    }, []);

    const loadInvoices = () => {
        API.get('invoices?pageNumber=0&numberOfRows=10&search_input=' + searchInput).then(function(response) {
            setInvoices(response.data.items);
            setIsLoading(false);
            setPages(Math.ceil(response.data.totalItemCount / response.data.itemNumberPerPage));
        });
    };

    const payInvoice = (event, invoiceId) => {
        event.preventDefault();
        API.put('invoice/' + invoiceId + '/pay').then(function() {
            API.get('invoices').then(function(response) {
                setInvoices(response.data);
            });
        });
    }

    const downloadPdf = (event, invoiceId, invoiceNumber) => {
        event.preventDefault();
        API.get('/pdf/invoice/' + invoiceId).then(function(response) {
            const linkId = 'link-invoice-id-' + invoiceId;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${invoiceNumber}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
        });
        return false;
    };

    const revertPayInvoice = (event, invoiceId) => {
        event.preventDefault();
        API.put('invoice/' + invoiceId + '/revertpay').then(function() {
            API.get('invoices').then(function(response) {
                setInvoices(response.data);
            });
        });
    }

    return (
        <>
            <Breadcrumb>Facturen</Breadcrumb>
            <div className="container-fluid">
                <div className="edit-profile">
                    <Row>
                        <Col lg={12}>
                        {invoices.length > 0 ? (
                            <ReactTable 
                                pages={pages}
                                data={invoices}
                                columns={invoiceColumns}
                                minRows={0}
                                manual
                                defaultPageSize={10}
                                className={"-striped -highlight"}
                                showPagination={true}
                                previousText={'Vorige'}
                                nextText={'Volgende'}
                                pageText={'Pagina'}
                                rowsText={'rijen'}
                                ofText={'van'}
                                getTheadThProps={getTheadThProps}
                                getTdProps={getTdProps}
                                getTrProps={getTrProps}
                                getPaginationProps={getPaginationProps}
                                onFetchData={(state, instance) => {
                                    API.get('invoices?pageNumber=' + state.page + '&numberOfRows=' + state.pageSize + '&search_input=' + searchInput).then(function(response) {
                                        setInvoices(response.data.items);
                                        setIsLoading(false);
                                        setPages(Math.ceil(response.data.totalItemCount / response.data.itemNumberPerPage));
                                    });
                                }}
                                />
                        ) : (
                            <p>Er zijn geen facturen aanwezig in het systeem</p>
                        )}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default InvoiceOverview;