import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";


class OffersOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            offers : [],
            columns : [
                {
                    Header : 'Offertenummer',
                    accessor : 'offerNumber',
                    headerClassName : 'text-left',
                    width: 150,
                    Cell: row => {
                        return <Link to={`/offer/detail/` + row.row._original.id} target={'_parent'}>{row.row.offerNumber}</Link>
                    }
                },
                {
                    Header : 'Klant',
                    accessor : 'customer',
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <>{row.row.customer.firstName} {row.row.customer.lastName}</>
                    }
                },
                {
                    Header : 'Project',
                    accessor : 'project',
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <>{row.row.project != null ? <Link to={'/project/detail/' + row.row.project.id} target={'_blankk'}>{row.row.project.name}</Link> : ''}</>
                    }
                },
                {
                    Header : 'Status',
                    accessor : 'status',
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <>{row.row.status}</>
                    }
                },
                {
                    Header : 'Offertedatum',
                    accessor : 'creationDate',
                    headerClassName : 'text-left',
                    width: 150,
                    Cell: row => {
                        return new Intl.DateTimeFormat("nl-BE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(new Date(row.row.creationDate))
                    }
                },
                {
                    Header : 'Vervaldatum',
                    accessor : 'expiryDate',
                    headerClassName : 'text-left',
                    width: 150,
                    Cell: row => {
                        return new Intl.DateTimeFormat("nl-BE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(new Date(row.row.expiryDate))
                    }
                },
                {
                    Header : () => {return (<i className="icofont icofont-2x icofont-thumbs-up"></i>)},
                    accessor : 'approved',
                    headerClassName : 'text-center',
                    className: 'text-center',
                    Cell: row => {
                        return <>{row.row.approved ? <strong><i className="icofont icofont-tick-mark"></i></strong> : <strong><i className="icofont icofont-close"></i></strong>}</>
                    }
                },
                {
                    Header : () => {return (<i className="icofont icofont-2x icofont-thumbs-down"></i>)},
                    accessor : 'refused',
                    headerClassName : 'text-center',
                    className: 'text-center',
                    Cell: row => {
                        return <>{row.row.refused ? <strong><i className="icofont icofont-tick-mark"></i></strong> : <strong><i className="icofont icofont-close"></i></strong>}</>
                    }
                },
                {
                    Header: '',
                    accessor : 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <>
                                <Link onClick={(event) => this.approveOffer(event, row.row.id)}><i className="icofont icofont-2x icofont-tick-mark"></i></Link>
                            </>
                    }
                },
                {
                    Header: '',
                    accessor : 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <>
                            <Link onClick={(event) => this.rejectOffer(event, row.row.id)}><i className="icofont icofont-2x icofont-close"></i></Link>
                        </>
                    }
                },
                {
                    Header: '',
                    accessor : 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <>
                            <Link onClick={(event) => this.resetOffer(event, row.row.id)}><i className="icofont icofont-2x icofont-undo"></i></Link>
                        </>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link><i className="icofont icofont-2x icofont-email"></i></Link>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link onClick={(event) => this.downloadPdf(event, row.row.id, row.row._original.offerNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
                    }
                }
            ]
        };
    }

    componentDidMount() {
        let me = this;
        API.get('offers').then(function(response) {
            me.setState({offers: response.data});
        });
    }

    downloadPdf = (event, offerId, offerNumber) => {
        event.preventDefault();
        API.get('/pdf/offer/' + offerId).then(function(response) {
            const linkId = `link-invoice-id-${offerId}`;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${offerNumber}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
        });

        return false;
    };

    approveOffer = (event, offerId) => {
        event.preventDefault();
        let me = this;
        API.put('offer/' + offerId + '/approve').then(function() {
            API.get('offers').then(function(response) {
                me.setState({offers: response.data});
            });
        });
    }

    resetOffer = (event, offerId) => {
        event.preventDefault();
        let me = this;
        API.put('offer/' + offerId + '/cancelapprovereject').then(function() {
            API.get('offers').then(function(response) {
                me.setState({offers: response.data});
            });
        });
    }

    rejectOffer = (event, offerId) => {
        event.preventDefault();
        let me = this;
        API.put('offer/' + offerId + '/reject').then(function() {
            API.get('offers').then(function(response) {
                me.setState({offers: response.data});
            });
        });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Offerten" title="Overzicht van offerten" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.offers}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   showPagination={true}
                                   previousText={'Vorige'}
                                   nextText={'Volgende'}
                                   pageText={'Pagina'}
                                   rowsText={'rijen'}
                                   ofText={'van'}
                                   getTheadThProps={(state, rowInfo, column) => ({
                                       style: {
                                           fontWeight: '600',
                                           height: '50px'
                                       },
                                   })}
                                   getTdProps={(state, rowInfo, column) => ({
                                       style: {
                                           height: '40px',
                                       },
                                   })}
                               />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default OffersOverview;