import React, { Fragment } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import SweetAlert from "react-bootstrap-sweetalert";

class ExternalPartyDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            externalParty : null,
            editableExternalParty : null,
            countries : [],
            editView : false,
            errorsOnForm : {

            },
            showDeleteDialog: false,
            showSuccessDialog : false
        };
    }

    componentDidMount() {
        let me = this;
        API.get('config/countries').then(function(response) {
            me.setState({countries: response.data});
        });
        this.loadExternalParty();
    }

    onCancel = () => {
        this.setState((prevState) => ({showDeleteDialog: false}));
    };

    loadExternalParty = () => {
        const { id } = this.props.match.params;
        let me = this;
        API.get('externalparty/' + id).then(function(response) {
            me.setState({externalParty: response.data});
            me.setState({editableExternalParty: response.data});
        });
    };

    updateExternalParty = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me = this;
        API.put('externalparty/' + id, {
            name: this.state.editableExternalParty.name,
            street: this.state.editableExternalParty.street,
            houseNumber: this.state.editableExternalParty.houseNumber,
            bus: this.state.editableExternalParty.bus,
            postalCode: this.state.editableExternalParty.postalCode,
            city: this.state.editableExternalParty.city,
            country: this.state.editableExternalParty.country.id,
            email: this.state.editableExternalParty.email,
            phone: this.state.editableExternalParty.phone,
            mobile: this.state.editableExternalParty.mobile
        }).then(function() {
            me.loadExternalParty();
        });
    };

    changeName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, name: value}}));
    };

    changeStreet = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableExternalParty, street: value}}));
    };

    changeHouseNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, houseNumber: value}}));
    };

    changeBus = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, bus: value}}));
    };

    changePostalCode = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, postalCode: value}}));
    };

    changeCity = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, city: value}}));
    };

    changeCountry = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, country: value}}));
    };

    changePhone = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, phone: value}}));
    };

    changeMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, mobile: value}}));
    };

    changeEmail = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, email: value}}));
    };

    changeWebsite = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableExternalParty: { ...prevState.editableExternalParty, website: value}}));
    };

    openEditView = () => {
        this.setState({editView: true});
    };

    closeEditView = () => {
        this.setState({editView: false});
    };

    deleteExternalParty = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({showDeleteDialog: true}));
    };

    doDeleteExternalParty = () => {
        this.setState((prevState) => ({showDeleteDialog: false}));
        const { id } = this.props.match.params;
        API.delete('externalparty/' + id).then(function() {
            window.location.href = "/externalparties/overview";
        });
    };

    render() {
        return (
            this.state.externalParty ? (
                <Fragment>
                    <Breadcrumb parent="Externe partijen" title={'Detail externe partij ' + this.state.externalParty.name} />
                    <div className="container-fluid">
                        <div className={'card'}>
                            <div>
                                <div className={'row product-page-main'}>
                                    <div className={'col-xl-8'}>
                                        <div className="btn-group btn-group-square" role="group">
                                            <button className={this.state.editView ? ('btn btn-outline-primary') : ('btn btn-primary')} onClick={this.closeEditView} type="button">Overzicht</button>
                                            <button className={this.state.editView ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={this.openEditView} type="button">Wijzig</button>
                                        </div>
                                    </div>
                                    <div className={'col-xl-4 text-right'}>
                                        <SweetAlert show={this.state.showDeleteDialog} warning title="Externe partij verwijderen" showCancel={true}  cancelBtnText={'Annuleren'} onCancel={() => this.onCancel()} onConfirm={() => this.doDeleteExternalParty()}>
                                            Ben je zeker dat je deze externe partij wilt verwijderen?
                                        </SweetAlert>
                                        <button className={'btn btn-danger'} onClick={(event) => this.deleteExternalParty(event)}>Verwijder externe partij</button>
                                    </div>
                                </div>
                                <div className={'row product-page-main'}>
                                    {this.state.editView ? (
                                        <form className="card"  style={{width: '100%'}} >
                                            <div className="card-body">
                                                <SweetAlert show={this.state.showSuccessDialog} success title="Succesvol toegevoegd" onConfirm={() => this.loadExternalParty()}>
                                                    Je externe partij is succesvol gewijzigd!
                                                </SweetAlert>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Naam:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'name'} className={'form-control'} value={this.state.editableExternalParty.name} onChange={this.changeCompanyName} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                E-mail:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'email'} className={'form-control'} value={this.state.editableExternalParty.email} onChange={this.changeEmail} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Straat:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'city'} className={'form-control'} value={this.state.editableExternalParty.street} onChange={this.changeStreet} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Huisnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'houseNumber'} className={'form-control'} value={this.state.editableExternalParty.houseNumber} onChange={this.changeHouseNumber} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Bus:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'bus'} className={'form-control'} value={this.state.editableExternalParty.bus} onChange={this.changeBus} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Postcode:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'postalCode'} className={'form-control'} value={this.state.editableExternalParty.postalCode} onChange={this.changePostalCode} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Gemeente:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'city'} className={'form-control'} value={this.state.editableExternalParty.city} onChange={this.changeCity} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Land:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <select onChange={this.changeCountry} className={'form-control'} name={'countryId'} value={this.state.editableExternalParty.country.id}>
                                                                    <option value={'0'}>---</option>
                                                                    {this.state.countries.map(function(country) {
                                                                        return (
                                                                            <option value={country.id}>{country.name}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Telefoonnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'phone'} className={'form-control'} value={this.state.editableExternalParty.phone} onChange={this.changePhone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                GSMnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'mobile'} className={'form-control'} value={this.state.editableExternalParty.mobile} onChange={this.changeMobile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right">
                                                <button className="btn btn-primary" type="submit" onClick={(event) => this.updateExternalParty(event)}>Externe partij wijzigen</button>
                                            </div>
                                        </form>
                                    ) : (
                                        <div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Naam</strong></h6>
                                                <p>{this.state.externalParty.name}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Adres</strong></h6>
                                                <p>
                                                    {this.state.externalParty.street} {this.state.externalParty.houseNumber} {this.state.externalParty.bus}<br />
                                                    {this.state.externalParty.postalCode} {this.state.externalParty.city} <br />
                                                    {this.state.externalParty.country !== null ? this.state.externalParty.country.name : ''}
                                                </p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>E-mailadres</strong></h6>
                                                <p>{this.state.externalParty.email}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Telefoonnummer</strong></h6>
                                                <p>{this.state.externalParty.phone}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>GSM nummer</strong></h6>
                                                <p>{this.state.externalParty.mobile}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <p>Laden van detail van de externe partij ...</p>
            )
        );
    }
}

export default ExternalPartyDetail;