import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';


const UserDetailCard = (props) => {

    return (
        
        props.user != null ? (
            <>
                <Card style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>
                    <CardBody>
                        <Row>
                            <Col lg={12} className='text-center'>
                                <h2 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}><i className="icofont icofont-2x icofont-ui-user" style={{ backgroundColor: 'white', color: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, display: 'inline-block', borderRadius: '100px', boxShadow:' 0 0 2px #888', padding: '0.5em 0.6em'}}></i></h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='text-center'>
                                <h2 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>{props.user.firstName + ' ' + props.user.lastName}</h2>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12} className='text-center'>
                                <h4 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>Adresgegevens</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='text-center'>
                                <p style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}>
                                    {props.user.street + ' ' + props.user.houseNumber + ' ' + props.user.bus} <br />
                                    {props.user.postalCode + ' ' + props.user.city}<br />
                                    {props.user.country ? (
                                        <>{props.user.country.name}</>
                                    ) : null}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='text-center'>
                                <h4 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>Contactgegevens</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='text-center'>
                                {props.user.primaryEmail ? (
                                    <div>
                                        <h6 style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}><i className="fa fa-envelope"></i>   {props.user.primaryEmail}</h6>
                                    </div>
                                ) : null}
                                {props.user.primaryPhone ? (
                                    <div>
                                        <h6 style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}><i className="fa fa-phone"></i>   {props.user.primaryPhone}</h6>
                                    </div>
                                ) : null}
                                {props.user.primaryMobile ? (
                                    <div>
                                        <h6 style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}><i className="fa fa-mobile-phone"></i>   {props.user.primaryMobile}</h6>
                                    </div>
                                ) : null}
                                {props.user.alternativePhone ? (
                                    <div>
                                        <h6 style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}><i className="fa fa-phone"></i>   {props.user.alternativePhone}</h6>
                                    </div>
                                ) : null}
                                {props.user.alternativeMobile ? (
                                    <div>
                                        <h6 style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}><i className="fa fa-mobile-phone"></i>   {props.user.alternativeMobile}</h6>
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='text-center'>
                                <h4 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>Facturatiegegevens</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className='text-center'>
                                {props.user && props.user.invoiceData ? (
                                    props.user.invoiceData.isBusinessUser ? (
                                        <p style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}>
                                            {props.user.invoiceData.companyName}<br />
                                            {props.user.invoiceData.vatNumber} <br />
                                            ({props.user.firstName} {props.user.lastName})
                                        </p>
                                    ) : (
                                        <>{props.user ? props.user.firstName : ''} {props.user ? props.user.lastName : ''}</>
                                    )
                                ) : null}
                                {props.user && props.user.invoiceData ? (
                                    <p style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}>
                                        {props.user.invoiceData.street + ' ' + props.user.invoiceData.houseNumber + ' ' + props.user.invoiceData.bus} <br />
                                        {props.user.invoiceData.postalCode + ' ' + props.user.invoiceData.city}<br />
                                        {props.user.invoiceData.country ? (
                                            <>{props.user.invoiceData.country.name}</>
                                        ) : null}
                                    </p>
                                ) : (
                                    <span style={{color: process.env.REACT_APP_STYLING_BODY_FONT_COLOR}}>Geen adresgegevens beschikbaar</span>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        ) : null
    );

}

export default UserDetailCard;