import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import StepZilla from "react-stepzilla";
import ProjectInformation from "./steps/projectinformation";
import CustomerInformation from "./steps/customerinformation";
import LocationInformation from "./steps/locationinformation";
import TaxInformation from "./steps/taxinformation";
import ConfirmProjectCreation from "./steps/confirmProjectCreation";


class ProjectCreate extends React.Component {

    constructor(props) {
        super(props);

        this.projectStore = {
            name: '',
            shortDescription : '',
            description : '',
            externalReference1: '',
            externalReference2: '',
            externalReference3: ''
        };

        this.locationStore = {
            useLocationOfCustomer : true,
            street : '',
            houseNumber : '',
            bus : '',
            postalCode : '',
            city : '',
            country : null
        };

        this.customerStore = {
            createNewCustomer : false,
            isBusinessUser : false,
            useAddressOfUser : true,
            firstName : "",
            lastName : "",
            street : "",
            houseNumber : "",
            bus : "",
            postalCode : "",
            city : "",
            country : "",
            extraInformation : "",
            primaryEmail : "",
            primaryPhone : "",
            primaryMobile : "",
            alternativeEmail : "",
            alternativePhone : "",
            alternativeMobile : "",
            userRole : "CUSTOMER",
            invoiceData : {
                companyName : "",
                vatNumber : "",
                cbeNumber : "",
                street : "",
                houseNumber : "",
                bus: "",
                postalCode : "",
                city : "",
                country : ""
            }
        };

        this.taxStore = {
            isAdvantageousTaxRate: false,
            projectRelationShip : null,
            locationUsage : null,
            agreementType : null
        };

        this.workOrderStore = {
            name: '',
            description : '',
            template:  null,
            responsibleUsers: [],
            accountableUsers: [],
            plannedDateForExecution: null,
            estimatedDaysWork : 0,
            estimatedHoursWork : 0
        };

        this.state = {
            customerlocation: {
                street : '',
                houseNumber : '',
                bus : '',
                postalCode : '',
                city : '',
                country : ''
            },
            customers : [],
            customerInformations : [],
            project : {
                name: '',
                type: null,
                customer: null,
                shortDescription : '',
                description : '',
                remarks : [],
                projectLines : [],
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : ""
            },
            newProject: null,
            createNewCustomer : false,
            useCustomerInfoAsInvoiceData : true,
            useCustomerLocationData : true,
            selectedCustomer : null,
            newCustomer : {
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                extraInformation : "",
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : "",
                alternativeEmail : "",
                alternativePhone : "",
                alternativeMobile : "",
                userRole : "CUSTOMER",
                invoiceData : {
                    companyName : "",
                    vatNumber : "",
                    cbeNumber : "",
                    street : "",
                    houseNumber : "",
                    bus: "",
                    postalCode : "",
                    city : "",
                    country : ""
                }
            }
        };
    }

    componentDidMount() {
        let me = this;
        API.get('users').then(function(response) {
            me.setState({customers: response.data});
        });
    }

    getProjectStore() {
        return this.projectStore;
    }

    updateProjectStore(update) {
        this.projectStore = {...this.projectStore, ...update}
    }

    getCustomerStore() {
        return this.customerStore;
    }

    updateCustomerStore(update) {
        this.customerStore = {...update};

        if (update.createNewCustomer === true) {
            this.setState({customerLocation: {
                street : update.newCustomer.street,
                houseNumber: update.newCustomer.houseNumber,
                bus: update.newCustomer.bus,
                postalCode: update.newCustomer.postalCode,
                city: update.newCustomer.city,
                country: update.newCustomer.country
                }});
        } else {
            let selectedCustomer = update.selectedCustomer;

            this.setState({customerLocation: {
                    street : selectedCustomer.street,
                    houseNumber: selectedCustomer.houseNumber,
                    bus: selectedCustomer.bus,
                    postalCode: selectedCustomer.postalCode,
                    city: selectedCustomer.city,
                    country: selectedCustomer.country
                }});
        }
    }

    getLocationStore() {
        return this.locationStore;
    }

    updateLocationStore(update) {
        this.locationStore = {...this.locationStore, ...update}
    }

    getWorkOrderStore() {
        return this.workOrderStore;
    }

    updateWorkOrderStore(update) {
        this.workOrderStore = {...this.workOrderStore, ...update}
    }

    getTaxStore() {
        return this.taxStore;
    }

    updateTaxStore(update) {
        this.taxStore = {...this.taxStore, ...update};
        this.createNewProject();
    }

    createNewProject = () => {
        let creationData = {
            project : this.projectStore,
            customer : this.customerStore,
            location : this.locationStore,
            taxInfo : this.taxStore
        };

        var me = this;

        API.post('project', creationData).then((response) => {
            me.setState({newProject: response.data});
        }).then(function() {
            window.location.href = "/projects/overview";
        });
    };

    render() {
        const steps = [
            {name : 'Project', component : <ProjectInformation getProjectStore={() => (this.getProjectStore())} updateProjectStore={(u) => {this.updateProjectStore(u)}}/> },
            {name : 'Klant', component : <CustomerInformation getCustomerStore={() => (this.getCustomerStore())} updateCustomerStore={(u) => {this.updateCustomerStore(u)}}/> },
            {name : 'Locatie', component : <LocationInformation customerLocation={this.state.customerLocation} getLocationStore={() => (this.getLocationStore())} updateLocationStore={(u) => {this.updateLocationStore(u)}}/> },
            {name : 'BTW', component : <TaxInformation getTaxStore={() => (this.getTaxStore())} updateTaxStore={(u) => {this.updateTaxStore(u)}}/> },
            {name : 'Bevestiging', component : <ConfirmProjectCreation newProject={this.state.newProject} />}
        ];

        return (
            <Fragment>
                <Breadcrumb parent="Projectbeheer" parentLink={'/projects/overview'}>Nieuw project aanmaken</Breadcrumb>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <form className="card" style={{backgroundColor: process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR}}>
                                   <div className="card-body">
                                       <StepZilla
                                           steps={steps}
                                           preventEnterSubmission={true}
                                           nextTextOnFinalActionStep={"Project opslaan"}
                                           nextButtonText={"Volgende stap"}
                                           backButtonText={"Vorige stap"}
                                           hocValidationAppliedTo={[]}
                                           startAtStep={0}
                                       />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ProjectCreate;