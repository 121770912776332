import { createStore, applyMiddleware, compose } from 'redux';

import thunkMiddleware from 'redux-thunk'

import reducers from '../reducers/index';


const store = createStore(reducers,   compose(
    applyMiddleware(thunkMiddleware),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.devToolsExtension ? window.devToolsExtension() : function (f) {
        return f;
    }
));


export default store;