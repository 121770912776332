import React, { Fragment, useEffect, useState, useRef } from 'react';
import API from "../../utils/API";
import _ from "lodash";
import {Input, Row, Col} from "reactstrap";
import Breadcrumb from '../common/breadcrumb';
import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';
import 'react-html5-camera-photo/build/css/index.css';
import { Redirect } from 'react-router';
import Select from 'react-select';

const TaskForProjectCreate = (props) =>  {
    const {register} = useForm();

    const ref = useRef(null);

    const [project, setProject] = useState([]);
    const [newTask, setNewTask] = useState({
        name: null,
        description: null,
        accountableUserId : null,
        responsibleUserIds: [],
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        inclusiveSaturday: false,
        inclusiveSunday: false
    });
    const [isBusyWithAddingTask, setIsBusyWithAddingTask] = useState(false);
    const [isSuccessfulAddedToProject, setIsSuccessfulAddedToProject] = useState(false);
    const [users, setUsers] = useState([]);
    const [taskExecutors, setTaskExecutors] = useState([]);
    const [taskResponsibles, setResponsibles] = useState([]);
    

    useEffect(() => {
        API.get('project/' + props.match.params.id).then(function(response) {
            setProject(response.data);
        });
        API.get('users/workers').then(function(response) {
            setUsers(response.data);
        });
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            let executorsArray = [];
            let reponsiblesArray = [];
            for (let userKey in users) {
                executorsArray.push({value: users[userKey].id, label: users[userKey].firstName + " " + users[userKey].lastName});
                reponsiblesArray.push({value: users[userKey].id, label: users[userKey].firstName + " " + users[userKey].lastName});
            }
            setTaskExecutors(executorsArray);
            setResponsibles(reponsiblesArray);    
        }
    }, [users]);


    const handleSubmit = (e) => {
        e.preventDefault();
        setIsBusyWithAddingTask(true);

        API.post('project/' + project.id +  '/task', newTask).then(function(response) {
            setIsSuccessfulAddedToProject(true);
        }).catch(function(response) {
            setIsBusyWithAddingTask(false);
            //setErrorOccurred(true);
        });
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setNewTask({...newTask, [name]: value});
    };

    const handleChangeCheckbox =  (e) => {
        const {name, value} = e.target;
        setNewTask({...newTask, [name]: value === 'on'});
    };

    const changeTaskAccountableUser = (event) => {
        let value = event.target.value;
        setNewTask({...newTask, accountableUserId: value});
    };

    const changeTaskResponsibleUser = (choice) => {
        let arr = [];
        for (let key in choice) {
            arr.push(choice[key].value);
        }
        setNewTask({...newTask, responsibleUserIds: arr});
    };
    

    return (
        <Fragment>
            {isSuccessfulAddedToProject === true && project !== null ? (
                <Redirect to={'/project/detail/' + project.id} />
            ) : null}
            {project !== null ? (
                <Breadcrumb parent={'Project ' + project.name} parentLink={'/project/detail/' + project.id}>
                    Taak toevoegen
                </Breadcrumb>
            ) : null}
            <div className="container-fluid">
                <div className="edit-profile">
                
                    <Row>
                        <Col lg={3} md={4} sm={12} xs={12}>
                            <Card>
                                <CardBody>
                                    <h4>{project.projectNumber} - {project.name}</h4>
                                    <p dangerouslySetInnerHTML={{__html: project.description}}></p>
                                    <p>
                                        <strong>Locatie van werken</strong><br />
                                        {project.street} {project.houseNumber} {project.bus}<br />
                                        {project.postalCode} {project.city}<br />
                                        {project.country ? project.country.name : ''}
                                    </p>
                                    <p>
                                        <strong>Contactpersoon</strong><br />
                                        {project.customer ? project.customer.firstName + ' ' + project.customer.lastName : ''}<br />
                                        {project.phone}<br />
                                        {project.mobile}<br />
                                        {project.primaryEmail}
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9} md={8} sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <form >
                                    <Row className={'form-group text-left'}>
                                        <div className={'col'}>Naam:</div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col-12'}><input onChange={handleChange} className={'form-control'} type={'text'} name={'name'} value={newTask.name} /></div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col-12'}>Beschrijving:</div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col-12'}><input onChange={handleChange} className={'form-control'} type={'text'} name={'description'} value={newTask.description} /></div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col'}>Uitvoerder:</div>
                                        <div className={'col'}>Eindverantwoordelijke:</div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col'}>
                                            <Select 
                                                ref={ref} 
                                                options={taskExecutors} 
                                                isMulti 
                                                onChange={(choice) => changeTaskResponsibleUser(choice)}
                                                menuPortalTarget={document.form}
                                                menuPosition={'fixed'}
                                                menuPlacement={'bottom'}
                                                menuShouldScrollIntoView={false}
                                            />
                                        </div>
                                        <div className={'col'}>
                                            <select onChange={changeTaskAccountableUser} className={'form-control'} name={'taskAccountableUserId'}>
                                                <option value={'0'}>---</option>
                                                {users.map(function(user) {
                                                    return (
                                                        <option value={user.id}>{user.firstName} {user.lastName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col'}>Start van uitvoering:</div>
                                        <div className={'col'}>Einde van uitvoering:</div>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        <div className={'col-2'}><input onChange={handleChange} className={'form-control digits'} name={'startDate'} type={'date'} value={newTask.startDate} /></div>
                                        <div className={'col-2'}><input onChange={handleChange} className={'form-control digits'} name={'startTime'} type={'time'} value={newTask.startTime} /></div>
                                        <Col lg={2}></Col>
                                        <div className={'col-2'}><input onChange={handleChange} className={'form-control digits'} name={'endDate'} type={'date'} value={newTask.endDate} /></div>
                                        <div className={'col-2'}><input onChange={handleChange} className={'form-control digits'} name={'endTime'} type={'time'} value={newTask.endTime} /></div>
                                        <Col lg={2}></Col>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                    </Row>
                                    <Row className={'form-group text-left'}>
                                        
                                    </Row>
                                    <Row className={'form-group'}>
                                        <div className={'col-xs-3 col-md-3'}>Inclusief werk op zaterdag</div>
                                        <div className={'col-xs-9 col-md-3'}><input type={'checkbox'} className={'form-control uniform'} name={'inclusiveSaturday'} checked={newTask.inclusiveSaturday} onChange={handleChangeCheckbox} /></div>
                                        <div className={'col-xs-3 col-md-3'}>Inclusief werk op zondag</div>
                                        <div className={'col-xs-9 col-md-3'}><input type={'checkbox'} className={'form-control uniform'} name={'inclusiveSunday'} checked={newTask.inclusiveSunday} onChange={handleChangeCheckbox} /></div>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <button className='btn btn-primary' onClick={handleSubmit}>Taak toevoegen</button>
                                        </Col>
                                    </Row>
                                </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            
            
        </Fragment>
    );
};

export default TaskForProjectCreate;