import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import CKEditors from "react-ckeditor-component";
import CurrencyInput from "react-currency-input";
import { Col, Row } from 'reactstrap';


const InvoiceCreateBasedOnOffer = (props) => {

    const [loading, setLoading] = useState(false);

    const [establishment, setEstablishment] = useState(null);
    const [offer, setOffer] = useState(null);
    const [editableInvoicerAddress, setEditableInvoicerAddress] = useState(false);
    const [editableBillToAddress, setEditableBillToAddress] = useState(false);
    const [editableDeliveryAddress, setEditableDeliveryAddress] = useState(false);

    const [extraInformation, setExtraInformation] = useState(null);
    const [externalReference1, setExternalReference1] = useState(null);
    const [externalReference2, setExternalReference2] = useState(null);
    const [externalReference3, setExternalReference3] = useState(null);

    const [invoicerCompanyName, setInvoicerCompanyName] = useState(null);
    const [invoicerCbeNumber, setInvoicerCbeNumber] = useState(null);
    const [invoicerFirstName, setInvoicerFirstName] = useState(null);
    const [invoicerLastName, setInvoicerLastName] = useState(null);
    const [invoicerStreet, setInvoicerStreet] = useState(null);
    const [invoicerHouseNumber, setInvoicerHouseNumber] = useState(null);
    const [invoicerBus, setInvoicerBus] = useState(null);
    const [invoicerPostalCode, setInvoicerPostalCode] = useState(null);
    const [invoicerCity, setInvoicerCity] = useState(null);
    const [invoicerCountry, setInvoicerCountry] = useState(null);

    const [billToCompanyName, setBillToCompanyName] = useState(null);
    const [billToCbeNumber, setBillToCbeNumber] = useState(null);
    const [billToFirstName, setBillToFirstName] = useState(null);
    const [billToLastName, setBillToLastName] = useState(null);
    const [billToStreet, setBillToStreet] = useState(null);
    const [billToHouseNumber, setBillToHouseNumber] = useState(null);
    const [billToBus, setBillToBus] = useState(null);
    const [billToPostalCode, setBillToPostalCode] = useState(null);
    const [billToCity, setBillToCity] = useState(null);
    const [billToCountry, setBillToCountry] = useState(null);
    
    const [deliveryCompanyName, setDeliveryCompanyName] = useState(null);
    const [deliveryCbeNumber, setDeliveryCbeNumber] = useState(null);
    const [deliveryFirstName, setDeliveryFirstName] = useState(null);
    const [deliveryLastName, setDeliveryLastName] = useState(null);
    const [deliveryStreet, setDeliveryStreet] = useState(null);
    const [deliveryHouseNumber, setDeliveryHouseNumber] = useState(null);
    const [deliveryBus, setDeliveryBus] = useState(null);
    const [deliveryPostalCode, setDeliveryPostalCode] = useState(null);
    const [deliveryCity, setDeliveryCity] = useState(null);
    const [deliveryCountry, setDeliveryCountry] = useState(null);

    const [invoiceItems, setInvoiceItems] = useState([]);

    useEffect(() => {
        API.get('establishments').then(function(response) {
            setEstablishment(response.data);
            API.get('offer/' + props.match.params.id).then(function(response) {
                setOffer(response.data);
            });
        });
    }, []);

    useEffect(() => {
        if (offer !== null) {
            setExtraInformation(offer.extraInformation);
            setExternalReference1(offer.externalReference1);
            setExternalReference2(offer.externalReference2);
            setExternalReference3(offer.externalReference3);
    
            setInvoicerCompanyName(offer.creator.companyName);
            setInvoicerCbeNumber(offer.creator.cbeNumber);
            setInvoicerFirstName(offer.creator.firstName);
            setInvoicerLastName(offer.creator.lastName);
            setInvoicerStreet(offer.creator.street);
            setInvoicerHouseNumber(offer.creator.houseNumber);
            setInvoicerBus(offer.creator.bus);
            setInvoicerPostalCode(offer.creator.postalCode);
            setInvoicerCity(offer.creator.city);
            setInvoicerCountry(offer.creator.country);
    
            setBillToCompanyName(offer.customer.companyName);
            setBillToCbeNumber(offer.customer.cbeNumber);
            setBillToFirstName(offer.customer.firstName);
            setBillToLastName(offer.customer.lastName);
            setBillToStreet(offer.customer.street);
            setBillToHouseNumber(offer.customer.houseNumber);
            setBillToBus(offer.customer.bus);
            setBillToPostalCode(offer.customer.postalCode);
            setBillToCity(offer.customer.city);
            setBillToCountry(offer.customer.country);
    
            setDeliveryCompanyName(offer.customer.companyName);
            setDeliveryCbeNumber(offer.customer.cbeNumber);
            setDeliveryFirstName(offer.customer.firstName);
            setDeliveryLastName(offer.customer.lastName);
            setDeliveryStreet(offer.customer.street);
            setDeliveryHouseNumber(offer.customer.houseNumber);
            setDeliveryBus(offer.customer.bus);
            setDeliveryPostalCode(offer.customer.postalCode);
            setDeliveryCity(offer.customer.city);
            setDeliveryCountry(offer.customer.country);
    
            setInvoiceItems(offer.offerItems);
        }
        
    }, [offer]);

    const createNewInvoice = () => {
        setLoading(true);
        API.post('invoice', {
            invoice : {
                invoicerAddress: {
                    organisationName: invoicerCompanyName,
                    cbeNumber: invoicerCbeNumber,
                    firstName: invoicerFirstName,
                    lastName: invoicerLastName,
                    street: invoicerStreet,
                    houseNumber: invoicerHouseNumber,
                    bus: invoicerBus,
                    postalCode: invoicerPostalCode,
                    city: invoicerCity,
                    country: invoicerCountry
                },
                billToAddress: {
                    organisationName: billToCompanyName,
                    cbeNumber: billToCbeNumber,
                    firstName: billToFirstName,
                    lastName: billToLastName,
                    street: billToStreet,
                    houseNumber: billToHouseNumber,
                    bus: billToBus,
                    postalCode: billToPostalCode,
                    city: billToCity,
                    country: billToCountry
                },
                deliveryAddress: {
                    organisationName: deliveryCompanyName,
                    cbeNumber: deliveryCbeNumber,
                    firstName: deliveryFirstName,
                    lastName: deliveryLastName,
                    street: deliveryStreet,
                    houseNumber: deliveryHouseNumber,
                    bus: deliveryBus,
                    postalCode: deliveryPostalCode,
                    city: deliveryCity,
                    country: deliveryCountry
                },
                externalReference1,
                externalReference2,
                externalReference3,
                extraInformation,
                invoiceItems,
                project: offer.project
            },
            offer
        }).then(function(response) {
            setLoading(false);
        });
    };

    const addInvoiceItem = () => {
        let tmpInvoiceItems = invoiceItems;
        tmpInvoiceItems.push({description: "", numberOfItems : 1, unitOfItems : "Stuks", unitPrice: 0.00, vatPercentage: "21", vatAmount: 0.00, totalAmount: 0.00 });
        setInvoiceItems(tmpInvoiceItems);
    };

    const removeInvoiceItem = (id) => {
        let tmpInvoiceItems = invoiceItems;
        tmpInvoiceItems.splice(id, 1);
        setInvoiceItems(tmpInvoiceItems);
    };

    const changeInvoiceItem = (name, value, index) => {
        let tmpInvoiceItems = [...invoiceItems];
        tmpInvoiceItems[index][name] = value;
        if (name === 'unitPrice' || name === 'numberOfItems' || name === 'vatPercentage') {
            tmpInvoiceItems[index]['vatAmount'] = tmpInvoiceItems[index]['vatPercentage'] / 100 * tmpInvoiceItems[index]['unitPrice'] * tmpInvoiceItems[index]['numberOfItems'];
            tmpInvoiceItems[index]['totalAmount'] = (1 + tmpInvoiceItems[index]['vatPercentage'] / 100) * tmpInvoiceItems[index]['unitPrice'] * tmpInvoiceItems[index]['numberOfItems'];
        }
        setInvoiceItems(tmpInvoiceItems);
    };

    const changeInvoiceItem2 = (maskedvalue, floatvalue, index) => {
        let tmpInvoiceItems = [...invoiceItems];
        tmpInvoiceItems[index]['unitPrice'] = floatvalue;

        tmpInvoiceItems[index]['vatAmount'] = tmpInvoiceItems[index]['vatPercentage'] / 100 * tmpInvoiceItems[index]['unitPrice'] * tmpInvoiceItems[index]['numberOfItems'];
        tmpInvoiceItems[index]['totalAmount'] = (1 + tmpInvoiceItems[index]['vatPercentage'] / 100) * tmpInvoiceItems[index]['unitPrice'] * tmpInvoiceItems[index]['numberOfItems'];
    
        setInvoiceItems(tmpInvoiceItems);
    };

    const changeInvoiceItemNumberOfItems = (maskedvalue, floatvalue, index) => {
        let tmpInvoiceItems = [...invoiceItems];
        tmpInvoiceItems[index]['numberOfItems'] = floatvalue;

        tmpInvoiceItems[index]['vatAmount'] = tmpInvoiceItems[index]['vatPercentage'] / 100 * tmpInvoiceItems[index]['unitPrice'] * tmpInvoiceItems[index]['numberOfItems'];
        tmpInvoiceItems[index]['totalAmount'] = (1 + tmpInvoiceItems[index]['vatPercentage'] / 100) * tmpInvoiceItems[index]['unitPrice'] * tmpInvoiceItems[index]['numberOfItems'];
        setInvoiceItems(tmpInvoiceItems);
    }

    return (
        <>
            {offer !== null ? (
                <Fragment>
                    <Breadcrumb parent={''} title="Factuur maken"/>
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={'card'} style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                        <div className={'card-body'}>
                                            <div className={'row'}>
                                                <div className={'col-xl-12'}>
                                                    {offer !== null && offer !== undefined && offer.project !== null && offer.project !== undefined ? (
                                                        <>
                                                            U staat op punt om een factuur te maken voor project <strong>'{offer.project.name}'</strong> gebaseerd op offerte <strong>'{offer.offerNumber}'</strong>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={() => createNewInvoice}>
                                <div className={'row'}>
                                    <div className={'col-lg-4'}>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <div className={'form-group row'}>
                                                    <div className={'col-sm-11'}>
                                                        <h4>Gefactureerd door:</h4>
                                                    </div>
                                                    <div className={'col-sm-1'}>
                                                        <a onClick={() => setEditableInvoicerAddress(!editableInvoicerAddress)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                    </div>
                                                </div>
                                                {editableInvoicerAddress ? (
                                                    <>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Organisatienaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerCompanyName} onChange={({target: {value}}) => setInvoicerCompanyName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>BTW-nummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerCbeNumber} onChange={({target: {value}}) => setInvoicerCbeNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Voornaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerFirstName} onChange={({target: {value}}) => setInvoicerFirstName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Naam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerLastName} onChange={({target: {value}}) => setInvoicerLastName(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Straat</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerStreet} onChange={({target: {value}}) => setInvoicerStreet(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Huisnummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerHouseNumber} onChange={({target: {value}}) => setInvoicerHouseNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Bus</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerBus} onChange={({target: {value}}) => setInvoicerBus(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Postcode</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerPostalCode} onChange={({target: {value}}) => setInvoicerPostalCode(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Gemeente</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerCity} onChange={({target: {value}}) => setInvoicerCity(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Land</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={invoicerCountry} onChange={({target: {value}}) => setInvoicerCountry(value)} />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>{invoicerCompanyName}</strong>
                                                        <br />{invoicerCbeNumber}
                                                        <br />
                                                        <br />{invoicerFirstName} {invoicerLastName}
                                                        <br />{invoicerStreet} {invoicerHouseNumber} {invoicerBus}
                                                        <br />{invoicerPostalCode} {invoicerCity}
                                                        <br />{invoicerCountry}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-lg-4'}>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <div className={'form-group row'}>
                                                    <div className={'col-sm-11'}>
                                                        <h4>Gefactureerd naar:</h4>
                                                    </div>
                                                    <div className={'col-sm-1'}>
                                                        <a onClick={() => setEditableBillToAddress(!editableBillToAddress)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                    </div>
                                                </div>
                                                {editableBillToAddress ? (
                                                    <>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Organisatienaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToCompanyName} onChange={({target: {value}}) => setBillToCompanyName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>BTW-nummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToCbeNumber} onChange={({target: {value}}) => setBillToCbeNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Voornaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToFirstName} onChange={({target: {value}}) => setBillToFirstName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Naam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToLastName} onChange={({target: {value}}) => setBillToLastName(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Straat</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToStreet} onChange={({target: {value}}) => setBillToStreet(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Huisnummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToHouseNumber} onChange={({target: {value}}) => setBillToHouseNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Bus</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToBus} onChange={({target: {value}}) => setBillToBus(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Postcode</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToPostalCode} onChange={({target: {value}}) => setBillToPostalCode(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Gemeente</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToCity} onChange={({target: {value}}) => setBillToCity(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Land</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={billToCountry} onChange={({target: {value}}) => setBillToCountry(value)}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>{billToCompanyName}</strong>
                                                        <br />{billToCbeNumber}
                                                        <br />
                                                        <br />{billToFirstName} {billToLastName}
                                                        <br />{billToStreet} {billToHouseNumber} {billToBus}
                                                        <br />{billToPostalCode} {billToCity}
                                                        <br />{billToCountry}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-lg-4'}>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <div className={'form-group row'}>
                                                    <div className={'col-sm-11'}>
                                                        <h4>Geleverd aan:</h4>
                                                    </div>
                                                    <div className={'col-sm-1'}>
                                                        <a onClick={() => setEditableDeliveryAddress(!editableDeliveryAddress)} href={'#'}><h4 className={'fa fa-pencil'}></h4></a>
                                                    </div>
                                                </div>
                                                {editableDeliveryAddress ? (
                                                    <>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Organisatienaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryCompanyName} onChange={({target:{value}}) => setDeliveryCompanyName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Voornaam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryFirstName} onChange={({target:{value}}) => setDeliveryFirstName(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Naam</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryLastName} onChange={({target:{value}}) => setDeliveryLastName(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Straat</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryStreet} onChange={({target:{value}}) => setDeliveryStreet(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Huisnummer</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryHouseNumber} onChange={({target:{value}}) => setDeliveryHouseNumber(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Bus</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryBus} onChange={({target:{value}}) => setDeliveryBus(value)}/>
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Postcode</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryPostalCode} onChange={({target:{value}}) => setDeliveryPostalCode(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Gemeente</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryCity} onChange={({target:{value}}) => setDeliveryCity(value)} />
                                                            </div>
                                                        </div>
                                                        <div className={'form-group'}>
                                                            <div className={'col-sm-12'}>
                                                                <label>Land</label>
                                                            </div>
                                                            <div className={'col-sm-12'}>
                                                                <input type={'text'} className={'form-control'} value={deliveryCountry} onChange={({target:{value}}) => setDeliveryCountry(value)}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>{deliveryCompanyName}</strong>
                                                        <br />{deliveryCbeNumber}
                                                        <br />
                                                        <br />{deliveryFirstName} {deliveryLastName}
                                                        <br />{deliveryStreet} {deliveryHouseNumber} {deliveryBus}
                                                        <br />{deliveryPostalCode} {deliveryCity}
                                                        <br />{deliveryCountry}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-xl-12'}>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <h4>Extra informatie</h4>
                                                    </div>
                                                </div>
                                                <div className={'row form-group'}>
                                                    <div className={'col-sm-2'}>
                                                        <label>Externe referentie 1:</label>
                                                    </div>
                                                    <div className={'col-sm-10'}>
                                                        <input type={'text'} className={'form-control'} value={externalReference1} onChange={({target:{value}}) => setExternalReference1(value)} />
                                                    </div>
                                                </div>
                                                <div className={'row form-group'}>
                                                    <div className={'col-sm-2'}>
                                                        <label>Externe referentie 2:</label>
                                                    </div>
                                                    <div className={'col-sm-10'}>
                                                        <input type={'text'} className={'form-control'} value={externalReference2} onChange={({target:{value}}) => setExternalReference2(value)} />
                                                    </div>
                                                </div>
                                                <div className={'row form-group'}>
                                                    <div className={'col-sm-2'}>
                                                        <label>Externe referentie 3:</label>
                                                    </div>
                                                    <div className={'col-sm-10'}>
                                                        <input type={'text'} className={'form-control'} value={externalReference3} onChange={({target:{value}}) => setExternalReference3(value)} />
                                                    </div>
                                                </div>
                                                <div className={'row form-group'}>
                                                    <div className={'col-xl-12'}>
                                                        <label>Extra informatie:</label>
                                                    </div>
                                                    <div className={'col-xl-12'}>
                                                        <CKEditors
                                                            activeclassName="p10"
                                                            content={extraInformation}
                                                            events={{
                                                                "change": ({editor}) => setExtraInformation(editor.getData())
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-xl-12'}>
                                        <div className={'card'}>
                                            <div className={'card-body'}>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <h4>Detaillijnen:</h4>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <div className={'row form-group'}>
                                                            <div className={'col-xl-4'}>Beschrijving</div>
                                                            <div className={'col-xl-1'}>Aantal</div>
                                                            <div className={'col-xl-1'}>Eenheid</div>
                                                            <div className={'col-xl-1'}>Eenheidsprijs</div>
                                                            <div className={'col-xl-1'}>BTW %</div>
                                                            <div className={'col-xl-1'}>BTW bedrag</div>
                                                            <div className={'col-xl-1'}>Totaal</div>
                                                            <div className={'col-xl-1'}></div>
                                                            <div className={'col-xl-1'}></div>
                                                        </div>
                                                        {invoiceItems.map((x,i) => {
                                                            return (
                                                                <div className={'row form-group'}>
                                                                    <label className={'col-xl-4'}>
                                                                        <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        <CurrencyInput decimalSeparator="," thousandSeparator="." allowNegative="true" className={'form-control'} name={'numberOfItems'} value={x.numberOfItems}  onChangeEvent={(e, maskedvalue, floatvalue) => changeInvoiceItemNumberOfItems(maskedvalue, floatvalue, i)} />
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        <input className={'form-control'} name={'unitOfItems'} value={x.unitOfItems} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        <CurrencyInput decimalSeparator="," thousandSeparator="."  className={'form-control'} name={'unitPrice'} value={x.unitPrice} onChangeEvent={(e, maskedvalue, floatvalue) => changeInvoiceItem2(maskedvalue, floatvalue, i)} />
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        <input className={'form-control'} name={'vatPercentage'} value={x.vatPercentage} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(x.vatAmount)}
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(x.totalAmount)}
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        {invoiceItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeInvoiceItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                    </label>
                                                                    <label className={'col-xl-1'}>
                                                                        {invoiceItems.length -1 === i && <a href={'#'} className={'btn btn-primary'} onClick={() => addInvoiceItem()}><span className={'fa fa-plus'}></span></a>}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <h4 style={{textAlign: 'right'}}>Totaal: {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(invoiceItems.reduce((totalPrice, invoiceItem) => totalPrice + parseFloat(invoiceItem.totalAmount),0))}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Row>
                                    <Col lg={12}>
                                        <a href={'#'} className={'btn btn-primary'} onClick={() => createNewInvoice()}><span className={'fa fa-plus'}></span> Factuur genereren</a>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Fragment>
            ): null}
        </>
    );
};

export default InvoiceCreateBasedOnOffer;