import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import API from "../../../utils/API";
import ReactTable from "react-table-6";
import Loader from '../../common/loader';
import useReactTable from '../../../hooks/useReactTable';
import {Link} from "react-router-dom";
import {Col, Input, Row} from "reactstrap";

const ProjectFlowOverview = () =>  {

    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [projectFlows, setProjectFlows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Naam',
            accessor : 'name',
            headerClassName : 'text-left'
        },
        {
            Header : 'Beschrijving',
            accessor : 'description',
            headerClassName : 'text-left'
        },
        {
            Header : 'Flow',
            accessor : 'description',
            headerClassName : 'text-left'
        },
        {
            Header : 'Verwijder',
            accessor : 'id',
            headerClassName : 'text-left',
            Cell: row => {
                return <a href='#'><span onClick={() => deleteProjectFlows(row.row._original.id)} className='icon icon-trash'></span></a>
            }
        }
]);

    useEffect(() => {
        loadCurrentUser();
    }, []);

    useEffect(() => {
        globalSearch();
    }, [searchInput]);

    useEffect(() => {
        loadProjectFlows();
    }, [currentUser]);

    const loadCurrentUser = () => {
        API.get('myuser').then(function(response) {
            if (response.data !== undefined) {
                setCurrentUser(response.data[0]);
            }
        });
    }

    const loadProjectFlows = () => {
        API.get('projectflows').then(function(response) {
            setProjectFlows(response.data);
            setFiltered(response.data);
            setIsLoading(false);
        });
    }
    const deleteProjectFlows = (id) => {
        API.delete('projectflows/' + id).then(function(response) {
            loadProjectFlows();
        });
    };

    const handleChange = event => {
        setSearchInput(event.target.value);
    };

    const globalSearch = () => {
        let filteredData = projectFlows.filter(value => {
            return (value.name.toLowerCase().includes(searchInput.toLowerCase()) || value.description.toLowerCase().includes(searchInput.toLowerCase()) );
        });
        setFiltered(filteredData);
    };

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb parent="Projectflows" title="Overzicht van projectflows" />
                        </Col>
                    </Row>
                    
                    <div className="container-fluid">
                        <div className="edit-profile">
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER') ? (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Input
                                            size="large"
                                            name="searchInput"
                                            value={searchInput || ""}
                                            onChange={handleChange}
                                            label="Search"
                                            placeholder={'Zoeken'}
                                        />
                                        <br />
                                        <br />
                                        <ReactTable 
                                            data={filtered}
                                            columns={columns}
                                            minRows={0}
                                            defaultPageSize={10}
                                            className={"-striped -highlight"}
                                            showPagination={true}
                                            previousText={'Vorige'}
                                            nextText={'Volgende'}
                                            pageText={'Pagina'}
                                            rowsText={'rijen'}
                                            ofText={'van'}
                                            getTheadThProps={getTheadThProps}
                                            getTdProps={getTdProps}
                                            getTrProps={getTrProps}
                                            getPaginationProps={getPaginationProps}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <Row>
                        <Col lg={12} className={'text-right'}>
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER') ? (
                                <div className={'row mt-3'}>
                                    <div className={'col-md-12'}>
                                        <Link to={'/config/projectflows/create'} className={'btn btn-primary'}>Nieuw projectflow aanmaken</Link>
                                    </div>
                                </div>
                            ) : null}
                        </Col>
                    </Row>

                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ProjectFlowOverview;