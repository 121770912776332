import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Button, Col, Input, Row} from "reactstrap";
import useReactTable from '../../hooks/useReactTable';
import Loader from '../common/loader';

const UsersOverview = () =>  {
    const [users, setUsers] = useState([]);

    const [searchInput, setSearchInput] = useState("");
    const [pages, setPages] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const userColumns = [
        {
            Header : 'Rol',
            headerClassName : 'text-left',
            accessor : 'userRole',
            width: 150,
            Cell : row => {
                const role = row.row.userRole;
                if (role === 'ADMIN') {
                    return (<><i className="icofont icofont-2x icofont-court" title={'Beheerder'}></i> Beheerder</>)
                } else if (role === 'EMPLOYEE') {
                    return (<><i className="icofont icofont-2x icofont-waiter" title={"Medewerker"}></i> Medewerker</>)
                } else if (role === 'EMPLOYER') {
                    return (<><i className="icofont icofont-2x icofont-user-suited" title={"Werkgever"}></i> Zaakeigenaar</>)
                } else if (role === 'CUSTOMER') {
                    return (<><i className="icofont icofont-2x icofont-user" title={"Klant"}></i> Klant</>)
                } else {
                    return (<><i className="icofont icofont-2x icofont-user" title={"Klant"}></i> Klant</>)
                }
            }
        },
        {
            Header : 'Naam',
            headerClassName : 'text-left',
            accessor : 'firstName',
            Cell : row => {
                return <>{row.row._original.firstName} {row.row._original.lastName}</>
            }
        },
        {
            Header : 'Gebruikersnaam',
            headerClassName : 'text-left',
            accessor : 'username'
        },
        {
            Header : 'Postcode',
            headerClassName : 'text-left',
            accessor : 'postalCode',
            width: 80
        },
        {
            Header : 'Gemeente',
            headerClassName : 'text-left',
            accessor : 'city'
        },
        {
            Header : 'E-mailadres',
            headerClassName : 'text-left',
            accessor : 'primaryEmail'
        },
        {
            Header : 'Telefoon',
            width: 150,
            headerClassName : 'text-left',
            accessor : 'primaryPhone'
        },
        {
            Header : 'GSM',
            width: 150,
            headerClassName : 'text-left',
            accessor : 'primaryMobile'
        },
        {
            Header : '',
            headerClassName : 'text-left',
            accessor : 'id',
            width: 50,
            Cell: row => {
                return <Link to={`/user/${row.row.id}/detail`} target={'_parent'} style={{color: process.env.REACT_APP_STYLING_CARD_FONT_COLOR}}><i className="icofont icofont-2x icofont-pencil-alt-1" title={"Bekijk"}></i></Link>
            }
        }

    ];
    
    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        setIsLoading(true);
        API.get('users?pageNumber=0&numberOfRows=10&search_input=' + searchInput).then(function(response) {
            setUsers(response.data.items);
            setPages(Math.ceil(response.data.totalItemCount / response.data.itemNumberPerPage));
            setIsLoading(false);
        });
    };


    return (
        isLoading ? (
            <>
                <Loader />
            </>
        ) : (
            <>
                <Breadcrumb>Gebruikersbeheer</Breadcrumb>
                <Row>
                    <Col lg={8} className='text-right'>Zoeken:</Col>
                    <Col lg={3}>
                        <Input className='form-control' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                    </Col>
                    <Col lg={1}>
                        <Button className='form-control btn btn-primary' onClick={() => loadUsers()}>Filter</Button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col lg={12}>
                        {users.length > 0 ? (
                            <ReactTable 
                                pages={pages}
                                data={users}
                                columns={userColumns}
                                minRows={0}
                                manual
                                defaultPageSize={10}
                                className={"-striped -highlight"}
                                showPagination={true}
                                previousText={'Vorige'}
                                nextText={'Volgende'}
                                pageText={'Pagina'}
                                rowsText={'rijen'}
                                ofText={'van'}
                                getTheadThProps={getTheadThProps}
                                getTdProps={getTdProps}
                                getTrProps={getTrProps}
                                getPaginationProps={getPaginationProps}
                                onFetchData={(state) => {
                                    //setIsLoading(true);
                                    API.get('users?pageNumber=' + state.page + '&numberOfRows=' + state.pageSize + '&search_input=' + searchInput).then(function(response) {
                                        setUsers(response.data.items);
                                        setPages(Math.ceil(response.data.totalItemCount / response.data.itemNumberPerPage));
                                        //setIsLoading(false);
                                    });
                                }}
                                />
                        ) : (
                            <p>Er zijn geen gebruikers aanwezig in het systeem</p>
                        )}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col lg={4}>
                        <Link className={'btn btn-primary form-control pt-3 pb-3'} to={'/users/create'}><h2><i className="icofont icofont-2x icofont-user" title={"Klant"}></i></h2>Klant aanmaken</Link>
                    </Col>
                    <Col lg={4}>
                        <Link className={'btn btn-primary form-control pt-3 pb-3'} to={'/employee/create'}><h2><i className="icofont icofont-2x icofont-waiter" title={"Klant"}></i></h2>Medewerker aanmaken</Link>
                    </Col>
                    <Col lg={4}>
                        <Link className={'btn btn-primary form-control pt-3 pb-3'} to={'/employer/create'}><h2><i className="icofont icofont-2x icofont-user-suited" title={"Klant"}></i></h2>Beheerder aanmaken</Link>
                    </Col>
                </Row>
                
            </>
        )
    );
};

export default UsersOverview;