import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import ProjectStatusSortableItem from './projectStatusSortableItem';
 
const ProjectStatusSortableList = ({items, onRemoveItem}) => {

  return (
    <ul>
      {items.map((value, index) => (
        <ProjectStatusSortableItem key={`item-${index}`} index={index} id={value.id} value={value.name} onRemoveItem={onRemoveItem} />
      ))}
    </ul>
  );
}
 
export default SortableContainer(ProjectStatusSortableList);