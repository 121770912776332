import React, { Fragment } from 'react';
import CKEditors from "react-ckeditor-component";
import _ from "lodash";

class Invoicedata extends React.Component {

    constructor(props) {
        super(props);
        const personStore = props.personStore();
        this.state = {
            invoiceData : {
                companyName : "",
                vatNumber : "",
                cbeNumber : "",
                street : personStore.street,
                houseNumber : personStore.houseNumber,
                bus: personStore.bus,
                postalCode : personStore.postalCode,
                city : personStore.city,
                country : personStore.country
            },
            errors : {
                street : "",
                houseNumber : "",
                postalCode : "",
                city : "",
                country : ""
            }
        };
        this._validateOnDemand = true;
    }

    componentDidMount() {
    }

    isValidated = () => {
        let isDataValid = this.validationCheck();
        if (isDataValid) {
            this.props.updateInvoiceDataStore(this.state.invoiceData);
        }
        return isDataValid;
    };

    validationCheck = () => {
        let isValid = true;
        if (_.isEmpty(this.state.invoiceData.street)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, street : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, street : "" }}));
        }
        if (_.isEmpty(this.state.invoiceData.houseNumber)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "" }}));
        }
        if (_.isEmpty(this.state.invoiceData.postalCode)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, postalCode : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, postalCode : "" }}));
        }
        if (_.isEmpty(this.state.invoiceData.city)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, city : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, city : "" }}));
        }

        return isValid;
    };

    changeCompanyName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, companyName : value }
        }));
    };

    changeVatNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, vatNumber : value }
        }));
    };

    changeCbeNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, cbeNumber : value }
        }));
    };

    changeStreet = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, street : value }
        }));
    };

    changeHouseNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, houseNumber : value }
        }));
    };

    changeBus = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, bus : value }
        }));
    };

    changeCity = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, city : value }
        }));
    };

    changeCountry = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, country : value }
        }));
    };

    changePostalCode = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            invoiceData: { ...prevState.invoiceData, postalCode : value }
        }));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Bedrijfsnaam</label>
                                    <input className="form-control" type="text" name="firstName" value={this.state.invoiceData.companyName} onChange={this.changeCompanyName} />
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">BTW nummer</label>
                                    <input className="form-control" type="text" name="lastName" value={this.state.invoiceData.vatNumber} onChange={this.changeVatNumber} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Ondernemingsnummer</label>
                                    <input className="form-control" type="text" name="lastName" value={this.state.invoiceData.cbeNumber} onChange={this.changeCbeNumber} />
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-8">
                                <div className="form-group">
                                    <label className="form-label">Straat</label>
                                    <input className="form-control" type="text" name="street" value={this.state.invoiceData.street} onChange={this.changeStreet} />
                                    {this.state.errors.street ? <span className={'errorInForm'}>Straat is verplicht</span> : null }
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label">Huisnummer</label>
                                    <input className="form-control" type="text" name="houseNumber" value={this.state.invoiceData.houseNumber} onChange={this.changeHouseNumber} />
                                    {this.state.errors.houseNumber ? <span className={'errorInForm'}>Huisnummer is verplicht</span> : null }
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label">Bus</label>
                                    <input className="form-control" type="text" name="bus" value={this.state.invoiceData.bus} onChange={this.changeBus} />
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-2">
                                <div className="form-group">
                                    <label className="form-label">Postcode</label>
                                    <input className="form-control" type="text" name="postalCode" value={this.state.invoiceData.postalCode} onChange={this.changePostalCode} />
                                    {this.state.errors.postalCode ? <span className={'errorInForm'}>Postcode is verplicht</span> : null }
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <div className="form-group">
                                    <label className="form-label">Gemeente</label>
                                    <input className="form-control" type="text" name="city" value={this.state.invoiceData.city} onChange={this.changeCity} />
                                    {this.state.errors.city ? <span className={'errorInForm'}>Gemeente is verplicht</span> : null }
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <div className="form-group">
                                    <label className="form-label">Land</label>
                                    <select className="form-control btn-square" name="country" value={this.state.invoiceData.country} onChange={this.changeCountry}>
                                        <option value="1">België</option>
                                        <option value="2">Nederland</option>
                                        <option value="3">Duitsland</option>
                                        <option value="4">Frankrijk</option>
                                        <option value="5">Luxemburg</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default Invoicedata;