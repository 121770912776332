import React from 'react';
import _ from "lodash";
import ReactTable from "react-table-6";
import API from "../../utils/API";


const UserTaxAttests = (props) => {   
    
    const taxCertificateColumns = [
        {
            Header : 'Aangemaakt op',
            accessor : 'createdAt',
            width: 250,
            Cell: row => {
                if (_.isEmpty(row.row.createdAt)) {
                    return <span></span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.createdAt))
                }
            }
        },
        {
            Header : 'Aangemaakt door',
            accessor : 'createdBy',
            Cell: row => {
                if (_.isEmpty(row.row.createdBy)) {
                    return <span></span>
                } else {
                    return <span>{row.row.createdBy.firstName} {row.row.createdBy.lastName} </span>
                }
            }
        },
        {
            Header : 'Facturen',
            accessor : 'includedInvoices',
            Cell: row => {
                if (_.isEmpty(row.row.includedInvoices)) {
                    return <span></span>
                } else {
                    var includedInvoicesString = "";
                    for (let index in row.row.includedInvoices) {
                        if (index > 0 ) {
                            includedInvoicesString += ", ";
                        }
                        includedInvoicesString += row.row.includedInvoices[index].id;
                    }
                    return includedInvoicesString;
                }
            }
        },
        {
            Header : 'Download',
            accessor: 'id',
            Cell : row => {
                return <button onClick={(event) => {downloadTaxCertificate(event, row.row._original.id)}}><span className="fa fa-download"></span></button>
            }
        }
    ];

    const downloadTaxCertificate = (event, taxCertificateId) => {
        event.preventDefault();
        API.get('/pdf/taxcertificate/' + taxCertificateId).then(function(response) {
            const linkId = `link-invoice-id-${taxCertificateId}`;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${taxCertificateId}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
        });

        return false;
    };

    return (
        <>
            <ReactTable
                data={props.taxCertificates}
                columns={taxCertificateColumns}
                defaultPageSize={10}
                minRows={1}
                className={"-striped -highlight"}
                showPagination={true}
            />
        </>
    );

}

export default UserTaxAttests;