import React, {useState} from 'react';
import API from "../../utils/API";
import _ from "lodash";
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import {Link} from "react-router-dom";
import {withScriptjs,withGoogleMap,GoogleMap, Marker} from "react-google-maps";



const TaskDetailModal = ({task, lat, lng, user}) =>  {

    const [selectedTaskProjectNotes, setSelectedTaskProjectNotes] = useState([]);

    const startTask = (id) => {
        API.put('task/' + id + '/state/changetobusy').then(function() {
            API.get('/task/' + id).then(function(response) {
                //setSelectedTask(response.data);
             });
        });
    };

    const cancelTask = (id) => {
        API.put('task/' + id + '/state/changetonew').then(function() {
            API.get('/task/' + id).then(function(response) {
                //setSelectedTask(response.data);
             });
        });
    };

    const pauseTask = (id) => {
        API.put('task/' + id + '/state/changetopause').then(function() {
            API.get('/task/' + id).then(function(response) {
                //setSelectedTask(response.data);
             });
        });
    };

    const endTask = (id, projectId) => {
        window.location.replace('/project/' + projectId + '/task/' + id + '/evaluation');
    };

    const CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            defaultZoom={11}
            defaultCenter={{ lat: lat, lng: lng }}
        >
            {props.children}
        </GoogleMap>
    ));

    return (
        <>
            {task !== null && user !== null ? (
                
                <>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <h3>
                                <Link className={'btn float-right'} to={'/task/detail/' + task.id}><i className="icofont icofont-2x icofont-eye-alt"></i> Taak bekijken</Link>
                            </h3>
                        </Col>
                    </Row>
                    
                    {new Date(task.plannedDateForExecution) < new Date() && new Date(task.plannedEndDateForExecution) > new Date() && task.status === 'NEW' ? (
                        <Row className={'mt-3'}>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <p>Deze taak is nog niet gestart en zou al begonnen moeten zijn.</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ): null}
                    <Row className={'mt-3'}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div dangerouslySetInnerHTML={{__html: task.description}} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <table className={'table'} style={{border: '0', textAlign: 'left'}}>
                            <tr>
                                    <th style={{ color: 'var(--body-color)'}}><strong>Status</strong></th>
                                    <td>
                                        {task.status}<br />
                                        {task.responsibleUser !== null && task.responsibleUser.id === user.id ? (
                                            <>
                                                {task.status === 'NEW' ? (
                                                    <button
                                                        className={'btn mr-2'}
                                                        onClick={() => startTask(task.id)}
                                                    >
                                                        <span>START</span>
                                                    </button>
                                                ) : null}

                                                {task.status === 'PAUSE' ? (
                                                    <button
                                                        className={'btn mr-2'}
                                                        onClick={() => startTask(task.id)}
                                                    >
                                                        <span>GA VERDER</span>
                                                    </button>
                                                ) : null}   
                                                    
                                                {task.status === 'BUSY' ? (
                                                    <>
                                                        <button
                                                            className={'btn mr-2'}
                                                            onClick={() => cancelTask(task.id)}
                                                        >
                                                            <span>ANNULEER</span>
                                                        </button>
                                                        <button
                                                            className={'btn mr-2'}
                                                            onClick={() => pauseTask(task.id)}
                                                        >
                                                            <span>PAUZEER</span>
                                                        </button>
                                                        <button
                                                            className={'btn mr-2'}
                                                            onClick={() => endTask(task.id, task.project.id)}
                                                        >
                                                            <span>EVALUEER</span>
                                                        </button>
                                                    </>
                                                ) : null}
                                                    
                                                {task.status === 'FINISHED' || task.status === 'DONE' ? (
                                                    <>
                                                        Deze taak is reeds beëindigd
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ color: 'var(--body-color)'}}><strong>Starttijd</strong></th>
                                    <td>
                                        {new Intl.DateTimeFormat("nl-BE", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    }).format(new Date(task.plannedDateForExecution))}
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ color: 'var(--body-color)'}}><strong>Eindtijd</strong></th>
                                    <td>
                                        {new Intl.DateTimeFormat("nl-BE", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    }).format(new Date(task.plannedEndDateForExecution))}
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ color: 'var(--body-color)'}}><strong>Uitvoerders</strong></th>
                                    <td>
                                        {task.responsibleUser !== undefined && task.responsibleUser !== null ? (
                                            <>{task.responsibleUser.firstName} {task.responsibleUser.lastName}</>
                                        ) : (
                                            <>
                                                {task.responsibleUsers.map((responsibleUser, index) => {
                                                    return <>{index !== 0 ? ', ': null}{responsibleUser.firstName} {responsibleUser.lastName}</>
                                                })}
                                            </>
                                        )}
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ color: 'var(--body-color)'}}><strong>Verantwoordelijke</strong></th>
                                    <td>{task.accountableUser.firstName} {task.accountableUser.lastName}</td>
                                </tr>
                            </table>
                            {lat !== 0 && lng !== 0 ? (
                                <>
                                    <CMap
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo&v=3.exp&libraries=geometry,drawing,places"}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `400px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        center= {{ lat: lat, lng:lng }}
                                    >
                                        <Marker
                                            position={{ lat: lat, lng: lng }}
                                        />
                                    </CMap>
                                </>
                    ) : null}
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} className='mt-3 mt-sm-1 mt-md-1 mt-lg-1 mt-xl-1'>
                            <Card style={{border: '2px dashed var(--table-header-bg)', color: 'var(--body-color)', textAlign: 'left'}}>
                                <CardBody>
                                    {task.project !== null && task.project !== undefined ? (
                                        <>
                                            <h6>
                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <strong>PROJECT {task.project.name}</strong>
                                                    </Col>
                                                    <Col sm={12} lg={6} className='mt-2 d-block d-sm-none' style={{textAlign: 'right'}}>
                                                        <a
                                                            className={'btn btn-primary mr-2 mb-2 form-control'}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            href={'/project/detail/'+ task.project.id}
                                                        ><i className="icofont icofont-2x icofont-eye-alt"></i> Project openen</a>

                                                        <a
                                                            className={'btn btn-primary mr-2 mb-2 form-control'}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            href={'/project/'+ task.project.id + '/workorder/create'}
                                                        ><i className="icofont icofont-2x icofont-addons"></i> Werkbon aanmaken</a>

                                                    </Col>
                                                    <Col sm={12} lg={6} className='d-none d-sm-block' style={{textAlign: 'right'}}>
                                                        <a
                                                            className={'btn btn-primary mr-2 mb-2'}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            href={'/project/detail/'+ task.project.id}
                                                        ><i className="icofont icofont-2x icofont-eye-alt"></i> Project openen</a>

                                                        <a
                                                            className={'btn btn-primary mr-2 mb-2'}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            href={'/project/'+ task.project.id + '/workorder/create'}
                                                        ><i className="icofont icofont-2x icofont-addons"></i> Werkbon aanmaken</a>

                                                    </Col>
                                                </Row>
                                            </h6>
                                            <p dangerouslySetInnerHTML={{__html: task.project.description}} />
                                        </>
                                    ) : null}
                                    <table style={{width: '100%'}}>
                                        <tr>
                                            <th style={{ verticalAlign: 'top'}}><strong>LOCATIE</strong></th>
                                            <td>
                                                {task.project !== null && task.project !== undefined ? (
                                                    <>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                                                {task.project.street} {task.project.houseNumber} {task.project.bus} <br />
                                                                {task.project.postalCode} {task.project.city} <br />
                                                                {task.project.country !== null ? task.project.country.name : null}
                                                            </Col>
                                                            
                                                            <Col xs={12} sm={12} md={12} lg={4} className='d-none d-sm-block' style={{textAlign: 'right'}}>
                                                                {lat !== null && lng !== null ? (
                                                                    <>
                                                                        <a
                                                                            className={'btn btn-primary mr-2'}
                                                                            target={'_blank'}
                                                                            rel="noreferrer"
                                                                            href={'https://www.google.be/maps/dir//' + task.project.street + ' ' + task.project.houseNumber + ',' + task.project.postalCode + ' ' + task.project.city/* + '/@' + this.state.lat + ','+ this.state.lng*/}
                                                                        >
                                                                            <i className="icofont icofont-2x icofont-social-google-map"></i> Google Maps
                                                                        </a>
                                                                        <a
                                                                            className={'btn btn-primary mr-3'}
                                                                            target={'_blank'}
                                                                            rel="noreferrer"
                                                                            href={'https://www.waze.com/ul?ll=' + lat + ',' + lng + '&navigate=yes&zoom=17'}
                                                                        >
                                                                            <i className="icofont icofont-2x icofont-direction-sign"></i> Waze
                                                                        </a>
                                                                    </>
                                                                ) :(
                                                                    <>
                                                                        Er zijn geen routebeschrijvingen beschikbaarh. De geocoördinaten konden niet berekend worden.
                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        
                                                        <br />
                                                        <br />
                                                        
                                                    </>
                                                ) : null}
                                            </td>
                                        </tr>
                                        </table>

                                        <Row className='d-block d-sm-none mt-2' >
                                            <Col xs={12} sm={12} md={12} lg={4} style={{textAlign: 'right'}}>
                                                {lat !== null && lng !== null ? (
                                                    <>
                                                        <a
                                                            className={'btn btn-primary form-control'}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            href={'https://www.google.be/maps/dir//' + task.project.street + ' ' + task.project.houseNumber + ',' + task.project.postalCode + ' ' + task.project.city/* + '/@' + this.state.lat + ','+ this.state.lng*/}
                                                        >
                                                            <i className="icofont icofont-2x icofont-social-google-map"></i> Google Maps
                                                        </a>
                                                        <a
                                                            className={'btn btn-primary mt-2 form-control'}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            href={'https://www.waze.com/ul?ll=' + lat + ',' + lng + '&navigate=yes&zoom=17'}
                                                        >
                                                            <i className="icofont icofont-2x icofont-direction-sign"></i> Waze
                                                        </a>
                                                    </>
                                                ) :(
                                                    <>
                                                        Er zijn geen routebeschrijvingen beschikbaarh. De geocoördinaten konden niet berekend worden.
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        

                                        <table style={{width: '100%'}}>
                                        <tr>
                                            <th style={{ verticalAlign: 'top'}}><strong>KLANT</strong></th>
                                            <td>
                                                {task.workOrder !== null && task.workOrder !== undefined ? (
                                                    task.workOrder.project !== null && task.workOrder.project !== undefined ? (
                                                        task.workOrder.project.customer !== null && task.workOrder.project.customer !== undefined ?
                                                            (
                                                                <>
                                                                    <table className='d-block d-sm-none' style={{width:'100%'}}>
                                                                        <tr>
                                                                            <td style={{width: '100%'}}>
                                                                                {task.workOrder.project.customer.firstName} {task.workOrder.project.customer.lastName}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{width: '100%'}}>
                                                                                {_.isEmpty(task.workOrder.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {task.workOrder.project.customer.primaryEmail}<br /></>)}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{width: '100%'}}>
                                                                                {_.isEmpty(task.workOrder.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.workOrder.project.customer.primaryPhone}>{task.workOrder.project.customer.primaryPhone}</a><br /></>)}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{width: '100%'}}>
                                                                                {_.isEmpty(task.workOrder.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.workOrder.project.customer.primaryMobile}>{task.workOrder.project.customer.primaryMobile}</a><br /></>)}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table className='d-none d-sm-block' style={{width:'100%'}}>
                                                                        <tr>
                                                                            <td style={{width: '50%'}}>
                                                                                {task.workOrder.project.customer.firstName} {task.workOrder.project.customer.lastName}
                                                                            </td>
                                                                            <td style={{width: '50%'}}>
                                                                                {_.isEmpty(task.workOrder.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {task.workOrder.project.customer.primaryEmail}<br /></>)}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{width: '50%'}}>
                                                                                {_.isEmpty(task.workOrder.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.workOrder.project.customer.primaryPhone}>{task.workOrder.project.customer.primaryPhone}</a><br /></>)}
                                                                            </td>
                                                                            <td style={{width: '50%'}}>
                                                                                {_.isEmpty(task.workOrder.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.workOrder.project.customer.primaryMobile}>{task.workOrder.project.customer.primaryMobile}</a><br /></>)}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </>
                                                                
                                                            ) : null
                                                    ) : null
                                                ) : (
                                                    task.project !== null && task.project !== undefined ? (
                                                        task.project !== null && task.project !== undefined ? (
                                                                task.project.customer !== null && task.project.customer !== undefined ?
                                                                    (
                                                                        <>
                                                                        <table style={{width:'100%'}} className='table d-block d-sm-none'>
                                                                            <tr>
                                                                                <td style={{width: '100%'}}>
                                                                                    {task.project.customer.firstName} {task.project.customer.lastName}<br />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{width: '100%'}}>
                                                                                    {_.isEmpty(task.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {task.project.customer.primaryEmail}<br /></>)}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{width: '100%'}}>
                                                                                    {_.isEmpty(task.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.project.customer.primaryPhone}>{task.project.customer.primaryPhone}</a><br /></>)}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{width: '100%'}}>
                                                                                    {_.isEmpty(task.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.project.customer.primaryMobile}>{task.project.customer.primaryMobile}</a><br /></>)}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                        <table style={{width:'100%'}} className='table d-none d-sm-block'>
                                                                            <tr>
                                                                                <td style={{width: '50%'}}>
                                                                                    {task.project.customer.firstName} {task.project.customer.lastName}<br />
                                                                                </td>
                                                                                <td style={{width: '50%'}}>
                                                                                    {_.isEmpty(task.project.customer.primaryEmail) ? null : (<><span className={'fa fa-envelope'}></span> {task.project.customer.primaryEmail}<br /></>)}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{width: '50%'}}>
                                                                                    {_.isEmpty(task.project.customer.primaryPhone) ? null : (<><span className={'fa fa-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.project.customer.primaryPhone}>{task.project.customer.primaryPhone}</a><br /></>)}
                                                                                </td>
                                                                                <td style={{width: '50%'}}>
                                                                                    {_.isEmpty(task.project.customer.primaryMobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> <a style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} href={'tel:' + task.project.customer.primaryMobile}>{task.project.customer.primaryMobile}</a><br /></>)}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                        </>
                                                                        
                                                                    ) : null
                                                            ) : null
                                                        ) : null
                                                )}
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>

                            <Card style={{border: '2px dashed var(--table-header-bg)', color: 'var(--body-color)', textAlign: 'left'}}>
                                <CardBody>
                                    <h6><strong>NOTITIES</strong></h6>
                                    <table className={'table'}>
                                        <tr style={{border: '0'}}>
                                            <td colSpan={2} style={{borderTop: '0px solid #000000', color: 'var(--table-header-color)'}}>
                                                {selectedTaskProjectNotes.map((note) => {
                                                    return (
                                                        <Row>
                                                            <Col lg={3} style={{fontSize: '9px'}}>
                                                                {new Intl.DateTimeFormat("nl-BE", {
                                                                    year: "numeric",
                                                                    month: "numeric",
                                                                    day: "2-digit",
                                                                    hour: "2-digit",
                                                                    minute: "2-digit"
                                                                }).format(new Date(note.creationDate))}
                                                            </Col>
                                                            <Col lg={8}><p dangerouslySetInnerHTML={{__html: note.note}}></p></Col>
                                                        </Row>    
                                                        );
                                                    })}
                                                        {selectedTaskProjectNotes.length === 0 ? (
                                                        <span style={{color: 'var(--body-color)'}}>Geen notities aanwezig</span>
                                                        ) : null}
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    {task.workOrder !== null && task.workOrder !== undefined ? (
                        <>
                            <Row className={'mt-3'}>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <h6>
                                        <strong>WERKBON: {task.workOrder.name}</strong>
                                        <Link
                                            className={'btn float-right'}
                                            target={'_blank'}
                                            to={'/workorder/detail/'+ task.workOrder.id}
                                        ><i className="icofont icofont-2x icofont-eye-alt"></i></Link>
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div dangerouslySetInnerHTML={{__html: task.workOrder.description}} />
                                </Col>
                            </Row>
                        </>
                    ) : null}
                </>
                
            ) : null}
        </>
    );
};

export default TaskDetailModal;