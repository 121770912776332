import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Card, CardBody, Col, Row} from "reactstrap";
import API from '../../../../utils/API';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import useReactTable from '../../../../hooks/useReactTable';
import { Link } from 'react-router-dom';

const ProjectWorkOrdersCompact = (props) =>  {
    const [project, setProject] = useState(props.project);
    const [workOrders, setWorkOrders] = useState([]);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Naam',
            accessor : 'name',
            width: 250,
            Cell : cell => (
                <div>
                    <a target={'_blank'} href={process.env.PUBLIC_URL + '/workorder/detail/' + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : '% ingevuld',
            accessor: 'fulfillmentPercentage',
            Cell: cell => (
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="{cell.value}" aria-valuemin="0" aria-valuemax="100" style={{width: cell.value +'%'}}>
                        {cell.value}%
                    </div>
                </div>
            )
        }
    ]);

    useEffect(() => {
        if (project !== null) {
            setWorkOrders(project.workOrders);
        }
    }, [project]);

    return (
        <>
            <Card style={{border: '1px solid ' + process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                <CardBody>
                    <h4>Werkbonnen</h4>
                    {project && workOrders !== undefined && workOrders.length > 0 ? (
                        <ReactTable 
                            data={workOrders}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={10}
                            className={"-striped -highlight"}
                            showPagination={true}
                            previousText={'Vorige'}
                            nextText={'Volgende'}
                            pageText={'Pagina'}
                            rowsText={'rijen'}
                            ofText={'van'}
                            getTheadThProps={getTheadThProps}
                            getTdProps={getTdProps}
                            getTrProps={getTrProps}
                            getPaginationProps={getPaginationProps}
                        />
                    ) : (
                        <p>Er zijn geen werkbonnen aangemaakt voor dit project.</p>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default ProjectWorkOrdersCompact;