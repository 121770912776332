import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useReactTable from '../../../hooks/useReactTable';

const ProjectTaxCertificates = (props) =>  {
    const [project, setProject] = useState(null);
    const [taxCertificates, setTaxCertificates] = useState([]);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Aangemaakt op',
            accessor : 'createdAt',
            width: 250,
            Cell: row => {
                if (_.isEmpty(row.row.createdAt)) {
                    return <span></span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.createdAt))
                }
            }
        },
        {
            Header : 'Aangemaakt door',
            accessor : 'createdBy',
            Cell: row => {
                if (_.isEmpty(row.row.createdBy)) {
                    return <span></span>
                } else {
                    return <span>{row.row.createdBy.firstName} {row.row.createdBy.lastName} </span>
                }
            }
        },
        {
            Header : 'Getekend',
            accessor : 'customerSignature',
            Cell: row => {
                if (_.isEmpty(row.row.customerSignature)) {
                    return <span>Neen</span>
                } else {
                    return <span>Ja</span>
                }
            }
        },
        {
            Header : 'Download',
            accessor: 'id',
            width: 50,
            Cell : row => {
                return <Link onClick={(event) => download(event, row.row.id, row.row._original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
            }
        },
        {
            Header : 'Download',
            accessor: 'id',
            width: 50,
            Cell : row => {
                return <Link to={'/taxcertificate/' + row.row.id + '/sign'}><i className="icofont icofont-2x icofont-pencil"></i></Link>
            }
        }
    ]);

    useEffect(() => {
        setProject(props.project);
    }, []);

    useEffect(() => {
        if (project !== null) {
            setTaxCertificates(props.project.taxCertificates);
        }
    }, [project]);

    const download = (event, taxcertificateId) => {
        API.get('/pdf/taxcertificate/' + taxcertificateId ).then(function(response) {
            const linkSource = `data:application/pdf;base64,${response.data.base64}`;
            const downloadLink = document.createElement("a");
            const fileName = "btwattest_" + taxcertificateId + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    };

    const createNewTaxAttest = () => {
        const me = this;
        API.post('project/' + project.id + '/taxcertificate').then(function(response) {
            //me.reloadProject();
        });
    };

    return (
        <>
            
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            {project !== undefined ? (
                                <ReactTable
                                    data={taxCertificates}
                                    columns={columns}
                                    minRows={0}
                                    defaultPageSize={10}
                                    className={"-striped -highlight"}
                                    showPagination={true}
                                    previousText={'Vorige'}
                                    nextText={'Volgende'}
                                    pageText={'Pagina'}
                                    rowsText={'rijen'}
                                    ofText={'van'}
                                    getTheadThProps={getTheadThProps}
                                    getTdProps={getTdProps}
                                    getTrProps={getTrProps}
                                    getPaginationProps={getPaginationProps}
                                />
                            ) : (
                                <p>Er zijn geen BTW-attesten gedefinieerd voor dit project.</p>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={'mt-1'}>
                <Col lg={12}>
                    {project !== null ? (
                        <a href={'#'} className={'btn btn-primary'} onClick={createNewTaxAttest}><span className={'fa fa-plus'}></span> Nieuw BTW attest toevoegen</a>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default ProjectTaxCertificates;