import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import _ from "lodash";
import useReactTable from '../../hooks/useReactTable';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const TimesheetsOverview = () =>  {

    const [timesheets, setTimesheets] = useState([]);
    const columns = useMemo(() => [
        {
            Header : 'Dag',
            accessor : 'startTime',
            width: 175,
            headerClassName: 'text-left',
            Cell: row => {
                if (_.isEmpty(row.row.startTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit"
                    }).format(new Date(row.row.startTime))
                }
            }
        },
        {
            Header : 'Starttijd',
            accessor : 'startTime',
            width: 100,
            headerClassName: 'text-left',
            Cell: row => {
                if (_.isEmpty(row.row.startTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.startTime))
                }
            }
        },
        {
            Header : 'Eindtijd',
            accessor : 'endTime',
            width: 100,
            Cell: row => {
                if (_.isEmpty(row.row.endTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.endTime))
                }
            }
        },
        {
            Header : 'Werktijd',
            accessor : 'duration',
            width: 100,
            Cell: row => {
                if (_.isNumber(row.row.duration)) {
                    if (row.row.pauseIncluded) {
                        return <span>{(row.row.duration / 60) - 0.5 }</span>
                    } else {
                        return <span>{row.row.duration / 60 }</span>
                    }
                } else {
                    return <span>-</span>
                }
            }
        },
        {
            Header : 'Pauze',
            accessor : 'pauseIncluded',
            width: 100,
            Cell: row => {
                if (row.row.pauseIncluded) {
                    return <span>Ja</span>
                } else {
                    return <span>Neen</span>
                }
            }
        },
        {
            Header : 'Totaal',
            accessor : 'duration',
            width: 100,
            Cell: row => {
                if (_.isNumber(row.row.duration)) {
                    return <span>{row.row.duration / 60 }</span>
                } else {
                    return <span>-</span>
                }
            }
        },
        {
            Header : 'Uitvoerder',
            accessor : 'user',
            width: 175,
            headerClassName: 'text-left',
            Cell: row => {
                if (row.row.user !== undefined && row.row.user !== null) {
                    return <span>{row.row.user.firstName} {row.row.user.lastName}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Beschrijving',
            accessor : 'description',
            headerClassName: 'text-left'
        }
    ]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();

    useEffect(() => {
        loadTimesheets();
    }, []);

    const loadTimesheets = () => {
        API.get('timesheetitems').then(function(response) {
            setTimesheets(response.data);
        });
    };

    return (
        <Fragment>
            <Breadcrumb>Tijdsregistraties</Breadcrumb>
            <div className="container-fluid">
                <div className="edit-profile">
                    <Row>
                        <Col lg={12}>
                            <ReactTable 
                                data={timesheets}
                                columns={columns}
                                minRows={0}
                                className={"-striped -highlight"}
                                showPagination={true}
                                previousText={'Vorige'}
                                nextText={'Volgende'}
                                pageText={'Pagina'}
                                rowsText={'rijen'}
                                ofText={'van'}
                                getTheadThProps={getTheadThProps}
                                getTdProps={getTdProps}
                                getTrProps={getTrProps}
                                getPaginationProps={getPaginationProps}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col lg={12}>
                            <Link className={'btn btn-primary'} to={'/timesheet/create'}>Log nieuwe prestatie</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default TimesheetsOverview;