import React, { useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";
import API from "./utils/API";

import Project from './components/dashboard/project/project';

import UserProfile from './components/users/userProfile';
import UserEdit from './components/users/userEdit';
import UserCards from './components/users/user-cards';
import ForgetPwd from './pages/forgetPwd';
import ResetPwd from './pages/resetPwd';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';

import Signin from './auth/signin';

//config data
import configDB from './data/customizer/config'
import UserCreate from "./components/users/userCreate";
import UsersOverview from "./components/users/usersOverview";
import WorkOrderTemplateCreate from "./components/workordertemplate/workOrderTemplateCreate";
import ProjectCreate from "./components/projects/projectCreate";
import WorkOrderTemplatesOverview from "./components/workordertemplate/workOrderTemplatesOverview";
import WorkOrderTemplateDetail from "./components/workordertemplate/workOrderTemplateDetail";
import ProjectsOverview from "./components/projects/projectsOverview";
import ProjectDetail from "./components/projects/projectDetail";
import WorkOrdersOverview from "./components/workorder/workOrdersOverview";
import WorkOrderDetail from "./components/workorder/workOrderDetail";
import TimesheetsOverview from "./components/timesheets/timesheetsOverview";
import MyTasksOverview from "./components/tasks/myTasksOverview";
import TaskDetail from "./components/tasks/taskDetail";
import TimesheetDetail from "./components/timesheets/timesheetDetail";
import InvoiceUpload from "./components/invoice/invoiceUpload";
import UploadedInvoicesOverview from "./components/invoice/uploadedInvoicesOverview";
import UserDetail from "./components/users/userDetail";
import EmployeeCreate from "./components/users/employeeCreate";
import IcoIcons from "./components/icons/icoIcons";
import TimesheetCreate from "./components/timesheets/timesheetCreate";
import TasksOverview from "./components/tasks/tasksOverview";
import EstablishmentsOverview from "./components/establishment/establishmentsOverview";
import EstablishmentCreate from "./components/establishment/establishmentCreate";
import EstablishmentDetail from "./components/establishment/establishmentDetail";
import OfferCreate from "./components/offers/offerCreate";
import InvoiceCreateBasedOnOffer from "./components/invoice/invoiceCreateBasedOnOffer";
import OfferDetail from "./components/offers/offerDetail";
import OffersOverview from "./components/offers/offersOverview";
import InvoicesOverview from "./components/invoice/invoicesOverview";
import OfferApprove from "./components/offers/offerApprove";
import TaxCertificateOverview from "./components/taxcertificates/taxCertificateOverview";
import EmployerCreate from "./components/users/employerCreate";
import CreditnoteCreateBasedOnInvoice from "./components/invoice/creditnoteCreateBasedOnInvoice";
import ProjectFinish from "./components/projects/projectFinish";
import InvoiceCreate from "./components/invoice/invoiceCreate";
import TaxCertificateSigning from "./components/taxcertificates/taxCertificateSigning";
import ExternalPartiesOverview from "./components/externalparty/externalpartiesOverview";
import ExternalPartyCreate from "./components/externalparty/externalpartyCreate";
import ExternalPartyDetail from "./components/externalparty/externalPartyDetail";
import InvoiceDetail from "./components/invoice/invoiceDetail";
import ConfigurationOverview from './components/configuration/configurationOverview';
import DocumentsOverview from './components/documents/documentsOverview';
import WorkOrderCreate from './components/workorder/workOrderCreate';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import WorkOrderForProjectCreate from './components/workorder/workOrderForProjectCreate';
import HolidaysOverview from './components/holidays/holidaysOverview';
import HolidayCreate from './components/holidays/holidayCreate';
import TaskEvaluation from './components/tasks/taskEvaluation';
import RegionsOverview from './components/region/regionsOverview';
import PostalCodesOverview from './components/postalcodes/postalCodesOverview';
import RegionCreate from './components/region/regionCreate';
import ProjectStatusOverview from './components/configuration/projectstatus/projectStatusOverview';
import ProjectStatusCreate from './components/configuration/projectstatus/projectStatusCreate';
import ProjectFlowOverview from './components/configuration/projectflow/projectFlowOverview';
import ProjectFlowCreate from './components/configuration/projectflow/projectFlowCreate';
import ProjectTaskboardOverview from './components/configuration/projecttaskboard/projectTaskboardOverview';
import ProjectTaskboardCreate from './components/configuration/projecttaskboard/projectTaskboardCreate';
import ProjectsInRegion from './components/projects/projectsInRegion';
import PlanningOverview from './components/planning/planningOverview';
import ProjectStatusPlanningOverview from './components/planning/projectStatusPlanningOverview';
import SettingsOverview from './components/settings/settingsOverview';
import ProjectTodoItemOverview from './components/projectTodoItems/projectTodoItemOverview';
import TaskForProjectCreate from './components/tasks/taskForProjectCreate';
import twoFactorAuthentication from './auth/twoFactorAuthentication';
import OfferOverview from './components/offers/offerOverview';
import InvoiceOverview from './components/invoice/invoiceOverview';

function Root() {
    const isLoggedIn = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) !== undefined && localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) !== null;

    const abortController = new AbortController();

    const innerTheme = createTheme({
        palette: {
          primary: {
            main: green[500],
          },
        },
      });

    useEffect(() => {


        document.documentElement.style.setProperty('--primary-color', process.env.REACT_APP_STYLING_PRIMARY_COLOR);
        document.documentElement.style.setProperty('--body-bg', process.env.REACT_APP_STYLING_BODY_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--body-color', process.env.REACT_APP_STYLING_BODY_FONT_COLOR);
        document.documentElement.style.setProperty('--card-bg', process.env.REACT_APP_STYLING_CARD_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--card-header-bg', process.env.REACT_APP_STYLING_CARD_HEADER_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--font-color', process.env.REACT_APP_STYLING_CARD_FONT_COLOR);
        document.documentElement.style.setProperty('--body-font-color', process.env.REACT_APP_STYLING_BODY_FONT_COLOR);
        document.documentElement.style.setProperty('--body-header-font-color', process.env.REACT_APP_STYLING_BODY_HEADER_FONT_COLOR);
        document.documentElement.style.setProperty('--body-sub-title-color', process.env.REACT_APP_INFO_MESSAGE_COLOR);
        document.documentElement.style.setProperty('--secondary-color', process.env.REACT_APP_STYLING_SECONDARY_COLOR);
        document.documentElement.style.setProperty('--primary-color', process.env.REACT_APP_STYLING_PRIMARY_COLOR);
        document.documentElement.style.setProperty('--menubar-bg', process.env.REACT_APP_STYLING_MENU_BAR_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--menubar-color', process.env.REACT_APP_STYLING_MENU_BAR_FONT_COLOR);
        document.documentElement.style.setProperty('--fc-border-color', process.env.REACT_APP_STYLING_PRIMARY_COLOR);
        document.documentElement.style.setProperty('--login-card-bg', process.env.REACT_APP_STYLING_LOGIN_CARD_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--login-card-color', process.env.REACT_APP_STYLING_LOGIN_CARD_FONT_COLOR);
        document.documentElement.style.setProperty('--table-header-bg', process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--table-header-color', process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR);
        document.documentElement.style.setProperty('--table-bg', process.env.REACT_APP_STYLING_TABLE_BACKGROUND_COLOR);
        document.documentElement.style.setProperty('--table-color', process.env.REACT_APP_STYLING_TABLE_FONT_COLOR);
        document.documentElement.style.setProperty('--fc-today-bg-color', process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR);


        



        API.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response !== undefined) {
                if (401 === error.response.status) {
                    window.location = '/login';
                } else {
                    return Promise.reject(error);
                }
            }
        });

        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version;
        document.body.classList.add(layout);
        if(
            !window.location.href.includes("/login") &&
            !window.location.href.includes("/forgetpassword") &&
            !window.location.href.includes("/resetpassword") &&
            !window.location.href.includes("/goedkeuren/offerte")
        ) {
            API.get('/checklogin').then(function(response) {
                console.log("user is logged in");
            }).catch(function(response) {
                console.log("user is not logged in");
            });
        }
        return function cleanup() {
            abortController.abort();
        }
    }, [abortController]);

    return (
        <div className="App">
            
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <ScrollContext>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                            <Route path={`${process.env.PUBLIC_URL}/2fa`} component={twoFactorAuthentication} />
                            <Route path={`${process.env.PUBLIC_URL}/forgetpassword`} component={ForgetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/resetpassword/:token`} component={ResetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/maintenance`} component={Maintenance} />
                            <Route path={`${process.env.PUBLIC_URL}/goedkeuren/offerte/:id`} component={OfferApprove} />

                            <Route path={`${process.env.PUBLIC_URL}/400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/500`} component={Error500} />
                            <Route path={`${process.env.PUBLIC_URL}/503`} component={Error503} />

                            {/* NOTE :- If u want login with firebase only then uncomment this currentUser condition*/}
                            {isLoggedIn ?
                            <Fragment>
                               
                                <App>
                                <ThemeProvider theme={innerTheme}>
                                    {/* dashboard menu */}
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/project`} />)
                                    }} />
                                     <Route exact path={`${process.env.PUBLIC_URL}/`} component={Project} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Project} />

                                    <Route path={`${process.env.PUBLIC_URL}/workorder/create`} component={WorkOrderCreate} />

                                    <Route path={`${process.env.PUBLIC_URL}/project/:id/workorder/create`} component={WorkOrderForProjectCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/project/:id/task/create`} component={TaskForProjectCreate} />

                                    <Route path={`${process.env.PUBLIC_URL}/project/:projectid/task/:taskid/evaluation`} component={TaskEvaluation} />


                                    <Route path={`${process.env.PUBLIC_URL}/settings`} component={ConfigurationOverview} />
                                    
                                    <Route path={`${process.env.PUBLIC_URL}/documents`} component={DocumentsOverview} />
                                    

                                    <Route path={`${process.env.PUBLIC_URL}/offerten`} component={OfferOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/offers`} component={OfferOverview} />

                                    <Route path={`${process.env.PUBLIC_URL}/facturen`} component={InvoiceOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/invoices`} component={InvoiceOverview} />

                                    {/* Users */}
                                    <Route path={`${process.env.PUBLIC_URL}/users/userProfile`} component={UserProfile} />
                                    <Route path={`${process.env.PUBLIC_URL}/user/:id/edit`} component={UserEdit} />
                                    <Route path={`${process.env.PUBLIC_URL}/users/create`} component={UserCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/employee/create`} component={EmployeeCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/employer/create`} component={EmployerCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/user/:id/detail`} component={UserDetail} />
                                    <Route path={`${process.env.PUBLIC_URL}/users/overview`} component={UsersOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/users/userCards`} component={UserCards} />

                                    {/* Uploaded invoices */}
                                    <Route path={`${process.env.PUBLIC_URL}/uploadedinvoices/create`} component={InvoiceUpload} />
                                    <Route path={`${process.env.PUBLIC_URL}/uploadedinvoices/overview`} component={UploadedInvoicesOverview} />

                                    {/* Projects */}
                                    <Route path={`${process.env.PUBLIC_URL}/projects/create`} component={ProjectCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/projects/overview`} component={ProjectsOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/project/detail/:id`} component={ProjectDetail} />
                                    <Route path={`${process.env.PUBLIC_URL}/project/finish/:id`} component={ProjectFinish} />
                                    
                                    <Route path={`${process.env.PUBLIC_URL}/config/projectstatusses/overview`} component={ProjectStatusOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/config/projectstatusses/create`} component={ProjectStatusCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/config/projectflows/overview`} component={ProjectFlowOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/config/projectflows/create`} component={ProjectFlowCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/config/projecttaskboards/overview`} component={ProjectTaskboardOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/config/projecttaskboards/create`} component={ProjectTaskboardCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/config/applicationproperties`} component={SettingsOverview} />

                                    <Route path={`${process.env.PUBLIC_URL}/projecttodoitems`} component={ProjectTodoItemOverview} />


                                    <Route path={`${process.env.PUBLIC_URL}/projectstatusses`} component={ProjectStatusPlanningOverview} />

                                    <Route path={`${process.env.PUBLIC_URL}/planning`} component={PlanningOverview} />

                                    <Route path={`${process.env.PUBLIC_URL}/holidays/overview`} component={HolidaysOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/holiday/create`} component={HolidayCreate} />

                                    <Route path={`${process.env.PUBLIC_URL}/project/:id/invoice/create`} component={InvoiceCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/taxcertificate/:id/sign`} component={TaxCertificateSigning} />

                                    <Route path={`${process.env.PUBLIC_URL}/project/:id/offer/create`} component={OfferCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/offer/detail/:id`} component={OfferDetail} />
                                    <Route path={`${process.env.PUBLIC_URL}/offers`} component={OffersOverview} />


                                    <Route path={`${process.env.PUBLIC_URL}/invoices/overview`} component={InvoicesOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/invoice/detail/:id`} component={InvoiceDetail} />
                                    <Route path={`${process.env.PUBLIC_URL}/taxcertificates/overview`} component={TaxCertificateOverview} />

                                    {/* Tasks */}
                                    <Route path={`${process.env.PUBLIC_URL}/tasks/overview`} component={MyTasksOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/task/detail/:id`} component={TaskDetail} />
                                    <Route path={`${process.env.PUBLIC_URL}/taskmanagement/overview`} component={TasksOverview} />

                                    {/* Projects */}
                                    <Route path={`${process.env.PUBLIC_URL}/establishments/overview`} component={EstablishmentsOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/establishment/create`} component={EstablishmentCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/establishment/detail/:id`} component={EstablishmentDetail} />

                                    <Route path={`${process.env.PUBLIC_URL}/externalparties/overview`} component={ExternalPartiesOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/externalparty/create`} component={ExternalPartyCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/externalparty/detail/:id`} component={ExternalPartyDetail} />

                                    {/* Invoice creation */}
                                    <Route path={`${process.env.PUBLIC_URL}/offer/:id/invoice`} component={InvoiceCreateBasedOnOffer} />

                                    <Route path={`${process.env.PUBLIC_URL}/invoice/:id/creditnote`} component={CreditnoteCreateBasedOnInvoice} />

                                    {/* WorkOrderTemplate */}
                                    <Route path={`${process.env.PUBLIC_URL}/workordertemplates/create`} component={WorkOrderTemplateCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/workordertemplates/overview`} component={WorkOrderTemplatesOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/workordertemplate/detail/:id`} component={WorkOrderTemplateDetail} />

                                    {/* WorkOrder */}
                                    <Route path={`${process.env.PUBLIC_URL}/workorders/create`} component={WorkOrderTemplateCreate} />
                                    <Route path={`${process.env.PUBLIC_URL}/workorders/overview`} component={WorkOrdersOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/workorder/detail/:id`} component={WorkOrderDetail} />

                                    <Route path={`${process.env.PUBLIC_URL}/regions/overview`} component={RegionsOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/region/create`} component={RegionCreate} />
                                    
                                    <Route path={`${process.env.PUBLIC_URL}/projects/nearby/:postalCode`} component={ProjectsInRegion} />

                                    <Route path={`${process.env.PUBLIC_URL}/postalcodes/overview`} component={PostalCodesOverview} />

                                    <Route path={`${process.env.PUBLIC_URL}/timesheets/overview`} component={TimesheetsOverview} />
                                    <Route path={`${process.env.PUBLIC_URL}/timesheet/detail/:id`} component={TimesheetDetail} />
                                    <Route path={`${process.env.PUBLIC_URL}/timesheet/create`} component={TimesheetCreate} />

                                    <Route path={`${process.env.PUBLIC_URL}/icons`} component={IcoIcons} />
                                    </ThemeProvider>
                                </App>
                                
                            </Fragment>
                             :
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                             }
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
            
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();