import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import API from "../../../utils/API";
import _ from "lodash";
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';


const ProjectTaskboardCreate = () =>  {
    const history = useHistory();
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [projectFlow, setProjectFlow] = useState(null);
    const [projectFlows, setProjectFlows] = useState([]);

    const createNewProjectTaskboard = () => {
        API.post('projecttaskboards', {
            name,
            description,
            projectFlowId : projectFlow.id
        }).then(function(response) {
            history.push("/config/projecttaskboards/overview");
        })
    };

    useEffect(() => {
        API.get('projectflows').then(function(response) {
            setProjectFlows(response.data);
        });
    }, []);

    return (
        <Fragment>
            <Breadcrumb parent="Projecttaakborden" title="Nieuw projecttaakbord aanmaken" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <Card>
                        <CardBody>
                            <Row className='form-group'>
                                <Col lg={3}>
                                    <label>Naam:</label>
                                </Col>
                                <Col lg={9}>
                                    <input type={'text'} className={'form-control'} value={name} onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={3}>
                                    <label>Beschijving:</label>
                                </Col>
                                <Col lg={9}>
                                    <input type={'text'} className={'form-control'} value={description} onChange={(e) => setDescription(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={3}>
                                    <label>Flows:</label>
                                </Col>
                                <Col lg={9}>
                                    <Typeahead
                                        id="project-typeahead"
                                        onChange={(selectedArray) => {
                                            setProjectFlow(selectedArray[0]);
                                          }}
                                        labelKey="name"
                                        multiple={false}
                                        options={projectFlows}
                                        placeholder="Selecteer een flow ..."
                                    />
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={12}>
                                    <Button className='form-control' onClick={() => createNewProjectTaskboard() }>Toevoegen</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </div>
            </div>
        </Fragment>
    );
};

export default ProjectTaskboardCreate;