import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import API from "../../../utils/API";
import _ from "lodash";
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { arrayMoveImmutable } from 'array-move';
import ProjectStatusSortableList from './projectStatusSortableList';

const ProjectFlowCreate = () =>  {
    const history = useHistory();
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [projectStatusses, setProjectStatusses] = useState([]);
    const [removedProjectStatusses, setRemovedProjectStatusses] = useState([]);

    useEffect(() => {
        API.get('projectstatusses').then(function(response) {
            setProjectStatusses(response.data);
        });
    }, []);

    const createNewProjectFlow = () => {
        API.post('projectflows', {
            name,
            description,
            projectFlowItems : projectStatusses
        }).then(function(response) {
            history.push("/config/projectflows/overview");
        })
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setProjectStatusses(prevItem => (arrayMoveImmutable(prevItem, oldIndex, newIndex)));
      };

    const onRemoveItem = (id) => {
        setProjectStatusses(projectStatusses.filter(function(item) {
            if (item.id === id) {
                setRemovedProjectStatusses([...removedProjectStatusses, item]);
            }
            return item.id !== id;
        }));
    };

    const addToFlow = (id) => {
        setRemovedProjectStatusses(removedProjectStatusses.filter(function(item) {
            if (item.id === id) {
                setProjectStatusses([...projectStatusses, item]);
            }
            return item.id !== id;
        }));
    };

    return (
        <Fragment>
            <Breadcrumb parent="Projectflows" title="Nieuw projectflow aanmaken" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <Card>
                        <CardBody>
                            <Row className='form-group'>
                                <Col lg={3}>
                                    <label>Naam:</label>
                                </Col>
                                <Col lg={9}>
                                    <input type={'text'} className={'form-control'} value={name} onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={3}>
                                    <label>Beschijving:</label>
                                </Col>
                                <Col lg={9}>
                                    <input type={'text'} className={'form-control'} value={description} onChange={(e) => setDescription(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={12}>
                                    <label>Projectstatussen</label>
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={6}>
                                    <h2>De gekozen flow</h2>
                                    <ProjectStatusSortableList items={projectStatusses} onSortEnd={onSortEnd} onRemoveItem={onRemoveItem} distance={1} />
                                </Col>
                                <Col lg={6}>
                                    <h2>De niet gekozen projectstatussen</h2>
                                
                                        <ul>
                                            {removedProjectStatusses.map((value, index) => (
                                                <li  style={{padding: '10px', margin: '2px', minHeight: '40px', backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR }}>
                                                    <div style={{float:'left'}}>
                                                        {value.name}
                                                    </div>
                                                    <div style={{textAlign: 'right', float: 'right'}}>
                                                        <a href='#' onClick={() => addToFlow(value.id)} style={{padding: '10px', margin: '2px', backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR }}>
                                                        <span className='icon icon-plus' ></span>
                                                        </a>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                </Col>
                            </Row>
                            <Row className='form-group'>
                                <Col lg={12}>
                                    <Button className='form-control' onClick={() => createNewProjectFlow() }>Toevoegen</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default ProjectFlowCreate;