import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Button, Card, CardBody, Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import useReactTable from '../../../hooks/useReactTable';
import { Link } from 'react-router-dom';
import WorkOrderTasks from '../../workorder/workOrderTasks';
import WorkOrderPhotos from '../../workorder/workOrderPhotos';

const ProjectWorkOrders = (props) =>  {
    const [project, setProject] = useState(props.project);
    const [workOrders, setWorkOrders] = useState([]);
    const [workOrdersOpened, setWorkOrdersOpened] = useState([]);
    
    useEffect(() => {
        if (project !== null) {
            setWorkOrders(project.workOrders);
        }
    }, [project]);


    useEffect(() => {
        let tmpArray = [];
        workOrders.map((workOrder) => {
            tmpArray[workOrder.id] = false;
        });
        setWorkOrdersOpened(tmpArray);
    }, [workOrders]);


    const openDetails = (id) => {
        let tmpArray = [...workOrdersOpened];
        tmpArray[id] = true;
        setWorkOrdersOpened(tmpArray);
    }

    const closeDetails = (id) => {
        let tmpArray = [...workOrdersOpened];
        tmpArray[id] = false;
        setWorkOrdersOpened(tmpArray);
    }

    return (
        <>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>

                            {workOrders.map((workOrder) => {
                                return (
                                    <Fragment>
                                            <Row>
                                                <Col lg={5}><h4>{workOrder.name}</h4></Col>
                                                <Col lg={5}>
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow="{workOrder.fulfillmentPercentage}" aria-valuemin="0" aria-valuemax="100" style={{width: workOrder.fulfillmentPercentage +'%'}}>
                                                            {workOrder.fulfillmentPercentage}%
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={2} className='text-right'>
                                                    <Link to={'/workorder/detail/' + workOrder.id} target='_blank' className={'btn btn-primary'}>...</Link> 
                                                </Col>
                                            </Row>
                                            {workOrder.name !== workOrder.description  && <Row><Col lg={12}><p>{workOrder.description}</p></Col></Row>}
                                            <Row>
                                                <Col lg={12}><h6>Fiche</h6></Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col lg={12}>
                                                    <table className={'table'}>
                                                        {workOrder.items.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{_.isEmpty(item.helpingImage) ? null : <img src={item.helpingImage} style={{height: '150px'}}/>}</td>
                                                                    <td>{item.question}</td>
                                                                    <td>
                                                                        {item.answer !== undefined && item.answer !== "" && item.answer !== null ? (
                                                                            <p>{item.answer}</p>
                                                                        ) : (
                                                                            <p>Niets ingevuld</p>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </table>
                                                </Col>
                                            </Row>
                                            
                                            {workOrdersOpened[workOrder.id] === true ? (
                                                <>
                                                    <WorkOrderPhotos workOrder={workOrder} />
                                                    <Row className='mt-3 mb-5'>
                                                        <Col lg={12} style={{textAlign: 'center'}}>
                                                        <Button onClick={() => closeDetails(workOrder.id)}>Verberg afbeeldingen</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <Row className='mt-3 mb-5'>
                                                    <Col lg={12} style={{textAlign: 'center'}}>
                                                        <Button onClick={() => openDetails(workOrder.id)}>Toon afbeeldingen</Button>
                                                    </Col>
                                                </Row>
                                            )}
                                            </Fragment>
                                );
                            })}
                        </div>
                    </div>
                    <br />
                </Col>
            </Row>
            <Row className={'mt-1'}>
                <Col lg={12}>
                    {project !== null ? (
                        <a href={`${process.env.PUBLIC_URL}/project/` + project.id + `/workorder/create`} className={'btn btn-primary'}><span className={'fa fa-plus'}></span> Nieuwe werkbon toevoegen</a>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default ProjectWorkOrders;