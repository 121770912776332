import React, {Fragment, useState} from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import {Button, Card, CardBody, CardTitle, Col, Input, Row} from "reactstrap";
import SignatureCanvas from "./signatureCanvas";
import CKEditors from "react-ckeditor-component";

class ProjectFinish extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : null,
            project : null,
            feedbacks : []
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        var me = this;
        API.get('myuser').then(function(response) {
            me.setState({user: response.data[0]});
        });

        API.get('project/' + id).then(function(response) {
            me.setState({project: response.data});
            let feedbacks = [];
            response.data.workOrders.forEach((workOrder) => {
                feedbacks[workOrder.id] = "";
            })
        });
    }

    saveFinishingProject = (event) =>  {
        event.preventDefault();
        const { id } = this.props.match.params;

        API.post('project/' + id + '/finish', {
            feedbacks: this.state.feedbacks,
            signature: this.refs.sigCanvas.toDataURL()
        }).then(function(response) {

        });
    };

    changeFeedback = (event, workOrderId) => {
        let value = event.editor.getData();

        let feedbackList = {...this.state.feedbacks};
        feedbackList[workOrderId] = value;
        this.setState((prevState) => ({
            feedbacks: feedbackList
        }));
    };

    render() {
        return (
            <>
                <Breadcrumb parent="Projecten" title={'Finaliseren project ' + (!(_.isEmpty(this.state.project)) ? this.state.project.name : '')} />
                <div className="container-fluid">

                    {this.state.user && (this.state.user.userRole === 'ADMIN' || this.state.user.userRole === 'EMPLOYER' || this.state.user.userRole === 'EMPLOYEE' ? (
                        <>
                            <Row>
                                <Col lg={12}>
                                    <div className="user-profile">
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        {this.state.project !== null ? (
                                                            <>
                                                            {_.isEmpty(this.state.project.customerSignature) ? (
                                                                <>
                                                                {this.state.project.workOrders.map((workOrder) => {
                                                                    return (
                                                                        <>
                                                                            <Row>
                                                                                <Col lg={12}>
                                                                                    <h2>{workOrder.name}</h2>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={12}>
                                                                                    <CKEditors content={this.state.feedbacks[workOrder.id]}
                                                                                               events={{
                                                                                                   "change": (event) => this.changeFeedback(event, workOrder.id)
                                                                                               }} />
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    );
                                                                })}
                                                                <Row className={'pt-3'}>
                                                                    <Col lg={12}>
                                                                        <SignatureCanvas ref={'sigCanvas'}
                                                                                         penColor='orange'
                                                                                         canvasProps={{height: 200, className: 'sigCanvas'}} />
                                                                        <button onClick={(e) => this.saveFinishingProject(e)} className={'btn btn-primary'}>Beëindig project</button>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            ) : (
                                                                <>
                                                                    <h2>Project '{this.state.project.name}'</h2>
                                                                    <p>
                                                                        Dit project is gehandtekend en beëindigd.
                                                                    </p>
                                                                    <h4>Feedback over de verschillende werkbonnen</h4>
                                                                    {this.state.project.workOrders.map((workOrder) => {
                                                                        return (
                                                                            <>
                                                                                <h6>{workOrder.name}</h6>
                                                                                <p dangerouslySetInnerHTML={{__html: workOrder.feedback}} />
                                                                                <br />
                                                                            </>
                                                                        );
                                                                    })}
                                                                    <img src={this.state.project.customerSignature} />
                                                                </>
                                                            )}

                                                            </>
                                                        ) : null}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col lg={12}>
                                U heeft onvoldoende rechten om deze pagina te bekijken. Indien je wel de nodige rechten zou moeten hebben, gelieve je systeembeheerder te contacteren.
                            </Col>
                        </Row>
                    )) }


                </div>
            </>
        );
    }
};

export default ProjectFinish;