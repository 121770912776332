import React, { useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Row} from "reactstrap";
import API from '../../../../utils/API';
import _ from "lodash";
import useReactTable from '../../../../hooks/useReactTable';

const ProjectInvoicesCompact = (props) =>  {

    const [project, setProject] = useState(props.project);
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Factuurnummer',
            accessor : 'invoiceNumber',
            Cell : cell => (
                <div>
                    {
                        _.isEmpty(cell.value) ? (
                            <a href={`${process.env.PUBLIC_URL}/invoice/detail/` + cell.original.id}>Factuur in ontwerp</a>
                        ) : (
                            <a href={`${process.env.PUBLIC_URL}/invoice/detail/` + cell.original.id}>{cell.value}</a>
                        )
                    }
                </div>
            )
        },
        {
            Header : 'Vervaldatum',
            accessor : 'expiryDate',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.DateTimeFormat('nl-BE', {dateStyle: 'medium'}).format(Date.parse(cell.value))}
                </>
            )
        },
        {
            Header : 'Totaal excl.',
            accessor : 'totalExcl',
            width: 100,
            Cell : cell => (
                <>
                    {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(cell.value)}
                </>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                <>
                    {cell.original.draft === true ? null : (
                        <a href={'#'} onClick={() => downloadInvoice(cell.original.id, cell.original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></a>
                    )}

                </>
            )
        }
    ]);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id + '/invoices').then(function(response) {
                setInvoices(response.data);
            });
        }
    }, [project]);

    const downloadInvoice = (invoiceId, invoiceNumber) => {
        API.get('pdf/invoice/' + invoiceId).then(function(response) {
            const linkSource = `data:application/pdf;base64,${response.data.base64}`;
            const downloadLink = document.createElement("a");
            const fileName = invoiceNumber + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    };

    return (
        <>
            <Card style={{border: '1px solid ' + process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                <CardBody lg={12}>
                    <h4>Facturen</h4>
                    {invoices.length >  0 ? (
                        <ReactTable
                            data={invoices}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={10}
                            className={"-striped -highlight"}
                            showPagination={false}
                            getTheadThProps={getTheadThProps}
                            getTdProps={getTdProps}
                            getTrProps={getTrProps}
                            getPaginationProps={getPaginationProps}
                        />
                    ) : (
                        <p>Er zijn geen facturen gevonden voor dit project.</p>
                    )}
                    
                </CardBody>
            </Card>
        </>
    );
};

export default ProjectInvoicesCompact;