import React, { Fragment, useEffect, useState } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import cloneDeep from 'lodash/cloneDeep';
import _ from "lodash";
import Loader from "../common/loader";
import {Col, Row} from "reactstrap";
import WorkOrderProjectInformation from './workOrderProjectInformation';
import WorkOrderTasks from './workOrderTasks';
import WorkOrderPhotos from './workOrderPhotos';

const WorkOrderDetail = (props) => {

    const [workOrder, setWorkOrder] = useState(null);
    const [editableWorkOrder, setEditableWorkOrder] = useState(null);
    const [editView, setEditView] = useState(false);

    useEffect(() => {
        const id = props.match.params.id;
        API.get('workorder/' + id).then(function(response) {
            setWorkOrder(response.data);
            setEditableWorkOrder(response.data);
        });
    }, []);

    const openEditView = () => {
        setEditView(true);
    };

    const closeEditView = () => {
        setEditView(false);
    };

    const changeAnswerOfQuestion = (event, questionId) => {
        event.preventDefault();
        let value = event.target.value;
        let updatedWorkOrder = cloneDeep(editableWorkOrder);
        updatedWorkOrder.items.map((item, index) => {
            if (item.id === questionId) {
                item.answer = value;
            }
        });
        setEditableWorkOrder(updatedWorkOrder);
    };

    const updateWorkOrder = () => {
        API.put('/workorder/' + workOrder.id, editableWorkOrder).then(function(response) {
            setWorkOrder(editableWorkOrder);
            setEditView(false);
        }).catch(function(response) {
            setEditView(false);
        });
    };

    return (

        <Fragment>
                {workOrder !== null ? (
                    <Breadcrumb parent={'Werkbonnen Project ' + workOrder.project.name} parentLink={'/project/detail/' + workOrder.project.id}>
                        Detail van werkbon {workOrder.name}
                    </Breadcrumb>
                ) : null}

                {workOrder !== null && editableWorkOrder !== null ? (

                    <div className="container-fluid">
                    <div className="user-profile">
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                {workOrder !== null ? (
                                    <WorkOrderProjectInformation workOrder={workOrder} />
                                ) : null}
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <div className="card firstPost" >
                                    <div className="profile-img-style">
                                        <div className="btn-group btn-group-square" role="group">
                                            <button className={editView ? 'btn btn-outline-primary' : 'btn btn-primary'} onClick={closeEditView} type="button">Overzicht</button>
                                            <button className={editView ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={openEditView} type="button">Wijzig</button>
                                        </div>
                                        <br />
                                        <br />
                                        {editView ? (
                                            <div style={{width: '100%'}}>
                                                <br />
                                                <form>
                                                    <div className={'form-group row'}>
                                                        <label className={'col-xl-12'}>
                                                            Naam:
                                                        </label>
                                                        <div className={'col-xl-12'}>
                                                            <input type={'text'} className={'form-control'} value={editableWorkOrder.name} onChange={(e) => setEditableWorkOrder(prevUser => ({...prevUser, name : e.target.value}))} />
                                                        </div>
                                                    </div>
                                                    <div className={'form-group row'}>
                                                        <label className={'col-xl-12'}>
                                                            Beschrijving:
                                                        </label>
                                                        <div className={'col-xl-12'}>
                                                            <input type={'text'} className={'form-control'} value={editableWorkOrder.description} onChange={(e) => setEditableWorkOrder(prevUser => ({...prevUser, description : e.target.value}))} />
                                                        </div>
                                                    </div>
                                                    {editableWorkOrder.items.map((item, index) => {
                                                        return (
                                                            <div className={'form-group row'}>
                                                                <label className={'col-xl-12'}>
                                                                    {item.question}
                                                                </label>
                                                                <div className={'col-xl-12'}>
                                                                    {item.type.name === 'text' ? (
                                                                        <textarea type={'text'} onChange={(event) => changeAnswerOfQuestion(event, item.id)} className={'form-control'} value={item.answer} />
                                                                    ) : null}
                                                                    {item.type.name === 'multiplechoice' ? (
                                                                        item.options.map((option) => {
                                                                            return <label className={'form-control'}><input type={'radio'} name={'item_' + item.id} value={option.name} checked={item.answer === option.name} onChange={(event) => changeAnswerOfQuestion(event, item.id)} /> {option.name}</label>
                                                                        })
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    <div className={'form-group row'}>
                                                        <div className={'col-xl-12'}>
                                                            <a href="#" onClick={() => updateWorkOrder()} className={'btn btn-primary'}>Werkbon wijzigen</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        ) :(
                                            <div style={{width: '100%'}}>
                                                <h4>{workOrder.name}</h4>
                                                <h5>Beschrijving:</h5>
                                                <p>
                                                    {workOrder.description}
                                                </p>
                                                <br />
                                                <br />
                                                <h5>Werkbonfiche</h5>
                                                <table className={'table'}>
                                                    {workOrder.items.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{_.isEmpty(item.helpingImage) ? null : <img src={item.helpingImage} style={{height: '150px'}}/>}</td>
                                                                <td>{item.question}</td>
                                                                <td>
                                                                    {item.answer !== undefined && item.answer !== "" && item.answer !== null ? (
                                                                        <p>{item.answer}</p>
                                                                    ) : (
                                                                        <p>Niets ingevuld</p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <WorkOrderTasks workOrder={workOrder} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                {workOrder !== undefined && workOrder !== null ? (
                                    <WorkOrderPhotos workOrder={workOrder} />
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </div>
                ) : (
                <Loader />
                )}
            </Fragment>

    );

}

export default WorkOrderDetail;