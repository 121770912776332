import React, { useState, useEffect } from 'react';
import logo_pouillon from '../assets/images/logo-pouillon.png';
import logo_planaxis from '../assets/images/logo_planaxis.png';
import logo_windowrepair from '../assets/images/logo_windowrepair.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from "react-router";
import API from '../utils/API';

const Signin = ({ history }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );

    useEffect(() => {
        API.get('2fa-ga-qr').then(function(response) {
            
        });
    }, [])

    useEffect(() => {
        if (value !== null)
            localStorage.setItem('profileURL', value);
        else
            localStorage.setItem('profileURL', man);
    }, [value]);

    const loginAuth = async (e) => {
        e.preventDefault();

        try {
                await fetch(process.env.REACT_APP_BASE_URL + '/api/login', {
                  method : 'POST',
                  headers : {
                      'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  body : "username=" + email + "&password=" + password + "&authCode=" + token
                }).then(function(response) {
                    console.log(response);
                    return response.json();
                }).then(function(response) {
                    console.log(response);
                    if (response.error) {
                        setTimeout(() => {
                            toast.error("De combinatie van het e-mailadres en wachtwoord is incorrect. Gelieve de juiste combinatie in te geven");
                        }, 200);
                    } else {
                        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN, response.token);
                        localStorage.setItem('werkbonnen_pouillon_dev_expires_in', response.expires_in);
                        localStorage.setItem('werkbonnen_pouillon_dev_token_type', response.token_type);
                        localStorage.setItem('werkbonnen_pouillon_dev_refresh_token', response.refresh_token);
                        window.location.href = "/";
                    }
                });
        } catch (error) {
            setTimeout(() => {
                toast.error("Er is een systeemfout opgetreden. Gelieve je systeembeheerder te contacteren.");
            }, 200);
        }
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body" style={{backgroundColor: process.env.REACT_APP_STYLING_LOGIN_CARD_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_LOGIN_CARD_FONT_COLOR}}>
                                                <div style={{textAlign: "center"}}>
                                                    {window.location.host.includes('planaxis') ? (
                                                        <img src={logo_planaxis} alt="" style={{height: '60px'}} />
                                                    ) : null}
                                                    {window.location.host.includes('windowrepair') ? (
                                                        <img src={logo_windowrepair} alt="" style={{height: '100px'}} />
                                                    ) : null}
                                                    {window.location.host.includes('pouillon') ? (
                                                        <img src={logo_pouillon} alt="" style={{height: '100px'}} />
                                                    ) : null}
                                                    {window.location.host.includes('localhost') ? (
                                                        <img src={logo_pouillon} alt="" style={{height: '100px'}} />
                                                    ) : null}
                                                </div>
                                                <br />
                                                <div>
                                                    <h4>INLOGGEN</h4>
                                                    <h6>Vul je e-mail en wachtwoord in </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={loginAuth} >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Jouw e-mail</label>
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                            placeholder="Email address"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Wachtwoord</label>
                                                        <input className="form-control" type="password" name="password"
                                                            value={password}
                                                            onChange={e => setPassword(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Token</label>
                                                        <input 
                                                            className="form-control" 
                                                            type="text" 
                                                            name="token"
                                                            value={token}
                                                            onChange={e => setToken(e.target.value)}
                                                            placeholder="Token"
                                                        />
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit">Inloggen</button>
                                                    </div>
                                                    <div className={'form-group form-row mt-3 mb-0'}>
                                                        <a href={`${process.env.PUBLIC_URL}/forgetpassword`}>Wachtwoord vergeten?</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Signin);