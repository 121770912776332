import React, { useState, useEffect } from 'react';
import {Col, Row} from "reactstrap";
import _ from "lodash";
import GeoCode from "react-geocode";
import { Link } from 'react-router-dom';

const WorkOrderProjectInformation = (props) =>  {

    const [workOrder, setWorkOrder] = useState(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    useEffect(() => {
        setWorkOrder(props.workOrder);
    }, []);

    useEffect(() => {
        if (workOrder !== null) {
            const address = workOrder.project.street + ' ' + workOrder.project.houseNumber + ' ' + workOrder.project.bus + ", " + workOrder.project.postalCode + " " + workOrder.project.city + ", " + workOrder.project.country;

            GeoCode.setApiKey("AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo");
            GeoCode.fromAddress(address).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setLat(lat);
                    setLng(lng);
                }
            );
        }
    }, [workOrder]);

    return (
        workOrder !== null ? (
            <>
                <div className="card" style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>
                    <div className="card-body">
                        <Row>
                            <Col sm={12} xs={12} md={12} lg={12}>
                                <h4 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}>PROJECTINFORMATIE</h4>
                            </Col>
                        </Row>
                        <Row className={'mt-4'}>
                            <Col sm={12} xs={12} md={12} lg={12}>

                                <h6 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}><strong>NAAM</strong></h6>
                                {workOrder.project.name}
                            </Col>
                        </Row>
                        <Row className={'mt-4'}>
                            <div className={'col-sm-12'}>
                                <p>
                                    <h6 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}><strong>BESCHRIJVING</strong></h6>
                                    <span dangerouslySetInnerHTML={{__html: workOrder.description}}></span>
                                </p>
                            </div>
                        </Row>
                        <Row className={'mt-4'}>
                            <Col sm={12} xs={12} md={12} lg={12}>

                                <h6 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}><i className="fa fa-user"></i> <strong>KLANT</strong></h6>
                                {workOrder !== null && workOrder !== undefined ? (
                                    workOrder.project !== null && workOrder.project !== undefined ? (
                                        workOrder.project.customer !== null && workOrder.project.customer !== undefined ?
                                            (
                                                <p>
                                                    {workOrder.project.customer.firstName} {workOrder.project.customer.lastName}<br />
                                                    {_.isEmpty(workOrder.project.customer.phone) ? null : (<><span className={'fa fa-phone'}></span> {workOrder.project.customer.phone}<br /></>)}
                                                    {_.isEmpty(workOrder.project.customer.mobile) ? null : (<><span className={'fa fa-mobile-phone'}></span> {workOrder.project.customer.mobile}<br /></>)}
                                                    {_.isEmpty(workOrder.project.customer.email) ? null : (<><span className={'fa fa-envelope'}></span> {workOrder.project.customer.email}<br /></>)}
                                                </p>
                                            ) : null
                                    ) : null
                                ) : null}

                            </Col>
                        </Row>
                        <Row className={'mt-4'}>
                            <Col sm={12} xs={12} md={12} lg={12}>
                                <Row>
                                    <Col sm={12}>
                                        <h6 style={{backgroundColor: process.env.REACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR, color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR}}><i className="fa fa-location-arrow"></i> <strong>PLAATS VAN UITVOERING</strong></h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <div className="text-left">

                                            {workOrder.project !== null && workOrder.project !== undefined ? (
                                                <>
                                                    {workOrder.project.street} {workOrder.project.houseNumber} {workOrder.project.bus}<br />
                                                    {workOrder.project.postalCode} {workOrder.project.city}<br />
                                                    {workOrder.project.country !== null ? workOrder.project.country.name : null}
                                                </>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <a
                                            className={'btn'}
                                            style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}
                                            target={'_blank'}
                                            href={'https://www.google.be/maps/dir//' + workOrder.project.street + ' ' + workOrder.project.houseNumber + ',' + workOrder.project.postalCode + ' ' + workOrder.project.city + '/@' + lat + ','+ lng}
                                        >
                                            <i className="icofont icofont-2x icofont-direction-sign"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={'mt-4'}>
                            <Col sm={12} xs={12} md={12} lg={12}>
                                <div className="user-designation">
                                    <Link className={'btn form-control'} style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} to={'/project/detail/' + workOrder.project.id} target={'_parent'}>Meer projectinformatie ...</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        ) : null
        
    );
};

export default WorkOrderProjectInformation;