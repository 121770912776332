import React, { useState, useEffect, useMemo } from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import Breadcrumb from '../common/breadcrumb';
import API from '../../utils/API';
import _ from "lodash";
import { Trash2 } from 'react-feather';
import ReactTable from 'react-table-6';
import useReactTable from '../../hooks/useReactTable';
import Loader from '../common/loader';

const ProjectTodoItemOverview = (props) =>  {

    const [todoItems, setTodoItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Beschrijving',
            accessor : 'description',
            Cell : cell => {return cell.value}
        },
        {
            Header : 'Status',
            accessor : 'status',
            Cell : cell => {return cell.value}
        },
        {
            Header : 'Vervaldatum',
            accessor : 'dueDate',
            Cell : cell => {return cell.value}
        },
        {
            Header : '',
            accessor : 'id',
            width: 125,
            Cell : cell => (
                
                <div>
                    {cell.original.status === 'new' ? (
                        <span>
                            <a href={'#'} onClick={() => changeTodoItemStatus(cell.original.project.id, cell.value, 'ongoing')}>Start</a>
                            <a className='ml-3' href={'#'} onClick={() => changeTodoItemStatus(cell.original.project.id, cell.value, 'done')}>Beëindig</a>
                        </span>
                    ) : null}
                    {cell.original.status === 'ongoing' ? (
                        <span>
                            <a href={'#'} onClick={() => changeTodoItemStatus(cell.original.project.id, cell.value, 'done')}>Beëindig</a>
                            <a className='ml-3' href={'#'} onClick={() => changeTodoItemStatus(cell.original.project.id, cell.value, 'new')}>Annuleer</a>
                        </span>
                    ) : null}
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                
                    <>
                        <a href={'#'}  onClick={() => deleteTodoItem(cell.original.project.id, cell.value)}><Trash2 /></a>
                    </>
            )
        }
    ]);


    useEffect(() => {
        API.get('/todoitems').then(function(response) {
            setTodoItems(response.data);
        });
    }, []);

    const changeTodoItemStatus = (projectId, todoItemId, status) => {
        setLoading(true);
        API.put('/project/' + projectId + '/todoitem/' + todoItemId + "/changestatus", {
            status
        }).then(function() {
            API.get('/project/' + projectId + '/todoitems').then(function(response) {
                setTodoItems(response.data);
                setLoading(false);
            });
        });
    }

    const deleteTodoItem = (projectId, todoItemId) => {
        API.delete('/project/' + projectId + '/todoitem/' + todoItemId).then(function() {
            API.get('/project/' + projectId + '/todoitems').then(function(response) {
                setTodoItems(response.data);
            });
        });
    }

    return (

        loading ? <Loader /> : (
            <>
                <Row>
                    <Col sm={12}>
                        <Breadcrumb>Todo-items</Breadcrumb>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <h2>Overzicht van Todo-items</h2>
                                    </Col>
                                </Row>
                                
                                {todoItems !== undefined ? (
                                    <ReactTable 
                                        data={todoItems}
                                        columns={columns}
                                        minRows={0}
                                        defaultPageSize={10}
                                        className={"-striped -highlight"}
                                        showPagination={true}
                                        previousText={'Vorige'}
                                        nextText={'Volgende'}
                                        pageText={'Pagina'}
                                        rowsText={'rijen'}
                                        ofText={'van'}
                                        getTheadThProps={getTheadThProps}
                                        getTdProps={getTdProps}
                                        getTrProps={getTrProps}
                                        getPaginationProps={getPaginationProps}
                                    />
                                ) : (
                                    <p>Er zijn geen offerten gedefinieerd voor dit project.</p>
                                )}
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    );
};

export default ProjectTodoItemOverview;