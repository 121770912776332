import React, { Fragment } from 'react';
import _ from "lodash";

class Noncustomercontactinformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact : {
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : "",
                alternativeEmail : "",
                alternativePhone : "",
                alternativeMobile : ""
            },
            errors : {
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : ""
            },
            typing: false,
            typingTimeout: 0
        };

        this._validateOnDemand = true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        let isDataValid = this.validationCheck();
        if (isDataValid) {
            this.props.updateContactStore(this.state.contact);
        }
        return isDataValid;
    };

    validationCheck = async () => {
        let isValid = true;
        if (_.isEmpty(this.state.contact.primaryEmail)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "E-mailadres is verplicht" }}));
            isValid = false;
        }
        return isValid;
    };

    changePrimaryEmail = (event) => {
        let value = event.target.value;

        this.setState((prevState) => ({contact : { ...prevState.contact, primaryEmail: value}}));

    };

    changePrimaryMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, primaryMobile : value }
        }));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Primair e-mailadres</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.primaryEmail} onChange={this.changePrimaryEmail} />
                                    {this.state.errors.primaryEmail ? <span className={'errorInForm'}>{this.state.errors.primaryEmail}</span> : null }
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Primair GSM</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.primaryMobile} onChange={this.changePrimaryMobile} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default Noncustomercontactinformation;