import React, { useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import { Card, CardBody} from "reactstrap";
import API from '../../../../utils/API';
import _ from "lodash";
import { useHistory } from 'react-router';
import useReactTable from '../../../../hooks/useReactTable';

const ProjectOffersCompact = (props) =>  {
    const [project, setProject] = useState(null);
    const [offers, setOffers] = useState([]);
    
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Offertenummer',
            accessor : 'offerNumber',
            Cell : cell => (
                <div>
                    <a href={`${process.env.PUBLIC_URL}/offer/detail/` + cell.original.id}>{cell.value}</a>
                </div>
            )
        },
        {
            Header : 'Status',
            accessor : 'status',
            width: 100,
            Cell : cell => (
                <div>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header : '',
            accessor : 'id',
            width: 50,
            Cell : cell => (
                <>
                    <a href={'#'} onClick={() => downloadOffer(cell.original.id, cell.original.offerNumber)}><i className="icofont icofont-2x icofont-download"></i></a>
                </>
            )
        }
    ]);

    useEffect(() => {
        setProject(props.project);
    }, []);

    useEffect(() => {
        if (project !== null) {
            setOffers(project.offers);
        }
    }, [project]);

    const downloadOffer = (offerId, offerName) => {
        API.get('pdf/offer/' + offerId).then(function(response) {
            const linkSource = `data:application/pdf;base64,${response.data.base64}`;
            const downloadLink = document.createElement("a");
            const fileName = offerName + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    };

    return (
        <>
            <Card style={{border: '1px solid ' + process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                <CardBody>
                    <h4>Offerten</h4>
                    {project && offers !== undefined && offers.length > 0 ? (
                        <ReactTable 
                            data={offers}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={10}
                            className={"-striped -highlight"}
                            showPagination={false}
                            previousText={'Vorige'}
                            nextText={'Volgende'}
                            pageText={'Pagina'}
                            rowsText={'rijen'}
                            ofText={'van'}
                            getTheadThProps={getTheadThProps}
                            getTdProps={getTdProps}
                            getTrProps={getTrProps}
                            getPaginationProps={getPaginationProps}
                        />
                    ) : (
                        <p>Er zijn geen offerten gedefinieerd voor dit project.</p>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default ProjectOffersCompact;