import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";


class UploadedInvoicesOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedInvoices : [],
            totalAmountExcl : 0,
            totalAmountVAT : 0,
            totalAmountIncl : 0,
            filtered: [],
            columns : [
                {
                    Header : 'Factuurnummer',
                    accessor : 'invoiceNumber',
                    headerClassName : 'text-left',
                    width: 150,
                    Cell: row => {
                        return <span>{row.row.invoiceNumber}</span>
                    }
                },{
                    Header : 'Naam',
                    accessor : 'name',
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <span>{row.row.name}</span>
                    }
                },
                {
                    Header : 'Klant',
                    accessor : 'customer',
                    headerClassName : 'text-left',
                    width: 200,
                    Cell: row => {
                        if (row.row.customer !== undefined && row.row.customer !== null) {
                            return <span>{row.row.customer.firstName} {row.row.customer.lastName}</span>
                        } else {
                            return <span></span>
                        }
                    }
                },
                {
                    Header : 'Prijs excl',
                    headerClassName: 'text-right',
                    accessor : 'totalPriceExcl',
                    className : 'text-right',
                    width: 100,
                    Cell: row => {
                        return Intl.NumberFormat('nl-BE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(row.row.totalPriceExcl);
                    }
                },
                {
                    Header : 'BTW',
                    headerClassName: 'text-right',
                    accessor : 'totalVatAmount',
                    className : 'text-right',
                    width: 100,
                    Cell: row => {
                        return Intl.NumberFormat('nl-BE', {
                            style: 'currency',
                            currency: 'EUR'

                        }).format(row.row.totalVatAmount);
                    }
                },
                {
                    Header : 'Prijs incl',
                    headerClassName: 'text-right',
                    accessor : 'totalPriceIncl',
                    className : 'text-right',
                    width: 100,
                    Cell: row => {
                        return Intl.NumberFormat('nl-BE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(row.row.totalPriceIncl);
                    }
                }
            ]
        };
    }

    componentDidMount() {
        var me = this;
        API.get('uploadedinvoices').then(function(response) {
            me.setState({uploadedInvoices: response.data});
            var totalPriceIncl = 0;
            var totalPriceExcl = 0;
            var totalVatAmount = 0;
            for (let index in response.data) {
                totalPriceExcl += response.data[index].totalPriceExcl;
                totalPriceIncl += response.data[index].totalPriceIncl;
                totalVatAmount += response.data[index].totalVatAmount;
            }
            me.setState({totalAmountExcl: totalPriceExcl});
            me.setState({totalAmountIncl: totalPriceIncl});
            me.setState({totalAmountVAT: totalVatAmount});
        });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Facturatie" title="Overzicht van alle facturen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.uploadedInvoices}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   minRows={0}
                                   previousText={'Vorige'}
                                   nextText={'Volgende'}
                                   pageText={'Pagina'}
                                   rowsText={'rijen'}
                                   ofText={'van'}
                               />
                            </div>
                        </div>
                        <br />
                        <div className={'row'}>
                            <div className={'col-xl-12 text-right'}>
                                <h2>Totaal inkomsten: {Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR'
                                }).format(this.state.totalAmountIncl)}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default UploadedInvoicesOverview;