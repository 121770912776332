const useReactTable = () => {

  const getTheadThProps = () => ({
    style: {
        fontWeight: '600',
        height: '50px',
        color: process.env.REACT_APP_STYLING_TABLE_HEADER_FONT_COLOR,
        backgroundColor: process.env.RREACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR,
        borderLeft: '0px',
        borderRight: '0px',
        textTransform: 'uppercase'
    },
  });
  const getTdProps = () => ({
    style: {
        height: '50px',
        color: process.env.REACT_APP_STYLING_TABLE_FONT_COLOR + ' !important',
        backgroundColor: process.env.REACT_APP_STYLING_TABLE_BACKGROUND_COLOR,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0
    },
  });
  const getTrProps = () => ({
    style: {
        height: '50px',
        color: process.env.REACT_APP_STYLING_TABLE_FONT_COLOR,
        backgroundColor: process.env.REACT_APP_STYLING_TABLE_BACKGROUND_COLOR,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0
    }
  });
  
  const getPaginationProps= () => ({
    style: {
      color: process.env.REACT_APP_STYLING_TABLE_HADER_FONT_COLOR,
      backgroundColor: process.env.RREACT_APP_STYLING_TABLE_HEADER_BACKGROUND_COLOR,
    } 
  });

    return {
      getTheadThProps: getTheadThProps,
      getTdProps: getTdProps,
      getTrProps: getTrProps,
      getPaginationProps: getPaginationProps
    };
}

export default useReactTable;