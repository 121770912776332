import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';

import { Row, Col, Card, CardBody} from 'reactstrap';
import {useLocation} from "react-router-dom";

import PlanningSideBarMenu from './planningSideBarMenu';
import ProjectStatusBoard from '../projecttaskboard/projectstatusboard';

const ProjectStatusPlanningOverview = () =>  {

    const queryParameters = new URLSearchParams(useLocation().search);

    return (
        <Fragment>
            <Breadcrumb parent="Planning" title="Planning" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <Row>
                        <Col lg={2}>
                            <PlanningSideBarMenu />
                        </Col>
                        <Col lg={10}>
                            <Card>
                                <CardBody>
                                    <div style={{width: '100%', overflow: 'auto'}}>
                                        <ProjectStatusBoard id={queryParameters.get('taskboardId')} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default ProjectStatusPlanningOverview;