import React from 'react';
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import {Col, Row} from "reactstrap";
import API from '../../../utils/API';

class Noncustomerpersonalinformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : {
                username: "",
                password: "",
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                extraInformation : "",
                userRole : "",
            },
            errors : {
                username: "",
                password: "",
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                extraInformation : ""
            },
            typing: false,
            typingTimeout: 0
        };

        this._validateOnDemand = true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        this.props.updatePersonalStore(this.state.user);
        return this.validationCheck();
    };

    validationCheck = () => {
        let isValid = true;
        if (_.isEmpty(this.state.user.firstName)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, firstName : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, firstName : "" }}));
        }
        if (_.isEmpty(this.state.user.lastName)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, lastName : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, lastName : "" }}));
        }
        return isValid;
    };

    changeUsername = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, username : value }
        }));

        const me = this;
        if (me.state.typingTimeout) {
            clearTimeout(me.state.typingTimeout);
        }
        me.setState({
            name: value,
            typing: false,
            typingTimeout: setTimeout(async function () {
                let response = await API.get('uniqueuser?username=' + value);
                if (response.data.unique.toString() === 'false') {
                    me.setState((prevState) => ({errors: { ...prevState.errors, username : "Gebruikersnaam moet uniek zijn" }}));
                } else {
                    me.setState((prevState) => ({errors: { ...prevState.errors, username : "" }}));
                }
            }, 5000)
        });

    };


    

    changePassword = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, password : value }
        }));
    };

    changeFirstName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, firstName : value }
        }));
    };

    changeLastName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, lastName : value }
        }));
    };

    changeExtraInformation = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({
            user: { ...prevState.user, extraInformation : value }
        }));
    };

    render() {
        return (
            <>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Gebruikersnaam</label>
                                    <input className="form-control" type="text" name="username" value={this.state.user.username} onChange={this.changeUsername} />
                                    {this.state.errors.username ? <span className={'errorInForm'}>Gebruikersnaam is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Wachtwoord</label>
                                    <input className="form-control" type="password" name="password" value={this.state.user.password} onChange={this.changePassword} />
                                    {this.state.errors.password ? <span className={'errorInForm'}>Wachtwoord is verplicht</span> : null }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Voornaam</label>
                                    <input className="form-control" type="text" name="firstName" value={this.state.user.firstName} onChange={this.changeFirstName} />
                                    {this.state.errors.firstName ? <span className={'errorInForm'}>Voornaam is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Naam</label>
                                    <input className="form-control" type="text" name="lastName" value={this.state.user.lastName} onChange={this.changeLastName} />
                                    {this.state.errors.lastName ? <span className={'errorInForm'}>Naam is verplicht</span> : null }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Extra informatie</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.user.extraInformation}
                                        events={{
                                            "change": this.changeExtraInformation
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
};

export default Noncustomerpersonalinformation;