import React, { Fragment } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import SweetAlert from "react-bootstrap-sweetalert";

class TimesheetDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timesheetItem : {},
            editableTimesheetItem : {},
            editView : false,
            errorsOnForm : {
                description : false,
                startDate : false,
                endDate : false
            },
            showDeleteDialog: false
        };
    }

    componentDidMount() {
        this.loadTimesheetDetail();
    }

    loadTimesheetDetail = () => {
        const { id } = this.props.match.params;
        let me = this;
        API.get('timesheetitem/' + id).then(function(response) {
            me.setState({timesheetItem: response.data});
            me.setState({editableTimesheetItem: response.data}, function() {
                this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, day : new Intl.DateTimeFormat("fr-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }).format(new Date(this.state.editableTimesheetItem.startTime)) }}));
                this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, startTime : new Intl.DateTimeFormat("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit"
                        }).format(new Date(this.state.editableTimesheetItem.startTime)) }}));
                this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, endTime : new Intl.DateTimeFormat("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit"
                        }).format(new Date(this.state.editableTimesheetItem.endTime)) }}));
            });
        });
    };

    updateTimesheetItem = () => {
        const { id } = this.props.match.params;
        let me = this;
        API.put('timesheetitem/' + id, {
            'description' : this.state.editableTimesheetItem.description,
            'day' : this.state.editableTimesheetItem.day,
            'startHour' : this.state.editableTimesheetItem.startTime,
            'endHour' :  this.state.editableTimesheetItem.endTime
        }).then(function() {
            me.loadTimesheetDetail();
        });
    };

    changeTimesheetItemDescription = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, description : value }}));
    };

    changeTimesheetItemDay = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, day : value }}));
    };

    changeTimesheetItemStartDate = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, startTime : value }}));
    };

    changeTimesheetItemEndDate = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableTimesheetItem: { ...prevState.editableTimesheetItem, endTime : value }}));
    };

    openEditView = () => {
        this.setState({editView: true});
    };

    closeEditView = () => {
        this.setState({editView: false});
    };

    deleteTimesheetItem = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({showDeleteDialog: true}));
    };

    doDeleteTimesheet = () => {
        this.setState((prevState) => ({showDeleteDialog: false}));
        const { id } = this.props.match.params;
        API.delete('timesheetitem/' + id).then(function() {
            window.location.href = "/timesheets/overview";
        });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Gepresteerde uren" title={'Detail prestatie ' + this.state.timesheetItem.description} />
                <div className="container-fluid">
                    <div className={'card'}>
                        {this.state.timesheetItem ? (
                            <div>
                                <div className={'row product-page-main'}>
                                    <div className={'col-xl-8'}>
                                        <div className="btn-group btn-group-square" role="group">
                                            <button className={this.state.editView ? ('btn btn-outline-primary') : ('btn btn-primary')} onClick={this.closeEditView} type="button">Overzicht</button>
                                            <button className={this.state.editView ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={this.openEditView} type="button">Wijzig</button>
                                        </div>
                                    </div>
                                    <div className={'col-xl-4 text-right'}>
                                        <SweetAlert show={this.state.showDeleteDialog} warning title="Prestatie verwijderen" onConfirm={() => this.doDeleteTimesheet()}>
                                            Ben je zeker dat je deze prestatie wilt verwijderen?
                                        </SweetAlert>
                                        <button className={'btn btn-danger'} onClick={(event) => this.deleteTimesheetItem(event)}>Verwijder prestatie</button>
                                    </div>
                                </div>
                                <div className={'row product-page-main'}>
                                    {this.state.editView ? (
                                        <form style={{width: '100%'}} >
                                            <div className={'form-group row'}>
                                                <label className={'col-xl-12'}>
                                                    Beschrijving:
                                                </label>
                                                <div className={'col-xl-12'}>
                                                    <input type={'text'} className={'form-control'} value={this.state.editableTimesheetItem.description} onChange={this.changeTimesheetItemDescription} />
                                                </div>
                                            </div>
                                            <div className={'form-group row'}>
                                                <label className={'col-xl-12'}>
                                                    Dag:
                                                </label>
                                                <div className={'col-xl-12'}>
                                                    <input type={'date'} className={'form-control digits'} value={this.state.editableTimesheetItem.day} onChange={this.changeTimesheetItemDay} />
                                                </div>
                                            </div>
                                            <div className={'form-group row'}>
                                                <label className={'col-xl-12'}>
                                                    Starttijd:
                                                </label>
                                                <div className={'col-xl-12'}>
                                                    <input type={'time'} className={'form-control digits'} value={this.state.editableTimesheetItem.startTime} onChange={this.changeTimesheetItemStartDate} />
                                                </div>
                                            </div>
                                            <div className={'form-group row'}>
                                                <label className={'col-xl-12'}>
                                                    Eindtijd:
                                                </label>
                                                <div className={'col-xl-12'}>
                                                    <input type={'time'} className={'form-control digits'} value={this.state.editableTimesheetItem.endTime} onChange={this.changeTimesheetItemEndDate} />
                                                </div>
                                            </div>
                                            <div className={'form-group row'}>
                                                <div className={'col-xl-12'}>
                                                    <a href={'#'} className={'btn btn-primary'} onClick={() => this.updateTimesheetItem()}>Wijzig prestatie</a>
                                                </div>
                                            </div>
                                        </form>
                                    ) : (
                                        <div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Beschrijving</strong></h6>
                                                <p>
                                                    {this.state.timesheetItem.description}
                                                </p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Starttijd</strong></h6>
                                                <p>
                                                    {this.state.timesheetItem.startTime ? new Intl.DateTimeFormat("nl-BE", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "2-digit",
                                                        hour: "2-digit",
                                                        minute: "2-digit"
                                                    }).format(new Date(this.state.timesheetItem.startTime)) : ''}
                                                </p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Eindtijd</strong></h6>
                                                <p>
                                                    {this.state.timesheetItem.endTime ? new Intl.DateTimeFormat("nl-BE", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "2-digit",
                                                        hour: "2-digit",
                                                        minute: "2-digit"
                                                    }).format(new Date(this.state.timesheetItem.endTime)) : ''}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={'row product-page-main'}>
                                    <div className="col-sm-12 col-xl-4">
                                        <div className="card card-absolute" style={{border: '1px solid rgb(255,102,0)', borderRadius: '0px'}}>
                                            <div className="card-header bg-primary"><h5>Project</h5></div>
                                            <div className="card-body">
                                                {this.state.timesheetItem.project ? (
                                                    <div>
                                                        <p>Naam: {this.state.timesheetItem.project.name}</p>
                                                        <p>{this.state.timesheetItem.project.description}</p>
                                                        <p>Uitgevoerd voor:{this.state.timesheetItem.project.customer.firstName} {this.state.timesheetItem.project.customer.lastName}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>
                                                            Geen gekoppeld project aan de prestatie
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xl-4">
                                        <div className="card card-absolute" style={{border: '1px solid rgb(255,102,0)', borderRadius: '0px'}}>
                                            <div className="card-header bg-primary"><h5>Werkbon</h5></div>
                                            <div className="card-body">
                                                {this.state.timesheetItem.workOrder ? (
                                                    <div>
                                                        <p>Naam: {this.state.timesheetItem.workOrder.name}</p>
                                                        <p>{this.state.timesheetItem.workOrder.description}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>Geen gekoppelde werkbon aan de prestatie</p>
                                                    </div>
                                                    )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xl-4">
                                        <div className="card card-absolute" style={{border: '1px solid rgb(255,102,0)', borderRadius: '0px'}}>
                                            <div className="card-header bg-primary"><h5>Taak</h5></div>
                                            <div className="card-body">
                                                {this.state.timesheetItem.task ? (
                                                    <div>
                                                        <p>Naam: {this.state.timesheetItem.task.name}</p>
                                                        <p>{this.state.timesheetItem.task.description}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>Geen gekoppelde taak aan de prestatie</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            <p>Laden van detail van de prestatie ...</p>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default TimesheetDetail;