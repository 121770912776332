import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import ReactStars from "react-rating-stars-component";
import Ckeditor from 'react-ckeditor-component/lib/ckeditor';
import SignatureCanvas from "./../projects/signatureCanvas";

const TaskEvaluation = (props) =>  {
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(null);
    const [task, setTask] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [workApproved, setWorkApproved] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [improvements, setImprovements] = useState("");
    const [strengths, setStrengths] = useState("");
    const [rating, setRating] = useState(0);

    const signatureRef = useRef(null);

    useEffect(() => {
        API.get('taskwithsignature/' + props.match.params.taskid).then(function(response) {
            setTask(response.data);
        });
        API.get('project/' + props.match.params.projectid).then(function(response) {
            setProject(response.data);
        });
        API.get('myuser').then(function(response) {
            setCurrentUser(response.data[0]);
        });
    }, []);

    const ratingChanged = (newRating) => {
        setRating(newRating);
      };

    const sendEvaluation = () => {
        setLoading(true);
        API.put('task/' + props.match.params.taskid + '/evaluate', {
            improvements,
            strengths,
            remarks,
            rating,
            agreedWithExecutedWork : workApproved,
            signature: signatureRef.current.toDataURL()
        }).then(function(response) {
            setLoading(false);
            window.location.replace('/task/detail/' + props.match.params.taskid);
        });
    };

    return (
        <>
        
            <Breadcrumb parent="Projecten">
                {!_.isEmpty(project) ? (
                    <span>
                        Project detail {project.name}
                    </span>
                ) : null}  
            </Breadcrumb>

            {task !== null ? (
                
                task.status === 'FINISHED' ? (
                    <>
                        <Row>
                            <Col lg={2} md={2}>
                                <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                    <CardBody>
                                        <Row className='mt-2'>
                                            <Col lg={12} style={{textAlign: 'center'}}>
                                                <h1>Score</h1>
                                                <h1 style={{fontSize: "10em"}}>{task.evaluationRating}</h1>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={10} md={10}>
                                <Card>
                                    <CardBody>
                                        <Row className='mt-2'>
                                            <Col lg={12}>
                                                <h2>Taak '{task.name}' is beëindigd en geëvalueerd</h2>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <h4>Opdrachtgever gaat akkoord met de uitgevoerde werken</h4>
                                                {task.evaluationAgreedWithExecutedWork ? (<>Ja</>) : (<>Neen</>)}
                                            </Col>
                                        </Row>
                                        {_.isEmpty(task.evaluationStrengths) ? null : (
                                            <Row className='mt-2'>
                                                <Col lg={12}>
                                                    <h4>Sterke punten</h4>
                                                    <p dangerouslySetInnerHTML={{__html: task.evaluationStrengths }}></p>
                                                </Col>
                                            </Row>
                                        )}
                                        {_.isEmpty(task.evaluationImprovements) ? null : (
                                            <Row className='mt-2'>
                                                <Col lg={12}>
                                                    <h4>Verbeterpunten</h4>
                                                    <p dangerouslySetInnerHTML={{__html: task.evaluationImprovements }}></p>
                                                </Col>
                                            </Row>
                                        )}
                                        {_.isEmpty(task.evaluationRemarks) ? null : (
                                            <Row className='mt-2'>
                                                <Col lg={12}>
                                                    <h4>Opmerkingen</h4>
                                                    <p dangerouslySetInnerHTML={{__html: task.evaluationRemarks }}></p>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className='mt-2'>
                                            <Col lg={12}>
                                                <h4>Gehandtekend op:</h4>
                                                <p>{task.signedAt}</p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col lg={12}>
                                                <h4>Handtekening:</h4>
                                                <img src={task.customerSignature} style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <h2>Beoordeling</h2>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg={12}>
                                        <h5>Tevredenheid over de werken</h5>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg={12}>
                                    <ReactStars
                                        count={10}
                                        onChange={ratingChanged}
                                        size={40}
                                        isHalf={false}
                                        emptyIcon={<i className="icofont icofont-ui-rate-blank"></i>}
                                        filledIcon={<i className="icofont icofont-ui-rating"></i>}
                                        activeColor="#ffd700"
                                    />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col lg={12}>
                                        <h5>Akkoord met de uitgevoerde opmerkingen?</h5>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col lg={12}>
                                        <input type="radio" value="1" id="yes"
                                            onChange={() => setWorkApproved(true)} name="yes" checked={workApproved === true} />
                                        <label className='ml-2' for="yes">Ja</label>

                                        <input className='ml-5' type="radio" value="0" id="no"
                                            onChange={() => setWorkApproved(false)} name="no" checked={workApproved === false} />
                                        <label className='ml-2' for="no">Neen</label>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col lg={12}>
                                        <h5>Sterke punten (Wat liep er goed?)</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Ckeditor content={strengths} events={{"change" : (evt) => setStrengths(evt.editor.getData())}} />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col lg={12}>
                                        <h5>Verbeterpunten (Wat liep er minder en kan beter?</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Ckeditor content={improvements} events={{"change" : (evt) => setImprovements(evt.editor.getData())}} />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col lg={12}>
                                        <h5>Extra opmerkingen</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Ckeditor content={remarks} events={{"change" : (evt) => setRemarks(evt.editor.getData())}} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <h5>Handtekening als akkoord</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <SignatureCanvas ref={signatureRef}
                                            penColor='white'
                                            canvasProps={{height: 400, className: 'sigCanvas'}} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Button onClick={sendEvaluation} className={'btn btn-primary'}>Taak beëindigen</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </>
                )
            ) : null}
        </>
    );
};

export default TaskEvaluation;