import React, { Fragment } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import SweetAlert from "react-bootstrap-sweetalert";

class EstablishmentDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            establishment : null,
            editableEstablishment : null,
            countries : [],
            editView : false,
            errorsOnForm : {

            },
            showDeleteDialog: false,
            showSuccessDialog : false
        };
    }

    componentDidMount() {
        let me = this;
        API.get('config/countries').then(function(response) {
            me.setState({countries: response.data});
        });
        this.loadEstablishment();
    }

    loadEstablishment = () => {
        const { id } = this.props.match.params;
        let me = this;
        API.get('establishment/' + id).then(function(response) {
            me.setState({establishment: response.data});
            me.setState({editableEstablishment: response.data});
        });
    };

    updateEstablishment = (event) => {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me = this;
        API.put('establishment/' + id, {
            companyName: this.state.editableEstablishment.companyName,
            cbeNumber: this.state.editableEstablishment.cbeNumber,
            street: this.state.editableEstablishment.street,
            houseNumber: this.state.editableEstablishment.houseNumber,
            bus: this.state.editableEstablishment.bus,
            postalCode: this.state.editableEstablishment.postalCode,
            city: this.state.editableEstablishment.city,
            country: this.state.editableEstablishment.country.id,
            email: this.state.editableEstablishment.email,
            phone: this.state.editableEstablishment.phone,
            mobile: this.state.editableEstablishment.mobile,
            website: this.state.editableEstablishment.website,
            bankAccounts : this.state.editableEstablishment.bankAccounts
        }).then(function() {
            me.loadEstablishment();
        });
    };

    changeBankName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newBankAccount: { ...prevState.newBankAccount, bankName: value}}));
    };

    changeIban = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newBankAccount: { ...prevState.newBankAccount, iban: value}}));
    };

    changeBic = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newBankAccount: { ...prevState.newBankAccount, bic: value}}));
    };

    changeCompanyName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, companyName: value}}));
    };

    changeCbeNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, cbeNumber: value}}));
    };

    changeStreet = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, street: value}}));
    };

    changeHouseNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, houseNumber: value}}));
    };

    changeBus = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, bus: value}}));
    };

    changePostalCode = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.estableditableEstablishmentshment, postalCode: value}}));
    };

    changeCity = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, city: value}}));
    };

    changeCountry = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, country: value}}));
    };

    changePhone = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, phone: value}}));
    };

    changeMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, mobile: value}}));
    };

    changeEmail = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, email: value}}));
    };

    changeWebsite = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({editableEstablishment: { ...prevState.editableEstablishment, website: value}}));
    };

    addBankAccount = () => {
        let bankAccountToAdd = {
            bankName : this.state.newBankAccount.bankName,
            iban : this.state.newBankAccount.iban,
            bic : this.state.newBankAccount.bic
        };
        let bankAccounts = [...this.state.establishment.bankAccounts];
        bankAccounts.push(bankAccountToAdd);

        this.setState((prevState) => ({establishment: { ...prevState.establishment, bankAccounts: bankAccounts}}), function() {
            console.log(this.state);
        });
        this.setState({showNewBankAccount: false});
    };

    cancelAddBankAccount = () => {
        this.setState({showNewBankAccount: false});
    };

    openEditView = () => {
        this.setState({editView: true});
    };

    closeEditView = () => {
        this.setState({editView: false});
    };

    openAddBankAccount = (event) => {
        event.preventDefault();
        this.setState({showNewBankAccount: true});
    };

    deleteEstablishment = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({showDeleteDialog: true}));
    };

    doDeleteEstablishment = () => {
        this.setState((prevState) => ({showDeleteDialog: false}));
        const { id } = this.props.match.params;
        API.delete('establishment/' + id).then(function() {
            window.location.href = "/establishments/overview";
        });
    };

    render() {
        return (
            this.state.establishment ? (
                <Fragment>
                    <Breadcrumb parent="Vestigingen" title={'Detail vestiging ' + this.state.establishment.companyName} />
                    <div className="container-fluid">
                        <div className={'card'}>

                            <div>
                                <div className={'row product-page-main'}>
                                    <div className={'col-xl-8'}>
                                        <div className="btn-group btn-group-square" role="group">
                                            <button className={this.state.editView ? ('btn btn-outline-primary') : ('btn btn-primary')} onClick={this.closeEditView} type="button">Overzicht</button>
                                            <button className={this.state.editView ? ('btn btn-primary') : ('btn btn-outline-primary')} onClick={this.openEditView} type="button">Wijzig</button>
                                        </div>
                                    </div>
                                    <div className={'col-xl-4 text-right'}>
                                        <SweetAlert show={this.state.showDeleteDialog} warning title="Vestiging verwijderen" onConfirm={() => this.doDeleteEstablishment()}>
                                            Ben je zeker dat je deze vestiging wilt verwijderen?
                                        </SweetAlert>
                                        <button className={'btn btn-danger'} onClick={(event) => this.deleteEstablishment(event)}>Verwijder vestiging</button>
                                    </div>
                                </div>
                                <div className={'row product-page-main'}>
                                    {this.state.editView ? (
                                        <form className="card"  style={{width: '100%'}} >
                                            <div className="card-body">
                                                <SweetAlert show={this.state.showSuccessDialog} success title="Succesvol toegevoegd" onConfirm={() => this.loadEstablishment()}>
                                                    Je vestiging is succesvol gewijzigd!
                                                </SweetAlert>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Organisatienaam:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'companyName'} className={'form-control'} value={this.state.editableEstablishment.companyName} onChange={this.changeCompanyName} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Ondernemingsnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'cbeNumber'} className={'form-control'} value={this.state.editableEstablishment.cbeNumber} onChange={this.changeCbeNumber} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Straat:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'city'} className={'form-control'} value={this.state.editableEstablishment.street} onChange={this.changeStreet} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Huisnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'houseNumber'} className={'form-control'} value={this.state.editableEstablishment.houseNumber} onChange={this.changeHouseNumber} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Bus:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'bus'} className={'form-control'} value={this.state.editableEstablishment.bus} onChange={this.changeBus} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Postcode:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'postalCode'} className={'form-control'} value={this.state.editableEstablishment.postalCode} onChange={this.changePostalCode} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Gemeente:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'city'} className={'form-control'} value={this.state.editableEstablishment.city} onChange={this.changeCity} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-3'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Land:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <select onChange={this.changeCountry} className={'form-control'} name={'countryId'} value={this.state.editableEstablishment.country.id}>
                                                                    <option value={'0'}>---</option>
                                                                    {this.state.countries.map(function(country) {
                                                                        return (
                                                                            <option value={country.id}>{country.name}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                E-mail:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'email'} className={'form-control'} value={this.state.establishment.email} onChange={this.changeEmail} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Telefoonnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'phone'} className={'form-control'} value={this.state.establishment.phone} onChange={this.changePhone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                GSMnummer:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'mobile'} className={'form-control'} value={this.state.establishment.mobile} onChange={this.changeMobile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Website:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} name={'website'} className={'form-control'} value={this.state.establishment.website} onChange={this.changeWebsite} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Bankaccounts <a href={'#'} onClick={(event) => this.openAddBankAccount(event)} className={'fa fa-plus'}></a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.establishment.bankAccounts.map((bankAccount, index) => {
                                                    return <p>{bankAccount.bankName}</p>
                                                })}

                                                {/*<SweetAlert*/}
                                                {/*    show={this.state.showNewBankAccount}*/}
                                                {/*    showCloseButton={true}*/}
                                                {/*    onCancel={() => this.cancelAddBankAccount()}*/}
                                                {/*    info*/}
                                                {/*    title="Bankaccount toevoegen"*/}
                                                {/*    onConfirm={() => this.addBankAccount()}*/}
                                                {/*    confirmBtnText={'Bankaccount toevoegen'}*/}
                                                {/*    cancelBtnText={'Annuleren'}*/}
                                                {/*    style={{width: '90%'}}*/}
                                                {/*>*/}
                                                {/*    <form>*/}
                                                {/*        <div className={'form-group row'}>*/}
                                                {/*            <div className={'col-3'}>Naam van bank:</div>*/}
                                                {/*            <div className={'col-9'}><input onChange={this.changeBankName} className={'form-control'} type={'text'} name={'bankName'} value={this.state.newBankAccount.bankName} /></div>*/}
                                                {/*        </div>*/}
                                                {/*        <div className={'form-group row'}>*/}
                                                {/*            <div className={'col-3'}>IBAN:</div>*/}
                                                {/*            <div className={'col-9'}><input onChange={this.changeIban} className={'form-control'} type={'text'} name={'iban'} value={this.state.newBankAccount.iban} /></div>*/}
                                                {/*        </div>*/}
                                                {/*        <div className={'form-group row'}>*/}
                                                {/*            <div className={'col-3'}>BIC:</div>*/}
                                                {/*            <div className={'col-9'}><input onChange={this.changeBic} className={'form-control'} type={'text'} name={'bic'} value={this.state.newBankAccount.bic} /></div>*/}
                                                {/*        </div>*/}
                                                {/*    </form>*/}
                                                {/*</SweetAlert>*/}

                                            </div>
                                            <div className="card-footer text-right">
                                                <button className="btn btn-primary" type="submit" onClick={(event) => this.updateEstablishment(event)}>Vestiging wijzigen</button>
                                            </div>
                                        </form>
                                    ) : (
                                        <div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Organisatienaam</strong></h6>
                                                <p>{this.state.establishment.companyName}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Ondernemingsnummer</strong></h6>
                                                <p>{this.state.establishment.cbeNumber}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Adres</strong></h6>
                                                <p>
                                                    {this.state.establishment.street} {this.state.establishment.houseNumber} {this.state.establishment.bus}<br />
                                                    {this.state.establishment.postalCode} {this.state.establishment.city} <br />
                                                    {this.state.establishment.country !== null ? this.state.establishment.country.name : ''}
                                                </p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>E-mailadres</strong></h6>
                                                <p>{this.state.establishment.email}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Telefoonnummer</strong></h6>
                                                <p>{this.state.establishment.phone}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>GSM nummer</strong></h6>
                                                <p>{this.state.establishment.mobile}</p>
                                            </div>
                                            <div className={'col-xl-12'}>
                                                <h6><strong>Website</strong></h6>
                                                <p>{this.state.establishment.website}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <p>Laden van detail van de vestiging ...</p>
            )
        );
    }
}

export default EstablishmentDetail;