import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import {withScriptjs,withGoogleMap,GoogleMap, Marker} from "react-google-maps";
import GeoCode from "react-geocode";

const ProjectLocation = (props) =>  {

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [project, setProject] = useState(null);

    useEffect(() => {
        setProject(props.project);
    }, []);

    useEffect(() => {
        if (project !== null) {
            const address = project.street + ' ' + project.houseNumber + ' ' + project.bus + ", " + project.postalCode + " " + project.city + ", " + project.country;
            GeoCode.setApiKey("AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo");
            GeoCode.fromAddress(address).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setLat(lat);
                    setLng(lng);
                }
            );
        }
    }, [project]);


    const CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            defaultZoom={11}
            defaultCenter={{ lat: lat, lng: lng }}
        >
            {props.children}
        </GoogleMap>
    ));

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <h5><strong>Locatie van project</strong></h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            {project !== null ? (
                                <p>
                                    {project.street + ' ' + project.houseNumber + ' ' + project.bus}<br />{project.postalCode + " " + project.city}<br />{project.country != null ? project.country.name : ''}<br /><br />
                                </p>
                            ) : null}   
                            
                            {project !== null ? (
                                <a className={'btn btn-primary form-control'} target={'_blank'} href={'https://www.google.be/maps/dir//' + project.street + ' ' + project.houseNumber + ',' + project.postalCode + ' ' + project.city + '/@' + lat + ','+ lng}>Ga naar route</a>
                            ) : null}
                            <br /><br />
                            {project !== null ? (
                                <a className={'btn btn-primary form-control'} target={'parent'} href={'/projects/nearby/' + project.postalCode}>Zoek projecten in de buurt</a>
                            ) : null} 
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={12}>
                                {lat !== 0 && lng !== 0 ? (
                                    <>
                                        <CMap
                                            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo&v=3.exp&libraries=geometry,drawing,places"}
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `400px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            center= {{ lat: lat, lng:lng }}
                                        >
                                            <Marker
                                                position={{ lat: lat, lng: lng }}
                                            />
                                        </CMap>
                                    </>
                                ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};

export default ProjectLocation;