import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import {Typeahead} from "react-bootstrap-typeahead";
import Dropzone from "react-dropzone-uploader";
import SweetAlert from "react-bootstrap-sweetalert";


class InvoiceUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customers : [],
            projects : [],
            vatRates : [],
            showSuccessDialog : false,
            newUploadedInvoice : {
                name : "",
                description : "",
                invoicenumber : "",
                project : null,
                workOrder : null,
                customer : null,
                invoiceTotals : [],
            }
        };
    }

    componentDidMount() {
        var me = this;
        API.get('users').then(function(response) {
            me.setState({customers: response.data});
        });

        API.get('projects').then(function(response) {
            me.setState({projects: response.data});
        });

        API.get('vatrates').then(function(response) {
            let arr = me.state.newUploadedInvoice.invoiceTotals;
            me.setState({vatRates: response.data}, function() {
                for (let vatRate of response.data) {
                    arr[vatRate.id] = [];
                    arr[vatRate.id]['totalPriceExcl'] = 0;
                    arr[vatRate.id]['totalVatAmount'] = 0;
                    arr[vatRate.id]['totalPriceIncl'] = 0;
                }
                me.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, invoiceTotals : arr }}));
            });

        });
    }

    changeName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, name : value }}));
    };

    changeDescription = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, description : value }}));
    };

    changeInvoiceNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, invoiceNumber : value }}));
    };

    changeProject = (selectedArray) => {
        let newProject = selectedArray[0];
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, project : newProject }}));
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, workOrder : null }}));
    };

    changeWorkOrder = (selectedArray) => {
        let newWorkOrder = selectedArray[0];
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, workOrder : newWorkOrder }}));
    };

    changeCustomer = (selectedArray) => {
        let newUser = selectedArray[0];
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, customer : newUser }}));
    };

    changeTotalPriceExcl = (vatRateId, event) => {
        let value = event.target.value;
        let invoiceTotals = this.state.newUploadedInvoice.invoiceTotals;
        invoiceTotals[vatRateId]['totalPriceExcl'] = value;
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, invoiceTotals : invoiceTotals }}));
    };

    changeTotalVatAmount = (vatRateId, event) => {
        let value = event.target.value;
        let invoiceTotals = this.state.newUploadedInvoice.invoiceTotals;
        invoiceTotals[vatRateId]['totalVatAmount'] = value;
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, invoiceTotals : invoiceTotals }}));
    };

    changeTotalPriceIncl = (vatRateId, event) => {
        let value = event.target.value;
        let invoiceTotals = this.state.newUploadedInvoice.invoiceTotals;
        invoiceTotals[vatRateId]['totalPriceIncl'] = value;
        this.setState((prevState) => ({newUploadedInvoice: { ...prevState.newUploadedInvoice, invoiceTotals : invoiceTotals }}));
    };

    readFile(file) {
        const reader = new FileReader();
        reader.onload = event => {
            let newFile = {
                fileName : file.name,
                mimeType : file.type,
                fileSize : file.size,
                file : event.target.result
            };
            this.setState({uploadedFile: newFile});
        };
        reader.readAsDataURL(file);
    }

    handleChangeStatus = ({ meta, file }, status) => {
        this.readFile(file);
    };

    createNewUploadedInvoice = (event) => {
        event.preventDefault();
        console.log(this.state.newUploadedInvoice.invoiceTotals);
        let arr = [];

        for (let index in this.state.newUploadedInvoice.invoiceTotals) {
            let invoiceTotal = this.state.newUploadedInvoice.invoiceTotals[index];
            if (invoiceTotal['totalPriceExcl'] !== 0) {
                arr.push({
                    vatRateId : index,
                    totalPriceExcl : invoiceTotal['totalPriceExcl'],
                    totalVatAmount : invoiceTotal['totalVatAmount'],
                    totalPriceIncl : invoiceTotal['totalPriceIncl']
                });
            }

        }


        let me = this;
        API.post('uploadedinvoice', {
            name : this.state.newUploadedInvoice.name,
            description : this.state.newUploadedInvoice.description,
            invoiceNumber : this.state.newUploadedInvoice.invoiceNumber,
            projectId : this.state.newUploadedInvoice.project.id,
            customerId: this.state.newUploadedInvoice.customer.id,
            workOrderId: this.state.newUploadedInvoice.workOrder.id,
            invoiceTotals : arr,
            file: this.state.uploadedFile
        }).then(function() {
            me.setState({showSuccessDialog: true});
        });
    };

    navigateToOverview = () => {
        window.location.href = `${process.env.PUBLIC_URL}/uploadedinvoices/overview`;
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Facturatie" title="Nieuwe factuur uploaden" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <SweetAlert show={this.state.showSuccessDialog} success title="Succesvol toegevoegd" onConfirm={() => this.navigateToOverview()}>
                            Je factuur is succesvol toegevoegd!
                        </SweetAlert>
                        <div className="row">
                           <div className="col-lg-12">
                                <form className="card" onSubmit={this.createNewUploadedInvoice}>
                                    <div className="card-body">
                                        <div className={"row"}>
                                            <div className={'col-xl-6'}>
                                                <div className={'row'}>
                                                    <div className="col-xl-12">
                                                        <div className="form-group">
                                                            <label className="form-label"><strong>Factuurnummer</strong></label>
                                                            <input className="form-control" type="text" name="shortDescription" value={this.state.newUploadedInvoice.invoiceNumber} onChange={this.changeInvoiceNumber} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12">
                                                        <div className="form-group">
                                                            <label className="form-label"><strong>Naam</strong></label>
                                                            <input className="form-control" type="text" name="name" value={this.state.newUploadedInvoice.name} onChange={this.changeName} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <div className={'form-group'}>
                                                            <label className="form-label"><strong>Klant</strong></label>
                                                            {this.state.customers ? (
                                                                <Typeahead
                                                                    id="customer-typeahead"
                                                                    onChange={this.changeCustomer}
                                                                    clearButton
                                                                    labelKey="email"
                                                                    selected={this.state.newUploadedInvoice.customer ? [this.state.newUploadedInvoice.customer] : []}
                                                                    multiple={false}
                                                                    options={this.state.customers}
                                                                    placeholder="Selecteer een klant ..."
                                                                />
                                                            ) : (
                                                                <p></p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="col-xl-12">
                                                        <div className="form-group">
                                                            <label className="form-label"><strong>Beschrijving</strong></label>
                                                            <input className="form-control" type="text" name="shortDescription" value={this.state.newUploadedInvoice.shortDescription} onChange={this.changeDescription} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <div className={'form-group'}>
                                                            <label className={'form-label'}><strong>Project</strong></label>
                                                            {this.state.projects && this.state.newUploadedInvoice ? (
                                                                <Typeahead
                                                                    id="project-typeahead"
                                                                    onChange={this.changeProject}
                                                                    clearButton
                                                                    labelKey="name"
                                                                    selected={this.state.newUploadedInvoice.project ? [this.state.newUploadedInvoice.project] : []}
                                                                    multiple={false}
                                                                    options={this.state.projects}
                                                                    placeholder="Selecteer een project ..."
                                                                />
                                                            ) : (
                                                                <p> </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'row'}>
                                                    <div className={'col-xl-12'}>
                                                        <div className={'form-group'}>
                                                            <label className={'form-label'}><strong>Werkbon</strong></label>
                                                            {this.state.newUploadedInvoice.project ? (
                                                                <Typeahead
                                                                    id="project-typeahead"
                                                                    onChange={this.changeWorkOrder}
                                                                    clearButton
                                                                    labelKey="name"
                                                                    selected={this.state.newUploadedInvoice.workOrder ? [this.state.newUploadedInvoice.workOrder] : []}
                                                                    multiple={false}
                                                                    options={this.state.newUploadedInvoice.project ? this.state.newUploadedInvoice.project.workOrders : []}
                                                                    placeholder="Selecteer een werkbon ..."
                                                                />
                                                            ) : (
                                                                <p> </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <label className={'col-xl-12'}>
                                                <strong>Totalen</strong>
                                            </label>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col-xl-12'}>
                                                <div className={'form-group'}>
                                                    <div className={'row'}>
                                                        <div className={'col-xl-1'}>

                                                        </div>
                                                        <div className={'col-xl-2'}>
                                                            <strong>Prijs excl</strong>
                                                        </div>
                                                        <div className={'col-xl-2'}>
                                                            <strong>BTW</strong>
                                                        </div>
                                                        <div className={'col-xl-2'}>
                                                            <strong>Prijs incl</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.vatRates.map((vatRate, index) =>
                                            <div key={'vatRate' + index} className={'row'}>
                                                <div className={'col-xl-12'}>
                                                    <div className={'form-group'}>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-1'}>
                                                                <label>{vatRate.name}</label>
                                                            </div>
                                                            <div className={'col-xl-2'}>
                                                                <input className="form-control" type="number" step={0.01} value={this.state.newUploadedInvoice !== undefined && this.state.newUploadedInvoice.invoiceTotals[vatRate.id] !== undefined ? this.state.newUploadedInvoice.invoiceTotals[vatRate.id]['totalPriceExcl'] : 0} onChange={(event) => this.changeTotalPriceExcl(vatRate.id, event)} />
                                                            </div>
                                                            <div className={'col-xl-2'}>
                                                                <input className="form-control" type="number" step={0.01} value={this.state.newUploadedInvoice !== undefined && this.state.newUploadedInvoice.invoiceTotals[vatRate.id] !== undefined ? this.state.newUploadedInvoice.invoiceTotals[vatRate.id]['totalVatAmount'] : 0} onChange={(event) => this.changeTotalVatAmount(vatRate.id, event)} />
                                                            </div>
                                                            <div className={'col-xl-2'}>
                                                                <input className="form-control" type="number" step={0.01} value={this.state.newUploadedInvoice !== undefined && this.state.newUploadedInvoice.invoiceTotals[vatRate.id] !== undefined ? this.state.newUploadedInvoice.invoiceTotals[vatRate.id]['totalPriceIncl'] : 0} onChange={(event) => this.changeTotalPriceIncl(vatRate.id, event)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                        <div className={'row'}>
                                            <label className={'col-xl-12'}>
                                                <strong>Factuur/Kredietnota</strong>
                                            </label>
                                            <div className={'col-xl-12'}>
                                                <Dropzone
                                                    onChangeStatus={this.handleChangeStatus}
                                                    maxFiles={1}
                                                    multiple={false}
                                                    canCancel={false}
                                                    inputContent="Plaats je factuur hier"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" type="submit">Factuur uploaden</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default InvoiceUpload;