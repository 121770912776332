import React, { useState, useEffect } from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import _ from "lodash";

const CustomerDetail = (props) =>  {

    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        setCustomer(props.customer);
    }, []);

    return (
        customer !== null ? (
            <>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={12}>
                                <h5><strong>{customer.firstName} {customer.lastName}</strong></h5>
                                <br />
                                <span>{customer.street} {customer.houseNumber} {customer.bus}</span><br />
                                <span>{customer.postalCode} {customer.city}</span><br />
                                {customer.country !== undefined && customer.country !== null ? (
                                    <span>{customer.country.name}<br /></span>
                                ) : null}

                                <br />
                                {customer !== undefined && customer.primaryPhone !== undefined && !_.isEmpty(customer.primaryPhone) ? (
                                    <p>
                                        <i className="fa fa-phone"></i>
                                        <a href={'tel:' + customer.primaryPhone.replace(/\D/g,'')}>{customer.primaryPhone}</a>
                                    </p>
                                ) : (
                                    null
                                )}
                                {customer !== undefined && customer.primaryMobile !== undefined && !_.isEmpty(customer.primaryMobile) ? (
                                    <p>
                                        <i className="fa fa-mobile-phone"></i>
                                        <a href={'tel:' + customer.primaryMobile.replace(/\D/g,'')} >{customer.primaryMobile}</a>
                                    </p>
                                ) : (
                                    null
                                )}
                                {customer !== undefined && customer.primaryEmail && !_.isEmpty(customer.primaryEmail) ? (
                                    <p>
                                        <i className="fa fa-envelope"></i>
                                        {customer.primaryEmail}
                                    </p>
                                ) : (
                                    null
                                ) }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        ) : null
        
    );
};

export default CustomerDetail;