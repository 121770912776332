import React, { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import ReactTable from "react-table-6";
import { Card, CardBody, Col, Row} from "reactstrap";
import API from '../../../../utils/API';
import _ from "lodash";
import { Link } from 'react-router-dom';
import useReactTable from '../../../../hooks/useReactTable';

const ProjectTasksCompact = (props) =>  {
    const [project, setProject] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const columns = useMemo(() => [
        {
            Header : 'Taak',
            accessor : 'name',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                return <Link to={`/task/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
            }
        },{
            Header : 'Begin',
            accessor : 'plannedDateForExecution',
            headerClassName: "text-left",
            width: 250,
            Cell: row => {
                if (row.row.plannedDateForExecution != null) {
                    return new Intl.DateTimeFormat("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.plannedDateForExecution));
                } else {
                    return "Nog te bepalen";
                }
            }
        },
        {
            Header : 'Uitvoerder',
            accessor : 'responsibleUser',
            headerClassName: "text-left",
            Cell: row => {
                if (row.row.responsibleUser !== undefined && row.row.responsibleUser !== null) {
                    return <span>{row.row.responsibleUser.firstName} {row.row.responsibleUser.lastName}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Status',
            accessor : 'status',
            headerClassName: "text-left",
            Cell: row => {
                return <span>{row.row.status}</span>
            }
        }
    ]);

    useEffect(() => {
        setProject(props.project);
    }, []);

    useEffect(() => {
        if (project !== null) {
            API.get('/project/' + project.id +'/tasks').then(function(response) {
                setTasks(response.data);
            });  
        }
    }, [project]);

    return (
        <>
            <Card style={{border: '1px solid ' + process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
                <CardBody>
                    <h4>Taken</h4>
                    {project && tasks !== undefined && tasks.length > 0 ? (
                        <ReactTable 
                            data={tasks}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={10}
                            className={"-striped -highlight"}
                            showPagination={false}
                            getTheadThProps={getTheadThProps}
                            getTdProps={getTdProps}
                            getTrProps={getTrProps}
                            getPaginationProps={getPaginationProps}
                        />
                    ) : (
                        <p>Er zijn geen taken gedefinieerd voor dit project.</p>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default ProjectTasksCompact;