import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';


const RegionCreate = () =>  {

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [postalCodes, setPostalCodes] = useState([]);
    
    const [availablePostalCodes, setAvailablePostalCodes] = useState([]);

    useEffect(() => {
        loadPostalCodes();
    }, []);

    const loadPostalCodes = () => {
        API.get('postalcodes').then(function(response) {
            setAvailablePostalCodes(response.data);
        });
    };

    const changePostalCodesOfRegion = (options) => {
        setPostalCodes(options);
    };

    const addRegion = () => {
        let data = {
            name,
            description,
            postalCodes
        };
        API.post('/region', data).then(function() {}).catch(function() {
            console.log('region submitted');
        });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Regio's" title="Overzicht van alle regio's" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <input type={'text'} className={'form-control'} value={name} onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <input type={'text'} className={'form-control'} value={description} onChange={(e) => setDescription(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                <Typeahead
                                    id="project-typeahead"
                                    onChange={(options) => changePostalCodesOfRegion(options)}
                                    clearButton
                                    labelKey={(item) => `${item.postalCode} - ${item.city}`}
                                    multiple={true}
                                    options={availablePostalCodes ? availablePostalCodes : []}
                                    placeholder="Selecteer postcodes ..."
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Button onClick={() => addRegion()}>Regio toevoegen</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </div>
            </div>
        </Fragment>
    );
};

export default RegionCreate;