import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Row, Col} from 'reactstrap';
import PlanningSideBarMenu from './planningSideBarMenu';
import TaskPlanningPerEmployee from './taskPlanningPerEmployee';

const PlanningOverview = () =>  {
    return (
        <Fragment>
            <Breadcrumb>Planning</Breadcrumb>
            <div className="container-fluid">
                <div className="edit-profile">
                    <Row>
                        <Col lg={2}>
                            <PlanningSideBarMenu />
                        </Col>
                        <Col lg={10}>
                            <TaskPlanningPerEmployee showDateFilter={true} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default PlanningOverview;