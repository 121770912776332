import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import StepZilla from "react-stepzilla/dist/main";
import Noncustomerpersonalinformation from "./steps/noncustomerpersonalinformation";
import Noncustomercontactinformation from "./steps/noncustomercontactinformation";
import NoncustomerconfirmCustomerCreation from "./steps/noncustomerconfirmCustomerCreation";


class EmployeeCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          user : null
        };
        this.personStore = {
            username: "",
            password: "",
            firstName : "",
            lastName : "",
            street : "",
            houseNumber : "",
            bus : "",
            postalCode : "",
            city : "",
            country : "",
            extraInformation : ""
        };
        this.contactStore = {
            primaryEmail : "",
            primaryPhone : "",
            primaryMobile : "",
            alternativeEmail : "",
            alternativePhone : "",
            alternativeMobile : ""
        };
        this.invoiceDataStore = {
            companyName : "",
            vatNumber : "",
            cbeNumber : "",
            street : "",
            houseNumber : "",
            bus: "",
            postalCode : "",
            city : "",
            country : ""
        };
    }

    saveNewCustomer = () => {
        const user = {
            username: this.personStore.username,
            password: this.personStore.password,
            firstName : this.personStore.firstName,
            lastName : this.personStore.lastName,
            street : this.personStore.street,
            houseNumber : this.personStore.houseNumber,
            bus : this.personStore.bus,
            postalCode : this.personStore.postalCode,
            city : this.personStore.city,
            country : this.personStore.country,
            extraInformation : this.personStore.extraInformation,
            primaryEmail : this.contactStore.primaryEmail,
            primaryPhone : this.contactStore.primaryPhone,
            primaryMobile : this.contactStore.primaryMobile,
            alternativeEmail : this.contactStore.alternativeEmail,
            alternativePhone : this.contactStore.alternativePhone,
            alternativeMobile : this.contactStore.alternativeMobile,
            userRole: "EMPLOYEE",
            invoiceData : {
                companyName : this.invoiceDataStore.companyName,
                vatNumber : this.invoiceDataStore.vatNumber,
                cbeNumber : this.invoiceDataStore.cbeNumber,
                street : this.invoiceDataStore.street,
                houseNumber : this.invoiceDataStore.houseNumber,
                bus: this.invoiceDataStore.bus,
                postalCode : this.invoiceDataStore.postalCode,
                city : this.invoiceDataStore.city,
                country : this.invoiceDataStore.country
            }
        };
        API.post('employee', user);
        this.setState({user : user});
    };

    getPersonalStore() {
        return this.personStore;
    };

    updatePersonalStore(update) {
        this.personStore = {...this.personStore, ...update};
    };

    getContactStore() {
        return this.contactStore;
    }

    updateContactStore(update) {
        this.contactStore = {...this.contactStore, ...update};
        this.saveNewCustomer();
    }
    
    createNewProfile = (event) => {
        event.preventDefault();
    };

    render() {

        const steps = [
            {name: 'Persoonsgegevens', component : <Noncustomerpersonalinformation getPersonalStore={() => (this.getPersonalStore())} updatePersonalStore={(u) => {this.updatePersonalStore(u)}} />} ,
            {name: 'Contactgegevens', component : <Noncustomercontactinformation getContactStore={() => {this.getContactStore()}} updateContactStore={(u) => {this.updateContactStore(u)}} />},
            {name: 'Bevestiging', component : <NoncustomerconfirmCustomerCreation user={this.state.user} />}
        ];
        return (
            <Fragment>
                <Breadcrumb parent="Gebruikersbeheer" parentLink="/users/overview">Nieuwe medewerker aanmaken</Breadcrumb>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                                <form className="card" onSubmit={this.createNewProfile}>
                                    <div className="card-body">
                                        <StepZilla
                                            steps={steps}
                                            preventEnterSubmission={true}
                                            nextTextOnFinalActionStep={"Medewerker opslaan"}
                                            nextButtonText={"Volgende stap"}
                                            backButtonText={"Vorige stap"}
                                            hocValidationAppliedTo={[]}
                                            startAtStep={0}
                                            onStepChange={step => {
                                                console.log(this.personStore, this.contactStore, this.invoiceDataStore);
                                            }

                                            }
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default EmployeeCreate;