import React, { Fragment } from 'react';
import _ from "lodash";
import {Col, Row} from "reactstrap";

class NoncustomerconfirmCustomerCreation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : props.user
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        {!_.isEmpty(this.state.user) ? (
                            <>
                                <Row className={'mt-3'}>
                                    <Col lg={12}>
                                        Gebruiker met naam {this.state.user.firstName} {this.state.user.lastName} is succesvol aangemaakt. De gegevens van deze klant zijn:
                                    </Col>
                                </Row>
                                <br />
                                <br />
                                <Row className={'mt-3'}>
                                    <Col lg={12}>
                                        <h5>Persoonsgegevens</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={1}>
                                        <h2><i className="icofont iconfont-2x icofont-user"></i></h2>
                                    </Col>
                                    <Col lg={11}>
                                        <Row>
                                            <Col lg={3}>
                                                Naam:
                                            </Col>
                                            <Col lg={9}>
                                                {this.state.user.firstName} {this.state.user.lastName}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                Extra informatie:
                                            </Col>
                                            <Col lg={9}>
                                                {this.state.user.extraInformation}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className={'mt-1'}>
                                    <Col lg={12}>
                                        <h5>Contactgegevens</h5>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col lg={1}>
                                        <h2><i className="icofont iconfont-2x icofont-ui-contact-list"></i></h2>
                                    </Col>
                                    <Col lg={11}>
                                        <Row>
                                            <Col lg={3}>
                                                Primair e-mailadres:
                                            </Col>
                                            <Col lg={9}>
                                                {this.state.user.primaryEmail}
                                            </Col>
                                        </Row>
                                        {!_.isEmpty(this.state.user.primaryMobile) ? (
                                            <Row>
                                                <Col lg={3}>
                                                    Primair GSM:
                                                </Col>
                                                <Col lg={9}>
                                                    {this.state.user.primaryMobile}
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </Col>
                                </Row>

                            </>
                        ) : null }
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default NoncustomerconfirmCustomerCreation;