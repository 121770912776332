import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import CKEditors from "react-ckeditor-component";
import CurrencyInput from "react-currency-input";
import Loader from '../common/loader';


const CreditnoteCreateBasedOnInvoice = (props) => {

    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState(null);

    const [creditNote, setCreditNote] = useState(null);


    useEffect(() => {
        API.get('invoice/' + props.match.params.id).then(function(response) {
            setInvoice(response.data);
        });
        
    }, []);

    useEffect(() => {
        if (invoice !== null) {
            let newCreditNote = {
                invoicerAddress : invoice.invoicerAddress,
                deliveryAddress : invoice.deliveryAddress,
                billToAddress : invoice.billToAddress,
                invoiceItems: invoice.invoiceItems,
                extraInformation : invoice.extraInformation,
                externalReference1 : invoice.externalReference1,
                externalReference2 : invoice.externalReference2,
                externalReference3 : invoice.externalReference3
            };
            newCreditNote.invoiceItems.map((invoiceItem) => {
                invoiceItem.numberOfItems *= -1;
                invoiceItem.vatAmount *= -1;
                invoiceItem.totalAmount *= -1;
            });
            setCreditNote(newCreditNote);
        }
        
    }, [invoice]);

    useEffect(() => {
        if (invoice !== null) {
            setLoading(false);
        } 
    }, [creditNote]);

    const addInvoiceItem = () => {
        let tmpInvoiceItems = [...creditNote.invoiceItems];
        tmpInvoiceItems.push({description: "", numberOfItems : 1, unitOfItems : "Stuks", unitPrice: 0.00, vatPercentage: "21", vatAmount: 0.00, totalAmount: 0.00 });
        creditNote.invoiceItems = tmpInvoiceItems;
    };

    const removeInvoiceItem = (id) => {
        let tmpInvoiceItems = [...creditNote.invoiceItems];
        tmpInvoiceItems.splice(id, 1);
        creditNote.invoiceItems = tmpInvoiceItems;
    };

    const changeInvoiceItem = (name, value, index) => {
        let tmpInvoice = {...creditNote};
        tmpInvoice.invoiceItems[index][name] = value;
        if (name === 'unitPrice' || name === 'numberOfItems' || name === 'vatPercentage') {
            tmpInvoice.invoiceItems[index]['vatAmount'] = tmpInvoice.invoiceItems[index]['vatPercentage'] / 100 * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
            tmpInvoice.invoiceItems[index]['totalAmount'] = (1 + tmpInvoice.invoiceItems[index]['vatPercentage'] / 100) * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        }
        setCreditNote(tmpInvoice);
    };

    const changeInvoiceItem2 = (maskedvalue, floatvalue, index) => {
        let tmpInvoice = {...creditNote};
        tmpInvoice.invoiceItems[index]['unitPrice'] = floatvalue;

        tmpInvoice.invoiceItems[index]['vatAmount'] = tmpInvoice.invoiceItems[index]['vatPercentage'] / 100 * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        tmpInvoice.invoiceItems[index]['totalAmount'] = (1 + tmpInvoice.invoiceItems[index]['vatPercentage'] / 100) * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
    
        setCreditNote(tmpInvoice);
    };

    const changeInvoiceItemNumberOfItems = (maskedvalue, floatvalue, index) => {
        let tmpInvoice = {...creditNote};
        tmpInvoice.invoiceItems[index]['numberOfItems'] = floatvalue;

        tmpInvoice.invoiceItems[index]['vatAmount'] = tmpInvoice.invoiceItems[index]['vatPercentage'] / 100 * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        tmpInvoice.invoiceItems[index]['totalAmount'] = (1 + tmpInvoice.invoiceItems[index]['vatPercentage'] / 100) * tmpInvoice.invoiceItems[index]['unitPrice'] * tmpInvoice.invoiceItems[index]['numberOfItems'];
        
        setCreditNote(tmpInvoice);
    }

    const createCreditNote = () => {
        setLoading(true);
        API.post('creditnote', {
            creditNote,
            invoice
        }).then(function(response) {
            setLoading(false);
        });
    };
    
    return (
        <>
            {loading ? <Loader /> : (
                <>
                <Breadcrumb parent={''} title="Kredietnota maken"/>
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={'card'} style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                        <div className={'card-body'}>
                                            <div className={'row'}>
                                                <div className={'col-xl-12'}>
                                                    {invoice !== null && invoice !== undefined ? (
                                                        <>
                                                            U staat op punt om een kredietnota te maken voor de factuur <strong>'{invoice.invoiceNumber}'</strong>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <form>
                                        <div className={'row'}>
                                            <div className={'col-lg-4'}>
                                                <div className={'card'}>
                                                    <div className={'card-body'}>
                                                        <div className={'form-group row'}>
                                                            <div className={'col-sm-12'}>
                                                                <h4>Gefactureerd door:</h4>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <strong>{creditNote.invoicerAddress.companyName}</strong>
                                                            <br />{creditNote.invoicerAddress.cbeNumber}
                                                            <br />
                                                            <br />{creditNote.invoicerAddress.firstName} {creditNote.invoicerAddress.lastName}
                                                            <br />{creditNote.invoicerAddress.street} {creditNote.invoicerAddress.houseNumber} {creditNote.invoicerAddress.bus}
                                                            <br />{creditNote.invoicerAddress.postalCode} {creditNote.invoicerAddress.city}
                                                            <br />{creditNote.invoicerAddress.country !== null ? creditNote.invoicerAddress.country.name : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-lg-4'}>
                                                <div className={'card'}>
                                                    <div className={'card-body'}>
                                                        <div className={'form-group row'}>
                                                            <div className={'col-sm-12'}>
                                                                <h4>Gefactureerd naar:</h4>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <strong>{creditNote.billToAddress.companyName}</strong>
                                                            <br />{creditNote.billToAddress.cbeNumber}
                                                            <br />
                                                            <br />{creditNote.billToAddress.firstName} {creditNote.billToAddress.lastName}
                                                            <br />{creditNote.billToAddress.street} {creditNote.billToAddress.houseNumber} {creditNote.billToAddress.bus}
                                                            <br />{creditNote.billToAddress.postalCode} {creditNote.billToAddress.city}
                                                            <br />{creditNote.billToAddress.country !== null ? creditNote.billToAddress.country.name : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-lg-4'}>
                                                <div className={'card'}>
                                                    <div className={'card-body'}>
                                                        <div className={'form-group row'}>
                                                            <div className={'col-sm-12'}>
                                                                <h4>Geleverd aan:</h4>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <strong>{creditNote.deliveryAddress.companyName}</strong>
                                                            <br />{creditNote.deliveryAddress.cbeNumber}
                                                            <br />
                                                            <br />{creditNote.deliveryAddress.firstName} {creditNote.deliveryAddress.lastName}
                                                            <br />{creditNote.deliveryAddress.street} {creditNote.deliveryAddress.houseNumber} {creditNote.deliveryAddress.bus}
                                                            <br />{creditNote.deliveryAddress.postalCode} {creditNote.deliveryAddress.city}
                                                            <br />{creditNote.deliveryAddress.country !== null ? creditNote.deliveryAddress.country.name : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-12'}>
                                                <div className={'card'}>
                                                    <div className={'card-body'}>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-12'}>
                                                                <h4>Extra informatie</h4>
                                                            </div>
                                                        </div>
                                                        <div className={'row form-group'}>
                                                            <div className={'col-sm-2'}>
                                                                <label>Externe referentie 1:</label>
                                                            </div>
                                                            <div className={'col-sm-10'}>
                                                                <input type={'text'} className={'form-control'} value={creditNote.externalReference1} onChange={({target:{value}}) => {creditNote.externalReference1 = value; setCreditNote(creditNote);}} />
                                                            </div>
                                                        </div>
                                                        <div className={'row form-group'}>
                                                            <div className={'col-sm-2'}>
                                                                <label>Externe referentie 2:</label>
                                                            </div>
                                                            <div className={'col-sm-10'}>
                                                                <input type={'text'} className={'form-control'} value={creditNote.externalReference2} onChange={({target:{value}}) => {creditNote.externalReference2 = value; setCreditNote(creditNote);}} />
                                                            </div>
                                                        </div>
                                                        <div className={'row form-group'}>
                                                            <div className={'col-sm-2'}>
                                                                <label>Externe referentie 3:</label>
                                                            </div>
                                                            <div className={'col-sm-10'}>
                                                                <input type={'text'} className={'form-control'} value={creditNote.externalReference3} onChange={({target:{value}}) => {creditNote.externalReference3 = value; setCreditNote(creditNote);}} />
                                                            </div>
                                                        </div>
                                                        <div className={'row form-group'}>
                                                            <div className={'col-xl-12'}>
                                                                <label>Extra informatie:</label>
                                                            </div>
                                                            <div className={'col-xl-12'}>
                                                                <CKEditors
                                                                    activeclassName="p10"
                                                                    content={creditNote.extraInformation}
                                                                    events={{
                                                                        "change": ({editor}) => creditNote.extraInformation = editor.getData()
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col-xl-12'}>
                                                <div className={'card'}>
                                                    <div className={'card-body'}>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-12'}>
                                                                <h4>Detaillijnen:</h4>
                                                            </div>
                                                        </div>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-12'}>
                                                                <div className={'row form-group'}>
                                                                    <div className={'col-xl-4'}>Beschrijving</div>
                                                                    <div className={'col-xl-1'}>Aantal</div>
                                                                    <div className={'col-xl-1'}>Eenheid</div>
                                                                    <div className={'col-xl-1'}>Eenheidsprijs</div>
                                                                    <div className={'col-xl-1'}>BTW %</div>
                                                                    <div className={'col-xl-1'}>BTW bedrag</div>
                                                                    <div className={'col-xl-1'}>Totaal</div>
                                                                    <div className={'col-xl-1'}></div>
                                                                    <div className={'col-xl-1'}></div>
                                                                </div>
                                                                {creditNote.invoiceItems.map((x,i) => {
                                                                    return (
                                                                        <div className={'row form-group'}>
                                                                            <label className={'col-xl-5'}>
                                                                                <input className={'form-control'} name={'description'} value={x.description} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                <CurrencyInput decimalSeparator="," thousandSeparator="." allowNegative="true"  className={'form-control'} name={'numberOfItems'} value={x.numberOfItems}  onChangeEvent={(e, maskedvalue, floatvalue) => changeInvoiceItemNumberOfItems(maskedvalue, floatvalue, i)} />
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                <input className={'form-control'} name={'unitOfItems'} value={x.unitOfItems} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                <CurrencyInput decimalSeparator="," thousandSeparator="."  className={'form-control'} name={'unitPrice'} value={x.unitPrice} onChangeEvent={(e, maskedvalue, floatvalue, index) => changeInvoiceItem2(maskedvalue, floatvalue, i)} />
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                <input className={'form-control'} name={'vatPercentage'} value={x.vatPercentage} onChange={({target: {name, value}}) => changeInvoiceItem(name, value, i)} />
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.vatAmount)}
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                {new Intl.NumberFormat('nl-BE', {style: 'currency', currency: 'EUR'}).format(x.totalAmount)}
                                                                            </label>
                                                                            <label className={'col-xl-1'}>
                                                                                {invoice.invoiceItems.length !== 1 && <a href={'#'} className={'btn btn-primary'} onClick={() => removeInvoiceItem(i)}><span className={'fa fa-times'}></span></a>}
                                                                            </label>
                                                                        
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className={'row'}>
                                                            <div className={'col-xl-12'}>
                                                                <h4 style={{textAlign: 'right'}}>Totaal: {new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' }).format(creditNote.invoiceItems.reduce((totalPrice, offerItem) => totalPrice + parseFloat(offerItem.totalAmount),0))}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-primary" type="submit"
                                                    onClick={() => createCreditNote()}>Kredietnota genereren
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CreditnoteCreateBasedOnInvoice;