import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import API from "../../utils/API";
import {useParams} from "react-router";
import {Typeahead} from "react-bootstrap-typeahead";
const UserEdit = () => {
    const {id} = useParams();
    const [user, setUser] = useState(null);
    const [baseUser, setBaseUser] = useState(null);
    const [contactUser, setContactUser] = useState(null);
    const [invoicingUser, setInvoicingUser] = useState(null);
    const [countries, setCountries] = useState([]);
    const onChangeEmail = ({target : {value}}) => {setUser(prevUser => ({...prevUser, email : value}))};
    const onChangeBaseUser = ({target : {name, value}}) => {setBaseUser(prevUser => ({...prevUser, [name] : value}))};
    const onChangeContactUser = ({target : {name, value}}) => {setContactUser(prevUser => ({...prevUser, [name] : value}))};
    const onChangeInvoicingUser = ({target : {name, value}}) => {setInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, [name] : value}}))};
    const changeIsEnabled = ({target : {checked}}) => {setUser(prevUser => ({...prevUser, enabled : checked}))};
    const changeIsBusinessAccount = ({target : {name, checked}}) => {setInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, [name] : checked}}))};
    const changeBaseCountry = (selectedArray) => {setContactUser(prevUser => ({...prevUser, country : selectedArray[0]}))};
    const changeInvoiceCountry = (selectedArray) => {setInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, country : selectedArray[0]}}))};
    const saveContactData = (e) => {
        e.preventDefault();
        API.put('user/' + id + '/contact', {
            primaryEmail : contactUser.primaryEmail,
            primaryPhone : contactUser.primaryPhone,
            primaryMobile : contactUser.primaryMobile,
            alternativeEmail : contactUser.alternativeEmail,
            alternativePhone : contactUser.alternativePhone,
            alternativeMobile : contactUser.alternativeMobile
        }).then(function() {
            console.log('user is saved');
        });
    };
    const saveLoginData = (e) => {
        e.preventDefault();
        API.put('user/' + id + '/login', {
            username : user.username,
            canLogIn : user.enabled,
            colorCode: user.colorCode
        })
    };
    const saveBaseData = (e) => {
        e.preventDefault();
        API.put('user/' + id + '/base', {
            firstName : baseUser.firstName,
            lastName : baseUser.lastName,
            street : baseUser.street,
            houseNumber : baseUser.houseNumber,
            bus : baseUser.bus,
            postalCode : baseUser.postalCode,
            city : baseUser.city,
            country : baseUser.country ? baseUser.country.id : 0,
            extraInformation : baseUser.extraInformation
        }).then(function() {
            console.log('user is saved');
        });
    };
    const saveInvoicingData = (e) => {
        e.preventDefault();
        if (invoicingUser.invoiceData) {
            API.put('user/' + id + '/invoicedata', {
                isBusinessAccount : invoicingUser.invoiceData.businessAccount,
                organisationName : invoicingUser.invoiceData.organisationName,
                vatNumber : invoicingUser.invoiceData.vatNumber,
                street : invoicingUser.invoiceData.street,
                houseNumber : invoicingUser.invoiceData.houseNumber,
                bus : invoicingUser.invoiceData.bus,
                postalCode : invoicingUser.invoiceData.postalCode,
                city : invoicingUser.invoiceData.city,
                country : invoicingUser.invoiceData.country ? invoicingUser.invoiceData.country.id : 0
            }).then(function() {
                console.log('user is saved');
            });
        }
    };
    useEffect(() => {
        API.get('user/' + id).then(function(response) {
            setUser(response.data);
            setBaseUser(response.data);
            setContactUser(response.data);
            setInvoicingUser(response.data);
        });
        API.get('config/countries').then(function(response) {
            setCountries(response.data);
        });
    }, []);
    return (
        <Fragment>
            <Breadcrumb parent="User" title="Edit Profile" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="ribbon-wrapper card">
                                <div className="card-body">
                                    <div className="ribbon ribbon-clip ribbon-primary">Inloggegevens</div>
                                    <div className={'row'}>
                                        {user ? (
                                            <form style={{width: '100%'}}>
                                                <div className="row mb-2">
                                                    <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={seven} /></div>
                                                    <div className="col">
                                                        <h3 className="mb-1">{user.firstName} {user.lastName}</h3>
                                                        <p className="mb-4">DESIGNER</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className={'form-group'}>
                                                    <label className="form-label"><input type={'checkbox'} name={'enabled'} defaultChecked={user.enabled} onChange={changeIsEnabled}  /> Kan inloggen </label>
                                                </div>
                                                {user.enabled ? (
                                                    <>
                                                        <div className="form-group">
                                                            <label className="form-label">E-mailadres</label>
                                                            <input className="form-control" value={user.email} onChange={onChangeEmail} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label">Kleurcode</label>
                                                            <input className="form-control" value={user.colorCode} onChange={({target : {value}}) => {setUser(prevUser => ({...prevUser, colorCode : value}))}} />
                                                        </div>
                                                    </>
                                                ) : null}
                                                <div className="form-footer">
                                                    <a href={'#'} className="btn btn-primary btn-block" onClick={saveLoginData}>Wijzig inloggegevens</a>
                                                </div>
                                            </form>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="ribbon-wrapper card">
                                <div className="card-body">
                                    <div className="ribbon ribbon-clip ribbon-primary">Contactgegevens</div>
                                    <div className={'row'}>
                                        {contactUser ? (
                                            <form style={{width: '100%'}}>
                                                <div className={'col-md-12'}>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            <h6>Primaire contactgegevens</h6>
                                                        </label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-email"></i></span></div>
                                                            <input className="form-control" type="text" name={'primaryEmail'} value={contactUser.primaryEmail} onChange={onChangeContactUser} />
                                                        </div>
                                                        <br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-phone"></i></span></div>
                                                            <input className="form-control" type="text" name={'primaryPhone'} value={contactUser.primaryPhone} onChange={onChangeContactUser} />
                                                        </div>
                                                        <br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-mobile-phone"></i></span></div>
                                                            <input className="form-control" type="text" name={'primaryMobile'} value={contactUser.primaryMobile} onChange={onChangeContactUser} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-md-12'}>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            <h6>Alternatieve contactgegevens</h6>
                                                        </label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-email"></i></span></div>
                                                            <input className="form-control" type="text" name={'alternativeEmail'} value={contactUser.alternativeEmail} onChange={onChangeContactUser} />
                                                        </div>
                                                        <br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-phone"></i></span></div>
                                                            <input className="form-control" type="text" name={'alternativePhone'} value={contactUser.alternativePhone} onChange={onChangeContactUser} />
                                                        </div>
                                                        <br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-mobile-phone"></i></span></div>
                                                            <input className="form-control" type="text" name={'alternativeMobile'} value={contactUser.alternativeMobile} onChange={onChangeContactUser} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-footer">
                                                    <a href={'#'} className="btn btn-primary btn-block" onClick={saveContactData}>Wijzig contactgegevens</a>
                                                </div>
                                            </form>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {baseUser ? (
                                <div className="ribbon-wrapper card">
                                    <div className="card-body">
                                        <div className="ribbon ribbon-clip ribbon-primary">Algemene gegevens</div>
                                        <div className={'row'}>
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Voornaam</label>
                                                            <input className="form-control" type="text" name={'firstName'} value={baseUser.firstName} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Naam</label>
                                                            <input className="form-control" type="text" name={'lastName'} value={baseUser.lastName} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Straat</label>
                                                            <input className="form-control" type="text" name={'street'} value={baseUser.street} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label">Huisnr</label>
                                                            <input className="form-control" type="text" name={'houseNumber'} value={baseUser.houseNumber} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label">Bus</label>
                                                            <input className="form-control" type="text" name={'bus'} value={baseUser.bus} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Gemeente</label>
                                                            <input className="form-control" type="text" name={'city'} value={baseUser.city} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label">Postal Code</label>
                                                            <input className="form-control" type="text" name={'postalCode'} value={baseUser.postalCode} onChange={onChangeBaseUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className="form-label">Country</label>
                                                            {countries ? (
                                                                <Typeahead
                                                                    id="project-typeahead"
                                                                    onChange={changeBaseCountry}
                                                                    clearButton
                                                                    labelKey="name"
                                                                    selected={baseUser.country ? [baseUser.country] : []}
                                                                    multiple={false}
                                                                    options={countries}
                                                                    placeholder="Selecteer een land ..."
                                                                />
                                                            ) : (
                                                                <p> </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Extra informatie</label>
                                                            <textarea className="form-control" rows="5"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-right">
                                                    <a href={'#'} className="btn btn-primary" onClick={saveBaseData}>Wijzig basisgegevens</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {invoicingUser && invoicingUser.invoiceData !== null ? (
                                <div className="ribbon-wrapper card">
                                    <div className="card-body">
                                        <div className="ribbon ribbon-clip ribbon-primary">Facturatiegegevens</div>
                                        <div className={'row'}>
                                            <form>
                                                <div className="row">
                                                    <div className={'col-md-12'}>
                                                        <div className={'form-group'}>
                                                            <label className="form-label"><input type={'checkbox'} name={'businessAccount'} defaultChecked={invoicingUser.invoiceData.businessAccount} onChange={changeIsBusinessAccount}  /> Zakelijk account</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Organisatie</label>
                                                            <input className="form-control" type="text" name={'organisationName'} value={invoicingUser.invoiceData.organisationName} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">BTWnummer</label>
                                                            <input className="form-control" type="text" name={'vatNumber'} value={invoicingUser.invoiceData.vatNumber} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Straat</label>
                                                            <input className="form-control" type="text" name={'street'} value={invoicingUser.invoiceData.street} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label">Huisnr</label>
                                                            <input className="form-control" type="text" name={'houseNumber'} value={invoicingUser.invoiceData.houseNumber} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label">Bus</label>
                                                            <input className="form-control" type="text" name={'bus'} value={invoicingUser.invoiceData.bus} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Gemeente</label>
                                                            <input className="form-control" type="text" name={'city'} value={invoicingUser.invoiceData.city} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label">Postal Code</label>
                                                            <input className="form-control" type="text" name={'postalCode'} value={invoicingUser.invoiceData.postalCode} onChange={onChangeInvoicingUser} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className="form-label">Country</label>
                                                            {countries ? (
                                                                <Typeahead
                                                                    id="project-typeahead"
                                                                    onChange={changeInvoiceCountry}
                                                                    clearButton
                                                                    labelKey="name"
                                                                    selected={invoicingUser.invoiceData.country ? [invoicingUser.invoiceData.country] : []}
                                                                    multiple={false}
                                                                    options={countries}
                                                                    placeholder="Selecteer een land ..."
                                                                />
                                                            ) : (
                                                                <p> </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-right">
                                                    <a href={'#'} className="btn btn-primary" onClick={saveInvoicingData}>Wijzig facturatiegegevens</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserEdit;