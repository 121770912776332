import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import SweetAlert from "react-bootstrap-sweetalert";


class EstablishmentCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countries : [],
            showSuccessDialog : false,
            showNewBankAccount : false,
            newBankAccount : {
                bankName : "",
                iban : "",
                bic : ""
            },
            establishment : {
                companyName: "",
                cbeNumber: "",
                street: "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                phone : "",
                mobile : "",
                email : "",
                website : "",
                bankAccounts : []
            }
        };
    }

    componentDidMount() {
        let me = this;
        API.get('config/countries').then(function(response) {
            me.setState({countries: response.data});
        });
    }

    changeBankName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newBankAccount: { ...prevState.newBankAccount, bankName: value}}));
    };

    changeIban = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newBankAccount: { ...prevState.newBankAccount, iban: value}}));
    };

    changeBic = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({newBankAccount: { ...prevState.newBankAccount, bic: value}}));
    };

    changeCompanyName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, companyName: value}}));
    };

    changeCbeNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, cbeNumber: value}}));
    };

    changeStreet = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, street: value}}));
    };

    changeHouseNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, houseNumber: value}}));
    };

    changeBus = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, bus: value}}));
    };

    changePostalCode = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, postalCode: value}}));
    };

    changeCity = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, city: value}}));
    };

    changeCountry = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, country: value}}));
    };

    changePhone = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, phone: value}}));
    };

    changeMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, mobile: value}}));
    };

    changeEmail = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, email: value}}));
    };

    changeWebsite = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({establishment: { ...prevState.establishment, website: value}}));
    };

    addBankAccount = () => {
        let bankAccountToAdd = {
            bankName : this.state.newBankAccount.bankName,
            iban : this.state.newBankAccount.iban,
            bic : this.state.newBankAccount.bic
        };
        let bankAccounts = [...this.state.establishment.bankAccounts];
        bankAccounts.push(bankAccountToAdd);

        this.setState((prevState) => ({establishment: { ...prevState.establishment, bankAccounts: bankAccounts}}), function() {
            console.log(this.state);
        });
        this.setState({showNewBankAccount: false});
    };

    cancelAddBankAccount = () => {
        this.setState({showNewBankAccount: false});
    };

    createNewEstablishment = (event) => {
        event.preventDefault();
        let me = this;
        API.post('establishment', {
            companyName: this.state.establishment.companyName,
            cbeNumber: this.state.establishment.cbeNumber,
            street: this.state.establishment.street,
            houseNumber: this.state.establishment.houseNumber,
            bus: this.state.establishment.bus,
            postalCode: this.state.establishment.postalCode,
            city: this.state.establishment.city,
            country: this.state.establishment.country,
            email: this.state.establishment.email,
            phone: this.state.establishment.phone,
            mobile: this.state.establishment.mobile,
            website: this.state.establishment.website,
            bankAccounts : this.state.establishment.bankAccounts
        }).then(function() {
            me.setState({showSuccessDialog: true});
        });
    };

    openAddBankAccount = (event) => {
        event.preventDefault();
        this.setState({showNewBankAccount: true});
    };

    navigateToOverview = () => {
      window.location.href = `${process.env.PUBLIC_URL}/establishments/overview`;
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Mijn vestigingen" title="Nieuwe vestiging toevoegen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                                <form className="card" onSubmit={this.createNewProject}>
                                    <div className="card-body">
                                        <SweetAlert show={this.state.showSuccessDialog} success title="Succesvol toegevoegd" onConfirm={() => this.navigateToOverview()}>
                                            Je vestiging is succesvol toegevoegd!
                                        </SweetAlert>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Organisatienaam:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'companyName'} className={'form-control'} value={this.state.establishment.companyName} onChange={this.changeCompanyName} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Ondernemingsnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'cbeNumber'} className={'form-control'} value={this.state.establishment.cbeNumber} onChange={this.changeCbeNumber} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Straat:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'city'} className={'form-control'} value={this.state.establishment.street} onChange={this.changeStreet} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Huisnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'houseNumber'} className={'form-control'} value={this.state.establishment.houseNumber} onChange={this.changeHouseNumber} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Bus:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'bus'} className={'form-control'} value={this.state.establishment.bus} onChange={this.changeBus} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Postcode:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'postalCode'} className={'form-control'} value={this.state.establishment.postalCode} onChange={this.changePostalCode} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Gemeente:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'city'} className={'form-control'} value={this.state.establishment.city} onChange={this.changeCity} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-3'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Land:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <select onChange={this.changeCountry} className={'form-control'} name={'countryId'}>
                                                            <option value={'0'}>---</option>
                                                            {this.state.countries.map(function(country) {
                                                                return (
                                                                    <option value={country.id}>{country.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        E-mail:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'email'} className={'form-control'} value={this.state.establishment.email} onChange={this.changeEmail} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Telefoonnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'phone'} className={'form-control'} value={this.state.establishment.phone} onChange={this.changePhone} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        GSMnummer:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'mobile'} className={'form-control'} value={this.state.establishment.mobile} onChange={this.changeMobile} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Website:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <input type={'text'} name={'website'} className={'form-control'} value={this.state.establishment.website} onChange={this.changeWebsite} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-xl-12'}>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Bankaccounts <a href={'#'} onClick={(event) => this.openAddBankAccount(event)} className={'fa fa-plus'}></a>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.establishment.bankAccounts.map((bankAccount, index) => {
                                            return <p>{bankAccount.bankName}</p>
                                        })}

                                        <SweetAlert
                                            show={this.state.showNewBankAccount}
                                            showCloseButton={true}
                                            onCancel={() => this.cancelAddBankAccount()}
                                            info
                                            title="Bankaccount toevoegen"
                                            onConfirm={() => this.addBankAccount()}
                                            confirmBtnText={'Bankaccount toevoegen'}
                                            cancelBtnText={'Annuleren'}
                                            style={{width: '90%'}}
                                        >
                                            <form>
                                                <div className={'form-group row'}>
                                                    <div className={'col-3'}>Naam van bank:</div>
                                                    <div className={'col-9'}><input onChange={this.changeBankName} className={'form-control'} type={'text'} name={'bankName'} value={this.state.newBankAccount.bankName} /></div>
                                                </div>
                                                <div className={'form-group row'}>
                                                    <div className={'col-3'}>IBAN:</div>
                                                    <div className={'col-9'}><input onChange={this.changeIban} className={'form-control'} type={'text'} name={'iban'} value={this.state.newBankAccount.iban} /></div>
                                                </div>
                                                <div className={'form-group row'}>
                                                    <div className={'col-3'}>BIC:</div>
                                                    <div className={'col-9'}><input onChange={this.changeBic} className={'form-control'} type={'text'} name={'bic'} value={this.state.newBankAccount.bic} /></div>
                                                </div>
                                            </form>
                                        </SweetAlert>

                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" type="submit" onClick={this.createNewEstablishment}>Nieuwe vestiging toevoegen</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default EstablishmentCreate;