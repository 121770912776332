import React from 'react';
import {Link} from "react-router-dom";
import ReactTable from "react-table-6";


const UserInvoices = (props) => {   
    
    const invoiceColumns = [
        {
            Header : 'Naam',
            accessor : 'name',
            width: 250,
            Cell: row => {
                return <Link to={`/task/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
            }
        },
        {
            Header : 'Klant',
            accessor : 'customer',
            Cell: row => {
                if (row.row.customer !== undefined && row.row.customer !== null) {
                    return <span>{row.row.customer.firstName} {row.row.customer.lastName}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Project',
            accessor : 'project',
            Cell: row => {
                if (row.row.project !== undefined && row.row.project !== null) {
                    return <span>{row.row.project.name}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Werkbon',
            accessor : 'workOrder',
            Cell: row => {
                if (row.row.workOrder !== undefined && row.row.workOrder !== null) {
                    return <span>{row.row.workOrder.name}</span>
                } else {
                    return <span></span>
                }
            }
        },
        {
            Header : 'Prijs excl',
            headerClassName: 'text-right',
            accessor : 'totalPriceExcl',
            className : 'text-right',
            Cell: row => {
                return Intl.NumberFormat('nl-BE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(row.row.totalPriceExcl);
            }
        },
        {
            Header : 'BTW',
            headerClassName: 'text-right',
            accessor : 'totalVatAmount',
            className : 'text-right',
            Cell: row => {
                return Intl.NumberFormat('nl-BE', {
                    style: 'currency',
                    currency: 'EUR'

                }).format(row.row.totalVatAmount);
            }
        },
        {
            Header : 'Prijs incl',
            headerClassName: 'text-right',
            accessor : 'totalPriceIncl',
            className : 'text-right',
            Cell: row => {
                return Intl.NumberFormat('nl-BE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(row.row.totalPriceIncl);
            }
        }
    ];

    return (
        <>
            <ReactTable
                data={props.invoices}
                columns={invoiceColumns}
                defaultPageSize={10}
                minRows={1}
                className={"-striped -highlight"}
                showPagination={true}
            />
        </>
    );

}

export default UserInvoices;