import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import _ from "lodash";
import useReactTable from '../../hooks/useReactTable';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const EvaluationCard = (props) =>  {

    const task = props.task;

    const downloadEvaluationReport = () => {
        API.get('/pdf/task/' + task.id + '/evaluationform.pdf' ).then(function(response) {
            const linkSource = `data:application/pdf;base64,${response.data.base64}`;
            const downloadLink = document.createElement("a");
            const fileName = 'evaluatierapport_' + task.id + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    };

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col lg={12}>
                        <h2>Evaluatie taak</h2>
                    </Col>
                </Row>
                
                {task.status != 'FINISHED' ? (
                    <Row>
                        <Col>
                            Deze taak is nog niet geëvalueerd
                        </Col>
                    </Row>
                ) :(
                    <Row>
                        <Col xs={12} sm={4} md={2} lg={1}>
                            <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
                                <CardBody style={{textAlign: 'center'}}>
                                    <span style={{fontSize: '75px'}}>{task.evaluationRating}</span>
                                    <h4>Score</h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} sm={4} md={8} lg={10}>
                            <Row>
                                <Col lg={12}>
                                    <h5>Sterkten</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    {_.isEmpty(task.evaluationStrengths) ? (
                                        'Geen informatie ingegeven'
                                    ) : (
                                        task.evaluationStrengths
                                    )}
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h5>Werkpunten</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    {_.isEmpty(task.evaluationImprovements) ? (
                                        'Geen informatie ingegeven'
                                    ) : (
                                        task.evaluationImprovements
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h5>Opmerkingen</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    {_.isEmpty(task.evaluationRemarks) ? (
                                        'Geen informatie ingegeven'
                                    ) : (
                                        task.evaluationRemarks
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={4} md={2} lg={1}>
                            <Card style={{backgroundColor: 'transparent', color: 'black'}}>
                                <CardBody style={{textAlign: 'center'}}>
                                    <button 
                                        style={{backgroundColor: 'transparent', color: 'black'}} 
                                        onClick={() => downloadEvaluationReport()} 
                                        className='btn'>
                                        <i className="icofont icofont-2x icofont-download" style={{fontSize: '75px'}}></i>
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
                
                
            </CardBody>
        </Card>
    );
};

export default EvaluationCard;