import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import {Typeahead} from "react-bootstrap-typeahead";
import SweetAlert from "react-bootstrap-sweetalert";
import CKEditors from "react-ckeditor-component";
import CurrencyInput from "react-currency-input";
import Loader from "../common/loader";
import _ from "lodash";


class TimesheetCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated : true,
            errorMessage : '',
            addingTimesheetItem : false,
            projects : [],
            workOrders : [],
            tasks : [],
            showSuccessDialog : false,
            timesheetItem : {
                description: '',
                project: null,
                workOrder: null,
                task : '',
                startDate : '',
                startTime : [],
                endTime : [],
                breakIncluded : false,
                drivingDistance: 0
            }
        };
    }

    componentDidMount() {
        let me = this;
        API.get('projects').then(function(response) {
            me.setState({projects: response.data});
        });
    }

    changeDescription = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, description: value}}));
    };

    // changeProject = (selectedArray) => {
    //     if (selectedArray.length > 0) {
    //         let newProject = selectedArray[0];
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, project : newProject }}));
    //         this.setState({workOrders: newProject.workOrders});
    //         this.setState({task: []});
    //     } else {
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, project : null }}));
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, workOrder : null }}));
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, task : null }}));
    //         this.setState({workOrders: []});
    //         this.setState({task: []});
    //     }
    //
    // };
    //
    // changeWorkOrder = (selectedArray) => {
    //     if (selectedArray.length > 0) {
    //         let newWorkOrder = selectedArray[0];
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, workOrder : newWorkOrder }}));
    //         this.setState({tasks: newWorkOrder.tasks});
    //     } else {
    //         let newWorkOrder = selectedArray[0];
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, workOrder : null }}));
    //         this.setState({tasks: []});
    //     }
    //
    // };
    //
    // changeTask = (selectedArray) => {
    //     if (selectedArray.length > 0) {
    //         let newTask = selectedArray[0];
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, task : newTask }}));
    //     } else {
    //         this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, task : null }}));
    //     }
    // };

    changeTimesheetDay = (event) => {
        let day = event.target.value;
        this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, day : day }}));
    };

    changeTimesheetStartHour = (event) => {
        let startHour = event.target.value;
        this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, startHour : startHour }}));
    };

    changeTimesheetEndHour = (event) => {
        let endHour = event.target.value;
        this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, endHour : endHour }}));
    };

    changeTimesheetDrivingDistance = (event, maskedvalue, floatvalue) => {
        event.preventDefault();
        this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, drivingDistance : floatvalue }}));
    };

    changeTimesheetBreakIncluded = (event) => {
        event.preventDefault();
        let value = event.target.value;
        this.setState((prevState) => ({timesheetItem: { ...prevState.timesheetItem, breakIncluded : value === 'on' }}));
    }

    createNewTimesheetItem = (event) => {
        event.preventDefault();
        let validated = true;
        let message = "";
        if (_.isEmpty(this.state.timesheetItem.description)) {
            validated = false;
            message = 'Beschrijving is een verplicht veld.<br />';
        }
        if (_.isEmpty(this.state.timesheetItem.day)) {
            validated = false;
            message += 'Dag van prestatie is een verplicht veld.<br />';
        }
        if (_.isEmpty(this.state.timesheetItem.startHour)) {
            validated = false;
            message += 'Startuur van prestatie is een verplicht veld.<br />';
        }
        if (_.isEmpty(this.state.timesheetItem.endHour)) {
            validated = false;
            message += 'Einduur van prestatie is een verplicht veld.<br />';
        }
        this.setState({validated: validated});
        this.setState({errorMessage: message});

        if (validated) {
            this.setState({addingTimesheetItem: true});
            let me = this;
            API.post('timesheetitem', {
                description: this.state.timesheetItem.description,
                day : this.state.timesheetItem.day,
                startHour : this.state.timesheetItem.startHour,
                endHour :  this.state.timesheetItem.endHour,
                drivingDistance: this.state.timesheetItem.drivingDistance,
                breakIncluded: this.state.timesheetItem.breakIncluded
            }).then(function() {
                me.setState({addingTimesheetItem: false});
                me.setState({showSuccessDialog: true});
            });
        }
    };

    navigateToOverview = () => {
      window.location.href = `${process.env.PUBLIC_URL}/timesheets/overview`;
    };

    doNothing = () => {
        this.setState({validated: true});
    }

    render() {
        return (
            this.state.addingTimesheetItem ? (
                <Loader />
            ) :  (
                <Fragment>
                    <Breadcrumb parent="Mijn prestaties" title="Nieuw prestatie toevoegen" />
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row">
                                <div className="col-lg-12">
                                    <form className="card" onSubmit={this.createNewProject}>
                                        <div className="card-body">
                                            <SweetAlert show={this.state.showSuccessDialog} success title="Succesvol toegevoegd" onConfirm={() => this.navigateToOverview()}>
                                                Je prestatie is succesvol toegevoegd!
                                            </SweetAlert>
                                            <SweetAlert show={!this.state.validated} error title="Er is een fout opgetreden" onConfirm={() => this.doNothing()}>
                                                <p dangerouslySetInnerHTML={{__html: this.state.errorMessage}} />
                                            </SweetAlert>
                                            <div className={'row'}>
                                                <div className={'col-xl-12'}>
                                                    <div className={'form-group row'}>
                                                        <label className={'col-xl-12'}>
                                                            Beschrijving:
                                                        </label>
                                                        <div className={'col-xl-12'}>
                                                            <CKEditors
                                                                activeclassName="p10"
                                                                content={this.state.timesheetItem.description}
                                                                events={{
                                                                    "change": this.changeDescription
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className={'row'}>*/}
                                            {/*    <div className={'col-xl-6'}>*/}
                                            {/*        <div className={'form-group row'}>*/}
                                            {/*            <label className={'col-xl-12'}>*/}
                                            {/*                Project:*/}
                                            {/*            </label>*/}
                                            {/*            <div className={'col-xl-12'}>*/}
                                            {/*                {this.state.projects ? (*/}
                                            {/*                    <Typeahead*/}
                                            {/*                        id="project-typeahead"*/}
                                            {/*                        onChange={this.changeProject}*/}
                                            {/*                        clearButton*/}
                                            {/*                        labelKey={option => `${option.projectNumber} - ${option.name}`}*/}
                                            {/*                        selected={this.state.timesheetItem.project ? [this.state.timesheetItem.project] : []}*/}
                                            {/*                        multiple={false}*/}
                                            {/*                        options={this.state.projects}*/}
                                            {/*                        placeholder="Selecteer een project ..."*/}
                                            {/*                    />*/}
                                            {/*                ) : (*/}
                                            {/*                    <p> </p>*/}
                                            {/*                )}*/}

                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <div className={'col-xl-6'}>*/}
                                            {/*        <div className={'form-group row'}>*/}
                                            {/*            <label className={'col-xl-12'}>*/}
                                            {/*                Werkbon:*/}
                                            {/*            </label>*/}
                                            {/*            <div className={'col-xl-12'}>*/}
                                            {/*                {this.state.workOrders ? (*/}
                                            {/*                    <Typeahead*/}
                                            {/*                        id="project-typeahead"*/}
                                            {/*                        onChange={this.changeWorkOrder}*/}
                                            {/*                        clearButton*/}
                                            {/*                        labelKey={option => `${option.workOrderNumber} - ${option.name}`}*/}
                                            {/*                        selected={this.state.timesheetItem.workOrder ? [this.state.timesheetItem.workOrder] : []}*/}
                                            {/*                        multiple={false}*/}
                                            {/*                        options={this.state.workOrders}*/}
                                            {/*                        placeholder="Selecteer een werkbon ..."*/}
                                            {/*                    />*/}
                                            {/*                ) : (*/}
                                            {/*                    <p> </p>*/}
                                            {/*                )}*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className={'row'}>*/}
                                            {/*    <div className={'col-xl-6'}>*/}
                                            {/*        <div className={'form-group row'}>*/}
                                            {/*            <label className={'col-xl-12'}>*/}
                                            {/*                Taak:*/}
                                            {/*            </label>*/}
                                            {/*            <div className={'col-xl-12'}>*/}
                                            {/*                {this.state.tasks ? (*/}
                                            {/*                    <Typeahead*/}
                                            {/*                        id="project-typeahead"*/}
                                            {/*                        onChange={this.changeTask}*/}
                                            {/*                        clearButton*/}
                                            {/*                        labelKey={option => `${option.taskNumber} - ${option.name}`}*/}
                                            {/*                        selected={this.state.timesheetItem.task ? [this.state.timesheetItem.task] : []}*/}
                                            {/*                        multiple={false}*/}
                                            {/*                        options={this.state.tasks}*/}
                                            {/*                        placeholder="Selecteer een taak ..."*/}
                                            {/*                    />*/}
                                            {/*                ) : (*/}
                                            {/*                    <p> </p>*/}
                                            {/*                )}*/}

                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Dag</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control digits" onChange={this.changeTimesheetDay} type="date" value={this.state.timesheetItem.day} /></div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Startuur</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control digits" onChange={this.changeTimesheetStartHour} type="time" value={this.state.timesheetItem.startHour} /></div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Einduur</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control digits" onChange={this.changeTimesheetEndHour} type="time" value={this.state.timesheetItem.endHour} /></div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Middagpauze inbegrepen</label>
                                                <div className={'col-sm-9'}>
                                                    <input type={'checkbox'} className={'uniform text-left'} checked={this.state.timesheetItem.breakIncluded} onChange={(event) => this.changeTimesheetBreakIncluded(event)} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Aantal KM</label>
                                                <div className="col-sm-3">
                                                    <CurrencyInput decimalSeparator="," thousandSeparator="." precision={1}  className={'form-control'} name={'unitPrice'} value={this.state.timesheetItem.drivingDistance} onChangeEvent={(e, maskedvalue, floatvalue) => this.changeTimesheetDrivingDistance(e, maskedvalue, floatvalue)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-primary" type="submit" onClick={this.createNewTimesheetItem}>Nieuwe prestatie toevoegen</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                )

        );
    }
};

export default TimesheetCreate;