import React, { Fragment } from 'react';
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import {Col, Row} from "reactstrap";

class Personalinformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : {
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                extraInformation : "",
                userRole : "",
            },
            errors : {
                firstName : "",
                lastName : "",
                street : "",
                houseNumber : "",
                bus : "",
                postalCode : "",
                city : "",
                country : "",
                extraInformation : ""
            }
        };

        this._validateOnDemand = true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        let isDataValid = true;
        this.props.updatePersonalStore(this.state.user);
        return this.validationCheck();
    };

    validationCheck = () => {
        let isValid = true;
        if (_.isEmpty(this.state.user.firstName)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, firstName : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, firstName : "" }}));
        }
        if (_.isEmpty(this.state.user.lastName)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, lastName : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, lastName : "" }}));
        }
        if (_.isEmpty(this.state.user.street)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, street : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, street : "" }}));
        }
        if (_.isEmpty(this.state.user.houseNumber)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, houseNumber : "" }}));
        }
        if (_.isEmpty(this.state.user.postalCode)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, postalCode : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, postalCode : "" }}));
        }
        if (_.isEmpty(this.state.user.city)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, city : "Fout opgetreden" }}));
            isValid = false;
        } else {
            this.setState((prevState) => ({errors: { ...prevState.errors, city : "" }}));
        }

        return isValid;
    };

    changeFirstName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, firstName : value }
        }));
    };

    changeLastName = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, lastName : value }
        }));
    };

    changeStreet = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, street : value }
        }));
    };

    changeHouseNumber = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, houseNumber : value }
        }));
    };

    changeBus = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, bus : value }
        }));
    };

    changeCity = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, city : value }
        }));
    };

    changeCountry = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, country : value }
        }));
    };

    changePostalCode = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            user: { ...prevState.user, postalCode : value }
        }));
    };

    changeUserRole = (event) => {
        let value = event.target.value;
        console.log(value);

        this.setState((prevState) => ({
            user: { ...prevState.user, userRole : value }
        }));
    }

    changeExtraInformation = (event) => {
        let value = event.editor.getData();
        this.setState((prevState) => ({
            user: { ...prevState.user, extraInformation : value }
        }));
    };

    render() {
        return (
            <>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Voornaam</label>
                                    <input className="form-control" type="text" name="firstName" value={this.state.user.firstName} onChange={this.changeFirstName} />
                                    {this.state.errors.firstName ? <span className={'errorInForm'}>Voornaam is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Naam</label>
                                    <input className="form-control" type="text" name="lastName" value={this.state.user.lastName} onChange={this.changeLastName} />
                                    {this.state.errors.lastName ? <span className={'errorInForm'}>Naam is verplicht</span> : null }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} md={8} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Straat</label>
                                    <input className="form-control" type="text" name="street" value={this.state.user.street} onChange={this.changeStreet} />
                                    {this.state.errors.street ? <span className={'errorInForm'}>Straat is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Huisnummer</label>
                                    <input className="form-control" type="text" name="houseNumber" value={this.state.user.houseNumber} onChange={this.changeHouseNumber} />
                                    {this.state.errors.houseNumber ? <span className={'errorInForm'}>Huisnummer is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Bus</label>
                                    <input className="form-control" type="text" name="bus" value={this.state.user.bus} onChange={this.changeBus} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} md={2} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Postcode</label>
                                    <input className="form-control" type="text" name="postalCode" value={this.state.user.postalCode} onChange={this.changePostalCode} />
                                    {this.state.errors.postalCode ? <span className={'errorInForm'}>Postcode is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={5} md={5} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Gemeente</label>
                                    <input className="form-control" type="text" name="city" value={this.state.user.city} onChange={this.changeCity} />
                                    {this.state.errors.city ? <span className={'errorInForm'}>Gemeente is verplicht</span> : null }
                                </div>
                            </Col>
                            <Col lg={5} md={5} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Land</label>
                                    <select className="form-control btn-square" name="country" value={this.state.user.country} onChange={this.changeCountry}>
                                        <option value="1">België</option>
                                        <option value="2">Nederland</option>
                                        <option value="3">Duitsland</option>
                                        <option value="4">Frankrijk</option>
                                        <option value="5">Luxemburg</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="form-group">
                                    <label className="form-label">Extra informatie</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.user.extraInformation}
                                        events={{
                                            "change": this.changeExtraInformation
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
};

export default Personalinformation;