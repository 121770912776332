import React, {useEffect, useState, useMemo} from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactTable from 'react-table-6';
import {Link} from "react-router-dom";
import useReactTable from '../../hooks/useReactTable';
import { Typeahead } from 'react-bootstrap-typeahead';

const ProjectsInRegion = (props) =>  {
    const [projectsPerRegion, setProjectsPerRegion] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [pages, setPages] = useState(-1);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const [search, setSearch] = React.useState('');
    const [projectStatusses, setProjectStatusses] = useState([]);
    const [selectedProjectStatusses, setSelectedProjectStatusses] = useState([]);
    const [typeOfSearch, setTypeOfSearch] = useState('include');

    const handleSearch = (event) => {
        setSearch(event.target.value);

    };

    const columns = useMemo(() => [{
        Header : 'Projectnummer',
        accessor : 'projectNumber',
        headerClassName : 'text-left',
        Cell: row => {
            return <Link to={`/project/detail/` + row.row._original.id} target={'_parent'}>{row.row.projectNumber}</Link>
        }
    },
    {
        Header : 'Project',
        accessor : 'name',
        headerClassName : 'text-left'
    },
    {
        Header : 'Klant',
        headerClassName : 'text-left',
        accessor : 'customer',
        width: 300,
        Cell : row => {
            return <>{row.row.customer !== null ? (
                <Link to={'/user/' + row.row.customer.id + '/detail'} target={'_parent'}>{row.row.customer.firstName} {row.row.customer.lastName}</Link>
            ): ''}</>
        }
    },
    {
        Header : 'Status',
        headerClassName : 'text-left',
        accessor : 'projectStatus',
        width: 300,
        Cell : row => {
            return <>{row.row.projectStatus !== null ? (
                <>{row.row.projectStatus.name}</>
            ): ''}</>
        }
    },
    {
        id: 'address',
        Header : 'Adres',
        headerClassName : 'text-left',
        width: 300,
        accessor : project => {
            const country = project.country !== undefined && project.country !== null ? project.country.name : '';
            return `${project.street} ${project.houseNumber} ${project.bus}, ${project.postalCode} ${project.city} ${country}`;
        }
    },
    {
        Header: 'Offerten',
        headerClassName: 'text-center',
        accessor: 'numberOfOffers',
        width: 200,
        className: 'text-center',
        Cell : row => <>{row.row._original.numberOfOffers}</>
    },
    {
        Header: 'Werkbonnen',
        headerClassName: 'text-center',
        accessor: 'numberOfWorkOrders',
        width: 200,
        className: 'text-center',
        Cell : row => <>{row.row._original.numberOfWorkOrders}</>
    },
    {
        Header: 'Facturen',
        headerClassName: 'text-center',
        accessor: 'numberOfInvoices',
        width: 200,
        className: 'text-center',
        Cell : row => {
            return <>{row.row._original.numberOfInvoices} {row.row._original.numberOfPaidInvoices !== row.row._original.numberOfInvoices ? ' ('+ (parseInt(row.row._original.numberOfInvoices) - parseInt(row.row._original.numberOfPaidInvoices)) + ' te betalen)' : ''}</>
        }
    }]);

    useEffect(() => {
        API.get('projectstatusses').then(function(response) {
            setProjectStatusses(response.data);
            API.get('projects/search/postalcode/' + props.match.params.postalCode).then(function(response) {
                setProjectsPerRegion(response.data);
            });
        });
    }, []);

    useEffect(() => {
        if (projectsPerRegion != null ) {
            projectsPerRegion.map((projectsOfRegion) => {
                setFiltered(projectsOfRegion.projects);
            });
        }
    }, [projectsPerRegion]);

    useEffect(() => {
        let filteredProjects = [];

        if (search != null) {
            if (projectsPerRegion != null ) {
                projectsPerRegion.map((projectsOfRegion) => {
                    filteredProjects = projectsOfRegion.projects.filter(project => {
                        return (
                            project.name.toLowerCase().includes(search.toLowerCase()) ||
                            (project.projectStatus != null ? project.projectStatus.name.toLowerCase().includes(search.toLowerCase()) : false) ||
                            project.customer.firstName.toLowerCase().includes(search.toLowerCase()) ||
                            project.customer.lastName.toLowerCase().includes(search.toLowerCase())  ||
                            project.street.toLowerCase().includes(search.toLowerCase()) ||
                            project.houseNumber.toLowerCase().includes(search.toLowerCase()) ||
                            project.bus.toLowerCase().includes(search.toLowerCase()) ||
                            project.postalCode.toLowerCase().includes(search.toLowerCase()) ||
                            project.city.toLowerCase().includes(search.toLowerCase()) ||
                            (project.country != null ? project.country.name.toLowerCase().includes(search.toLowerCase())  : false)
                        );
                    });
                });
            }
        } else {
            filteredProjects = projectsPerRegion.map((projectsOfRegion) => {
                filteredProjects = projectsOfRegion.projects;
            });
        }

        if (typeOfSearch === 'include' || typeOfSearch === 'exclude') {

            if (typeOfSearch === 'include') {
                if (selectedProjectStatusses.length > 0 ) {
                    filteredProjects = filteredProjects.filter(project => {
                        let result = false;
                        selectedProjectStatusses.map((selectedStatus) => {
                            if (!result)
                                result = project.projectStatus !== null ? project.projectStatus.id === selectedStatus.id : false;
                        });
                        return result;
                    });
                } 
            }
            if (typeOfSearch === 'exclude') {
                if (selectedProjectStatusses.length > 0 ) {
                    filteredProjects = filteredProjects.filter(project => {
                        let result = false;
                        selectedProjectStatusses.map((selectedStatus) => {
                            if (!result)
                                result = project.projectStatus !== null ? project.projectStatus.id === selectedStatus.id : false;
                        });
                        return !result;
                    });
                } 
            }   
        }
        setFiltered(filteredProjects);
    }, [search, typeOfSearch, selectedProjectStatusses]);

        return (
            <>
                <Breadcrumb parent={'Projecten'} parentLink={'/projects/overview'}>
                    Projecten in de regio van postcode {props.match.params.postalCode} 
                </Breadcrumb>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className={'row'}>
                            <div className={'col-md-12 col-sm-12'}>
                                <Card>
                                    <CardBody>
                                        {projectsPerRegion !== null && projectsPerRegion.length > 0 ? (
                                            <>
                                                
                                                    {projectsPerRegion.map((projectsOfRegion) => (
                                                        <>
                                                            {projectsOfRegion.projects.length > 0 ? (
                                                                <>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <h2>Projecten in de regio van postcode {props.match.params.postalCode} </h2>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg={12} className='mb-3'>
                                                                            <input id="search" type="text" placeholder={'Zoeken'} className={'form-control'} onChange={handleSearch} />
                                                                
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='form-group'>
                                                                        <Col lg={3}>Toonbare statussen</Col>
                                                                        <Col lg={3}>
                                                                            <select onChange={(event) => setTypeOfSearch(event.target.value)} className='form-control'>
                                                                                <option value="include">Enkel geselecteerde statussen</option>
                                                                                <option value="exclude">Alle statussen behalve geselecteerde</option>
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                        <Typeahead
                                                                            id="project-typeahead"
                                                                            onChange={(selectedArray) => {
                                                                                setSelectedProjectStatusses(selectedArray);
                                                                            }}
                                                                            labelKey="name"
                                                                            multiple
                                                                            options={projectStatusses}
                                                                            placeholder="Selecteer een status ..."
                                                                        />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <ReactTable 
                                                                                pages={pages}
                                                                                data={filtered}
                                                                                columns={columns}
                                                                                minRows={0}
                                                                                manual
                                                                                defaultPageSize={10}
                                                                                className={"-striped -highlight"}
                                                                                showPagination={true}
                                                                                previousText={'Vorige'}
                                                                                nextText={'Volgende'}
                                                                                pageText={'Pagina'}
                                                                                rowsText={'rijen'}
                                                                                ofText={'van'}
                                                                                getTheadThProps={getTheadThProps}
                                                                                getTdProps={getTdProps}
                                                                                getTrProps={getTrProps}
                                                                                getPaginationProps={getPaginationProps}
                                                                            
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            ) : (
                                                                <p>
                                                                    Er zijn geen projecten gevonden in de regio van {props.match.params.postalCode} 
                                                                </p>
                                                            )}
                                                        </>
                                                    ))}    
                                                
                                            </>
                                        ) : (
                                            <p>
                                                Er zijn geen projecten gevonden in de regio van {props.match.params.postalCode} 
                                            </p>
                                        )}
                                    </CardBody>
                                </Card>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default ProjectsInRegion;