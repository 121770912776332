import React, { Fragment, useEffect, useState } from 'react';

import { Row, Col, Card, CardBody} from 'reactstrap';
import moment from "moment-timezone";

import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from '@fullcalendar/react';
import nlLocale from '@fullcalendar/core/locales/nl';

import GeoCode from "react-geocode";
import scrollgridPlugin from "@fullcalendar/scrollgrid";

import API from '../../utils/API';
import SweetAlert from 'react-bootstrap-sweetalert';
import TaskDetailModal from '../tasks/taskDetailModal';

const TaskPlanningPerEmployee = (props) =>  {

    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);
    const ref = React.createRef();
    const [showTaskDialog, setShowTaskDialog] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [selectedTaskProjectNotes, setSelectedTaskProjectNotes] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        API.get('myuser').then(function(response) {
            setUser(response.data[0]);
        });
    }, []);

    useEffect(() => {
        if (user !== null) {
            API.get('users/workers').then(function(response) {
                let tmpResourcesArray = [];
                response.data.map(user => 
                    tmpResourcesArray.push({id: user.id, title: user.firstName +  ' ' + user.lastName, eventBackgroundColor: user.colorCode})
                );
                setResources(tmpResourcesArray);
            });
        }
    }, [user]);

    const getTasks = (date) => {
        let tmpArray = [];
        API.get('/tasks?dateFrom=' + moment(date).format('YYYY-MM-DD 00:00:00') + '&dateUntil=' + moment(date).format('YYYY-MM-DD 23:59:59')).then(function(response) {
            response.data.map(task => {
                
                if (task.responsibleUser !== null ) {
                    tmpArray.push({
                        resourceId: task.responsibleUser !== null ? task.responsibleUser.id : task.responsibleUsers[0].id,
                        title: task.name,
                        taskId: task.id,
                        status: task.status,
                        backgroundColor: task.responsibleUser !== null ? task.responsibleUser.colorCode : task.responsibleUsers[0].colorCode,
                        color: task.responsibleUser !== null ? task.responsibleUser.colorCode : task.responsibleUsers[0].colorCode,
                        start: new Date(task.plannedDateForExecution),
                        end: new Date(task.plannedEndDateForExecution),
                        blurred: task.blurred,
                        description: task.project != null ? task.project.description : '',
                        location: task.project != null ? task.project.postalCode + ' ' + task.project.city : '',
                        customer: task.project != null && task.project.customer != null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                    });
                } else {
                    task.responsibleUsers.map(responsibleUser => {
                        tmpArray.push({
                            resourceId: responsibleUser.id,
                            title: task.name,
                            taskId: task.id,
                            status: task.status,
                            backgroundColor: responsibleUser.colorCode,
                            color: responsibleUser.colorCode,
                            start: new Date(task.plannedDateForExecution),
                            end: new Date(task.plannedEndDateForExecution),
                            blurred: task.blurred,
                            description: task.project != null ? task.project.description : '',
                            location: task.project != null ? task.project.postalCode + ' ' + task.project.city : '',
                            customer: task.project != null && task.project.customer != null ? task.project.customer.firstName + ' ' + task.project.customer.lastName : '',
                        });
                    });
                }
            });
            setEvents(tmpArray);
        });
    };

    useEffect(() => {
        getTasks(new Date());
    }, [resources]);

    useEffect(() => {
        if (selectedTask !== null) {
            setShowTaskDialog(true);
        }
    }, [selectedTask]);


    const changeDateOnCalendar = (event) => {
        let newDate = new Date(event.start);
        getTasks(newDate);
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <b>{moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}</b>
                <span className='ml-2'>{eventInfo.event.title}</span><br />
                <div className={eventInfo.event.extendedProps.blurred ? 'blurred' : ''}>
                    <i><b>Klant:</b> {eventInfo.event.extendedProps.customer}</i><br />{eventInfo.event.extendedProps.backgroundColor}
                    <i><b>Locatie:</b> {eventInfo.event.extendedProps.location}</i>
                </div>
                
            </>
        );
    };

    const handleEventClick = (eventInfo) => {
        API.get('/task/' + eventInfo.event.extendedProps.taskId).then(function(response) {
            setSelectedTask(response.data);
         });
    }

    const renderResourceContent = (resourceInfo) => {
        return (<><h5><b className='mt-2 mb-2'><br />{resourceInfo.resource.title}<br /></b></h5></>)
    };

    const getContrast = (hexcolor) => {
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
        if (hexcolor.length === 3) {
            hexcolor = hexcolor.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    };

    useEffect(() => {
        if (selectedTask !== null && selectedTask.project !== null) {
            const address = selectedTask.project.street + ' ' + selectedTask.project.houseNumber + ' ' + selectedTask.project.bus + ", " + selectedTask.project.postalCode + " " + selectedTask.project.city + ", " + selectedTask.project.country;
            GeoCode.setApiKey("AIzaSyDK6dN8ajm8oGhTLtkz6Vxgj9qbyMUrfxo");
            GeoCode.fromAddress(address).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setLat(lat);
                    setLng(lng);
                }
            );
            API.get('/project/' + selectedTask.project.id + '/notes').then(function(response) {
                setSelectedTaskProjectNotes(response.data);
            });
        }
        
    }, [selectedTask]);

    return (
        <Fragment>
            {props.showDateFilter ? (
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={2}>
                                Selecteer datum
                            </Col>
                            <Col lg={4}>
                                <input type='date' onChange={changeDateOnCalendar} className='form-control' />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ) : null}
            <Card>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <h2>Dagplanning</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                        {selectedTask !== null ? (
                        <SweetAlert show={showTaskDialog} style={{width: '100%'}} title={'TAAK: ' + selectedTask.name} onConfirm={() => setShowTaskDialog(false)}>
                            <TaskDetailModal task={selectedTask} lat={lat} lng={lng} user={user} />
                        </SweetAlert>
                        ) : null}
                            <FullCalendar
                                ref={ref}
                                editable={true}
                                businessHours={{
                                    daysOfWeek: [ 1, 2, 3, 4, 5 ],
                                    startTime: '06:00',
                                    endTime: '20:00'}
                                }
                                slotTime={'06:00:00'}
                                locale={nlLocale}
                                contentHeight={500}
                                datesSet={changeDateOnCalendar}
                                datesAboveResources={true}
                                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                                initialView="resourceTimeGrid"
                                plugins={[resourceTimeGridPlugin, interactionPlugin, scrollgridPlugin]}
                                resourceLabelContent={(resourceInfo) => renderResourceContent(resourceInfo)}
                                resourceAreaWidth={'20%'}
                                resources={resources}
                                events={events}
                                eventContent={(eventInfo) => renderEventContent(eventInfo)}
                                eventClick={function (info) {
                                  handleEventClick(info);
                                }}
                                eventDidMount={function (event) {
                                  event.el.setAttribute("tabindex", 0);
                                  if (event.event.extendedProps.status === 'FINISHED') {
                                    event.el.setAttribute("style", "background-color: #aeaeae !important; color: " + getContrast('#aeaeae') + " !important");
                                  } else {
                                    event.el.setAttribute("style", "background-color: " + event.event.backgroundColor + " !important; color: " + getContrast(event.event.backgroundColor) + " !important");
                                  }
                                }}
                                navLinks={true}
                                allDaySlot={false}
                                dayMinWidth={110}
                                droppable={false}
                                selectable={false}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default TaskPlanningPerEmployee;