import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const TaskWorkOrderInformation = (props) =>  {
    const task = props.task;
    return (
        <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}>
            <CardBody>
                {task.project !== null && task.project !== undefined ? (
                    <Row className={'mt-3'}>
                        <Col xl={12}>
                            <h2 style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR}}><strong>Werkboninformatie</strong></h2>
                            <p>
                                {task.workOrder !== null && task.workOrder !== undefined ? (
                                    <p>
                                        <Link to={'/workorder/detail/' + task.workOrder.id}>{task.workOrder.name}</Link>
                                        <br />Beschrijving: {task.workOrder.description}
                                    </p>
                                ) : (
                                    <p>(Niet gekoppeld aan een werkbon)</p>
                                )}
                            </p>
                        </Col>
                    </Row>
                ) : null}
            </CardBody>
        </Card>
    );
};

export default TaskWorkOrderInformation;