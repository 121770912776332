import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {Link} from "react-router-dom";
import _ from "lodash";
import ReactTable from 'react-table-6';
import API from '../../../utils/API';
import useReactTable from '../../../hooks/useReactTable';
import Loader from '../../common/loader';

const DocumentsTaxCertificatesOverview = () =>  {
    const [taxCertificates, setTaxCertificates] = useState([]);
    const [loadingTaxCertificates, setLoadingTaxCertificates] = useState(false);
    const [loading, setLoading] = useState(false);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();
    const taxCertificateColumns = useMemo(() => [
        {
            Header : 'ID',
            accessor : 'id',
            width: 100,
            headerClassName : 'text-left',
            Cell: row => {
                return <>{row.row.id}</>
            }
        },
        {
            Header : 'Klant',
            accessor : 'project',
            width: 300,
            headerClassName : 'text-left',
            Cell: row => {
                return <>{row.row.project !== null ? row.row.project.customer.firstName : null} {row.row.project !== null ? row.row.project.customer.lastName : null}</>
            }
        },
        {
            Header : 'Project',
            accessor : 'project',
            headerClassName : 'text-left',
            Cell: row => {
                return <>{row.row.project != null ? row.row.project.name : ''}</>
            }
        },
        {
            Header: '',
            accessor: 'id',
            width: 75,
            headerClassName: 'text-center',
            Cell: row => {
                return <Link onClick={(event) => sendTaxCertificate(event, row.row.id)}><i className="icofont icofont-2x icofont-email"></i></Link>
            }
        },
        {
            Header: '',
            accessor: 'id',
            width: 75,
            headerClassName: 'text-center',
            Cell: row => {
                return <Link onClick={(event) => downloadPdf(event, row.row.id, row.row._original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
            }
        },
        {
            Header: '',
            accessor: 'id',
            width: 75,
            headerClassName: 'text-center',
            Cell: row => {
                return <Link to={'/taxcertificate/' + row.row.id + '/sign'}><i className="icofont icofont-2x icofont-pencil"></i></Link>
            }
        }
    ]);


    useEffect(() => {
        setLoadingTaxCertificates(true);
        loadTaxCertificates();
    }, []);

    const loadTaxCertificates = () => {
        API.get('taxcertificates').then(function(response) {
            setTaxCertificates(response.data);
            setLoadingTaxCertificates(false);
        });
    };

    const sendTaxCertificate = (event, taxCertificateId) => {
        event.preventDefault();
        setLoading(true);
        API.get('mail/taxcertificate/' + taxCertificateId).then(() => {
            setLoading(false);
        });
    }

    const downloadPdf = (event, taxCertificateId) => {
        event.preventDefault();
        setLoading(true);
        let me = this;
        API.get('/pdf/taxcertificate/' + taxCertificateId).then(function(response) {
            const linkId = `link-invoice-id-${taxCertificateId}`;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${taxCertificateId}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
            setLoading(false);
        });

        return false;
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {loadingTaxCertificates ? (
                    <p>Even geduld. Bezig met het laden van de BTW-attesten ...</p>
                ) : (
                    <>
                        {taxCertificates.length > 0 ? (
                        <ReactTable 
                            data={taxCertificates}
                            columns={taxCertificateColumns}
                            defaultPageSize={10}
                            className={"-striped -highlight"}
                            showPagination={true}
                            showPagination={true}
                            previousText={'Vorige'}
                            nextText={'Volgende'}
                            pageText={'Pagina'}
                            rowsText={'rijen'}
                            ofText={'van'}
                            getTheadThProps={getTheadThProps}
                            getTdProps={getTdProps}
                            getTrProps={getTrProps}
                            getPaginationProps={getPaginationProps}
                        />
                    ) : (
                        <p>Er zijn geen offerten aanwezig in het systeem</p>
                    )}
                    </>
                )}
                </>
            )}
        </>
    );
};

export default DocumentsTaxCertificatesOverview;