import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Link} from "react-router-dom";
import Loader from "../common/loader";

class TaxCertificateOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            taxCertificates : [],
            loading : false,
            columns : [
                {
                    Header : 'ID',
                    accessor : 'id',
                    width: 100,
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <>{row.row.id}</>
                    }
                },
                {
                    Header : 'Klant',
                    accessor : 'project',
                    width: 300,
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <>{row.row.project !== null ? row.row.project.customer.firstName : null} {row.row.project !== null ? row.row.project.customer.lastName : null}</>
                    }
                },
                {
                    Header : 'Project',
                    accessor : 'project',
                    headerClassName : 'text-left',
                    Cell: row => {
                        return <>{row.row.project != null ? row.row.project.name : ''}</>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link onClick={(event) => this.sendTaxCertificate(event, row.row.id)}><i className="icofont icofont-2x icofont-email"></i></Link>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link onClick={(event) => this.downloadPdf(event, row.row.id, row.row._original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link to={'/taxcertificate/' + row.row.id + '/sign'}><i className="icofont icofont-2x icofont-pencil"></i></Link>
                    }
                }
            ]
        };
    }

    componentDidMount() {
        let me = this;
        API.get('taxcertificates').then(function(response) {
            me.setState({taxCertificates: response.data});
        });
    }

    sendTaxCertificate = (event, taxCertificateId) => {
        event.preventDefault();
        this.setState({loading: true});
        API.get('mail/taxcertificate/' + taxCertificateId).then(() => {
            this.setState({loading: false});
        });
    }

    downloadPdf = (event, taxCertificateId) => {
        event.preventDefault();
        this.setState({loading: true});
        let me = this;
        API.get('/pdf/taxcertificate/' + taxCertificateId).then(function(response) {
            const linkId = `link-invoice-id-${taxCertificateId}`;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${taxCertificateId}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
            me.setState({loading: false});
        });

        return false;
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="BTW-attesten" title="Overzicht van BTW-attesten" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                            {this.state.loading ? (
                                <Loader />
                            ) : (
                                <div className="col-lg-12">
                                    <ReactTable onChange={this.componentDidMount}
                                                data={this.state.taxCertificates}
                                                columns={this.state.columns}
                                                defaultPageSize={10}
                                                className={"-striped -highlight"}
                                                showPagination={true}
                                                showPagination={true}
                                                previousText={'Vorige'}
                                                nextText={'Volgende'}
                                                pageText={'Pagina'}
                                                rowsText={'rijen'}
                                                ofText={'van'}
                                                getTheadThProps={(state, rowInfo, column) => ({
                                                    style: {
                                                        fontWeight: '600',
                                                        height: '50px'
                                                    },
                                                })}
                                                getTdProps={(state, rowInfo, column) => ({
                                                    style: {
                                                        height: '40px',
                                                    },
                                                })}
                                    />
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default TaxCertificateOverview;