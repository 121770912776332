import React from 'react';
import {Link} from "react-router-dom";
import ReactTable from "react-table-6";


const UserProjects = (props) => {   
    
    const projectColumns =  [
            {
                Header : 'Project',
                headerClassName: 'text-left',
                accessor : 'name',
                width: 250,
                Cell: row => {
                    return <Link to={`/project/detail/` + row.row._original.id} target={'_parent'}>{row.row.name}</Link>
                }
            },
            {
                Header : 'Beschrijving',
                headerClassName: 'text-left',
                accessor : 'shortDescription'
            },
            {
                id: 'address',
                headerClassName: 'text-left',
                Header : 'Adres',
                accessor : project => `${project.street} ${project.houseNumber} ${project.bus}`
            },
            {
                Header : 'Postcode',
                headerClassName: 'text-left',
                accessor : 'postalCode'
            },
            {
                Header : 'Postcode',
                headerClassName: 'text-left',
                accessor : 'city'
            },
            {
                Header : 'Land',
                headerClassName: 'text-left',
                accessor : 'country',
                Cell: row => {
                    if (row.row.country !== undefined && row.row.country !== null) {
                        return <span>{row.row.country.name}</span>
                    } else {
                        return <span></span>
                    }
                }
            }
        ];

    return (
        <>
            <ReactTable
                data={props.projects}
                columns={projectColumns}
                minRows={1}
                defaultPageSize={10}
                className={"-striped -highlight"}
                showPagination={true}
            />
        </>
    );

}

export default UserProjects;