import React, {Fragment, useState} from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import _ from "lodash";
import {Button, Card, CardBody, CardTitle, Col, Input, Row} from "reactstrap";
import SignatureCanvas from "../projects/signatureCanvas";

class TaxCertificateSigning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : null,
            taxCertificate : null,
            agreementTypes : [],
            locationUsages: [],
            projectRelationships : [],
            selectedTaxCertificate : null,
            selectedAgreementType : null,
            selectedLocationUsage : null
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        var me = this;
        API.get('myuser').then(function(response) {
            me.setState({user: response.data[0]});
        });
        API.get('taxcertificate/' + id).then(function(response) {
            me.setState({taxCertificate: response.data});
        });

        API.get('config/agreementtypes').then(function(response) {
            me.setState({agreementTypes: response.data});
        });

        API.get('config/locationusages').then(function(response) {
            me.setState({locationUsages: response.data});
        });

        API.get('config/projectrelationships').then(function(response) {
            me.setState({projectRelationships: response.data});
        });

    }

    signTaxCertificate = (event) =>  {
        event.preventDefault();
        const { id } = this.props.match.params;
        let me = this;
        let locationUsageId = 0;
        me.state.locationUsages.map((locationUsage) => {
           if (me.state.selectedLocationUsage === locationUsage.name) {
               locationUsageId = locationUsage.id;
           }
        });
        let agreementTypeId = 0;
        me.state.agreementTypes.map((agreementType) => {
            if (me.state.selectedAgreementType === agreementType.name) {
                agreementTypeId = agreementType.id;
            }
        });
        let projectRelationshipId = 0;
        me.state.projectRelationships.map((projectRelationship) => {
            if (me.state.selectedProjectRelationship === projectRelationship.name) {
                projectRelationshipId = projectRelationship.id;
            }
        });


        API.put('/taxcertificate/' + id + '/sign', {
            signature: this.refs.sigCanvas.toDataURL(),
            locationUsageId: locationUsageId,
            agreementTypeId: agreementTypeId,
            projectRelationshipId: projectRelationshipId
        }).then(function(response) {
            me.props.history.push('/project/detail/' + me.state.taxCertificate.project.id);
        });
    };

    changeLocationUsage = (event) => {
        event.preventDefault();
        this.setState({selectedLocationUsage: event.target.value});
    }

    changeAgreementType = (event) => {
        event.preventDefault();
        this.setState({selectedAgreementType: event.target.value});
    }

    changeProjectRelationship = (event) => {
        event.preventDefault();
        let me = this;
        this.setState({selectedProjectRelationship: event.target.value}, function() {
            console.log(me.state);
        });
    }

        render() {
        return (
            <>
                <Breadcrumb parent="BTW attesten" title={'Teken BTW-attest van project "' + ( this.state.taxCertificate !== null &&!(_.isEmpty(this.state.taxCertificate.project)) ? this.state.taxCertificate.project.name : '') + '"'} />
                <div className="container-fluid">

                    {this.state.user && (this.state.user.userRole === 'ADMIN' || this.state.user.userRole === 'EMPLOYER' || this.state.user.userRole === 'EMPLOYEE' ? (
                        <>

                            <Row>
                                <Col xs={12}>
                                    <h2>Selecteer de parameters van het BTW-attest:</h2>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col xs={3}><label className={'control-label'}>Gebruik van locatie waar de werken gebeuren:</label></Col>
                                <Col xs={9}>
                                    <select className={'form-control'} value={this.state.selectedLocationUsage} onChange={e=> this.changeLocationUsage(e)}>
                                        <option value={null}> --- </option>
                                        {this.state.locationUsages.map(locationUsage =>
                                            <option key={locationUsage.id} value={locationUsage.name}>{locationUsage.name}</option>
                                        )}
                                    </select>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col xs={3}><label className={'control-label'}>Wie geeft de opdracht voor de te uitvoeren werken:</label></Col>
                                <Col xs={9}>
                                    <select className={'form-control'} value={this.state.selectedProjectRelationship} onChange={e=>this.changeProjectRelationship(e)}>
                                       <option value={null}> --- </option>
                                        {this.state.projectRelationships.map(projectRelationship =>
                                            <option key={projectRelationship.id} value={projectRelationship.name}>{projectRelationship.name}</option>
                                        )}
                                    </select>
                                </Col>
                            </Row>
                            <Row className={'form-group'}>
                                <Col xs={3}><label className={'control-label'}>Type document waarop de keuze van btw-tarief bepaald wordt:</label></Col>
                                <Col xs={9}>
                                    <select className={'form-control'} value={this.state.selectedAgreementType} onChange={e=>this.changeAgreementType(e)}>
                                        <option value={null}> --- </option>
                                        {this.state.agreementTypes.map(agreementType =>
                                            <option key={agreementType.id} value={agreementType.name}>{agreementType.name}</option>
                                        )}
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="user-profile">
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        {this.state.taxCertificate !== null ? (
                                                            <>
                                                            {_.isEmpty(this.state.taxCertificate.customerSignature) ? (
                                                                <>
                                                                    <div style={{width:'800px', marginRight:'auto',marginLeft:'auto', border: '5px solid black', padding: '2em'}}>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                <h2 style={{textAlign: 'center'}}><strong>6% btw-tarief voor renovatiewerken aan woningen
                                                                                    ouder dan 10 jaar</strong></h2>
                                                                                <h3 style={{textAlign: 'center'}}><strong>ATTEST</strong></h3>
                                                                                <p>
                                                                                    Toepassing van het verlaagd btw-tarief van 6% op werken in onroerende staat aan privéwoningen krachtens de bepalingen van de rubrieken XXXI en XXXVIII van het koninklijk besluit{' '}
                                                                                    nr. 20 van 20 juli 1970 tot vaststelling van de tarieven van de belasting over de toegevoegde{' '}
                                                                                    waarde en tot indeling van de goederen en diensten bij die tarieven.
                                                                                </p>
                                                                                <p>
                                                                                    Ondergetekende <strong><i>{this.state.taxCertificate.project.customer.firstName} {this.state.taxCertificate.project.customer.lastName}</i></strong>{' '}
                                                                                    wonende te <strong><i>{this.state.taxCertificate.project.customer.city} ({this.state.taxCertificate.project.customer.country.name})</i></strong>{' '}
                                                                                    verklaart dat het gebouw gelegen te <strong><i>{this.state.taxCertificate.project.street} {this.state.taxCertificate.project.houseNumber} {this.state.taxCertificate.project.bus}</i></strong>{' '}
                                                                                    in <strong><i>{this.state.taxCertificate.project.city} ({this.state.taxCertificate.project.postalCode}{' '}
                                                                                    {this.state.taxCertificate.project.country !== undefined && this.state.taxCertificate.project.country !== null ? ' - ' + this.state.taxCertificate.project.country.name : ''})</i></strong> waarvan hij/zij{' '}
                                                                                    <strong><i>{this.state.selectedProjectRelationship !== null && this.state.selectedProjectRelationship !== undefined ? _.lowerCase(this.state.selectedProjectRelationship) : <span style={{color: 'red', border: '1px solid red'}}>[ NOG IN TE VULLEN]</span>}</i></strong>{' '}
                                                                                     is, effectief in gebruik is sedert ten minste 10 jaar voor het eerste tijdstip van de verschuldigheid van de btw{' '}
                                                                                    op de werken die worden uitgevoerd overeenkomstig{' '}
                                                                                    <strong><i>{this.state.selectedAgreementType !== null && this.state.selectedAgreementType !== undefined ? _.lowerCase(this.state.selectedAgreementType): <span style={{color: 'red', border: '1px solid red'}}>[ NOG IN TE VULLEN]</span>}</i></strong>{' '}
                                                                                     die werd ondertekend op <strong><i>{new Intl.DateTimeFormat("nl-BE", {
                                                                                    year: "numeric",
                                                                                    month: "long",
                                                                                    day: "2-digit"
                                                                                }).format(new Date())}</i></strong> en na de uitvoering van de vermelde werken effectief en{' '}
                                                                                    <strong><i>{this.state.selectedLocationUsage !== null && this.state.selectedLocationUsage !== undefined ? _.lowerCase(this.state.selectedLocationUsage) : <span style={{color: 'red', border: '1px solid red'}}>[ NOG IN TE VULLEN]</span> }</i></strong>{' '}
                                                                                    zal gebruikt worden.
                                                                                </p>
                                                                                <p>
                                                                                    Ondergetekende verklaart te weten dat een foutieve verklaring aanleiding kan geven tot {' '}
                                                                                    supplementaire BTW, boeten en interesten, waarvoor de ondertekende verantwoordelijk is, {' '}
                                                                                    ingevolge het Koninklijk Besluit nr. 20 van 20 juli 1970. <br />
                                                                                    Deze bedragen zullen hem integraal ten laste worden gelegd, indien ze verschuldigd blijken te {' '}
                                                                                    zijn volgens de BTW-administratie ingevolge van de wetgeving.
                                                                                </p>
                                                                                <p className={'mt-5'}>
                                                                                    Opgemaakt in 2 exemplaren te {this.state.taxCertificate.project.city} ({this.state.taxCertificate.project.postalCode}
                                                                                    {this.state.taxCertificate.project.country !== undefined && this.state.taxCertificate.project.country !== null ? ' - ' + this.state.taxCertificate.project.country.name : ''})<br />
                                                                                    Datum {new Intl.DateTimeFormat("nl-BE", {
                                                                                    year: "numeric",
                                                                                    month: "long",
                                                                                    day: "2-digit"
                                                                                }).format(new Date())}<br />
                                                                                </p>
                                                                                <p className={'mt-5'}></p>
                                                                                <p className={'mt-5'} style={{textAlign: 'center'}}>
                                                                                    <h6><strong>TERUG TE ZENDEN NAAR DE AANNEMER DIE DE WERKEN HEEFT UITGEVOERD</strong></h6>
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <Row className={'mt-5'}>
                                                                        <Col lg={12}>
                                                                            Plaats in de onderstaande rechthoek je handtekening. <br />
                                                                            Door het tekenen verklaar je dat je dit document gelezen en goedgekeurd hebt.
                                                                            Na tekenen wordt er een e-mail met het getekende document naar jou en de uitvoerder van het project verstuurd.
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className={'pt-3'}>
                                                                        <Col lg={12}>
                                                                            <SignatureCanvas ref={'sigCanvas'}
                                                                                             penColor='black'
                                                                                             canvasProps={{height: 200, className: 'sigCanvas'}} />
                                                                            <button onClick={(e) => this.signTaxCertificate(e)} className={'btn btn-primary'}>Teken BTW-attest</button>
                                                                        </Col>
                                                                    </Row>
                                                            </>
                                                            ) : (
                                                                <>
                                                                    {this.state.taxCertificate.project !== null && this.state.taxCertificate.project !== undefined ? (
                                                                        <>
                                                                            <h2>Project '{this.state.taxCertificate.project.name}'</h2>
                                                                            <p>
                                                                                Dit BTW-attest is al gehandtekend.
                                                                            </p>
                                                                            <img src={this.state.taxCertificate.project.customerSignature} />
                                                                        </>
                                                                    ) : null}

                                                                </>
                                                            )}

                                                            </>
                                                        ) : null}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col lg={12}>
                                U heeft onvoldoende rechten om deze pagina te bekijken. Indien je wel de nodige rechten zou moeten hebben, gelieve je systeembeheerder te contacteren.
                            </Col>
                        </Row>
                    )) }


                </div>
            </>
        );
    }
};

export default TaxCertificateSigning;