import React, { Fragment } from 'react';
import CKEditors from "react-ckeditor-component";
import _ from "lodash";
import API from "../../../utils/API";

class Contactinformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact : {
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : "",
                alternativeEmail : "",
                alternativePhone : "",
                alternativeMobile : ""
            },
            errors : {
                primaryEmail : "",
                primaryPhone : "",
                primaryMobile : ""
            },
            typing: false,
            typingTimeout: 0
        };

        this._validateOnDemand = true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated = () => {
        let isDataValid = this.validationCheck();
        if (isDataValid) {
            this.props.updateContactStore(this.state.contact);
        }
        return isDataValid;
    };

    validationCheck = async () => {
        let isValid = true;
        if (_.isEmpty(this.state.contact.primaryEmail)) {
            this.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "E-mailadres is verplicht" }}));
            isValid = false;
        } else {
            let response = await API.get('uniqueuser?username=' + this.state.contact.primaryEmail);
            let me =this;
            if (response.data.unique.toString() === 'false') {
                me.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "E-mailadres moet uniek zijn" }}));
                isValid = false;
            } else {
                me.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "" }}));
            }
        }
        return isValid;
    };

    changePrimaryEmail = (event) => {
        let value = event.target.value;

        this.setState((prevState) => ({contact : { ...prevState.contact, primaryEmail: value}}));

        const me = this;
        if (me.state.typingTimeout) {
            clearTimeout(me.state.typingTimeout);
        }
        me.setState({
            name: value,
            typing: false,
            typingTimeout: setTimeout(async function () {
                let response = await API.get('uniqueuser?username=' + value);
                if (response.data.unique.toString() === 'false') {
                    me.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "E-mailadres moet uniek zijn" }}));
                } else {
                    me.setState((prevState) => ({errors: { ...prevState.errors, primaryEmail : "" }}));
                }
            }, 5000)
        });
    };

    changePrimaryPhone = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, primaryPhone : value }
        }));
    };

    changePrimaryMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, primaryMobile : value }
        }));
    };

    changeAlternativeEmail = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, alternativeEmail : value }
        }));
    };

    changeAlternativePhone = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, alternativePhone : value }
        }));
    };

    changeAlternativeMobile = (event) => {
        let value = event.target.value;
        this.setState((prevState) => ({
            contact: { ...prevState.contact, alternativeMobile : value }
        }));
    };

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Primair e-mailadres</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.primaryEmail} onChange={this.changePrimaryEmail} />
                                    {this.state.errors.primaryEmail ? <span className={'errorInForm'}>{this.state.errors.primaryEmail}</span> : null }
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Primair telefoon</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.primaryPhone} onChange={this.changePrimaryPhone} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Primair GSM</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.primaryMobile} onChange={this.changePrimaryMobile} />
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Alternatief e-mailadres</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.alternativeEmail} onChange={this.changeAlternativeEmail} />
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Alternatief telefoon</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.alternativePhone} onChange={this.changeAlternativePhone} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Alternatief GSM</label>
                                    <input className="form-control" type="text" name="primaryEmail" value={this.state.contact.alternativeMobile} onChange={this.changeAlternativeMobile} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default Contactinformation;