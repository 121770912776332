import React, { useState, useRef } from 'react';
import API from "../../utils/API";
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import ReactStars from "react-rating-stars-component";
import Ckeditor from 'react-ckeditor-component/lib/ckeditor';
import SignatureCanvas from "./../projects/signatureCanvas";
import SweetAlert from 'react-bootstrap-sweetalert';

const EvaluationReportModal = (props) =>  {
    const [nameOfSigner, setNameOfSigner] = useState(null);
    const [rating, setRating] = useState(null);
    const [workApproved, setWorkApproved] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const signatureRef = useRef(null);
    const [sign, setSign] = useState();
    const ratingChanged = (newRating) => {
        setRating(newRating);
      };

      const sendEvaluation = () => {
        props.close();
        API.put('task/' + props.taskId + '/evaluate', {
            remarks,
            rating,
            nameOfSigner,
            agreedWithExecutedWork : workApproved,
            signature: sign
        }).then(function() {
            props.updateTask();
        });
    };

    return (
        <SweetAlert show={props.showEvaluationReport} style={{width: '100%'}} title={'Evaluatie taak ' + props.taskName} onConfirm={() => sendEvaluation()} >
            <Card>
                <CardBody>
                    <Row className='mt-2'>
                        <Col lg={3}>
                            <h5>Tevredenheid over de werken</h5>
                        </Col>
                        <Col lg={9}>
                        <ReactStars
                            count={10}
                            onChange={ratingChanged}
                            size={50}
                            isHalf={false}
                            emptyIcon={<i className="icofont icofont-ui-rate-blank"></i>}
                            filledIcon={<i className="icofont icofont-ui-rating"></i>}
                            activeColor="#ffd700"
                        />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col lg={3}>
                            <h5>Akkoord met de uitgevoerde werken?</h5>
                        </Col>
                        <Col lg={9}>
                            <input type="radio" value="1" id="yes"
                                onChange={() => setWorkApproved(true)} name="yes" checked={workApproved === true} />
                            <label className='ml-2' for="yes">Ja</label>

                            <input className='ml-5' type="radio" value="0" id="no"
                                onChange={() => setWorkApproved(false)} name="no" checked={workApproved === false} />
                            <label className='ml-2' for="no">Neen</label>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col lg={12}>
                            <h5>Opmerkingen</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Ckeditor content={remarks} events={{"change" : (evt) => setRemarks(evt.editor.getData())}} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col lg={12}>
                            <h5>Gehandtekend door</h5>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col lg={12}>
                            <input type={'text'} name="signedBy" value={nameOfSigner} onChange={(e) => setNameOfSigner(e.target.value)} className='form-control' />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <h5>Handtekening voor akkoord</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className={'d-block d-sm-none'}>
                            <SignatureCanvas ref={signatureRef}
                                onEnd={() => setSign(signatureRef.current.toDataURL())}
                                penColor='black'
                                canvasProps={{height: 800, className: 'sigCanvas'}} />
                        </Col>
                        <Col lg={12} className={'d-none d-sm-block'}>
                            <SignatureCanvas ref={signatureRef}
                                onEnd={() => setSign(signatureRef.current.toDataURL())}
                                penColor='black'
                                canvasProps={{height: 400, className: 'sigCanvas'}} />
                        </Col>
                    </Row>
                    <Button onClick={() => sendEvaluation()}>Evalueer</Button>
                </CardBody>
            </Card>
        </SweetAlert>
    );
};

export default EvaluationReportModal;