import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import Loader from '../common/loader';
import useReactTable from '../../hooks/useReactTable';
import {Link, useHistory } from "react-router-dom";
import {Col, Input, Row} from "reactstrap";
import { useForm, useFormContext } from 'react-hook-form';

const HolidayCreate = () =>  {
    const history = useHistory();
    const {register, setValue} = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [name, setName] = useState('');
    const [startDay, setStartDay] = useState();
    const [startHour, setStartHour] = useState();
    const [endDay, setEndDay] = useState();
    const [endHour, setEndHour] = useState();

    useEffect(() => {
        loadCurrentUser();
    }, []);

    useEffect(() => {
        if (currentUser != null) {
            setIsLoading(false);
        }
    }, [currentUser]);

    const loadCurrentUser = () => {
        API.get('myuser').then(function(response) {
            if (response.data !== undefined) {
                setCurrentUser(response.data[0]);
            }
        });
    }

    const createHoliday = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = {
            name,
            startDay,
            startHour,
            endDay,
            endHour
        };
        API.post('holiday', formData).then(function() {
            history.push('/holidays/overview');
            setIsLoading(false);
        });
    }

    return (
        <Fragment>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Row>
                        <Col lg={6}>
                            <Breadcrumb parent="Projecten" title="Overzicht van verlofdagen" />
                        </Col>
                        <Col lg={6} className={'text-right'}>
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER') ? (
                                    <div className={'row mt-3'}>
                                        <div className={'col-md-12'}>
                                            <Link to={'/projects/create'} className={'btn btn-primary'}>Nieuwe verlofdag aanmaken</Link>
                                        </div>
                                    </div>
                                ) : null}
                        </Col>
                    </Row>
                
                    <div className="container-fluid">
                        <div className="edit-profile">
                            {currentUser !== null && (currentUser.userRole === 'ADMIN' ||  currentUser.userRole === 'EMPLOYER' || currentUser.userRole === 'EMPLOYEE') ? (
                                <div className="row">
                                    <div className="col-lg-12">
                                    <input type={'text'} className={'form-control'} name="name" ref={register} onChange={event => setName(event.target.value)} />
                                    <input type={'date'} className={'form-control digits'} name="startDay" ref={register} onChange={event => setStartDay(event.target.value)} />
                                    <input type={'time'} className={'form-control digits'} name="startHour" ref={register} onChange={event => setStartHour(event.target.value)} />
                                    <input type={'date'} className={'form-control digits'} name="endDay" ref={register} onChange={event => setEndDay(event.target.value)} />
                                    <input type={'time'} className={'form-control digits'} name="endHour" ref={register} onChange={event => setEndHour(event.target.value)} />
                                    <button className={'btn btn-primary'} onClick={createHoliday}>Submit</button>
                                    </div>
                                </div>
                                
                            ) : null}

                            
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default HolidayCreate;