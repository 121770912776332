import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import _ from "lodash";
import useReactTable from '../../hooks/useReactTable';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const TaskProjectInformation = (props) =>  {

    const task = props.task;

    return (
        <Card style={{backgroundColor: process.env.REACT_APP_INFO_MESSAGE_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR}}>
            <CardBody>
                {task.project !== null && task.project !== undefined ? (
                    <Row className={'mt-3'}>
                        <Col xl={12}>
                            <h2><strong>Projectinformatie</strong></h2>
                            <p>
                                {task.project !== null && task.project !== undefined ? (
                                    <p>
                                        <Link to={'/project/detail/' + task.project.id}>{task.project.name}</Link>
                                        <br />Korte beschrijving: {task.project.shortDescription}
                                    </p>
                                ) : (
                                    <p>(Niet gekoppeld aan een project)</p>
                                )}
                            </p>
                        </Col>
                    </Row>
                ) : null}
            </CardBody>
        </Card>
    );
};

export default TaskProjectInformation;