import React, { useState, useEffect } from 'react';
import {Col, Row} from "reactstrap";
import API from '../../../utils/API';
import _ from "lodash";
import { PenTool, Trash2 } from 'react-feather';
import CKEditors from "react-ckeditor-component";
import Loader from '../../common/loader';

const ProjectNotes = (props) =>  {
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.project !== null) {
            API.get('/project/' + props.project.id + '/notes').then(function(response) {
                setNotes(response.data);
            });
        }
    }, [props.project]);

    const addNote = (note) => {
        setIsLoading(true);
        API.post('/project/' + props.project.id + '/note', {
            note
        }).then(function() {
            API.get('/project/' + props.project.id + '/notes').then(function(response) {
                setNotes(response.data);
                setIsLoading(false);
            });
        });
    };

    const deleteNote = (noteId) => {
        setIsLoading(true);
        API.delete('/project/' + props.project.id + '/note/' + noteId).then(function() {
            API.get('/project/' + props.project.id + '/notes').then(function(response) {
                setNotes(response.data);
                setIsLoading(false);
            });
        });
    }

    return (
        isLoading ? (
            <Loader />
        ) : (
            <>
            <Row className={'mt-3'}>
                <Col lg={12}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <br />
                            
                            {notes.map(note => {
                                return (
                                    <div className="card bg-primary">
                                        <div className="card-body">
                                            <div className="media">
                                                <PenTool />
                                                <div className="media-body pl-3">
                                                    <p dangerouslySetInnerHTML={{__html: note.note}}></p>
                                                </div>
                                                <button className='btn btn-primary' onClick={() => deleteNote(note.id)}><Trash2 /></button>
                                            </div>
                                        </div>
                                    </div>
                              
                                )
                            })}
                            
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10'>
                            <CKEditors
                                activeclassName="p10"
                                content={note}
                                events={{
                                    "change": event => setNote(event.editor.getData())
                                }}
                            />
                        </div>
                        <div className='col-md-2'>
                            <button className='form-control bg-primary' onClick={() => addNote(note)}>Notitie toevoegen</button> 
                        </div>
                    </div>
                    <br />
                </Col>
            </Row>
        </>
        )
    );
};

export default ProjectNotes;