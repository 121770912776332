import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import Personalinformation from "./steps/personalinformation";
import Contactinformation from "./steps/contactinformation";
import Invoicedata from "./steps/invoicedata";
import ConfirmCustomerCreation from "./steps/confirmCustomerCreation";
import StepZilla from "react-stepzilla/dist/main";


class UserCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : null
        };
        this.personStore = {
            firstName : "",
            lastName : "",
            street : "",
            houseNumber : "",
            bus : "",
            postalCode : "",
            city : "",
            country : "",
            extraInformation : ""
        };
        this.contactStore = {
            primaryEmail : "",
            primaryPhone : "",
            primaryMobile : "",
            alternativeEmail : "",
            alternativePhone : "",
            alternativeMobile : ""
        };
        this.invoiceDataStore = {
            companyName : "",
            vatNumber : "",
            cbeNumber : "",
            street : "",
            houseNumber : "",
            bus: "",
            postalCode : "",
            city : "",
            country : ""
        };
    }

    saveNewCustomer = () => {
        const user = {
            firstName : this.personStore.firstName,
            lastName : this.personStore.lastName,
            street : this.personStore.street,
            houseNumber : this.personStore.houseNumber,
            bus : this.personStore.bus,
            postalCode : this.personStore.postalCode,
            city : this.personStore.city,
            country : this.personStore.country,
            extraInformation : this.personStore.extraInformation,
            primaryEmail : this.contactStore.primaryEmail,
            primaryPhone : this.contactStore.primaryPhone,
            primaryMobile : this.contactStore.primaryMobile,
            alternativeEmail : this.contactStore.alternativeEmail,
            alternativePhone : this.contactStore.alternativePhone,
            alternativeMobile : this.contactStore.alternativeMobile,
            userRole : 'CUSTOMER',
            invoiceData : {
                companyName : this.invoiceDataStore.companyName,
                vatNumber : this.invoiceDataStore.vatNumber,
                cbeNumber : this.invoiceDataStore.cbeNumber,
                street : this.invoiceDataStore.street,
                houseNumber : this.invoiceDataStore.houseNumber,
                bus: this.invoiceDataStore.bus,
                postalCode : this.invoiceDataStore.postalCode,
                city : this.invoiceDataStore.city,
                country : this.invoiceDataStore.country
            }
        };
        API.post('user', user);
        this.setState({user : user});
    };

    getPersonalStore() {
        return this.personStore;
    };

    updatePersonalStore(update) {
        this.personStore = {...this.personStore, ...update};
    };

    getContactStore() {
        return this.contactStore;
    }

    updateContactStore(update) {
        this.contactStore = {...this.contactStore, ...update};
    }

    getInvoiceDataStore() {
        return this.invoiceDataStore;
    }

    updateInvoiceDataStore(update) {
        this.invoiceDataStore = {...this.invoiceDataStore, ...update};
        this.saveNewCustomer();
    }

    createNewProfile = (event) => {
        event.preventDefault();
        API.post('user', this.state.user);
    };

    render() {

        const steps = [
            {name: 'Persoonsgegevens', component : <Personalinformation userType={'CUSTOMER'} getPersonalStore={() => (this.getPersonalStore())} updatePersonalStore={(u) => {this.updatePersonalStore(u)}} />} ,
            {name: 'Contactgegevens', component : <Contactinformation getContactStore={() => {this.getContactStore()}} updateContactStore={(u) => {this.updateContactStore(u)}} />},
            {name: 'Facturatiegegevens', component : <Invoicedata personStore={() => this.getPersonalStore()} getInvoiceDataStore={() => {this.getInvoiceDataStore()}} updateInvoiceDataStore={(u) => {this.updateInvoiceDataStore(u)}} />},
            {name: 'Bevestiging', component : <ConfirmCustomerCreation user={this.state.user} />}
        ];
        return (
            <Fragment>
                <Breadcrumb parent="Gebruikersbeheer" title="Nieuwe klant aanmaken" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                                <form className="card" onSubmit={this.createNewProfile}>
                                    <div className="card-body">
                                        <StepZilla
                                            steps={steps}
                                            preventEnterSubmission={true}
                                            nextTextOnFinalActionStep={"Klant opslaan"}
                                            nextButtonText={"Volgende stap"}
                                            backButtonText={"Vorige stap"}
                                            hocValidationAppliedTo={[]}
                                            startAtStep={0}
                                            onStepChange={step => {
                                                console.log(this.personStore, this.contactStore, this.invoiceDataStore);
                                            }

                                            }
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default UserCreate;