import React, { useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { instagramTabsStylesHook } from '@mui-treasury/styles/tabs';
import {Typeahead} from "react-bootstrap-typeahead";
import UserDetailCard from './userDetailCard';
import Loader from '../common/loader';
import UserProjects from './userProjects';
import UserInvoices from './userInvoices';
import UserTaxAttests from './userTaxAttests';
import { FormLabel } from 'react-bootstrap';
import { ChromePicker } from 'react-color';


const UserDetail = (props) => {

    const [id] = useState(props.match.params.id);

    const [editModusLoginData, setEditModusLoginData] = useState(false);
    const [editModusContactData, setEditModusContactData] = useState(false);
    const [editModusGeneralInformation, setEditModusGeneralInformation] = useState(false);
    const [editModusInvoiceData, setEditModusInvoiceData] = useState(false);

    const [tabValue, setTabValue] = useState(0);
    const tabItemStyles = instagramTabsStylesHook.useTabItem({bgColor: '#ececec', selectedBgColor: '#dc6809'});

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    const [loginUser, setLoginUser] = useState(null);
    const [editableLoginUser, setEditableLoginUser] = useState(null);
    const [baseUser, setBaseUser] = useState(null);
    const [editableBaseUser, setEditableBaseUser] = useState(null);
    const [contactUser, setContactUser] = useState(null);
    const [editableContactUser, setEditableContactUser] = useState(null);
    const [invoicingUser, setInvoicingUser] = useState(null);
    const [editableInvoicingUser, setEditableInvoicingUser] = useState(null);
    const [countries, setCountries] = useState([]);

    const [projects, setProjects] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [taxCertificates, setTaxCertificates] = useState([]);
    const onChangeBaseUser = ({target : {name, value}}) => {setEditableBaseUser(prevUser => ({...prevUser, [name] : value}))};
    const onChangeContactUser = ({target : {name, value}}) => {setEditableContactUser(prevUser => ({...prevUser, [name] : value}))};
    const onChangeInvoicingUser = ({target : {name, value}}) => {setEditableInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, [name] : value}}))};
    const changeIsBusinessAccount = ({target : {name, checked}}) => {setEditableInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, [name] : checked}}))};
    const changeBaseCountry = (selectedArray) => {setEditableContactUser(prevUser => ({...prevUser, country : selectedArray[0]}))};
    const changeInvoiceCountry = (selectedArray) => {setEditableInvoicingUser(prevUser => ({...prevUser, invoiceData : {...prevUser.invoiceData, country : selectedArray[0]}}))};
    
    

    useEffect(() => {
        API.get('user/' + id).then(function(response) {
            setUser(response.data);
            setContactUser(response.data);
            setInvoicingUser(response.data);
            setBaseUser(response.data);
            setLoginUser(response.data);
            setEditableLoginUser(response.data);
            setEditableBaseUser(response.data);
            setEditableContactUser(response.data);
            setEditableInvoicingUser(response.data);
        });

        API.get('user/' + id +  '/uploadedinvoices').then(function(response) {
           setInvoices(response.data);
        });

        API.get('user/' + id + '/taxcertificates').then(function(response) {
            setTaxCertificates(response.data);
        });

        API.get('user/' + id + '/projects').then(function(response) {
           setProjects(response.data);
        });
        API.get('config/countries').then(function(response) {
            setCountries(response.data);
        });
    }, [id]);

    useEffect(() => {
        if (user !== null) {
            setIsLoading(false);
        }
    }, [user]);


    const saveContactData = () => {
        API.put('user/' + id + '/contact', {
            primaryEmail : editableContactUser.primaryEmail,
            primaryPhone : editableContactUser.primaryPhone,
            primaryMobile : editableContactUser.primaryMobile,
            alternativeEmail : editableContactUser.alternativeEmail,
            alternativePhone : editableContactUser.alternativePhone,
            alternativeMobile : editableContactUser.alternativeMobile
        }).then(function() {
            setEditModusContactData(false);
            setContactUser(editableContactUser);
        });
    };

    const saveInvoicingData = () => {
        if (invoicingUser.invoiceData) {
            API.put('user/' + id + '/invoicedata', {
                isBusinessAccount : editableInvoicingUser.invoiceData.businessAccount,
                organisationName : editableInvoicingUser.invoiceData.organisationName,
                vatNumber : editableInvoicingUser.invoiceData.vatNumber,
                street : editableInvoicingUser.invoiceData.street,
                houseNumber : editableInvoicingUser.invoiceData.houseNumber,
                bus : editableInvoicingUser.invoiceData.bus,
                postalCode : editableInvoicingUser.invoiceData.postalCode,
                city : editableInvoicingUser.invoiceData.city,
                country : editableInvoicingUser.invoiceData.country ? editableInvoicingUser.invoiceData.country.id : 0
            }).then(function() {
                setEditModusInvoiceData(false);
                setInvoicingUser(editableInvoicingUser);
            });
        }
    };

    const saveLoginData = () => {
        API.put('user/' + id + '/login', {
            username : editableLoginUser.username,
            canLogIn : editableLoginUser.enabled,
            colorCode: editableLoginUser.colorCode,
            googleAuthenticatorEnabled: editableLoginUser.googleAuthenticatorEnabled
        }).then(function() {
            setEditModusLoginData(false);
            setLoginUser(editableLoginUser);
        });
    };

    const saveBaseData = () => {
        API.put('user/' + id + '/base', {
            firstName : editableBaseUser.firstName,
            lastName : editableBaseUser.lastName,
            street : editableBaseUser.street,
            houseNumber : editableBaseUser.houseNumber,
            bus : editableBaseUser.bus,
            postalCode : editableBaseUser.postalCode,
            city : editableBaseUser.city,
            country : editableBaseUser.country ? editableBaseUser.country.id : 0,
            extraInformation : editableBaseUser.extraInformation
        }).then(function() {
            setEditModusGeneralInformation(false);
            setBaseUser(editableBaseUser);
        });
    };


    return (
        isLoading ? (
            <Loader />
        ) : (
            user != null ? (
                <>
                    <Breadcrumb parent="Gebruikers" parentLink={'/users/overview'} title="Gebruikersprofiel">
                        {user.firstName + ' ' + user.lastName}
                    </Breadcrumb>

                    <Row>
                        <Col lg={3}>
                            <UserDetailCard user={user}></UserDetailCard>
                        </Col>
                        <Col lg={9}>
                            <Row>
                                <Col lg={12}>
                                <Toolbar style={{ minHeight: 72 }}>
                                    <Tabs 
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        value={tabValue} 
                                        onChange={(e, index) => setTabValue(index)}
                                        >
                                            <Tab classes={tabItemStyles} label={"Algemeen"}></Tab>
                                            <Tab classes={tabItemStyles} label={"Projecten"}></Tab>
                                            <Tab classes={tabItemStyles} label={"Facturen"}></Tab>
                                            <Tab classes={tabItemStyles} label={"BTW-attesten"}></Tab>
                                    </Tabs>
                                </Toolbar>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                {tabValue === 0 ? (
                                    <>
                                        <Row>
                                            <Col lg={4}>
                                                <Card>
                                                    <CardBody>
                                                    <Row>
                                                        <Col lg={4}><h2>Login</h2></Col>
                                                        <Col lg={8} className='text-right'>
                                                            {editModusLoginData ? (
                                                                <>
                                                                    <Button onClick={() => setEditModusLoginData(!editModusLoginData)}>Annuleren</Button>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <Button onClick={() => saveLoginData()}>Opslaan</Button>
                                                                </>
                                                                
                                                            ) : (
                                                                <Button onClick={() => setEditModusLoginData(!editModusLoginData)}>Wijzig</Button>
                                                            )}
                                                        
                                                        </Col>
                                                    </Row>
                                                    {editModusLoginData ? (
                                                        <>
                                                            {loginUser ? (
                                                                <form style={{width: '100%'}}>
                                                                    <div className="row mb-2">
                                                                        <div className="col">
                                                                            <p className="mb-4">{editableLoginUser.userRole}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />

                                                                    <Row className='mt-2'>
                                                                        <Col md={12} lg={4}>
                                                                            <FormLabel><strong>Gebruikersnaam</strong></FormLabel>
                                                                        </Col>
                                                                        <Col md={12} lg={8}>
                                                                            <input type={'text'} className='form-control' value={editableLoginUser.username} onChange={(e) => setEditableLoginUser(prevUser => ({...prevUser, username : e.target.value}))} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='mt-3'>
                                                                        <Col md={12} lg={4}><FormLabel><strong>Login geactiveerd</strong></FormLabel></Col>
                                                                        <Col md={12} lg={8}>
                                                                            <input type={'checkbox'} defaultChecked={editableLoginUser.enabled} onChange={(e) => {setEditableLoginUser(prevUser => ({...prevUser, enabled : e.target.checked}))}} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='mt-3'>
                                                                        <Col md={12} lg={4}><FormLabel><strong>2FA geactiveerd</strong></FormLabel></Col>
                                                                        <Col md={12} lg={8}>
                                                                            <input type={'checkbox'} defaultChecked={editableLoginUser.googleAuthenticatorEnabled} onChange={(e) => {setEditableLoginUser(prevUser => ({...prevUser, googleAuthenticatorEnabled : e.target.checked}))}} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='mt-3'>
                                                                        <Col md={12} lg={4}><FormLabel><strong>Kleurcode</strong></FormLabel></Col>
                                                                        <Col md={12} lg={8}>
                                                                            <ChromePicker color={editableLoginUser.colorCode}
                                                                                onChangeComplete={(color) => setEditableLoginUser(prevUser => ({...prevUser, colorCode : color.hex}))} />
                                                                    
                                                                        </Col>
                                                                    </Row>
                                                                </form>
                                                            ) : null}    
                                                        </>
                                                    ) : (
                                                        <>
                                                            {loginUser ? (
                                                                <>
                                                                    <strong>Type:</strong> {loginUser.userRole}<br /><br />
                                                                    <table className='table mt-3'>
                                                                        <tr>
                                                                            <th>Login beschikbaar</th>
                                                                            <td>{loginUser.enabled ? ('Ja') : ('Neen') }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Gebruikersnaam</th>
                                                                            <td>{loginUser.username}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Kleurcode</th>
                                                                            <td><span style={{width: '50px', height: '25px', display: 'inline-block', backgroundColor: loginUser.colorCode}}></span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>2FA geactiveerd</th>
                                                                            <td>{loginUser.googleAuthenticatorEnabled ? ('Ja') : ('Neen') }</td>
                                                                        </tr>
                                                                    </table>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                        
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12} className='text-right'>
                                                                {editModusContactData ? (
                                                                    <>
                                                                        <Button onClick={() => setEditModusContactData(!editModusContactData)}>Annuleren</Button>
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        <Button onClick={() => saveContactData()}>Opslaan</Button>
                                                                    </>
                                                                ) : (
                                                                    <Button onClick={() => setEditModusContactData(!editModusContactData)}>Wijzig</Button>
                                                                )}
                                                            
                                                            </Col>
                                                        </Row>
                                                        {editModusContactData ? (
                                                            <>
                                                                {editableContactUser ? (
                                                                    <form style={{width: '100%'}}>
                                                                        <div className={'col-md-12'}>
                                                                            <div className="form-group">
                                                                                <label className="form-label">
                                                                                    <h6>Primaire contactgegevens</h6>
                                                                                </label>
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-email"></i></span></div>
                                                                                    <input className="form-control" type="text" name={'primaryEmail'} value={editableContactUser.primaryEmail} onChange={onChangeContactUser} />
                                                                                </div>
                                                                                <br />
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-phone"></i></span></div>
                                                                                    <input className="form-control" type="text" name={'primaryPhone'} value={editableContactUser.primaryPhone} onChange={onChangeContactUser} />
                                                                                </div>
                                                                                <br />
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-mobile-phone"></i></span></div>
                                                                                    <input className="form-control" type="text" name={'primaryMobile'} value={editableContactUser.primaryMobile} onChange={onChangeContactUser} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={'col-md-12'}>
                                                                            <div className="form-group">
                                                                                <label className="form-label">
                                                                                    <h6>Alternatieve contactgegevens</h6>
                                                                                </label>
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-email"></i></span></div>
                                                                                    <input className="form-control" type="text" name={'alternativeEmail'} value={editableContactUser.alternativeEmail} onChange={onChangeContactUser} />
                                                                                </div>
                                                                                <br />
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-phone"></i></span></div>
                                                                                    <input className="form-control" type="text" name={'alternativePhone'} value={editableContactUser.alternativePhone} onChange={onChangeContactUser} />
                                                                                </div>
                                                                                <br />
                                                                                <div className="input-group">
                                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="icofont icofont-mobile-phone"></i></span></div>
                                                                                    <input className="form-control" type="text" name={'alternativeMobile'} value={editableContactUser.alternativeMobile} onChange={onChangeContactUser} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h2>Contactgegevens</h2>
                                                                <h5>Eerste contactgegevens</h5>
                                                                <table className='table mt-3'>
                                                                    <tr>
                                                                        <th><i className="icofont icofont-email"></i></th>
                                                                        <td>{contactUser.primaryEmail}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th><i className="icofont icofont-phone"></i></th>
                                                                        <td>{contactUser.primaryPhone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th><i className="icofont icofont-mobile-phone"></i></th>
                                                                        <td>{contactUser.primaryMobile}</td>
                                                                    </tr>
                                                                </table>
                                                                <h5>Tweede contactgegevens</h5>
                                                                <table className='table mt-3'>
                                                                    <tr>
                                                                        <th><i className="icofont icofont-email"></i></th>
                                                                        <td>{contactUser.alternativeEmail}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th><i className="icofont icofont-phone"></i></th>
                                                                        <td>{contactUser.alternativePhone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th><i className="icofont icofont-mobile-phone"></i></th>
                                                                        <td>{contactUser.alternativeMobile}</td>
                                                                    </tr>
                                                                </table>
                                                            </>
                                                        )}
                                                        
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg={8}>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={4}><h2>Algemeen</h2></Col>
                                                            <Col lg={8} className='text-right'>
                                                                {editModusGeneralInformation ? (
                                                                    <>
                                                                        <Button onClick={() => setEditModusGeneralInformation(!editModusGeneralInformation)}>Annuleren</Button>
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        <Button onClick={() => saveBaseData()}>Opslaan</Button>
                                                                    </>
                                                                ) : (
                                                                    <Button onClick={() => setEditModusGeneralInformation(!editModusGeneralInformation)}>Wijzig</Button>
                                                                )}
                                                            
                                                            </Col>
                                                        </Row>
                                                        {editModusGeneralInformation ? (
                                                            <>
                                                                {editableBaseUser ? (
                                                                    <form>
                                                                    <div className="row">
                                                                        <div className="col-sm-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Voornaam</label>
                                                                                <input className="form-control" type="text" name={'firstName'} value={editableBaseUser.firstName} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Naam</label>
                                                                                <input className="form-control" type="text" name={'lastName'} value={editableBaseUser.lastName} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Straat</label>
                                                                                <input className="form-control" type="text" name={'street'} value={editableBaseUser.street} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Huisnr</label>
                                                                                <input className="form-control" type="text" name={'houseNumber'} value={editableBaseUser.houseNumber} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Bus</label>
                                                                                <input className="form-control" type="text" name={'bus'} value={editableBaseUser.bus} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Gemeente</label>
                                                                                <input className="form-control" type="text" name={'city'} value={editableBaseUser.city} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-3">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Postal Code</label>
                                                                                <input className="form-control" type="text" name={'postalCode'} value={editableBaseUser.postalCode} onChange={onChangeBaseUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Country</label>
                                                                                {countries ? (
                                                                                    <Typeahead
                                                                                        id="project-typeahead"
                                                                                        onChange={changeBaseCountry}
                                                                                        clearButton
                                                                                        labelKey="name"
                                                                                        selected={editableBaseUser.country ? [editableBaseUser.country] : []}
                                                                                        multiple={false}
                                                                                        options={countries}
                                                                                        placeholder="Selecteer een land ..."
                                                                                    />
                                                                                ) : (
                                                                                    <p> </p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Extra informatie</label>
                                                                                <textarea className="form-control" rows="5"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p>
                                                                    {baseUser ? (
                                                                        <table className='table mt-3'>
                                                                            <tr>
                                                                                <th>Naam</th>
                                                                                <td>{baseUser.firstName + ' ' + baseUser.lastName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Adres</th>
                                                                                <td>
                                                                                    {baseUser.street + ' ' + baseUser.houseNumber + ' ' + baseUser.bus}<br />
                                                                                    {baseUser.postalCode + ' ' + baseUser.city}<br />
                                                                                    {baseUser.country != null && baseUser.country.name}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Extra informatie</th>
                                                                                <td>{baseUser.extraInformation} </td>
                                                                            </tr>
                                                                        </table>
                                                                    ) : null}
                                                                    
                                                                </p>
                                                            </>
                                                        )}
                                                        
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12} className='text-right'>
                                                                {editModusInvoiceData ? (
                                                                    <>
                                                                        <Button onClick={() => setEditModusInvoiceData(!editModusInvoiceData)}>Annuleren</Button>
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        <Button onClick={() => saveInvoicingData()}>Opslaan</Button>
                                                                    </>
                                                                    
                                                                ) : (
                                                                    <Button onClick={() => setEditModusInvoiceData(!editModusInvoiceData)}>Wijzig</Button>
                                                                )}
                                                            
                                                            </Col>
                                                        </Row>
                                                        {editModusInvoiceData ? (
                                                            <>
                                                                {editableInvoicingUser ? (
                                                                    <form>
                                                                    <div className="row">
                                                                        <div className={'col-md-12'}>
                                                                            <div className={'form-group'}>
                                                                                <label className="form-label"><input type={'checkbox'} name={'businessAccount'} defaultChecked={editableInvoicingUser.invoiceData.businessAccount} onChange={changeIsBusinessAccount}  /> Zakelijk account</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Organisatie</label>
                                                                                <input className="form-control" type="text" name={'organisationName'} value={editableInvoicingUser.invoiceData.organisationName} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">BTWnummer</label>
                                                                                <input className="form-control" type="text" name={'vatNumber'} value={editableInvoicingUser.invoiceData.vatNumber} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Straat</label>
                                                                                <input className="form-control" type="text" name={'street'} value={editableInvoicingUser.invoiceData.street} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Huisnr</label>
                                                                                <input className="form-control" type="text" name={'houseNumber'} value={editableInvoicingUser.invoiceData.houseNumber} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Bus</label>
                                                                                <input className="form-control" type="text" name={'bus'} value={editableInvoicingUser.invoiceData.bus} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Gemeente</label>
                                                                                <input className="form-control" type="text" name={'city'} value={editableInvoicingUser.invoiceData.city} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-3">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Postal Code</label>
                                                                                <input className="form-control" type="text" name={'postalCode'} value={editableInvoicingUser.invoiceData.postalCode} onChange={onChangeInvoicingUser} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Country</label>
                                                                                {countries ? (
                                                                                    <Typeahead
                                                                                        id="project-typeahead"
                                                                                        onChange={changeInvoiceCountry}
                                                                                        clearButton
                                                                                        labelKey="name"
                                                                                        selected={editableInvoicingUser.invoiceData.country ? [editableInvoicingUser.invoiceData.country] : []}
                                                                                        multiple={false}
                                                                                        options={countries}
                                                                                        placeholder="Selecteer een land ..."
                                                                                    />
                                                                                ) : (
                                                                                    <p> </p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                ) : null}    
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h2>Facturatiegegevens</h2>
                                                                {invoicingUser != null && invoicingUser.invoiceData !== null ? (
                                                                    <p>
                                                                        {invoicingUser.invoiceData.businessAccount ? 'Professioneel account' : 'Particulier'}<br />
                                                                        <br />
                                                                        {invoicingUser.invoiceData.businessAccount ? (
                                                                            <>{invoicingUser.invoiceData.organisationName + '(' + invoicingUser.invoiceData.vatNumber + ')'}<br /></>
                                                                        ) : (
                                                                            <>{invoicingUser.firstName + ' ' + invoicingUser.lastName}<br /></>
                                                                        )}
                                                                        {invoicingUser.invoiceData.street + ' ' + invoicingUser.invoiceData.houseNumber + ' ' + invoicingUser.invoiceData.bus}<br />
                                                                        {invoicingUser.invoiceData.postalCode + ' ' + invoicingUser.invoiceData.city}<br />
                                                                        {invoicingUser.invoiceData.country != null && invoicingUser.invoiceData.country.name}
                                                                    </p>
                                                                ) : (
                                                                    <p>Geen facturatiegegevens</p>
                                                                )}    
                                                            </>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}    
                                {tabValue === 1 ? (
                                    <UserProjects projects={projects}></UserProjects>
                                ) : null}
                                {tabValue === 2 ? (
                                    <UserInvoices invoices={invoices}></UserInvoices>
                                ) : null}
                                {tabValue === 3 ? (
                                    <UserTaxAttests taxCertificates={taxCertificates}></UserTaxAttests>
                                ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : null
        )
    );

}

export default UserDetail;