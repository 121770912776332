import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import _ from "lodash";
import useReactTable from '../../hooks/useReactTable';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


const RegionsOverview = () =>  {

    const [regions, setRegions] = useState([]);
    const columns = useMemo(() => [
        {
            Header : 'Beschrijving',
            accessor : 'description',
            headerClassName: 'text-left'
        },
        {
            Header : 'Beschrijving',
            accessor : 'description',
            headerClassName: 'text-left'
        },
        {
            Header : 'Postcodes',
            accessor : 'postalCodes',
            width: 100,
            Cell: row => {
                if (_.isEmpty(row.row.endTime)) {
                    return <span>(geen datum)</span>
                } else {
                    return new Intl.DateTimeFormat("nl-BE", {
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(row.row.endTime))
                }
            }
        }
    ]);
    const {getTheadThProps,getTdProps,getTrProps,getPaginationProps} = useReactTable();

    useEffect(() => {
        loadRegions();
    }, []);

    const loadRegions = () => {
        API.get('regions').then(function(response) {
            setRegions(response.data);
        });
    };

    return (
        <Fragment>
            <Breadcrumb parent="Regio's" title="Overzicht van alle regio's" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <Row>
                        <Col lg={12}>
                            <ReactTable 
                                data={regions}
                                columns={columns}
                                minRows={0}
                                className={"-striped -highlight"}
                                showPagination={true}
                                previousText={'Vorige'}
                                nextText={'Volgende'}
                                pageText={'Pagina'}
                                rowsText={'rijen'}
                                ofText={'van'}
                                getTheadThProps={getTheadThProps}
                                getTdProps={getTdProps}
                                getTrProps={getTrProps}
                                getPaginationProps={getPaginationProps}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col lg={12}>
                            <Link className={'btn btn-primary'} to={'/region/create'}>Creëer nieuwe regio</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default RegionsOverview;