import React from 'react';
import {Link} from "react-router-dom";
import API from "../../utils/API";
import ReactTable from "react-table-6";
import Breadcrumb from "../common/breadcrumb";

class InvoicesOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            columns: [
                {
                    Header: 'Factuurnummer',
                    accessor: 'invoiceNumber',
                    headerClassName: 'text-left',
                    Cell: row => {
                        return <Link to={`/invoice/detail/` + row.row._original.id}
                                     target={'_parent'}>{row.row.invoiceNumber}</Link>
                    }
                },
                {
                    Header: 'Klant',
                    accessor: 'billToAddress',
                    headerClassName: 'text-left',
                    Cell: row => {
                        return <>{row.row.billToAddress.firstName} {row.row.billToAddress.lastName}</>
                    }
                },
                {
                    Header: 'Project',
                    accessor: 'project',
                    headerClassName: 'text-left',
                    Cell: row => {
                        return <>{row.row.project != null ? row.row.project.name : ''}</>
                    }
                },
                {
                    Header: 'Factuurdatum',
                    accessor: 'creationDate',
                    headerClassName: 'text-left',
                    Cell: row => {
                        return new Intl.DateTimeFormat("nl-BE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(new Date(row.row.creationDate))
                    }
                },
                {
                    Header: 'Vervaldatum',
                    accessor: 'expiryDate',
                    headerClassName: 'text-left',
                    Cell: row => {
                        let isExpired = new Date(row.row.expiryDate) < new Date() && !row.row.paid;
                        return (
                            isExpired ? (
                                <span style={{
                                    backgroundColor: "#cc2200",
                                    color: '#ffffff',
                                    fontWeight: '300',
                                    borderRadius: '10px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px'
                                }}>
                                    {new Intl.DateTimeFormat("nl-BE", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit"
                                    }).format(new Date(row.row.expiryDate))}
                                </span>
                            ) : (
                                <span>
                                    {new Intl.DateTimeFormat("nl-BE", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit"
                                    }).format(new Date(row.row.expiryDate))}
                                </span>
                            )

                        )
                    }
                },
                {
                Header : 'Bedrag',
                    accessor : 'totalIncl',
                    headerClassName : 'text-left',
                    Cell: row => {
                        return new Intl.NumberFormat("nl-BE", {style: 'currency', currency: 'EUR'}).format(new Date(row.row.totalIncl))
                    }
                },
                {
                    Header : 'Betaald',
                    accessor : 'paid',
                    headerClassName : 'text-center',
                    Cell: row => {
                        return row.row.paid ? <strong><i className="icofont icofont-tick-mark"></i></strong> : <strong><i className="icofont icofont-close"></i></strong>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link><i className="icofont icofont-2x icofont-email"></i></Link>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return row.row._original.paid ? (
                            <Link onClick={(event) => this.revertPayInvoice(event, row.row.id)}><i className="icofont icofont-2x icofont-undo"></i></Link>
                        ) : (
                            <Link onClick={(event) => this.payInvoice(event, row.row.id)}><i className="icofont icofont-2x icofont-money"></i></Link>
                        )
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link onClick={(event) => this.downloadPdf(event, row.row.id, row.row._original.invoiceNumber)}><i className="icofont icofont-2x icofont-download"></i></Link>
                    }
                },
                {
                    Header: '',
                    accessor: 'id',
                    width: 75,
                    headerClassName: 'text-center',
                    Cell: row => {
                        return <Link to={`/invoice/` + row.row._original.id + `/creditnote`}><i className="icofont icofont-2x icofont-paper"></i></Link>
                    }
                }
            ]
        };
    }

    componentDidMount() {
        let me = this;
        API.get('invoices').then(function(response) {
            me.setState({invoices: response.data});
        });
    }

    payInvoice = (event, invoiceId) => {
        event.preventDefault();
        let me = this;
        API.put('invoice/' + invoiceId + '/pay').then(function() {
            API.get('invoices').then(function(response) {
                me.setState({invoices: response.data});
            });
        });
    }

    downloadPdf = (event, invoiceId, invoiceNumber) => {
        event.preventDefault();
        API.get('/pdf/invoice/' + invoiceId).then(function(response) {
            const linkId = 'link-invoice-id-' + invoiceId;
            const link = document.createElement("a");
            link.id = linkId;
            link.innerHTML = "Download PDF file";
            link.download = `${invoiceNumber}.pdf`;
            link.href = "data:application/octet-stream;base64," + response.data.base64;
            document.body.appendChild(link);
            document.getElementById(linkId).click();
        });
        return false;
    };

    revertPayInvoice = (event, invoiceId) => {
    event.preventDefault();
    let me = this;
    API.put('invoice/' + invoiceId + '/revertpay').then(function() {
            API.get('invoices').then(function(response) {
                me.setState({invoices: response.data});
            });
        });
    }

    render() {
        return (
            <>
                <Breadcrumb parent="Facturen" title="Overzicht van facturen" />
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row">
                           <div className="col-lg-12">
                               <ReactTable onChange={this.componentDidMount}
                                   data={this.state.invoices}
                                   columns={this.state.columns}
                                   defaultPageSize={10}
                                   className={"-striped -highlight"}
                                   showPagination={true}
                                   previousText={'Vorige'}
                                   nextText={'Volgende'}
                                   pageText={'Pagina'}
                                   rowsText={'rijen'}
                                   ofText={'van'}
                                   getTheadThProps={(state, rowInfo, column) => ({
                                       style: {
                                           fontWeight: '600',
    height: '50px'
    },
    })}
    getTdProps=
        {
            (state, rowInfo, column) => ({
                style: {
                    height: '40px',
                },
            })
        }
    />
    </div>
    </div>
    </div>
    </div>
    </>
    );
    }
    };

    export default InvoicesOverview;