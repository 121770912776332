import React, { Fragment, useEffect, useRef, useState } from 'react';
import '../../assets/css/reacttable_pouillon.css';
import Breadcrumb from '../common/breadcrumb';
import API from "../../utils/API";
import ReactTable from "react-table-6";
import {Typeahead} from "react-bootstrap-typeahead";
import _ from "lodash";
import CKEditors from "react-ckeditor-component";
import SweetAlert from "react-bootstrap-sweetalert";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import Loader from "../common/loader";
import {Link, useLocation} from "react-router-dom";
import EvaluationCard from './evaluationCard';
import TaskWorkOrderInformation from './taskWorkOrderInformation';
import TaskProjectInformation from './taskProjectInformation';
import TaskCustomerInformation from './taskCustomerInformation';
import Select from 'react-select';
import EvaluationReportModal from './evaluationReportModal';


const TaskDetail = (props) =>  {

    const ref = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [task, setTask] = useState(null);
    const [editableTask, setEditableTask] = useState(null);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState(null);
    const [timesheetItems, setTimesheetItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const [editView, setEditView] = useState(false);
    const [showAddNewTimesheetItem, setShowAddNewTimesheetItem] = useState(false);

    const [timesheetDescription, setTimesheetDescription] = useState("");
    const [timesheetDay, setTimesheetDay] = useState("");
    const [timesheetStartHour, setTimesheetStartHour] = useState("");
    const [timesheetEndHour, setTimesheetEndHour] = useState("");

    const [taskExecutors, setTaskExecutors] = useState([]);
    const [taskResponsibles, setResponsibles] = useState([]);


    const [showEvaluationReport, setShowEvaluationReport] = useState(false);

    useEffect(() => {
        let id = props.match.params.id;
        API.get('users/workers').then(function(response) {
            setUsers(response.data);
        });

        API.get('projects').then(function(response) {
            setProjects(response.data);
        });

        API.get('myuser').then(function(response) {
            setCurrentUser(response.data[0]);
        });

        API.get('task/' + id).then(function(response) {
            setTask(response.data);
        });

        API.get('task/' + id + '/timesheetItems').then(function(response) {
           setTimesheetItems(response.data);
        });

        setColumns([
            {
                Header : 'Beschrijving',
                headerClassName: 'text-left',
                accessor : 'description'
            },
                {
                    Header : 'Dag',
                    headerClassName: 'text-left',
                    accessor : 'startTime',
                    Cell: row => {
                        if (_.isEmpty(row.row.startTime)) {
                            return <span>(geen datum)</span>
                        } else {
                            return new Intl.DateTimeFormat("nl-BE", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit"
                            }).format(new Date(row.row.startTime))
                        }
                    }
                },
            {
                Header : 'Starttijd',
                headerClassName: 'text-left',
                accessor : 'startTime',
                Cell: row => {
                    if (_.isEmpty(row.row.startTime)) {
                        return <span>(geen datum)</span>
                    } else {
                        return new Intl.DateTimeFormat("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit"
                        }).format(new Date(row.row.startTime))
                    }
                }
            },
            {
                Header : 'Eindtijd',
                headerClassName: 'text-left',
                accessor : 'endTime',
                Cell: row => {
                    if (_.isEmpty(row.row.endTime)) {
                        return <span>(geen datum)</span>
                    } else {
                        return new Intl.DateTimeFormat("nl-BE", {
                            hour: "2-digit",
                            minute: "2-digit"
                        }).format(new Date(row.row.endTime))
                    }
                }
            },
            {
                Header : 'Uitvoerder',
                headerClassName: 'text-left',
                accessor : 'user',
                Cell: row => {
                    if (row.row.user !== undefined && row.row.user !== null) {
                        return <span>{row.row.user.firstName} {row.row.user.lastName}</span>
                    } else {
                        return <span></span>
                    }
                }
            }
        ]);
    }, []);

    useEffect(() => {
        if (task != null) {
            console.log('task is changed');
            let tmpEditableTask = {...task};
            tmpEditableTask.plannedDateForExecution = new Intl.DateTimeFormat("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }).format(new Date(task.plannedDateForExecution));
            tmpEditableTask.plannedTimeForExecution = new Intl.DateTimeFormat("en", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            }).format(new Date(task.plannedDateForExecution));
            tmpEditableTask.plannedEndDateForExecution = new Intl.DateTimeFormat("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }).format(new Date(task.plannedEndDateForExecution));
            tmpEditableTask.plannedEndTimeForExecution = new Intl.DateTimeFormat("en", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            }).format(new Date(task.plannedEndDateForExecution));
            if (task.accountableUser !== null && task.accountableUser !== undefined) {
                tmpEditableTask.accountableUser = task.accountableUser.id;
            }
            if (task.responsibleUser !== null && task.responsibleUser !== undefined) {
                tmpEditableTask.responsibleUser = task.responsibleUser.id;
            }
            if (task.responsibleUsers !== null && task.responsibleUsers !== undefined) {
                tmpEditableTask.responsibleUserForSelect = [];
                task.responsibleUsers.map((responsibleUser) => tmpEditableTask.responsibleUserForSelect.push({value: responsibleUser.id, label: responsibleUser.firstName + ' ' + responsibleUser.lastName}));
            }

            setEditableTask(tmpEditableTask);

            setIsLoading(false);
        }
        
    }, [task]);

    useEffect(() => {
        if (users.length > 0) {
            let executorsArray = [];
            let reponsiblesArray = [];
            for (let userKey in users) {
                executorsArray.push({value: users[userKey].id, label: users[userKey].firstName + " " + users[userKey].lastName});
                reponsiblesArray.push({value: users[userKey].id, label: users[userKey].firstName + " " + users[userKey].lastName});
            }
            setTaskExecutors(executorsArray);
            setResponsibles(reponsiblesArray);    
        }
    }, [users]);

    const openEditView = () => {
        setEditView(true);
    };

    const closeEditView = () => {
        setEditView(false);
    };

    const changeTaskName = ({target : {value}}) => {
        setEditableTask({...editableTask, 'name' : value});
    };

    const changeTaskDescription = (event) => {
        let descriptionValue = event.editor.getData();
        if (descriptionValue === "" || descriptionValue === null) {
            this.setState((prevState) => ({errorsOfTaskForm: { ...prevState.errorsOfTaskForm, description : true }}));
        }
        setEditableTask({...editableTask, 'description' : descriptionValue});
    };

    const changeTaskProject = (selectedArray) => {
        let newProject = selectedArray[0];
        setEditableTask({...editableTask, project: newProject});
        setEditableTask({...editableTask, workOrder: null});
    };

    const changeTaskWorkOrder = (selectedArray) => {
        let newWorkOrder = selectedArray[0];
        setEditableTask({...editableTask, workOrder : newWorkOrder });
    };

    const changeTaskStatus = (selectedArray) => {
        if (selectedArray.length > 0) {
            let newStatus = selectedArray[0];
            setEditableTask({...editableTask, status : newStatus.label });
        } else {
            setEditableTask({...editableTask, status : "" });
        }
    };

    const changeTaskResponsibleUser = (choice) => {
        let arr2 = [];
        for (let key in choice) {
            arr2.push({value: choice[key].value, label: choice[key].label});
        }
        setEditableTask({...editableTask, responsibleUserForSelect : arr2})

    };

    const changeTaskAccountableUser = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, accountableUser : value });
    };

    const changeTaskEstimatedHoursWork = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, estimatedHoursWork : value });
    };

    const changeTaskEstimatedDaysWork = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, estimatedDaysWork : value });
    };

    const changePlannedDayOfExecution = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, plannedDateForExecution : value });
    };

    const changePlannedTimeOfExecution = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, plannedTimeForExecution : value });
    };

    const changePlannedEndDayOfExecution = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, plannedEndDateForExecution : value });
    };

    const changePlannedEndTimeOfExecution = (event) => {
        let value = event.target.value;
        setEditableTask({ ...editableTask, plannedEndTimeForExecution : value });
    };


    const startTask = (event) => {
        event.preventDefault();
        let id = props.match.params.id;
        API.put('/task/' + id + '/state/changetobusy').then(function(response) {
            setTask(response.data);
        });
    };

    const stopTask = (event) => {
        event.preventDefault();
        let id = props.match.params.id;
        API.put('/task/' + id + '/state/changetodone').then(function(response) {
            setTask(response.data);
        });
    };

    const pauseTask = (event) => {
        event.preventDefault();
        let id = props.match.params.id;
        API.put('/task/' + id + '/state/changetopause').then(function(response) {
            setTask(response.data);
        });
    };

    const openSegmentToAddTimesheetItem = () => {
        setShowAddNewTimesheetItem(true);
    };

    const cancelAddNewTimesheetItem = () => {
        setShowAddNewTimesheetItem(false);
    };

    const updateTask = (event) => {
        event.preventDefault();
        let id = props.match.params.id;
        let responsibleUserIds = [];
        editableTask.responsibleUserForSelect.map((selectedResponsibleUser) => responsibleUserIds.push(selectedResponsibleUser.value));
        
        
        API.put('task/' + id, {
            'name' : editableTask.name,
            'description' : editableTask.description,
            'responsibleUserIds' : responsibleUserIds,
            'accountableUserId' : editableTask.accountableUser,
            'projectId' :  editableTask.project != null ? editableTask.project.id : null,
            'workOrderId' : editableTask.workOrder != null ? editableTask.workOrder.id : null,
            'plannedDateForExecution' : editableTask.plannedDateForExecution + ' ' + editableTask.plannedTimeForExecution,
            'plannedEndDateForExecution' : editableTask.plannedEndDateForExecution + ' ' + editableTask.plannedEndTimeForExecution,
            'status' : editableTask.status
        }).then(function() {
            API.get('task/' + id).then(function(response) {
                setTask(response.data);
                setEditView(false);
            });
        });
    };

    const addTimesheetItem = () => {
        let id = props.match.params.id;
        API.post('/task/' + id + '/timesheetitem', {
           'description' : timesheetDescription,
           'day' : timesheetDay,
           'startHour' : timesheetStartHour,
           'endHour' :  timesheetEndHour
        }).then(function() {
            API.get('task/' + id + '/timesheetItems').then(function(response) {
                setTimesheetItems(response.data);
                setShowAddNewTimesheetItem(false);
                setTimesheetDescription(null);
                setTimesheetDay(null);
                setTimesheetStartHour(null);
                setTimesheetEndHour(null);
            });
        });
    };

    const closeEvaluationReportModalAndReloadTask = () => {
        setIsLoading(true);
        setShowEvaluationReport(false);
    };

    const reloadTask = () => {
        API.get('task/' + props.match.params.id).then(function(response) {
            setTask(response.data);
        });
    };

    return (
        <>
            {isLoading || task == null ? (
                <Loader />
            ) : (
                <>
                    <Breadcrumb parent={'Project ' + task.project.name} parentLink={'/project/detail/' + task.project.id}>
                        {!_.isEmpty(task) ? (
                            <>
                                {task.workOrder != null ? (
                                    <Link to={'/workOrder/detail/' + task.workOrder.id} ></Link>
                                ) : null}
                                <span>
                                    {task.name}
                                </span>
                            </>
                            
                        ) : null}  
                    </Breadcrumb>
                    <div className="container-fluid">
                {currentUser && (currentUser.userRole === 'ADMIN' || currentUser.userRole === 'EMPLOYER' || currentUser.userRole === 'EMPLOYEE' ? (
                    <>
                        
                            <EvaluationReportModal showEvaluationReport={showEvaluationReport} taskName={task.name} taskId={task.id} close={closeEvaluationReportModalAndReloadTask} updateTask={reloadTask}></EvaluationReportModal>
                    
                        {(currentUser.userRole === 'ADMIN' || currentUser.userRole === 'EMPLOYER') && editView ? (
                            <>
                                <Row>
                                    <Col lg={12}>                 
                                        <div className={'row product-page-main mt-3'} style={{width: '100%'}}>
                                            <Col lg={12}>
                                            <Card>
                                                <CardBody>
                                                <form style={{width: '100%'}} >
                                                <div className={'row'}>
                                                    <div className={'col-xl-10'}>
                                                        <h6><strong>Algemene informatie</strong></h6>
                                                    </div>
                                                    <Col xl={2} className='text-right'>
                                                        <Button onClick={closeEditView} className='btn btn-primary'><i className='fa fa-pencil'></i>  Annuleren</Button>
                                                    </Col>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Naam:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                <input type={'text'} className={'form-control'} value={editableTask.name} onChange={changeTaskName} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Startuur:
                                                            </label>
                                                            <div className={'col-xl-8'}>
                                                                <input type={'date'} className={'form-control digits'} value={editableTask.plannedDateForExecution} onChange={changePlannedDayOfExecution} />
                                                            </div>
                                                            <div className={'col-xl-4'}>
                                                                <input type={'time'} className={'form-control digits'} value={editableTask.plannedTimeForExecution} onChange={changePlannedTimeOfExecution} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Einduur:
                                                            </label>
                                                            <div className={'col-xl-8'}>
                                                                <input type={'date'} className={'form-control digits'} value={editableTask.plannedEndDateForExecution} onChange={changePlannedEndDayOfExecution} />
                                                            </div>
                                                            <div className={'col-xl-4'}>
                                                                <input type={'time'} className={'form-control digits'} value={editableTask.plannedEndTimeForExecution} onChange={changePlannedEndTimeOfExecution} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Uitvoerder:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                            <Select 
                                                                value={editableTask.responsibleUserForSelect} 
                                                                options={taskExecutors} 
                                                                isMulti 
                                                                onChange={(choice) => changeTaskResponsibleUser(choice)}
                                                                menuPortalTarget={document.form}
                                                                menuPosition={'fixed'}
                                                                menuPlacement={'bottom'}
                                                                menuShouldScrollIntoView={false}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Eindverantwoordelijke:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                {users && editableTask ? (
                                                                    <select className={'form-control'} value={editableTask.accountableUser} onChange={(event) => changeTaskAccountableUser(event)}>
                                                                        <option value={""}>---</option>
                                                                        {users.map((user, index) => {
                                                                            return <option value={user.id} key={index}>{user.firstName} {user.lastName} ({user.email})</option>;
                                                                        })}
                                                                    </select>
                                                                ) : (
                                                                    <p></p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Project:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                {projects && editableTask ? (
                                                                    <Typeahead
                                                                        id="project-typeahead"
                                                                        onChange={changeTaskProject}
                                                                        clearButton
                                                                        labelKey="name"
                                                                        selected={editableTask.project ? [editableTask.project] : []}
                                                                        multiple={false}
                                                                        options={projects}
                                                                        placeholder="Selecteer een project ..."
                                                                    />
                                                                ) : (
                                                                    <p> </p>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col-xl-6'}>
                                                        <div className={'form-group row'}>
                                                            <label className={'col-xl-12'}>
                                                                Werkbon:
                                                            </label>
                                                            <div className={'col-xl-12'}>
                                                                {editableTask && editableTask.project ? (
                                                                    <Typeahead
                                                                        id="project-typeahead"
                                                                        onChange={changeTaskWorkOrder}
                                                                        clearButton
                                                                        labelKey="name"
                                                                        selected={editableTask.workOrder ? [editableTask.workOrder] : []}
                                                                        multiple={false}
                                                                        options={editableTask.project ? editableTask.project.workOrders : []}
                                                                        placeholder="Selecteer een werkbon ..."
                                                                    />
                                                                ) : (
                                                                    <p> </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Status:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <Typeahead
                                                            id="status-typeahead"
                                                            name="status"
                                                            onChange={changeTaskStatus}
                                                            clearButton
                                                            labelKey="label"
                                                            selected={[editableTask.status]}
                                                            multiple={false}
                                                            options={[{"label": "NEW"}, {"label": "BUSY"}, {"label": "PAUSE"}, {"label": "DONE"}]}
                                                            placeholder="Selecteer de status ..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'form-group row'}>
                                                    <label className={'col-xl-12'}>
                                                        Beschrijving:
                                                    </label>
                                                    <div className={'col-xl-12'}>
                                                        <CKEditors
                                                            activeclassName="p10"
                                                            content={editableTask.description}
                                                            events={{
                                                                "change": changeTaskDescription
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={'form-group row'}>
                                                    <div className={'col-xl-12'}>
                                                        <button className={'btn btn-primary'} onClick={(e) => updateTask(e)}>Taak wijzigen</button>
                                                    </div>
                                                </div>
                                            </form>
                                                </CardBody>
                                            </Card>
                                            </Col>
                                            </div>
                                            </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <Row className={'mt-3'}>
                                                <Col lg={12} sm={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <div className={'row'}>
                                                                <div className={'col-xl-10'}>
                                                                    <h6><strong>Algemene informatie</strong></h6>
                                                                </div>
                                                                <Col xl={2} className='text-right'>
                                                                    <Button onClick={openEditView} className='btn btn-primary'><i className='fa fa-pencil'></i>  Wijzigen</Button>
                                                                </Col>
                                                            </div>
                                                            <div className={'row mt-3'}>
                                                                <div className={'col-xl-6'}>
                                                                    <h6><strong>Naam</strong></h6>
                                                                    <p>
                                                                        {task.name}
                                                                    </p>
                                                                </div>
                                                                <div className={'col-xl-6'}>
                                                                    <h6><strong>Status</strong></h6>
                                                                    <p>
                                                                        {task.status}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className={'row mt-3'}>
                                                                <div className={'col-xl-12'}>
                                                                    <h6><strong>Beschrijving</strong></h6>
                                                                    <p>
                                                                        {task.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className={'row mt-3'}>
                                                                <div className={'col-xl-6'}>
                                                                    <h6><strong>Uitvoerder</strong></h6>
                                                                    <p>
                                                                        {task.responsibleUsers.map((responsibleUser) => <>
                                                                                {responsibleUser.firstName} {responsibleUser.lastName}
                                                                            </>)}
                                                                    </p>
                                                                </div>
                                                                <div className={'col-xl-6'}>
                                                                    <h6><strong>Eindverantwoordelijke</strong></h6>
                                                                    <p>
                                                                        {task.accountableUser ? (
                                                                            <>
                                                                                {task.accountableUser.firstName} {task.accountableUser.lastName}
                                                                            </>
                                                                        ) : (
                                                                            <span>Geen eindverantwoordelijk ingegeven</span>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className={'row mt-3'}>
                                                                <div className={'col-xl-6'}>
                                                                    <h6><strong>Geplande startdatum van uitvoering</strong></h6>
                                                                    <p>
                                                                        {new Intl.DateTimeFormat("nl-BE", {
                                                                            year: "numeric",
                                                                            month: "long",
                                                                            day: "2-digit",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit"
                                                                        }).format(new Date(task.plannedDateForExecution))}
                                                                    </p>
                                                                </div>
                                                                <div className={'col-xl-6'}>
                                                                    <h6><strong>Geplande einddatum van uitvoering</strong></h6>
                                                                    <p>
                                                                        {new Intl.DateTimeFormat("nl-BE", {
                                                                            year: "numeric",
                                                                            month: "long",
                                                                            day: "2-digit",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit"
                                                                        }).format(new Date(task.plannedEndDateForExecution))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <Row className={'mt-5'}>
                                                                <Col lg={12}>
                                                                    {task.status === 'NEW' ? (
                                                                        <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => startTask(event)}>Start taak</a>
                                                                    ) : null}
                                                                    {task.status === 'BUSY' ? (
                                                                        <>
                                                                            <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => pauseTask(event)}>Pauzeer taak</a>
                                                                            <Button className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={() => setShowEvaluationReport(true)}>Evalueer taak</Button>
                                                                        </>
                                                                    ) : null}
                                                                    {task.status === 'EVALUATED' ? (
                                                                        <>
                                                                            <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => stopTask(event)}>Stop taak</a>
                                                                        </>
                                                                    ) : null}
                                                                    {task.status === 'PAUSE' ? (
                                                                        <>
                                                                            <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => startTask(event)}>Start taak</a>
                                                                            <a href={'#'} className={'btn btn-primary pt-3 pb-3 mr-2'} onClick={(event) => stopTask(event)}>Stop taak</a>
                                                                        </>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>

                                                </Col>
                                            </Row>

                                            
                                        </>
                                    )}


                                    {currentUser.userRole === 'ADMIN' || currentUser.userRole === 'EMPLOYER'  || currentUser.userRole === 'EMPLOYEE' ? (
                                        <>
                                            <Row>
                                                <Col lg={4}>
                                                    <TaskCustomerInformation task={task}></TaskCustomerInformation>
                                                </Col>
                                                <Col lg={4}>
                                                    <TaskProjectInformation task={task}></TaskProjectInformation>
                                                </Col>
                                                <Col lg={4}>
                                                    <TaskWorkOrderInformation task={task}></TaskWorkOrderInformation>
                                                </Col>
                                            </Row>
                                            <EvaluationCard task={task}></EvaluationCard>
                                        </>
                                    ) : null}

                                    <Card>
                                        <CardBody>
                                            <SweetAlert
                                                show={showAddNewTimesheetItem}
                                                showCloseButton={true}
                                                onCancel={() => cancelAddNewTimesheetItem()}
                                                info
                                                title="Prestatie toevoegen"
                                                onConfirm={() => addTimesheetItem()}
                                                confirmBtnText={'Prestatie toevoegen'}
                                                cancelBtnText={'Annuleren'}
                                                style={{width: '90%'}}
                                            >
                                                
                                                <form className={'form'}>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Beschrijving</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" onChange={(e) => setTimesheetDescription(e.target.value)} type="text" value={timesheetDescription} /></div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Dag</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control digits" onChange={(e) => setTimesheetDay(e.target.value)} type="date" value={timesheetDay} /></div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Startuur</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control digits" onChange={(e) => setTimesheetStartHour(e.target.value)} type="time" value={timesheetStartHour} /></div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Einduur</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control digits" onChange={(e) => setTimesheetEndHour(e.target.value)} type="time" value={timesheetEndHour} /></div>
                                                    </div>
                                                </form>
                                                

                                                
                                            </SweetAlert>

                                            <div className={'col-xl-12'}>
                                                <h4><strong>Gepresteerde uren</strong> <a onClick={openSegmentToAddTimesheetItem}><span className={'fa fa-plus'}></span></a></h4>
                                            </div>
                                            <br />
                                            <div className={'col-xl-12'}>
                                                <ReactTable 
                                                            data={timesheetItems}
                                                            columns={columns}
                                                            defaultPageSize={10}
                                                            className={"-striped -highlight"}
                                                            showPagination={true}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                            </>
                            
                        ) : (
                            <Row>
                                <Col lg={12}>
                                    U heeft onvoldoende rechten om deze pagina te bekijken. Indien je wel de nodige rechten zou moeten hebben, gelieve je systeembeheerder te contacteren.
                                </Col>
                            </Row>
                        )) }
                    </div>
                </>
                
            )}
        </>
    );
}




export default TaskDetail;