import React, { useEffect, useState } from 'react';

import _ from "lodash";
import { Row, Col, Card, CardBody } from 'reactstrap';

import API from '../../utils/API';

const PlanningSideBarMenu = () =>  {

    const [projectBoards, setProjectBoards] = useState([]);

    useEffect(() => {

        API.get('projecttaskboards').then(function(response) {
            setProjectBoards(response.data);
        });

    }, []);


    return (
        <Card>
            <CardBody>
                <Row className='form-group'>
                    <Col lg={12}>
                        <a href='/planning' className='btn btn-primary form-control'><br /><i className='fa fa-3x fa-user'></i><br />Taken<br /><br /></a>
                    </Col>
                </Row>
                {
                    projectBoards.map((projectBoard) => {
                        return (
                            <Row className='form-group'>
                                <Col lg={12}>
                                    <a href={'projectstatusses?taskboardId=' + projectBoard.id} className='btn btn-primary form-control'><br /><i className='icofont fa-3x icofont-clip-board'></i><br />{projectBoard.name}<br /><br /></a>
                                </Col>
                            </Row>)
                    })
                }
            </CardBody>
        </Card>                
    );
};

export default PlanningSideBarMenu;