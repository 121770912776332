import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
 
const ProjectStatusSortableItem = ({id, value, onRemoveItem}) => {
  return (
        <li style={{padding: '10px', margin: '2px', minHeight: '40px', backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR }}>
            <div style={{float:'left'}}>
                    {value}
            </div>
            <div style={{textAlign: 'right', float: 'right'}}>
                <a href='#' onClick={() => onRemoveItem(id)} style={{padding: '10px', margin: '2px', backgroundColor: process.env.REACT_APP_INFO_MESSAGE_BACKGROUND_COLOR, color: process.env.REACT_APP_INFO_MESSAGE_COLOR }}>
                    <span className='icon icon-trash' ></span>
                </a>
            </div>
        </li>
    );
}
 
export default SortableElement(ProjectStatusSortableItem);